import {
  Badge,
  Box,
  Heading,
  HStack,
  VStack,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CopyButton, DateDialog, Dot, QuickActions } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiAddLine } from "react-icons/ri";
import {
  useGetUserByIdQuery,
  useToggleUserIsActiveMutation,
  useToggleUserUserTypeMutation,
} from "graphql/queries/generated/queries";

const UsersViewPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();
  const { userId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetUserByIdQuery({
    variables: { id: userId ?? "" },
  });

  const [
    toggleIsActiveUser,
    {
      data: toggleIsActiveUserData,
      loading: toggleIsActiveUserLoading,
      error: toggleIsActiveUserError,
    },
  ] = useToggleUserIsActiveMutation();

  const [
    toggleUserUserType,
    {
      data: toggleUserUserTypedata,
      loading: toggleUserUserTypeLoading,
      error: toggleUserUserTypeError,
    },
  ] = useToggleUserUserTypeMutation();

  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  if (!data || !data.user) return null;

  const {
    user: {
      isActive,
      userType,
      country,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      dateJoined,
      email,
      language,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      lastLogin,
      name,
      phone,
      userPermissions,
      id,
    },
  } = data;

  const onDelete = async () => {
    await toggleIsActiveUser({
      variables: {
        input: {
          id: userId,
        },
      },
    });

    await refetch();
  };

  const onToggleUserType = async ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    await toggleUserUserType({
      variables: {
        input: {
          id: userId,
          expiration: endDate,
        },
      },
    });

    await refetch();
  };

  const QUICK_ACTIONS = [
    {
      name: userType === "INTERNAL" ? t("Set user as guest") : t("Set user as internal"),
      action: userType === "INTERNAL" ? onOpen : onToggleUserType,
      icon: <RiAddLine />,
      intent: "outline",
    },
    {
      name: isActive ? t("Disable user") : t("Enable user"),
      action: () => onDelete(),
      icon: <RiAddLine />,
      intent: isActive ? "danger" : "outline",
    },
    {
      name: t("Edit user"),
      href: `/o/users/edit/${id}`,
      icon: <BiEdit />,
    },
  ];

  return (
    <>
      <Box bgColor="white" p={8} rounded="lg" shadow="md">
        <Skeleton isLoaded={!loading}>
          <HStack justifyContent="space-between" alignItems="flex-start">
            <VStack alignItems="flex-start" mb={8}>
              <Heading>{name}</Heading>
              <Text fontSize="lg" variant="muted">
                #{userId ?? ""} <CopyButton value={userId ?? ""} />
              </Text>
            </VStack>
            <QuickActions noTitle actions={QUICK_ACTIONS} />
          </HStack>

          <HStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>User Type</Trans>
              </Text>
              <Badge
                variant="subtle"
                colorScheme={userType === "INTERNAL" ? "green" : "purple"}
                mr={4}
              >
                {userType}
              </Badge>
            </VStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Is active</Trans>
              </Text>
              <Text>
                <Dot status={isActive} />
              </Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Last login</Trans>
              </Text>
              <Text>{dateJoined}</Text>
            </VStack>
          </HStack>

          <HStack w="100%" my={4}>
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Email</Trans>
              </Text>
              <Text>{email}</Text>
            </VStack>
            {/* <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Preferred language</Trans>
          </Text>
          <Text>{language}</Text>
        </VStack> */}
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Country</Trans>
              </Text>
              <Text>{country?.code}</Text>
            </VStack>
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Phone</Trans>
              </Text>
              <Text>{phone}</Text>
            </VStack>
          </HStack>
        </Skeleton>
      </Box>
      <DateDialog
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        title="Set user as guest"
        message="Set an expiration date to mark user as guest"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onToggleUserType}
      />
    </>
  );
};

export default UsersViewPage;
