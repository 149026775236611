/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */

import { Trans, useTranslation } from "react-i18next";
import { Box, Button, ButtonGroup, HStack, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal, Stack, StackDivider, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AssetPresentValueInputSchema } from 'graphql/queries/generated/validation-schema';
import { FormInputHook, MoneyInputHook } from "components/ui";
import { useState, useEffect } from "react";
import  cleanObject from 'helpers/cleanObject';
import { AssetPresentValueInput, useDeleteAssetPresentValueMutation, useUpdateAssetPresentValueMutation, useCreateAssetPresentValueMutation, useGetAssetPresentValuesByAssetIdLazyQuery} from "graphql/queries/generated/queries";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import ASSET_COSTANTS from 'constants/asset';
import { ValidationProvider } from 'components/form/ValidationContext';









interface AssetPresentValueFormProps {
    assetId: string;
    // children: any;
}

const AssetPresentValueForm = ({ assetId }: AssetPresentValueFormProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const methods = useForm<AssetPresentValueInput>({
        defaultValues: {
            amount: {
                amount: 0,
                currency: "EUR",
            },
            authorId: "",
            estimateDate: "",
            estimateReason: "",
            fileTitle: "",
            file: "",
            notes: "",
            assetId
        },
        resolver: yupResolver(AssetPresentValueInputSchema()),
        mode: "onChange",
    })

    const { setValue } = methods;

    const [selectedId, setSelectedId] = useState(null);

    const toast = useToast();

    const { t } = useTranslation();

    const onEditModalOpen = (
            id: string,
            amount: {
                amount: number,
                currency:  string
            },
            author: {
                id: string,
                fullName: string,
            },
            estimateDate: string,
            estimateReason: string,
            fileTitle: string,
            file: string,
            notes: string
        ) => {
        setSelectedId(id);
        setValue("amount", amount);
        setValue("authorId", author.id);
        setValue("estimateDate", estimateDate);
        setValue("estimateReason", estimateReason ? estimateReason.toLowerCase() : '');
        setValue("fileTitle", fileTitle);
        setValue("file", file);
        setValue("notes", notes);
        onOpen();
    };

    const [loadAssetPresentValues, refetch] = useGetAssetPresentValuesByAssetIdLazyQuery();

    const [ deleteAssetPresentValue, {
        data: deleteAssetPresentValueData,
        error: deleteAssetPresentValueError,
        loading: deleteAssetPresentValueLoading }
    ] = useDeleteAssetPresentValueMutation();

    const [ assetPresentValues, setAssetPresentValues ] = useState<Array<{
        id: string,
        amount: {
            amount: number,
            currency: string
        },
        author: {
            id: string,
            fullName: string
        },
        estimateDate: string,
        estimateReason: string,
        fileTitle: string,
        file: string,
        notes: string
        assetId: string
    }>>([]);

    // eslint-disable-next-line consistent-return
    const loadParsedAssetPresentValues = async () => {
        const {
        data: assetPresentValues,
        loading: assetPresentValuesLoading,
        error: assetPresentValuesError
        } = await loadAssetPresentValues({
        variables: {
            assetId
        }
        });

        if (!assetPresentValues || !assetPresentValues.allAssetPresentValues) return null;
        const {
            allAssetPresentValues: { edges },
        } = assetPresentValues;

        setAssetPresentValues(edges.flatMap(( { node } ) => ({
            id: node.id,
            amount: {
                amount: node.amount.amount,
                currency: node.amount.currency.code
            },
            author: {
                id: node?.author?.id,
                fullName: node?.author?.fullName,
            },
            estimateDate: node.estimateDate,
            estimateReason: node.estimateReason ? node.estimateReason.toLowerCase() : '',
            fileTitle: node.fileTitle,
            file: node.file,
            notes: node.notes,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            assetId,
        })));
        return assetPresentValues;
    }

    const onDelete = async (id: string) => {
        await deleteAssetPresentValue({
          variables: {
            input: {
              id,
            },
          },
        });

        const assetPresentValues = await loadParsedAssetPresentValues();
        return assetPresentValues;
    };


    const [
        createAssetPresentValue,
        { data: createAssetPresentValueData, loading: createAssetPresentValueLoading, error: createAssetPresentValueError },
      ] = useCreateAssetPresentValueMutation();

    const [
        updateAssetPresentValue, {
            data: updateAssetPresentValueData, loading: updateAssetPresentValueLoading, error: updateAssetPresentValueError
        }

    ] = useUpdateAssetPresentValueMutation();


    const findLabelByValue = (value: string, array: { value: string, label: string}[]) => {
        const obj = array.find(x => x.value === value);
        return obj? obj.label : "";
    }


    useEffect( () => {
         // eslint-disable-next-line @typescript-eslint/no-floating-promises
         loadParsedAssetPresentValues();
         return undefined;
    } , []);
    // useEffect(() => {}, [assetPresentValues]);


    useEffect(() => {
        if (createAssetPresentValueData) {
          toast({
            title: t("Asset value has been created."),
            description: t(""),
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }

        if (createAssetPresentValueError)
          toast({
            title: t("Asset value hasn't been created."),
            description: t(`Error: ${createAssetPresentValueError.message}`),
            status: "error",
            duration: 9000,
            isClosable: true,
          });
    }, [createAssetPresentValueData, createAssetPresentValueError]);


    useEffect(() => {
    if (updateAssetPresentValueData) {
        toast({
        title: t("Asset value has been updated."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
        });
    }

    if (updateAssetPresentValueError)
        toast({
        title: t("Asset value hasn't been update."),
        description: t(`Error: ${updateAssetPresentValueError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
        });
    }, [updateAssetPresentValueData, updateAssetPresentValueError]);


    useEffect(() => {
        if (deleteAssetPresentValueData) {
            toast({
            title: t("Asset value has been deleted."),
            description: t(""),
            status: "success",
            duration: 9000,
            isClosable: true,
            });
        }

        if (deleteAssetPresentValueError)
            toast({
            title: t("Asset value couldn't be deleted."),
            description: t(`Error: ${deleteAssetPresentValueError.message}`),
            status: "error",
            duration: 9000,
            isClosable: true,
            });
    }, [deleteAssetPresentValueData, deleteAssetPresentValueError]);


    const onCloseModal = () => {
        setSelectedId(null);
        setValue("authorId", "");
        setValue("amount", {
            amount: 0,
            currency: 'EUR'
        });
        setValue("estimateDate", "");
        setValue("estimateReason", "");
        setValue("fileTitle", "");
        setValue("file", "");
        setValue("notes", "");
        onClose();
    }

    const onSubmit: SubmitHandler<AssetPresentValueInput> = async (values: any, e: any) => {
        const assetPresentValueData = cleanObject(values);
        let assetPresentValues;
        let response;
        if (selectedId) {
            response = await updateAssetPresentValue({
                variables: {
                    input: {
                        assetPresentValueData: {
                            amount: assetPresentValueData.amount,
                            authorId: assetPresentValueData.authorId,
                            estimateDate: assetPresentValueData.estimateDate,
                            estimateReason: assetPresentValueData.estimateReason,
                            fileTitle: assetPresentValueData.fileTitle,
                            file: assetPresentValueData.file,
                            notes: assetPresentValueData.notes,
                        },
                        id: selectedId
                    }
                }
            })
            if (
                response.data &&
                response.data.updateAssetPresentValue &&
                response.data.updateAssetPresentValue.assetPresentValue &&
                response.data.updateAssetPresentValue.assetPresentValue.id
            ) {
                assetPresentValues = await loadParsedAssetPresentValues();
                onCloseModal();


            }
        } else {
            response = await createAssetPresentValue({
                variables: {
                    input: {
                        assetPresentValueData: {
                            amount: assetPresentValueData.amount,
                            authorId: assetPresentValueData.authorId,
                            estimateDate: assetPresentValueData.estimateDate,
                            estimateReason: assetPresentValueData.estimateReason,
                            fileTitle: assetPresentValueData.fileTitle,
                            file: assetPresentValueData.file,
                            notes: assetPresentValueData.notes,
                            assetId
                        }
                    }
                }
            });

            if (
                response.data &&
                response.data.createAssetPresentValue &&
                response.data.createAssetPresentValue.assetPresentValue &&
                response.data.createAssetPresentValue.assetPresentValue.id
            ) {
                assetPresentValues = await loadParsedAssetPresentValues();
                onCloseModal();


            }
        }

        return assetPresentValues;
    }




    const onError = (errors: any, e: any) => console.log(errors, e);

    const handleSubmit = methods.handleSubmit(onSubmit, onError);

    return <>
            <Box p={6} maxW="6xl">
                <Stack spacing="4" divider={<StackDivider />}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>
                                    <Trans>Author</Trans>
                                </Th>
                                <Th>
                                    <Trans>Estimate Date</Trans>
                                </Th>
                                <Th>
                                    <Trans>Estimate Reason</Trans>
                                </Th>
                                <Th>
                                    <Trans>Amount</Trans>
                                </Th>
                                <Th>
                                    <Trans>File</Trans>
                                </Th>
                                <Th>
                                    <Trans>Notes</Trans>
                                </Th>
                                <Th />
                            </Tr>
                        </Thead>
                        <Tbody>
                            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                            {assetPresentValues.map(({author, estimateDate, estimateReason, amount, notes, fileTitle, file, id}, index) =>
                                // eslint-disable-next-line react/no-array-index-key
                                <Tr key={id}>
                                    <Td>{author.fullName}</Td>
                                    <Td>{estimateDate}</Td>
                                    <Td>{estimateReason ? findLabelByValue(estimateReason.toLowerCase(), ASSET_COSTANTS.ASSET_VALUES_REASONS) : ""}</Td>
                                    <Td>{amount.amount} {amount.currency}</Td>
                                    <Td>{fileTitle}</Td>
                                    <Td>{notes}</Td>
                                    <Td>
                                        {/* <Stack isInline><Icon name="edit"/><Icon name="delete" /></Stack> */}

                                        <IconButton
                                            variant="outline"
                                            icon={<RiEditLine />}
                                            onClick={() => onEditModalOpen(
                                                id,
                                                amount,
                                                author,
                                                estimateDate.toString(),
                                                estimateReason,
                                                fileTitle,
                                                file,
                                                notes
                                                ) }
                                            aria-label="Edit asset value present"
                                        />
                                        <Popover>
                                            <PopoverTrigger>
                                            <IconButton
                                                icon={<RiDeleteBinLine color="red.500" />}
                                                bg="white"
                                                variant="outline"
                                                size="sm"
                                                aria-label="Delete asset value present"
                                            />
                                            </PopoverTrigger>
                                            <Portal>
                                                <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                                                    <PopoverHeader pt={4} fontWeight="bold" border="0">
                                                    <Trans>Are you sure you want to delete it?</Trans>
                                                    </PopoverHeader>
                                                    <PopoverArrow />
                                                    <PopoverCloseButton />
                                                    <PopoverBody><Trans>Warning message</Trans></PopoverBody>
                                                    <PopoverFooter
                                                    border="0"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    pb={4}
                                                    >
                                                    <ButtonGroup size="sm">
                                                        <Button
                                                        variant="danger"
                                                        isLoading={deleteAssetPresentValueLoading}
                                                        onClick={() => {
                                                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                                            onDelete(id);
                                                        }}
                                                        >
                                                        <Trans>Delete it</Trans>
                                                        </Button>
                                                        <Button><Trans>Cancel</Trans></Button>
                                                    </ButtonGroup>
                                                    </PopoverFooter>
                                                </PopoverContent>
                                                </Portal>
                                        </Popover>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>


                    <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                        <Button
                            onClick={onOpen}
                            variant="primary"
                        >
                            <Trans>Add new asset present value</Trans>
                        </Button>
                    </ButtonGroup>

                    <Modal
                        closeOnOverlayClick={false}
                        isOpen={isOpen}
                        size="xl"
                        onClose={onCloseModal}
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                {selectedId ? <Trans>Update asset present value</Trans> : <Trans>Add new asset present value</Trans>}
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <FormProvider {...methods}>
                                    <ValidationProvider schema={AssetPresentValueInputSchema()}>
                                        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                                        <form id="addAssetPresentValue" onSubmit={handleSubmit} >
                                            <Box maxW="6xl">
                                                <Stack spacing="4" divider={<StackDivider />}>
                                                    <HStack alignItems="flex-end">
                                                        <FormInputHook label="Estimate date" name="estimateDate" type="date" />
                                                        <FormInputHook
                                                            label=""
                                                            options={[{ label: "- Estimate reason -", value: "" }].concat(ASSET_COSTANTS.ASSET_VALUES_REASONS)}
                                                            name="estimateReason"
                                                        />

                                                        {/* <FormInputHook label="Estimate reason" name="estimateReason" /> */}
                                                    </HStack>
                                                    <HStack alignItems="flex-end">
                                                        <MoneyInputHook name="amount.amount" label="Amount" />
                                                    </HStack>
                                                    <HStack alignItems="flex-end">
                                                        <FormInputHook name="authorId" label="Author" type="registry" />
                                                        {/* <FormInputHook label="Author" name="author" /> */}
                                                        <FormInputHook label="Notes" name="notes"
                                                        />
                                                    </HStack>



                                                </Stack>
                                            </Box>
                                        </form>
                                    </ValidationProvider>
                                </FormProvider>


                            </ModalBody>
                            <ModalFooter>
                            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
                                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                                <Button
                                    variant="primary"
                                    onClick={handleSubmit}
                                    mr={1}
                                    isLoading={createAssetPresentValueLoading || updateAssetPresentValueLoading}
                                    loadingText="Loading"
                                >
                                    <Trans>Save</Trans>
                                </Button>
                                <Button onClick={onCloseModal}><Trans>Cancel</Trans></Button>
                            </ButtonGroup>

                            </ModalFooter>
                        </ModalContent>
                    </Modal>

        {/* { children } */}
                </Stack>
            </Box>
            {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
                {children}
            </ButtonGroup> */}
        </>

}

export default AssetPresentValueForm;