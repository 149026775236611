import {
  HStack,
  VStack,
  Heading,
  Text,
  Box,
  Badge,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Grid,
} from "@chakra-ui/react";
import { CopyButton, Dot, QuickActions, RegistryAwareField } from "components/ui";
import { Action, QuickLink } from "components/ui/QuickActions";
import { GetPolicyByIdQuery } from "graphql/queries/generated/queries";
import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RiArrowRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";

interface BorderedBoxProps {
  title: string;
  subtitle?: string;
  children: ReactNode;
  action?: {
    href: string;
    title: string;
  };
}

const BorderedBox = ({ title, subtitle, children, action }: BorderedBoxProps) => (
  <Box borderWidth="1px" borderColor="gray.200" borderRadius="xl" flex="1" w="full" h="full">
    <VStack align="flex-start" borderBottomColor="gray.200" borderBottomWidth="1px" spacing="0">
      {/* <RiMapPinTimeLine size="2rem" /> */}
      <HStack justify="space-between" align="center" w="full" bgColor="cardBg.50" p="4">
        <VStack align="flex-start" spacing="0">
          <Heading size="md">
            <Trans>{title}</Trans>
          </Heading>
          <Text variant="muted">{subtitle}</Text>
        </VStack>
        {action && (
          <Button>
            <Link to={action.href}>
              <Trans>{action.title}</Trans>
            </Link>
          </Button>
        )}
      </HStack>
    </VStack>
    <VStack align="flex-start" spacing="4" p="4">
      {children}
    </VStack>
  </Box>
);

const PolicySummary = ({
  policy,
  actions,
}: {
  policy: GetPolicyByIdQuery["policy"];
  actions?: (Partial<Action> | Partial<QuickLink>)[];
}) => {
  const { t } = useTranslation();

  const {
    policyCode,
    offerCode,
    issuedAt,
    expiresAt,
    policyStatus,
    autoRenewal,
    contractingParty,
    broker,
    insuranceCompany,
    catNat,
    coverTerrorism,
    hasVariableBasket,
    coverType,
    coveredRiskType,
    evaluationType,
    eventEntity,
    eventLocationEntity,
    eventFrom,
    eventName,
    eventTo,
    policyType,
    totalInsuredPremium,
    totalInsuredValue,
  } = policy;

  const isExpired = new Date(expiresAt).getTime() < new Date().getTime();

  return (
    <VStack spacing="6" align="flex-start" w="full">
      <HStack justifyContent="space-between" alignItems="flex-start" w="full">
        <VStack alignItems="flex-start">
          <HStack>
            <Badge variant={policyStatus}>
              {policyStatus === "PENDING" && t("Offer")}
              {policyStatus === "APPROVED" && t("Policy")}
            </Badge>
            <Badge variant={isExpired ? "EXPIRED" : "CURRENT"}>
              {isExpired ? t("Expired") : t("Current")}
            </Badge>
          </HStack>

          <Heading>
            <Trans>Policy #</Trans>
            {policyCode}
          </Heading>
          <Text fontSize="lg" variant="muted">
            <Trans>Offer #</Trans>
            {offerCode ?? ""} <CopyButton value={offerCode ?? ""} />
          </Text>
        </VStack>
        {actions && <QuickActions noTitle actions={actions} />}
      </HStack>
      <HStack justify="flex-start" spacing="12" w="full">
        <Box>
          <Stat>
            <StatLabel>
              <Trans>Total insured premium</Trans>
            </StatLabel>
            <StatNumber>
              <>
                {totalInsuredPremium.amount}&nbsp;
                {totalInsuredPremium.currency?.code}
              </>
            </StatNumber>
            <StatHelpText>
              <Trans>Helper text for total insured premium</Trans>
            </StatHelpText>
          </Stat>
        </Box>
        <Box>
          <Stat>
            <StatLabel>
              <Trans>Total insured value</Trans>
            </StatLabel>
            <StatNumber>
              <>
                {totalInsuredValue.amount}&nbsp;
                {totalInsuredValue.currency?.code}
              </>
            </StatNumber>
            <StatHelpText>
              <Trans>Helper text for total insured value</Trans>
            </StatHelpText>
          </Stat>
        </Box>
      </HStack>
      <BorderedBox
        title={policyType === "PERMANENT_COLLECTION" ? t("Collection") : t("Exhibition")}
        subtitle={
          policyType === "PERMANENT_COLLECTION"
            ? "Collection subtitle in policy summary"
            : "Exhibition subtitle in policy summary"
        }
      >
        <HStack spacing="16" w="full">
          <VStack alignItems="flex-start">
            <Text variant="muted">
              {policyType === "PERMANENT_COLLECTION" ? (
                <Trans>Collection name</Trans>
              ) : (
                <Trans>Event name</Trans>
              )}
            </Text>
            <Text fontWeight="600">{eventName}</Text>
          </VStack>
          <VStack alignItems="flex-start">
            <Text variant="muted">
              <Trans>
                {policyType === "PERMANENT_COLLECTION"
                  ? "Museum/Collector"
                  : "Institution/Museum/Location name"}
              </Trans>
            </Text>
            <RegistryAwareField id={eventEntity?.id} />
          </VStack>
        </HStack>

        {eventFrom && eventTo && (
          <HStack spacing="16">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Event dates</Trans>
              </Text>
              {eventFrom && eventTo && (
                <HStack mt="0 !important">
                  <Text>{new Date(eventFrom).toLocaleString("it-IT")}</Text> <RiArrowRightFill />{" "}
                  <Text>{new Date(eventTo).toLocaleString("it-IT")}</Text>
                </HStack>
              )}
            </VStack>
          </HStack>
        )}
      </BorderedBox>
      <Grid templateRows="1fr" templateColumns="1fr 1fr" gap="6" w="full">
        <BorderedBox title="Coverage" subtitle="Coverage subtitle in policy summary">
          <HStack align="flex-start" spacing="16">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Coverage</Trans>
              </Text>
              <HStack>
                <Text>{new Date(issuedAt).toLocaleString("it-IT")}</Text> <RiArrowRightFill />{" "}
                <Text>{new Date(expiresAt).toLocaleString("it-IT")}</Text>
              </HStack>
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Auto renewal</Trans>
              </Text>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <Dot status={autoRenewal} />
            </VStack>
          </HStack>
          <HStack spacing="16">
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Contracting party</Trans>
              </Text>
              <RegistryAwareField id={contractingParty.id} />
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Broker</Trans>
              </Text>
              <RegistryAwareField id={broker.id} />
            </VStack>
            <VStack alignItems="flex-start">
              <Text variant="muted">
                <Trans>Insurance company</Trans>
              </Text>
              <RegistryAwareField id={insuranceCompany.id} />
            </VStack>
          </HStack>
        </BorderedBox>

        <BorderedBox title="Parameters" subtitle="Parameters subtitle in policy summary">
          <VStack align="flex-start" spacing="4">
            <HStack spacing="16">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Natural disasters</Trans>
                </Text>
                <Dot status={catNat as boolean} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Cover Terrorism</Trans>
                </Text>
                <Dot status={coverTerrorism as boolean} />
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Has variable basket</Trans>
                </Text>
                <Dot status={hasVariableBasket as boolean} />
              </VStack>
            </HStack>

            <HStack spacing="16">
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Cover type</Trans>
                </Text>
                <Text>
                  <Badge mt={1} variant={coverType}>
                    <Trans>{coverType}</Trans>
                  </Badge>
                </Text>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Covered risk type</Trans>
                </Text>
                <Text>
                  <Badge mt={1} variant={coveredRiskType}>
                    <Trans>{coveredRiskType}</Trans>
                  </Badge>
                </Text>
              </VStack>
              <VStack alignItems="flex-start">
                <Text variant="muted">
                  <Trans>Evalutation Type</Trans>
                </Text>
                <Text>
                  <Badge mt={1} variant={evaluationType}>
                    <Trans>{evaluationType}</Trans>
                  </Badge>
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </BorderedBox>
      </Grid>
    </VStack>
  );
};

export default PolicySummary;
