import {
  useCreateRegistryMutation,
  useGetRegistriesBySearchLazyQuery,
} from "graphql/queries/generated/queries";

const useGetOrCreateRegistry = () => {
  const [searchRegistry] = useGetRegistriesBySearchLazyQuery();
  const [createRegistry] = useCreateRegistryMutation();

  const doUseGetOrCreateRegistry = async (search: string) => {
    const { data: searchRegistryData, error: searchRegistryError } = await searchRegistry({
      variables: {
        search,
      },
    });

    if (!searchRegistryData?.registries || searchRegistryData.registries.length === 0) {
      const { data: createRegistryData, errors: createRegistryError } = await createRegistry({
        variables: {
          input: {
            registryData: {
              fullName: search,
              isCompany: false,
            },
          },
        },
      });

      if (createRegistryError) throw new Error(createRegistryError[0].message);
      const {
        createRegistry: { registry },
      } = createRegistryData;
      return registry.id;
    }

    const { registries } = searchRegistryData;

    return registries[0].id;
  };

  return doUseGetOrCreateRegistry;
};

export default useGetOrCreateRegistry;
