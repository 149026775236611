import { extendTheme } from "@chakra-ui/react";
import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import BADGE_VARIANTS from "./badgeVariants";

const brandColors = {
  primaryYellow: "#FFCF21",
  primaryGreen: "#328D2E",
  primaryFuchsia: "#E72B78",
  primaryGrey: "#50535A",
  pastelPrimaryYellow: "#fff9e5",
  pastelPrimaryGreen: "#d6e8d5",
  pastelPrimaryFuchsia: "#F7BFD6",
};

const theme = extendTheme({
  styles: {
    global: () => ({
      body: {
        // bg: "gray.50",
        letterSpacing: "-0.5px !important",
      },
      input: {
        // bg: "white !important",
        borderRadius: "md !important",
      },
      select: {
        // bg: "white !important",
        borderRadius: "md !important",
      },
      table: {
        // boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
        // borderColor: "gray.200",
        // borderLeftWidth: "1px",
        // borderRightWidth: "1px",
        // backgroundColor: "white",
        // borderRadius: "1rem",
        // color: "black",
      },
      td: {
        width: "1px !important",
        whiteSpace: "nowrap !important",
      },
      th: {
        fontSize: "0.7rem !important",
        fontWeight: "500 !important",
      },
    }),
  },
  colors: {
    ...brandColors,
    cardBg: {
      50: "#edf2f796",
    },
    brand: {
      50: "#FFFEFF",
      100: "#FCE9F1",
      200: "#FAD4E4",
      300: "#F7BFD6",
      400: "#F5AAC9",
      500: "#F395BB",
      600: "#e72b78",
      700: "#EE6AA0",
      800: "#EB5593",
      900: "#e72b78",
    },
  },
  components: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Steps,
    Alert: {
      baseStyle: ({ status }: { status: string }) => {
        const infoBase = status === "info" && {
          container: {
            borderRadius: "md",
          },
          icon: {},
        };
        return {
          ...infoBase,
        };
      },
    },
    Badge: {
      baseStyle: {
        backgroundColor: "pastelPrimaryFuchsia",
        fontWeight: 600,
        padding: "0.2rem 0.5rem",
        borderRadius: "0.2rem",
      },
      defaultProps: {
        colorScheme: "brand",
      },
      variants: BADGE_VARIANTS,
    },
    Box: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    Stack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    HStack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    VStack: {
      baseStyle: {
        backgroundColor: "gray.800",
      },
      variants: {
        brand: {
          background: "red.800",
        },
      },
      defaultProps: {
        background: "gray.800",
        variant: "brand",
      },
    },
    Divider: {
      defaultProps: {
        colorScheme: "white",
      },
      baseStyle: {
        borderColor: "white",
        borderWidth: "2px",
      },
    },
    Text: {
      baseStyle: {
        color: "black",
        marginTop: "0 !important",
      },
      variants: {
        muted: {
          color: "gray.500",
          fontWeight: "400",
          marginTop: "0.2rem",
          fontSize: "0.9rem",
        },
        tooltip: {
          textDecoration: "underline",
          textDecorationStyle: "dashed",
        },
      },
    },
    MenuItem: {
      variants: {
        danger: {
          color: "red.500",
        },
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "semibold", // Normally, it is "semibold"
        borderRadius: "0.5rem",
        backgroundColor: "white",
        color: "black",
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          fontSize: "lg",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        danger: {
          backgroundColor: "red.600",
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          color: "white",
        },
        "with-shadow": {
          boxShadow: "0 0 2px 2px #efdfde",
        },
        // 4. We can override existing variants
        solid: (props: StyleFunctionProps) => ({}),
        // 5. We can add responsive variants
        sm: {
          fontSize: "sm",
        },
        outline: {
          boxShadow: "0px 5px 3px -3px rgba(0,0,0,0.1)",
          borderColor: "gray.200",
          borderWidth: "1px",
          backgroundColor: "white",
          color: "black",
          defaultProps: {},
        },
        primary: {
          boxShadow: "0px 5px 3px -3px rgb(0 0 0 / 20%)",
          borderColor: "gray.300",
          borderWidth: "1px",
          backgroundColor: "brand.900",
          color: "white",
          defaultProps: {},
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        variant: "outline",
        size: "sm",
      },
    },
    Input: {
      // baseStyle: {
      //   bgColor: "white",
      //   background: "white",
      //   backgroundColor: "white",
      // },
      defaultProps: {
        size: "sm",
        borderRadius: "lg",
      },
    },
    Select: {
      defaultProps: {
        size: "sm",
        borderRadius: "lg",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: "sm",
        color: "gray.800",
      },
    },
    TableContainer: {
      baseStyle: {
        borderRadius: "1rem",
      },
    },
    Menu: {
      baseStyle: {
        button: {
          backgroundColor: "white",
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            fontSize: "sm",
            padding: "0 0 0.4rem 0",
            fontWeight: 600,
            color: "gray.600",

            _selected: {
              fontWeight: 600,
              "& > span.chakra-badge": {
                backgroundColor: "primaryFuchsia",
                color: "gray.100",
              },
            },
          },
        },
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px lightgray",
  },
});

export default theme;
