import { Box, Button, Flex, HStack, Input, Select, Text } from "@chakra-ui/react";

interface PaginationProps {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  canPreviousPage: boolean;
  previousPage: () => void;
  nextPage: () => void;
  canNextPage: boolean;
  pageCount: number;
  pageIndex: number;
  pageOptions: number[];
  pageSize: number;
  setPageSize: (pageSize: number) => void;
}

const Pagination = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
}: PaginationProps) => (
  <HStack mt={4}>
    <Flex mr={8} align="center">
      <Button
        type="button"
        size="sm"
        mr={2}
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {"<<"}
      </Button>{" "}
      <Button type="button" size="sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
        {"<"}
      </Button>{" "}
      <Box mx={8}>
        <Text>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </Text>
      </Box>
      <Button type="button" size="sm" mr={2} onClick={() => nextPage()} disabled={!canNextPage}>
        {">"}
      </Button>{" "}
      <Button
        type="button"
        size="sm"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {">>"}
      </Button>{" "}
    </Flex>

    <Box mr={8}>
      <Text>
        Go to page:{" "}
        <Input
          type="number"
          size="sm"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          style={{ width: "100px" }}
        />
      </Text>{" "}
    </Box>
    <Select
      size="sm"
      w="10rem"
      value={pageSize}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[10, 20, 30, 40, 50].map((pgSize) => (
        <option key={pgSize} value={pgSize}>
          Show {pgSize}
        </option>
      ))}
    </Select>
  </HStack>
);

export { Pagination };
