import { Badge, Box, Divider, Skeleton, useDisclosure } from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { DropdownFilter, QuickActions, Table } from "components/ui";

import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  UserNode,
  UserNodeEdge,
  useToggleUserIsActiveMutation,
} from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { MutableRefObject, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";

const CUSTOM_FIELDS = ["userType", "country"];
const CUSTOM_COLUMNS = [
  {
    Header: "User Type",
    Footer: "User Type",
    accessor: "userType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
  },
  {
    Header: "Country",
    Footer: "Country",
    accessor: "country.code",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
  },
];

const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new users",
    href: "new",
    icon: <RiAddLine />,
  },
  {
    name: "Invite user",
    href: "invite",
    icon: <RiAddLine />,
  },
];

const UsersPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllUsersQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  const [
    toggleIsActiveUser,
    {
      data: toggleIsActiveUserData,
      loading: toggleIsActiveUserLoading,
      error: toggleIsActiveUserError,
    },
  ] = useToggleUserIsActiveMutation();

  const [deleteUser, { data: deleteUserData, error: deleteUserError, loading: deleteUserLoading }] =
    useDeleteUserMutation();
  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  if (!data || !data.allUsers || data.allUsers.edges.length === 0) return null;

  const {
    allUsers: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await toggleIsActiveUser({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Disable selected users</Trans>,
      action: (selectedRows: UserNodeEdge[]) => console.log(selectedRows),
    },
    {
      name: <Trans>Delete users</Trans>,
      action: async (selectedRows: { original: UserNode }[]) => {
        await Promise.all(
          selectedRows.map(async (row) => {
            const { original } = row;
            console.log(row);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await deleteUser({
              variables: {
                input: {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  id: original.id,
                },
              },
            });
            return null;
          })
        );
        await refetch();
      },
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allUsers.edges.flatMap((edge) => edge?.node)}
          pathname="/o/users/"
          deleteAction={(id: string) => onDelete(id)}
          deleteActionLoading={toggleIsActiveUserLoading}
          isLoading={deleteUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default UsersPage;
