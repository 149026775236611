import { Text, Tooltip } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

interface TextWithTooltipProps {
  children: string;
  tooltipText: string;
}

const TextWithTooltip = ({ children, tooltipText }: TextWithTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t(`${tooltipText}`)}>
      <Text variant="tooltip" fontWeight="bold">
        <Trans>{children}</Trans>
      </Text>
    </Tooltip>
  );
};
export default TextWithTooltip;
