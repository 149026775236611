import {
  VStack,
  Heading,
  Text,
  Box,
  useDisclosure,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { DateDialog } from "components/ui";
import {
  useGenerateCertificateMutation,
  useGetAllCertificatesByPolicyIdAndIsActiveQuery,
  useGetAllCertificateTemplatesByNameLazyQuery,
  useGetPolicyByIdQuery,
  useGetPolicyDocumentsByPolicyIdQuery,
  useGetPolicyNotesByPolicyIdQuery,
  useUpdatePolicyMutation,
} from "graphql/queries/generated/queries";
import PolicyCertificatesSummary from "pages/spin/shared/PolicyCertificatesSummary";
import PolicyDocumentsSummary from "pages/spin/shared/PolicyDocumentsSummary";
import PolicyNotesSummary from "pages/spin/shared/PolicyNotesSummary";
import PolicySummary from "pages/spin/shared/PolicySummary";
import OnViewPolicyAssetsTable from "pages/spin/shared/view/OnViewPolicyAssetsTable";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const TEPoliciesViewPage = () => {
  const toast = useToast();
  const [getOnlyActiveCertificates, setGetOnlyActiveCertificates] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { policyId } = useParams();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useGetPolicyByIdQuery({
    variables: {
      id: policyId,
    },
  });

  const {
    data: certificateSet,
    loading: certificateSetLoading,
    error: certificateSetError,
    refetch: refetchCertificates,
  } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
    variables: {
      policy: policyId,
      isValid: getOnlyActiveCertificates,
    },
  });
  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation();

  const {
    data: policyDocumentsData,
    loading: policyDocumentsLoading,
    error: policyDocumentsError,
  } = useGetPolicyDocumentsByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const {
    data: policyNotesData,
    loading: policyNotesLoading,
    error: policyNotesError,
  } = useGetPolicyNotesByPolicyIdQuery({
    variables: {
      policyId,
    },
  });

  const [
    generateCertificate,
    {
      data: generateCertificateData,
      loading: generateCertificateLoading,
      error: generateCertificateError,
    },
  ] = useGenerateCertificateMutation();

  const [getAllCertificateTemplatesByName] = useGetAllCertificateTemplatesByNameLazyQuery({
    variables: {
      name: "inventory",
    },
  });

  useEffect(() => {
    if (generateCertificateData) {
      toast({
        title: t("Certificate has been generated."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (generateCertificateError)
      toast({
        title: t("Certificate couldn't be generated."),
        description: t(`Error: ${generateCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [generateCertificateData, generateCertificateError]);
  if (loading) return <Text>Loading...</Text>;
  if (!data || !data?.policy) return null;

  const {
    policy: { policyAssets, policyType, expiresAt },
  } = data;

  const parsedForTablePolicyAssets = policyAssets.edges.flatMap((e) => {
    const { asset, insuredValue, ...rest } = e.node;
    return {
      ...rest,
      insuredValue: {
        amount: insuredValue?.amount,
        currency: insuredValue?.currency?.code,
      },
      ...asset,
    };
  });

  const isExpired = new Date(expiresAt).getTime() < new Date().getTime();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { allPolicyDocuments } = policyDocumentsData || {};
  const { allPolicyNotes } = policyNotesData || {};

  const renewOrExtend = async ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    const {
      policy: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        __typename,
        id,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        policyAssets,
        policyDocuments,
        policyNotes,
        totalInsuredPremium,
        totalInsuredValue,
        ...policy
      },
    } = data;

    await updatePolicy({
      variables: {
        input: {
          id: policyId,
          policyData: {
            ...policy,
            eventEntityId: policy.eventEntity?.id,
            eventLocationEntityId: policy.eventLocationEntity?.id,
            brokerId: policy.broker.id,
            insuranceCompanyId: policy.insuranceCompany.id,
            contractingPartyId: policy.contractingParty.id,
          },
        },
      },
    });

    await refetch();
  };

  const generateInventories = async () => {
    const { data: certificateTemplatesData } = await getAllCertificateTemplatesByName();

    if (
      !certificateTemplatesData ||
      !certificateTemplatesData?.allCertificateTemplates ||
      certificateTemplatesData?.allCertificateTemplates?.edges.length === 0
    )
      return;

    const {
      allCertificateTemplates: {
        edges: [baseTemplate],
      },
    } = certificateTemplatesData;

    const {
      node: { id: certificateTemplateId },
    } = baseTemplate;
    await generateCertificate({
      variables: {
        input: {
          certificateTemplateId,
          id: policyId,
          language: "en",
          hasPdf: true,
        },
      },
    });

    await refetchCertificates();
  };

  const deletePolicy = () => {};

  const QUICK_ACTIONS = [
    {
      name: t("Edit policy"),
      href: `/spin/pc-policies/edit/${policyId}`,
      intent: "primary",
    },
    {
      name: isExpired ? t("Renew policy") : t("Extend policy"),
      action: onOpen,
    },
    {
      name: t("Generate inventory PDFs"),
      action: generateInventories,
      loading: generateCertificateLoading,
    },
    {
      name: t("Delete policy"),
      action: deletePolicy,
      intent: "danger",
    },
  ];

  const openAllCertificates = () => {
    certificateSet?.allCertificates?.edges?.map(({ node }) => window.open(node?.file));
  };

  return (
    <>
      <Box>
        <PolicySummary policy={data.policy} actions={QUICK_ACTIONS} />
        <VStack my={16} align="flex-start">
          <Heading size="md">
            <Trans>Assets</Trans>
          </Heading>
          {/* TODO: refactor this */}
          <OnViewPolicyAssetsTable
            mode="view"
            data={parsedForTablePolicyAssets}
            type="PERMANENT_COLLECTION"
          />
        </VStack>
        <VStack my={16} align="flex-start">
          <Heading size="md">
            <Trans>{t("Notes")}</Trans>
          </Heading>
          <PolicyNotesSummary policyNotes={allPolicyNotes?.edges.map(({ node }) => node)} />
        </VStack>
        <VStack my={16} align="flex-start">
          <Heading size="md">
            <Trans>{t("Documents")}</Trans>
          </Heading>
          <PolicyDocumentsSummary
            policyDocuments={allPolicyDocuments?.edges.map(({ node }) => node)}
          />
        </VStack>
        <VStack my={16} align="flex-start">
          <HStack>
            <Heading size="md">
              <Trans>{t("Emitted inventories")}</Trans>
            </Heading>
            <Button variant="ghost" onClick={openAllCertificates}>
              <Text decoration="underline">
                <Trans>Download all</Trans>
              </Text>
            </Button>
            <Button
              variant="ghost"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                setGetOnlyActiveCertificates((prevState) => !prevState);
                await refetchCertificates({ isValid: getOnlyActiveCertificates, policy: policyId });
              }}
            >
              <Text decoration="underline">
                {getOnlyActiveCertificates ? (
                  <Trans>Show only invalid</Trans>
                ) : (
                  <Trans>Show only valid</Trans>
                )}
              </Text>
            </Button>
          </HStack>
          <PolicyCertificatesSummary
            policyCertificates={certificateSet?.allCertificates?.edges?.map(({ node }) => node)}
            refetchCertificates={refetchCertificates}
            loading={certificateSetLoading}
          />
        </VStack>
      </Box>
      <DateDialog
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        title="Update policy expiration"
        message="Set new starting date and expiration date"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={renewOrExtend}
      />
    </>
  );
};

export default TEPoliciesViewPage;
