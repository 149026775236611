/* eslint-disable @typescript-eslint/no-misused-promises */
import { GetPolicyByIdQuery, useUpdatePolicyMutation } from "graphql/queries/generated/queries";
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { PolicyDetailsInput, PolicyInputExtended, PolicyType } from "pages/spin/types";
import { PolicyInputSchema } from "graphql/queries/overrides";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans } from "react-i18next";
import { ValidationProvider } from "components/form/ValidationContext";
import { PCPolicyAssetFields, TEPolicyAssetFields } from "../types";
import PolicyDetailsForm from "./edit/PolicyDetailsForm";
import PolicyAssetsForm from "./edit/PolicyAssetsForm";
import PolicyNotesForm from "./edit/PolicyNotesForm";
import PolicyDocumentsForm from "./edit/PolicyDocumentsForm";

const EditPolicyPage = ({
  data,
  policyAssetsFields,
  type,
}: {
  type: PolicyType;
  data: GetPolicyByIdQuery;
  policyAssetsFields: PCPolicyAssetFields | TEPolicyAssetFields;
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { policyId } = useParams();
  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation();

  const {
    policy: {
      policyAssets,
      policyDocuments,
      policyNotes,
      totalInsuredPremium,
      totalInsuredValue,
      broker,
      insuranceCompany,
      contractingParty,
      eventEntity,
      eventLocationEntity,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __typename,
      ...policy
    },
  } = data;

  const parsedForTablePolicyAssets = policyAssets.edges.flatMap((e) => {
    const { asset, insuredValue, ...rest } = e.node;
    return {
      ...rest,
      insuredValue: {
        amount: insuredValue.amount,
        currency: insuredValue.currency.code,
      },
      ...asset,
    };
  });

  const methods = useForm<PolicyInputExtended>({
    defaultValues: {
      ...policy,
      brokerId: broker.id,
      insuranceCompanyId: insuranceCompany.id,
      contractingPartyId: contractingParty.id,
      totalInsuredPremium: {
        ...totalInsuredPremium,
        currency: totalInsuredPremium?.currency?.code || "EUR",
      },
      totalInsuredValue: {
        ...totalInsuredValue,
        currency: totalInsuredValue?.currency?.code || "EUR",
      },
      policyAssets: parsedForTablePolicyAssets,
    },
    resolver: yupResolver(PolicyInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof PolicyInputExtended);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const policyDetailsFormMethods = useForm<PolicyDetailsInput>({
    defaultValues: {
      ...policy,
      brokerId: broker.id,
      insuranceCompanyId: insuranceCompany.id,
      contractingPartyId: contractingParty.id,
      eventEntityId: eventEntity?.id,
      eventLocationEntityId: eventLocationEntity?.id,
      totalInsuredPremium: {
        ...totalInsuredPremium,
        currency: totalInsuredPremium?.currency?.code || "EUR",
      },
      totalInsuredValue: {
        ...totalInsuredValue,
        currency: totalInsuredValue?.currency?.code || "EUR",
      },
    },
    resolver: yupResolver(PolicyInputSchema()),
  });

  const policyDetailsFormSubmit: SubmitHandler<PolicyDetailsInput> = async (formData, e) => {
    const {
      totalInsuredPremium: totalInsuredPremiumEdited,
      totalInsuredValue: totalInsuredValueEdited,
      ...policyData
    } = formData;

    await updatePolicy({
      variables: {
        input: {
          id: policyId,
          policyData: {
            ...policyData,
            totalInsuredPremium: {
              amount: totalInsuredPremiumEdited?.amount,
              currency: totalInsuredPremiumEdited?.currency || "EUR",
            },
            totalInsuredValue: {
              amount: totalInsuredValueEdited?.amount,
              currency: totalInsuredValueEdited?.currency || "EUR",
            },
          },
        },
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow

  return (
    <>
      <Tabs colorScheme="brand" variant="line" mt="4" index={tabIndex} onChange={handleTabChange}>
        <TabList>
          <Tab mr={8}>
            <Trans>Policy Details</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Assets</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Notes</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Documents</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <FormProvider {...policyDetailsFormMethods}>
              <ValidationProvider schema={PolicyInputSchema()}>
                <PolicyDetailsForm
                  policyType={policy.policyType as PolicyType}
                  onSubmit={policyDetailsFormMethods.handleSubmit(policyDetailsFormSubmit)}
                  data={updatePolicyData}
                  loading={updatePolicyLoading}
                  error={updatePolicyError}
                />
              </ValidationProvider>
            </FormProvider>
          </TabPanel>
          <TabPanel p={0}>
            <PolicyAssetsForm
              type={type}
              policy={policy}
              data={parsedForTablePolicyAssets}
              policyAssetFields={policyAssetsFields}
            />
          </TabPanel>
          <TabPanel p={0}>
            <PolicyNotesForm data={policyNotes?.edges?.map(({ node }) => node)} />
          </TabPanel>
          <TabPanel p={0}>
            <PolicyDocumentsForm data={policyDocuments?.edges?.map(({ node }) => node)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Stack spacing="4" />
    </>
  );
};

export default EditPolicyPage;
