import {
    Box,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
  import { ApolloError } from "@apollo/client";
  import { useTranslation } from "react-i18next";
  // import { CreateAsset } from 'graphql/queries/types/CreateAsset';
  // import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
  import {
    AssetInput,
    CreateAssetMutation,
    UpdateAssetMutation,
  } from "graphql/queries/generated/queries";
  import {
    UseFormGetValues,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
  } from "react-hook-form";
  import { FieldGroup, FormInputHook } from "components/ui";
  import { BaseSyntheticEvent } from "react";



  interface BibliographyPanelNewCategoryFormProps {
    onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
    register: UseFormRegister<AssetInput>;
    getValues: UseFormGetValues<AssetInput>;
    watch: any; // UseFormWatch<AssetInput>,
    setValue: UseFormSetValue<AssetInput>;
    setError: UseFormSetError<AssetInput>;
    category: string;
    children?: React.ReactNode;
    createAssetData: CreateAssetMutation | null | undefined;
    updateAssetData: UpdateAssetMutation | null | undefined;
    createAssetError: ApolloError | undefined;
    updateAssetError: ApolloError | undefined;
    createAssetLoading: boolean | undefined;
    updateAssetLoading: boolean | undefined;
  }

  const BibliographyNewCategoryForm = ({
    // eslint-disable-next-line react/prop-types
    onSubmit,
    // handleChange,
    // handleRadioButtons,
    // values,
    register,
    setValue,
    setError,
    getValues,
    watch,
    createAssetData,
    updateAssetData,
    createAssetError,
    updateAssetError,
    createAssetLoading,
    updateAssetLoading,
    category,
    children,
  }: BibliographyPanelNewCategoryFormProps) => {
    const { t } = useTranslation();
    const { title } = getValues();



    return (
      <>
        {/* <Box p={6} maxW="6xl">
          <Stack spacing="4" divider={<StackDivider />}> */}
            <Box alignItems="flex-start">
              <Text textAlign="left">Categoria {category}</Text>
            </Box>

            <FieldGroup title={t("Notes")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <FormInputHook
                    label={t("Bibliography")}
                    name="bibliographyNotes"
                    type="textarea"
                  />
                </HStack>
              </VStack>
            </FieldGroup>



            {/* <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
            >
              {t("Save")}
            </Button>
            {children} */}
          {/* </Stack>
        </Box> */}
        {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
          <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
              variant="primary"
            >
              {t("Update")}
          </Button>
          {children}
        </ButtonGroup> */}
      </>
    );
  };

  export default BibliographyNewCategoryForm;
