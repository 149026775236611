import { Box, FormLabel, HStack, Input, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { Wrapper } from "./Wrapper";
import { FormInputProps } from "./type";

const DateTime = ({
  name,
  label,
  type,
  errors,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  watch,
  control,
  required,
}: FormInputProps) => (
  <Wrapper name={name} error={errors[name]}>
    {label && (
      <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
        {" "}
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
    )}
    <Box minW="12rem">
      <Controller
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        control={control}
        name={name}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        render={({ field: { onChange, value, name, ref } }) => {
          // split and get naive datetime and timezone separated
          const splitDateTZRegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+|-]\d{2}:\d{2})/;
          const matches = (value as string)?.match(splitDateTZRegex) || [];

          return (
            <HStack>
              <Input
                width="auto"
                id={name}
                name={name}
                disabled={disabled}
                placeholder={placeholder}
                type="datetime-local"
                value={matches[1] as string | number | readonly string[]}
                borderRadius="md"
                borderWidth="2px"
                borderColor="gray.200"
                onChange={(e) => onChange(e.target.value)}
              />
              <Input
                disabled
                w="4.5rem"
                name={`${name}-timezone`}
                value={process.env.REACT_APP_TENANT_TIMEZONE ?? "UTC"}
                onChange={(e) => console.log(e.target.value)}
              />
            </HStack>
          );
        }}
      />
    </Box>
  </Wrapper>
);

export default DateTime;
