/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FieldGroup } from "components/ui";
import { useFormik } from "formik";

import {
  useGetAllPermissionQuery,
  useInviteUserMutation,
  useUpdateUserMutation,
} from "graphql/queries/generated/queries";

import cleanObject from "helpers/cleanObject";
import groupBy from "lodash/groupBy";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PermissionsForm from "../PermissionsForm";
import { DictionaryPermissions } from "../types";

const UsersInvitePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signatureUploadRef = useRef<HTMLInputElement>(null);
  const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

  const [createUser, { data: createUserData, loading: createUserLoading, error: createUserError }] =
    useInviteUserMutation();
  const [formattedPermissions, setFormattedPermissions] = useState<DictionaryPermissions | null>();

  const {
    data: allPermissions,
    loading: allPermissionsLoading,
    error: allPermissionsError,
  } = useGetAllPermissionQuery({
    onCompleted(permissionData) {
      if (!permissionData?.allPermissions?.edges) return;

      const {
        allPermissions: { edges },
      } = permissionData;

      const groupedPermissions = groupBy(
        edges,
        (permission) => permission?.node?.contentType.model
      );

      setFormattedPermissions(groupedPermissions);
    },
  });

  const [
    updateUserPermissions,
    {
      data: updateUserPermissionsData,
      loading: updateUserPermissionsLoading,
      error: updateUserPermissionsError,
    },
  ] = useUpdateUserMutation();

  const formikInviteUser = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      permissions: [],
      expiresAt: "",
    },
    onSubmit: async (values) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { password, email, expiresAt, ...userData } = cleanObject(values);
      await createUser({
        variables: {
          input: {
            email,
            permissions: allowedPermissions,
            expiresAt,
          },
        },
      });
      // setTimeout(() => navigate("/o/users", { replace: true }), 5000);
    },
  });
  return (
    <Stack spacing="4" divider={<StackDivider />}>
      <form id="createUser" onSubmit={formikInviteUser.handleSubmit}>
        <VStack px="0">
          <FieldGroup
            title={t("Personal Info")}
            subtitle={t("Lorem ipsum dolor sit amet")}
            width="full"
            spacing="6"
            bgColor="white"
            py="8"
            rounded="lg"
            align="left"
          >
            <VStack>
              <FormControl>
                <FormLabel htmlFor="email">{t("Email Address")}</FormLabel>
                <Input
                  width="auto"
                  id="email"
                  name="email"
                  type="email"
                  onChange={formikInviteUser.handleChange}
                  value={formikInviteUser.values.email}
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="expiresAt">{t("Expires at")}</FormLabel>
                <Input
                  width="auto"
                  id="expiresAt"
                  name="expiresAt"
                  type="datetime-local"
                  onChange={formikInviteUser.handleChange}
                  value={formikInviteUser.values.expiresAt}
                />
              </FormControl>
            </VStack>
          </FieldGroup>
          <Box w="100%">
            <PermissionsForm
              success={!!updateUserPermissionsData}
              permissions={formattedPermissions}
              loading={updateUserPermissionsLoading}
              error={updateUserPermissionsError}
              allowedPermissions={allowedPermissions}
              setAllowedPermissions={setAllowedPermissions}
            />
          </Box>

          <FieldGroup width="full" spacing="6" bgColor="white" rounded="lg">
            <VStack width="full" align="flex-start" justify="flex-start">
              {createUserError && (
                <Alert status="error">
                  <>
                    <AlertIcon />
                    {createUserError.message}
                  </>
                </Alert>
              )}
              {createUserData && (
                <Alert status="success">
                  <>
                    <AlertIcon />
                    <VStack alignItems="flex-start">
                      <Text>
                        {t(
                          `We've sent an email to ${formikInviteUser.values.email} with instructions to log in!`
                        )}
                      </Text>
                    </VStack>
                  </>
                </Alert>
              )}
              <HStack>
                <Button size="sm" isLoading={createUserLoading} loadingText="Loading" type="submit">
                  {t("Save Changes")}
                </Button>
                <Button size="sm" variant="danger">
                  {t("Cancel")}
                </Button>
              </HStack>
            </VStack>
          </FieldGroup>
        </VStack>
      </form>
    </Stack>
  );
};

export default UsersInvitePage;
