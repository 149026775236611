import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface BCrumbProps {
  description?: string;
  title: string;
}

const BCrumb = ({ description = "", title }: BCrumbProps) => (
  <HStack spacing="24px">
    <VStack align="left" mb={4} w="100%">
      <HStack justifyContent="space- between">
        <Box maxW="80%" my={4}>
          <Heading>
            <Trans>{title}</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>{`${title} breadcrumb description`}</Trans>
          </Text>
        </Box>
      </HStack>
    </VStack>
  </HStack>
);

export default BCrumb;
