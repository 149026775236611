/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Box,
  HStack,
  VStack,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";
import {
  CreatePolicyMutationResult,
  PolicyAssetInput,
  useDeletePolicyAssetMutation,
  useGetAllAssetsByTitleLazyQuery,
} from "graphql/queries/generated/queries";
import { useTranslation, Trans } from "react-i18next";
import { BaseSyntheticEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  Control,
} from "react-hook-form";
import { scroller } from "react-scroll";

import { FormFooter } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { useNavigate } from "react-router-dom";
import { PolicyAssetInputExtended, PolicyInputExtended, PolicyType } from "../types";
import PolicyDetailsFields from "./PolicyFields";
import PolicyAssetsEditableTable from "./PolicyAssetsEditableTable";
import NotesDocumentsPolicyForm from "./PolicyNotesDocumentsForm";
import PolicyReview from "./PolicyReview";

interface ExtraFields {
  fields: FieldArrayWithId<PolicyInputExtended, "policyAssets", "id">[];
  append: UseFieldArrayAppend<PolicyInputExtended, "policyAssets">;
  remove: UseFieldArrayRemove;
  control?: Control<PolicyAssetInput[], any>;
}

interface PolicyFormProps {
  // onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => void;
  loading: boolean;
  data: CreatePolicyMutationResult["data"] | null | undefined;
  error: ApolloError[] | undefined;
  extraFields?: ExtraFields;
  getValues: UseFormGetValues<PolicyInputExtended>;
  originalPolicyAssets: PolicyAssetInputExtended[];
  currentPolicyAssets: PolicyAssetInputExtended[];
  setShowNewAssetDrawer: Dispatch<SetStateAction<boolean>>;
  setShowImportAssetsDrawer: Dispatch<SetStateAction<boolean>>;
  goToNextTab: () => void;
  handleTabChange: (index: number) => void;
  tabIndex: number;
  addPolicyAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
}

const policyAssetFields = {
  asset: "",
  catNat: false,
  coverTerrorism: false,
  coveredRiskType: "",
  coverType: "",
  evaluationType: "",
  exemption: false,
  title: "",
  author: "",
  creationPeriod: "",
};

const EditPolicyForm = ({
  onSubmit,
  loading,
  data,
  error,
  extraFields,
  getValues,
  currentPolicyAssets,
  originalPolicyAssets,
  setShowNewAssetDrawer,
  setShowImportAssetsDrawer,
  goToNextTab,
  handleTabChange,
  tabIndex,
  addPolicyAsset,
}: PolicyFormProps) => {
  const navigate = useNavigate();
  const userWantsToAddAssetsRefToScrollTo = useRef<HTMLDivElement>(null);
  const [showRecap, setShowRecap] = useState<boolean>(false);
  const { t } = useTranslation();
  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [loadAssets] = useGetAllAssetsByTitleLazyQuery();

  const [deletePolicyAsset] = useDeletePolicyAssetMutation();

  const removeAndDeletePolicyAsset = async (id: number | number[]) => {
    const { data: deletePolicyAssetData } = await deletePolicyAsset({
      variables: {
        input: {
          id: id.toString(),
        },
      },
    });

    extraFields.remove(id);
  };

  const loadParsedAssets = async (inputValue: string) => {
    const {
      data: assets,
      loading: assetsLoading,
      error: assetsError,
    } = await loadAssets({
      variables: {
        title_Contains: inputValue,
      },
    });

    if (!assets || !assets.allAssets) return null;

    const {
      allAssets: { edges },
    } = assets;

    return edges.flatMap(({ node }) => ({
      label: `${node.author as string} - ${node.title} - ${node.creationDate as string}`,
      value: node.id,
    }));
  };

  return (
    <form onSubmit={onSubmit}>
      <Tabs colorScheme="brand" variant="line" mt="4" index={tabIndex} onChange={handleTabChange}>
        <TabList>
          <Tab mr={8}>
            <Trans>Summary</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Policy Details</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Assets</Trans>
          </Tab>
          <Tab mr={8}>
            <Trans>Notes & Documents</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <PolicyReview values={getValues()} remove={extraFields.remove} />
          </TabPanel>
          <TabPanel p={0}>
            <PolicyDetailsFields policyType={getValues().policyType as PolicyType} />
          </TabPanel>
          <TabPanel p={0}>
            <VStack my="6" rounded="lg" align="left" py="6" spacing={0}>
              <VStack alignItems="flex-start" mb={8}>
                <Heading>Assets currently in policy</Heading>
                <Text variant="muted">
                  <Trans>Lorem ipsum dolor sit amet</Trans>
                </Text>
                <HStack pt={2} pb={8}>
                  <Button
                    onClick={() => {
                      setUserWantsToAddAssets(true);
                      setTimeout(() => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                        scroller.scrollTo("userWantsToAddAssetsRefToScrollTo", {
                          duration: 800,
                          delay: 100,
                          smooth: "easeInOutQuart",
                        });
                      }, 500);
                    }}
                  >
                    <Trans>Select an existing asset</Trans>
                  </Button>

                  <Button onClick={() => setShowNewAssetDrawer(true)}>
                    <Trans>Create new asset</Trans>
                  </Button>
                  <Button onClick={() => setShowImportAssetsDrawer(true)}>
                    <Trans>Import from csv</Trans>
                  </Button>
                </HStack>
                <PolicyAssetsEditableTable
                  data={originalPolicyAssets}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  remove={removeAndDeletePolicyAsset}
                  type={getValues().policyType as PolicyType}
                />
              </VStack>
            </VStack>
          </TabPanel>
          <TabPanel p={0}>
            <NotesDocumentsPolicyForm />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <FormFooter errors={error} data={data} loading={loading} title="policy">
        <HStack width="full" justify="space-between" my="4">
          <HStack>
            <Button isLoading={loading} loadingText="Loading" type="submit">
              <Trans>Update policy</Trans>
            </Button>
          </HStack>
        </HStack>
      </FormFooter>
      <Box my="8">
        {/* {error && (
          <Alert status="error" my="4">
            <>
              <AlertIcon />
              {error.message}
            </>
          </Alert>
        )} */}
      </Box>
    </form>
  );
};

export default EditPolicyForm;
