import "./index.css";
import "./i18n/i18n";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Fallback from "components/ui/Fallback";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_SENTRY_ENABLED && process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const unwrappedForDevelopment = (
  <Suspense>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
);

const wrappedForProduction = (
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <Fallback error={error} componentStack={componentStack} resetError={resetError} />
    )}
  >
    <Suspense>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Sentry.ErrorBoundary>
);

root.render(unwrappedForDevelopment);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
