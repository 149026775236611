/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Trans, useTranslation } from "react-i18next";
import { useController, useFormContext } from "react-hook-form";
import { useValidationContext } from "components/form/ValidationContext";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { DateRangePicker, RangeKeyDict, Range } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  Box,
  Text,
  FormLabel,
  HStack,
  Input,
  useDisclosure,
  useOutsideClick,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { InputWrapper } from "components/form";
import isFieldRequired from "helpers/isFieldRequired";

interface RangePickerProps {
  names: string[];
  label?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  value?: string | number | readonly string[] | boolean | null | undefined;
  disabled?: boolean;
  hidden?: boolean;
  required?: boolean;
}

const splitDateTime = (value: string) => {
  if (!value) return [];
  const splitDateTZRegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+|-]\d{2}:\d{2})/;
  const matches = value.match(splitDateTZRegex) || [];
  return matches;
};

// this needs to have FormProvider from 'react-hook-form' to consume its context
const RangePicker = ({
  names,
  label,
  placeholder,
  onChange,
  value,
  disabled = false,
  hidden = false,
  required,
}: RangePickerProps) => {
  const ref = useRef<HTMLDivElement>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();
  const { schema } = useValidationContext() || {};
  const {
    watch,
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const [range, setRange] = useState<Range>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [startDateInput, endDateInput] = names.map((name) => register(name));
  const [startDateController, endDateController] = names.map((name) =>
    useController({
      name,
      control,
      defaultValue: "",
    })
  );

  const {
    field: { value: startDateInitialValue },
  } = startDateController;
  const {
    field: { value: endDateInitialValue },
  } = endDateController;

  const startDateInitialValueWithoutTZ = (startDateInitialValue as string).split("+") || [];
  const startDateInitialValueSplitted =
    startDateInitialValueWithoutTZ.length > 0 ? startDateInitialValueWithoutTZ[0].split("T") : [];

  const endDateInitialValueWithoutTZ = (endDateInitialValue as string).split("+") || [];
  const endDateInitialValueSplitted =
    endDateInitialValueWithoutTZ.length > 0 ? endDateInitialValueWithoutTZ[0].split("T") : [];

  const [startDateValue, setStartDateValue] = useState(
    startDateInitialValueSplitted[0] ?? format(new Date(), "yyyy-MM-dd")
  );
  const [startTimeValue, setStartTimeValue] = useState(startDateInitialValueSplitted[1] ?? "00:00");
  // const [composedStartDateTimeValue, setComposedStartDateTimeValue] = useState(
  //   startDateController.field.value
  // );

  const [endDateValue, setEndDateValue] = useState(
    endDateInitialValueSplitted[0] ?? format(new Date(), "yyyy-MM-dd")
  );
  const [endTimeValue, setEndTimeValue] = useState(endDateInitialValueSplitted[1] ?? "23:59");
  // const [composedEndDateTimeValue, setComposedEndDateTimeValue] = useState(
  //   endDateController.field?.value
  // );

  const areFieldsRequired = schema ? names.map((name) => isFieldRequired(schema, name)) : false;
  //   console.log(name, isRequired);

  const handleChange = (rangesByKey: RangeKeyDict) => {
    const {
      selection: { startDate, endDate },
    } = rangesByKey;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setRange(rangesByKey.selection);

    const stringStartDate = format(startDate, "yyyy-MM-dd");
    const stringEndDate = format(endDate, "yyyy-MM-dd");
    setStartDateValue(stringStartDate);
    setEndDateValue(stringEndDate);
    // setSelectedRange(value);
  };

  useEffect(() => {
    const formattedStartDateTime = `${startDateValue}T${startTimeValue}`;
    // setComposedStartDateTimeValue(formattedStartDateTime);
    startDateController.field.onChange(formattedStartDateTime);
  }, [startDateValue, startTimeValue]);
  useEffect(() => {
    const formattedEndDateTime = `${endDateValue}T${endTimeValue}`;
    // setComposedEndDateTimeValue(formattedEndDateTime);
    endDateController.field.onChange(formattedEndDateTime);
  }, [endDateValue, endTimeValue]);

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  return (
    <HStack position="relative">
      <InputWrapper name={names[0]} error={errors[names[0]]}>
        {names[0] && (
          <FormLabel htmlFor={names[0]} mb="1" fontSize="xs" display="inline-flex">
            {" "}
            <Text>
              <Trans>{names[0]}</Trans>
            </Text>
            {areFieldsRequired && areFieldsRequired[0] && (
              <Text px="1" color="red.500">
                <Trans>(required)</Trans>
              </Text>
            )}
          </FormLabel>
        )}
        <Box>
          <Input
            type="datetime-local"
            width="auto"
            onClick={(e) => {
              e.preventDefault();

              onOpen();
            }}
            {...startDateInput}
            value={startDateController.field.value}
          />
          <Input
            disabled
            w="4.5rem"
            ml="2"
            name={`${names[0]}-timezone`}
            value={process.env.REACT_APP_TENANT_TIMEZONE ?? "UTC"}
          />
        </Box>
      </InputWrapper>

      <InputWrapper name={names[1]} error={errors[names[1]]}>
        {names[1] && (
          <FormLabel htmlFor={names[1]} mb="1" fontSize="xs" display="inline-flex">
            {" "}
            <Text>
              <Trans>{names[1]}</Trans>
            </Text>
            {areFieldsRequired && areFieldsRequired[1] && (
              <Text px="1" color="red.500">
                <Trans>(required)</Trans>
              </Text>
            )}
          </FormLabel>
        )}
        <Box>
          <Input
            width="auto"
            type="datetime-local"
            onClick={(e) => {
              e.preventDefault();
              onOpen();
            }}
            {...endDateInput}
            value={endDateController.field.value}
          />
          <Input
            ml="2"
            w="4.5rem"
            disabled
            name={`${names[1]}-timezone`}
            value={process.env.REACT_APP_TENANT_TIMEZONE ?? "UTC"}
          />
        </Box>
      </InputWrapper>

      {isOpen && (
        <Box
          position="absolute"
          border="1px solid brand.500"
          top="80px"
          zIndex="toast"
          background="gray.200"
          p="2"
          ref={ref}
          shadow="lg"
          ml={0}
          borderRadius="md"
          marginInlineStart="0 !important"
        >
          <VStack>
            <DateRangePicker ranges={[range]} onChange={handleChange} />
            <HStack justify="center" align="center">
              <FormLabel mb={0}>
                <Trans>From</Trans>
              </FormLabel>
              <Input
                type="time"
                name={`${names[0]}-time`}
                bg="white"
                defaultValue="00:00"
                value={startTimeValue}
                onChange={(e) => setStartTimeValue(e.target.value)}
              />

              <FormLabel mb={0}>
                <Trans>To</Trans>
              </FormLabel>
              <Input
                type="time"
                name={`${names[1]}-time`}
                bg="white"
                defaultValue="23:59"
                value={endTimeValue}
                onChange={(e) => setEndTimeValue(e.target.value)}
              />
            </HStack>
          </VStack>
        </Box>
      )}
    </HStack>
  );

  // if all else but value isn't boolean
  //   return (
  //     <Wrapper name={name} error={errors[name]} visibility={hidden ? "hidden" : "visible"}>
  //       {label && (
  //         <FormLabel htmlFor={name} mb="1" fontSize="xs" display="flex">
  //           <Text>{t(label)}</Text> {isRequired && <Text color="red">*</Text>}
  //         </FormLabel>
  //       )}
  //       <Input
  //         width="auto"
  //         id={name}
  //         name={name}
  //         disabled={disabled}
  //         placeholder={placeholder}
  //         type={type}
  //         {...register(name)}
  //         value={value}
  //         borderRadius="md"
  //         borderWidth="2px"
  //         borderColor="gray.200"
  //       />
  // {names.map((name) => (
  //     <input type="hidden" {...register(name)} />
  //   ))}
  //   <DateTimeRangePicker onChange={handleChange} value={value} />
  //     </Wrapper>)
};
export default RangePicker;
