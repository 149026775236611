/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useRef } from "react";
import { RiDeleteBinLine, RiEditLine, RiEyeLine, RiMoreFill } from "react-icons/ri";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import {
  Box,
  Icon,
  ButtonGroup,
  IconButton,
  Table as ChakraTable,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Heading,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverFooter,
  PopoverBody,
  useDisclosure,
  TableContainer,
  MenuGroup,
} from "@chakra-ui/react";
import { Pagination } from "./Pagination";
import styles from "./scss/index.module.scss";
import { Link, router, useNavigate } from "react-router-dom";
import { GlobalFilter, fuzzyTextFilterFn, DefaultColumnFilter } from "./GlobalFilter";
import TableCSVDownload from "./ExportCsv";
import { BiChevronDown } from "react-icons/bi";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import classNames from "classnames";
import { RiRefreshLine } from "react-icons/ri";
import { useOutsideClick } from "@chakra-ui/react";

const CustomCell = ({
  row,
  viewAction,
  editAction,
  deleteAction,
  pathname,
  deleteActionLoading = false,
  renderRowSubComponent = false,
  customActions = [],
}) => {
  const navigate = useNavigate();
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isCustomOpen,
    onToggle: onCustomToggle,
    onClose: onCustomClose,
    onOpen: onCustomOpen,
  } = useDisclosure();
  // const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useDisclosure();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const menuRef = useRef(null);
  useOutsideClick({
    ref: menuRef,
    handler: () => setIsMenuOpened(false),
  });

  return (
    <Box ref={menuRef}>
      <HStack justifyContent="flex-end">
        <Box px={4}>
          <Icon as={RiMoreFill} w={4} h={4} />
        </Box>
      </HStack>
      <Box
        className={classNames(styles["show-on-hover"], {
          [styles["force-open"]]: isMenuOpened,
        })}
        py={2}
        position="relative"
        zIndex="dropdown"
      >
        <ButtonGroup isAttached variant="outline" size="sm" bg="transparent">
          {(viewAction || pathname) && (
            <IconButton
              variant="outline"
              icon={<RiEyeLine w={6} h={4} />}
              onClick={() => {
                viewAction ? viewAction(row) : navigate(`${pathname}${row?.original?.id}`);
              }}
            />
          )}

          {renderRowSubComponent && (
            <IconButton
              variant="outline"
              icon={<RiEditLine w={6} h={4} />}
              {...row?.getToggleRowExpandedProps()}
            />
          )}
          {pathname && (
            <IconButton
              variant="outline"
              icon={<RiEditLine w={6} h={4} />}
              onClick={() => navigate(`${pathname}edit/${row?.original?.id}`)}
            />
          )}
          <Popover>
            <PopoverTrigger>
              {deleteAction && (
                <IconButton
                  icon={<RiDeleteBinLine color="red.500" />}
                  bg="white"
                  variant="outline"
                  loading={deleteActionLoading}
                  size="sm"
                ></IconButton>
              )}
            </PopoverTrigger>
            <Portal>
              <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                <PopoverHeader pt={4} fontWeight="bold" border="0">
                  {t("Are you sure you want to delete it?")}
                </PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>{t("Warning message")}</PopoverBody>
                <PopoverFooter
                  border="0"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  pb={4}
                >
                  <ButtonGroup size="sm">
                    <Button
                      variant="danger"
                      isLoading={deleteActionLoading}
                      onClick={() => {
                        deleteAction(row?.original?.id ?? row.id);
                      }}
                    >
                      {t("Delete it")}
                    </Button>
                    <Button>{t("Cancel")}</Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Portal>
          </Popover>
          <Menu
            isLazy={true}
            isOpen={isMenuOpened}
            flip={false}
            zIndex="modal"
            placement="bottom-end"
            preventOverflow
            strategy="fixed"
            autoSelect={false}
          >
            <MenuButton
              as={IconButton}
              aria-label="Other actions"
              icon={<RiMoreFill w={6} />}
              borderLeftRadius={0}
              onClick={() => {
                setIsMenuOpened(true);

                if (menuRef && menuRef.current) {
                  const hoveredRow = document.querySelector(`tr[data-id="${row?.original?.id}"]`);
                  hoveredRow.focus();
                }
              }}
            />
            <Portal>
              <MenuList py="2" px="0" shadow="2xl">
                <MenuGroup title={t("Actions")} fontWeight="400">
                  {pathname && (
                    <MenuItem
                      icon={<RiEyeLine w={6} />}
                      command="⌘V"
                      onClick={() => navigate(`${pathname}${row?.original?.id}`)}
                    >
                      View item
                    </MenuItem>
                  )}
                  {pathname && (
                    <MenuItem
                      icon={<RiEditLine w={6} />}
                      command="⌘E"
                      onClick={() => navigate(`${pathname}edit/${row?.original?.id}`)}
                    >
                      {t("Edit item")}
                    </MenuItem>
                  )}
                  {customActions &&
                    customActions.length > 0 &&
                    customActions.map(({ icon, onClick, name, command = "" }) => (
                      <MenuItem icon={icon} command={command} onClick={() => onClick(row)}>
                        {name}
                      </MenuItem>
                    ))}
                  {deleteAction && (
                    <MenuItem
                      icon={<RiDeleteBinLine w={6} />}
                      command="⌘⇧D"
                      onClick={onOpen}
                      fontWeight={600}
                      color="brand.900"
                    >
                      {t("Delete item")}
                    </MenuItem>
                  )}
                  {/* </MenuItem> */}
                </MenuGroup>
              </MenuList>
            </Portal>
          </Menu>

          {/* <IconButton onMouseEnter={onMenuOpen} onMouseLeave={onMenuClose} /> */}
        </ButtonGroup>
      </Box>

      {/* <IconButton aria-label="Add to friends" icon={<RiMoreFill w={6} />} /> */}
    </Box>
  );
};

export const renderCustomActions = ({
  viewAction,
  editAction,
  deleteAction,
  pathname,
  deleteActionLoading = false,
  renderRowSubComponent = false,
  customActions = [],
}) => {
  return {
    id: "customActions",
    accessor: "customActions",
    disableFilters: true,
    Cell: ({ row }) => (
      <CustomCell
        row={row}
        viewAction={viewAction}
        editAction={editAction}
        deleteAction={deleteAction}
        pathname={pathname}
        deleteActionLoading={deleteActionLoading}
        renderRowSubComponent={renderRowSubComponent}
        customActions={customActions}
      />
    ),
  };
};
