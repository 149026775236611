// import { useMutation, useQuery } from "@apollo/client";
import {
  //  Badge,
  Box,
  //  Button,
  Divider,
  //  Heading,
  //  HStack,
  Icon,
  Skeleton,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
// import { FocusableElement } from "@chakra-ui/utils";
import { DropdownFilter, QuickActions, Table } from "components/ui";
// import { GET_ALL_ASSETS } from "graphql/queries";
// import { SEARCH_ASSETS } from "graphql/queries/assets.gql";
import {
  useGetAllAssetsQuery,
  AssetNodeEdge,
  useDeleteAssetMutation
  // GetAllAssetsDocument,
  // GetAllAssets_allAssets_edges,
  // GetAllAssets_allAssets_edges_node,
} from "graphql/queries/generated/queries";

import getColumns from "helpers/getColumns";
// import { MutableRefObject, useRef /* , useState, useEffect */ } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiPlusCircle, BiCheckCircle, BiXCircle } from "react-icons/bi";
// import { Link } from "react-router-dom";

// import { SearchAssets_assets, SearchAssetsVariables, SearchAssets } from '../../graphql/queries/types/SearchAssets';
import { useEffect } from 'react';


// const CUSTOM_FIELDS = ["userType", "country"];
// const CUSTOM_COLUMNS = [
//   {
//     Header: "User Type",
//     Footer: "User Type",
//     accessor: "userType",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
//   {
//     Header: "Country",
//     Footer: "Country",
//     accessor: "country.code",
//     Filter: DropdownFilter,
//     Cell: ({ cell: { value } }: { cell: { value: string } }) => <Badge>{value}</Badge>,
//   },
// ];

// interface GetAllAssetsNodeDimensions extends GetAllAssets_allAssets_edges_node{
//   dimensions?: string
// }

const CUSTOM_FIELDS = [
  "dimensions",
  "netLengthUnit",
  "creationDate",
  "nft",
  "sku",
  "description",
  "validated",
  "inventoryNumber",
  "archiveNumber",
  "catalogNumber",
  "deleted",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Creation Date",
    Footer: "Creation Date",
    accessor: "creationDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      if (value) {
        const d = new Date(value).toLocaleDateString("it-IT");
        return <div>{d}</div>;
      }
      return <div />;
    },
  },
  {
    Header: "Dimensions WxHxD",
    Footer: "Dimensions",
    disableFilters: true,
    accessor: "dimensions",
    disableSortBy: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  },
  {
    Header: "N.inventory",
    Footer: "N.inventory",
    disableFilters: true,
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  },
  {
    Header: "N.archive",
    Footer: "N.archive",
    disableFilters: true,
    accessor: "archiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{value}</div>,
  },
  // {
  //   Header: "N.catalog",
  //   Footer: "N.catalog",
  //   disableFilters: true,
  //   accessor: "catalogNumber",
  //   Cell: ({ cell: { value } }: { cell: { value: string } }) => <div>{ value }</div>
  // },
  {
    Header: "nft",
    Footer: "nft",
    accessor: "nft",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  {
    Header: "validated",
    Footer: "validated",
    accessor: "validated",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? (
        <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
      ) : (
        <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
      ),
  },
  // {
  //   Header: "deleted",
  //   Footer: "deleted",
  //   accessor: "deleted",
  //   Filter: DropdownFilter,
  //   Cell: ({ cell: { value } }: { cell: { value: Date} }) =>
  //   value ? (
  //     <Icon as={BiCheckCircle} color="primaryGreen" size="md" w={5} h={5} />
  //   ) : (
  //     <Icon as={BiXCircle} color="primaryFuchsia" size="md" w={5} h={5} />
  //   ),
  // }
];

const BATCH_ACTIONS = [
  {
    name: <Trans>Delete selected assets</Trans>,
    action: (selectedRows: AssetNodeEdge) => console.log(selectedRows),
  },
];
const FILTERABLE_COLUMNS = ["deleted" /* , "status" */];
const QUICK_ACTIONS = [
  {
    name: "Add new assets",
    href: "new",
    icon: <BiPlusCircle />,
  },
  //   {
  //     name: "Invite user",
  //     href: "invite",
  //     icon: <BiPlusCircle />,
  //   },
];

const AssetsPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  // const  [search, setSearch] = useState<string>("");
  // const  [first, setFirst] = useState<number>(0);
  // const  [skip, setSkip]   = useState<number>(0);
  // const [tableData, setTableData] = useState<SearchAssets | undefined | null>(null);

  // const { data: apiResponse, loading, error, refetch } = useQuery<SearchAssets, SearchAssetsVariables>(SEARCH_ASSETS, {
  //   variables: { search, first, skip }
  // })

  // useEffect(() => {
  //   setTableData(apiResponse);
  // }, [apiResponse])

  // const { data, loading, error, refetch } = useQuery<GetAllAssetsDocument>(GET_ALL_ASSETS);

  const { data, loading, error, refetch } = useGetAllAssetsQuery();

  const { isOpen, onOpen, onClose } = useDisclosure();


  const [ deleteAsset, {
    data: deleteAssetData, error: deleteAssetError, loading: deleteAssetLoading
  }] = useDeleteAssetMutation();
  // const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  //   const [
  //     toggleIsActiveUser,
  //     {
  //       data: toggleIsActiveUserData,
  //       loading: toggleIsActiveUserLoading,
  //       error: toggleIsActiveUserError,
  //     },
  //   ] = useMutation<ToggleUserIsActive, ToggleUserIsActiveVariables>(TOGGLE_USER_IS_ACTIVE);
  useEffect(() => {
    if (deleteAssetData) {
      toast({
        title: t("Asset has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deleteAssetError)
      toast({
        title: t("Asset couldn't be deleted."),
        description: t(`Error: ${deleteAssetError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deleteAssetData, deleteAssetError]);

  if (loading) return <Skeleton w="100%" h="20vh" isLoaded={!loading} />;
  if (error) return <p>{error.toString()}</p>;

  // if (!tableData || !tableData.assets || (tableData.assets.length === 0)) return null;

  // if (tableData.assets[0] == null) {return null;}

  // const dataToGetColumns: SearchAssets_assets = tableData.assets[0];

  if (!data || !data.allAssets || data.allAssets.edges.length === 0) return null;
  const {
    allAssets: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const nodes = edges.map((edge) => {
    let node: {
      title: string | null;
      category: string | null;
      subcategory: string | null;
      author: string | null;
      creationDate: string | null;
      netWidth: number | null;
      netHeight: number | null;
      netDepth: number | null;
      netLengthUnit: string | null;
    } = {
      title: null,
      category: null,
      subcategory: null,
      author: null,
      creationDate: null,
      netWidth: null,
      netHeight: null,
      netDepth: null,
      netLengthUnit: null,
    };
    if (edge) {
      node = {
        title: null,
        category: null,
        subcategory: null,
        author: null,
        creationDate: null,
        netWidth: null,
        netHeight: null,
        netDepth: null,
        netLengthUnit: null,
        ...edge.node,
      };
    }
    return node;
  });

  const newEdges = nodes.map((node) => {
    const {
      title,
      category,
      subcategory,
      author,
      creationDate,
      netWidth,
      netHeight,
      netDepth,
      netLengthUnit,
      ...rest
    } = node;

    let dimensions = null;
    if (netHeight != null && netWidth != null && netDepth != null) {
      dimensions = `${netWidth}x${netHeight}x${netDepth}` || "";
      if (netLengthUnit != null) {
        dimensions = `${dimensions} ${netLengthUnit}`;
      }
    }
    return { title, category, subcategory, author, creationDate, dimensions, ...rest };
  });

  if (!newEdges || newEdges.length === 0 || !newEdges[0]) return null;

  const dataToGetColumns = newEdges[0];

  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
    filterableColumns: FILTERABLE_COLUMNS,
  });

  const onDelete = async (id: string) => {
    await deleteAsset({
      variables: {
        input: {
          id
        }
      }
    });

    await refetch();
  };

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          data={newEdges}
          pathname="/mgmt/assets/"
          deleteAction={(id: string) => onDelete(id)}
          isLoading={deleteAssetLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default AssetsPage;
