/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import coverPNG from "assets/placeholder.png";
import { PasswordInput } from "components/ui";
import { useFormik } from "formik";

import { useResetUserPasswordMutation } from "graphql/queries/generated/queries";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useParams } from "react-router-dom";

import styles from "./scss/Onboard.module.scss";

function validateEmail(value: string) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return { error };
}

const OnboardPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const [
    resetPassword,
    { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError },
  ] = useResetUserPasswordMutation();

  const formikRecoverPassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    onSubmit: async ({ newPassword, confirmPassword }) => {
      if (!token) return;
      await resetPassword({
        variables: { input: { newPassword, confirmPassword, resetPasswordToken: token } },
      });
    },
  });

  if (!token) return <Navigate to="/" />;
  return (
    <div className={styles["with-sidebar"]}>
      <div className={styles.placeholder}>
        <img src={coverPNG} alt="" className={styles.placeholder__illustration} />
      </div>
      <div className={styles.login}>
        <div>
          <Stack spacing="4" divider={<StackDivider />}>
            <form id="requestPasswordResetToken" onSubmit={formikRecoverPassword.handleSubmit}>
              <VStack width="full" spacing="6" bgColor="white" p="8" align="flex-start">
                {resetPasswordError && (
                  <Alert status="error">
                    <>
                      <AlertIcon />
                      {resetPasswordError.message}
                    </>
                  </Alert>
                )}

                {resetPasswordData && (
                  <Alert status="info">
                    <VStack alignItems="flex-start">
                      <Text>{t("We've set your new password!")}</Text>
                      <Link to="/login">{t("You can now log in")}</Link>
                    </VStack>
                  </Alert>
                )}

                <Heading>{t("Welcome to speakart.io!")}</Heading>
                <Text align="left">{t("Set your password before logging in.")}</Text>

                <FormControl isRequired>
                  <FormLabel htmlFor="oldPassword">{t("New Password")}</FormLabel>
                  <PasswordInput
                    name="newPassword"
                    onChange={formikRecoverPassword.handleChange}
                    value={formikRecoverPassword.values.newPassword}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="oldPassword">{t("Confirm Password")}</FormLabel>
                  <PasswordInput
                    name="confirmPassword"
                    onChange={formikRecoverPassword.handleChange}
                    value={formikRecoverPassword.values.confirmPassword}
                  />
                </FormControl>

                <HStack width="full">
                  <Button
                    size="sm"
                    isLoading={resetPasswordLoading}
                    loadingText="Loading"
                    type="submit"
                  >
                    {t("Continue")}
                  </Button>
                </HStack>
              </VStack>
            </form>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default OnboardPage;
