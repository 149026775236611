import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { yupResolver } from "@hookform/resolvers/yup";
import { OfficeInputSchema } from "graphql/queries/generated/validation-schema";
import { FormInputHook } from "components/ui";
import { useState, useEffect } from "react";
import cleanObject from "helpers/cleanObject";
import {
  useDeleteOfficeMutation,
  useGetOfficesByRegistryIdLazyQuery,
  OfficeInput,
  useCreateOfficeMutation,
  useUpdateOfficeMutation,
} from "graphql/queries/generated/queries";

interface OfficeFormProps {
  registryId: string;
}

const OfficeForm = ({ registryId }: OfficeFormProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const methods = useForm<OfficeInput>({
    defaultValues: {
      name: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      main: false,
      notActive: false,
      registry: registryId,
    },
    resolver: yupResolver(OfficeInputSchema()),
    mode: "onChange",
  });

  const { setValue } = methods;

  const [selectedId, setSelectedId] = useState<string | null>(null);

  const onEditModalOpen = (
    id: string,
    name: string,
    address: string,
    city: string,
    zip: string,
    state: string,
    country: string,
    main: boolean,
    notActive: boolean
  ) => {
    setSelectedId(id);
    setValue("name", name);
    setValue("address", address);
    setValue("city", city);
    setValue("zip", zip);
    setValue("state", state);
    setValue("country", country);
    setValue("main", main);
    setValue("notActive", notActive);
    onOpen();
  };

  const [loadOffices, refetch] = useGetOfficesByRegistryIdLazyQuery();
  const [deleteOffice, { loading: deleteLoading }] = useDeleteOfficeMutation();

  const [offices, setOffices] = useState<
    Array<{
      id: string;
      name: string;
      address: string;
      city: string;
      zip: string;
      state: string;
      country: string;
      main: boolean;
      notActive: boolean;
    }>
  >([]);

  // eslint-disable-next-line consistent-return
  const loadParsedOffices = async () => {
    const {
      data: offices,
      loading: officesLoading,
      error: officesError,
    } = await loadOffices({
      variables: {
        registryId,
      },
    });

    if (!offices || !offices.allOffices) return null;
    const {
      allOffices: { edges },
    } = offices;

    setOffices(
      edges.flatMap(({ node }) => ({
        id: node.id,
        name: node.name,
        address: node.address,
        city: node.city,
        zip: node.zip,
        state: node.state,
        country: node.country,
        main: node.main,
        notActive: node.notActive,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        registryId,
      }))
    );
    return offices;
  };

  const onDelete = async (id: string) => {
    await deleteOffice({
      variables: {
        input: {
          id,
        },
      },
    });

    const assetElements = await loadParsedOffices();
    return assetElements;
  };

  const [
    createOffice,
    { data: createOfficeData, loading: createOfficeLoading, error: createOfficeError },
  ] = useCreateOfficeMutation();
  const [
    updateOffice,
    { data: updateOfficeData, loading: updateOfficeLoading, error: updateOfficeError },
  ] = useUpdateOfficeMutation();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadParsedOffices();
    return undefined;
  }, []);

  const onCloseModal = () => {
    setSelectedId(null);
    setValue("name", "");
    setValue("address", "");
    setValue("city", "");
    setValue("zip", "");
    setValue("state", "");
    setValue("country", "");
    setValue("main", false);
    setValue("notActive", true);

    onClose();
  };

  const onSubmit: SubmitHandler<OfficeInput> = async (values, e) => {
    const officeData = cleanObject(values) as Partial<OfficeInput>;
    let offices;
    let response;

    if (selectedId) {
      response = await updateOffice({
        variables: {
          input: {
            officeData: {
              name: officeData.name,
              address: officeData.address,
              city: officeData.city,
              zip: officeData.zip,
              state: officeData.state,
              country: officeData.country,
              main: officeData.main,
              notActive: officeData.notActive,
              registry: registryId,
            },
            id: selectedId,
          },
        },
      });

      if (
        response.data &&
        response.data.updateOffice &&
        response.data.updateOffice.office &&
        response.data.updateOffice.office.id
      ) {
        offices = await loadParsedOffices();
        onCloseModal();
      }
    } else {
      response = await createOffice({
        variables: {
          input: {
            officeData: {
              name: officeData.name,
              address: officeData.address,
              city: officeData.city,
              zip: officeData.zip,
              state: officeData.state,
              country: officeData.country,
              main: officeData.main,
              notActive: officeData.notActive,
              registry: registryId,
            },
          },
        },
      });

      if (
        response.data &&
        response.data.createOffice &&
        response.data.createOffice.office &&
        response.data.createOffice.office.id
      ) {
        offices = await loadParsedOffices();
        onCloseModal();
      }
    }

    return offices;
  };

  const onError = (errors: any, e: any) => console.log(errors, e);
  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <Trans>Name</Trans>
            </Th>
            <Th>
              <Trans>Address</Trans>
            </Th>
            <Th>
              <Trans>City</Trans>
            </Th>
            <Th>
              <Trans>Zip</Trans>
            </Th>
            <Th>
              <Trans>State</Trans>
            </Th>
            <Th>
              <Trans>Country</Trans>
            </Th>
            <Th />
          </Tr>
        </Thead>

        <Tbody>
          {offices.map(
            ({ name, address, city, zip, state, country, id, main, notActive }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Tr key={id}>
                <Td>{name}</Td>
                <Td>{address}</Td>
                <Td>{city}</Td>
                <Td>{zip}</Td>
                <Td>{state}</Td>
                <Td>{country}</Td>
                <Td>
                  {/* <Stack isInline><Icon name="edit"/><Icon name="delete" /></Stack> */}
                  <IconButton
                    variant="outline"
                    icon={<RiEditLine />}
                    onClick={() =>
                      onEditModalOpen(id, name, address, city, zip, state, country, main, notActive)
                    }
                    aria-label="Edit asset element"
                  />
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        icon={<RiDeleteBinLine color="red.500" />}
                        bg="white"
                        variant="outline"
                        size="sm"
                        aria-label="Delete asset element"
                      />
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
                        <PopoverHeader pt={4} fontWeight="bold" border="0">
                          <Trans>Are you sure you want to delete it?</Trans>
                        </PopoverHeader>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Trans>Warning message</Trans>
                        </PopoverBody>
                        <PopoverFooter
                          border="0"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          pb={4}
                        >
                          <ButtonGroup size="sm">
                            <Button
                              variant="danger"
                              isLoading={deleteLoading}
                              onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                                onDelete(id);
                              }}
                            >
                              <Trans>Delete it</Trans>
                            </Button>
                            <Button>
                              <Trans>Cancel</Trans>
                            </Button>
                          </ButtonGroup>
                        </PopoverFooter>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Td>
              </Tr>
            )
          )}
        </Tbody>
      </Table>

      <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
        <Button onClick={onOpen} variant="primary">
          <Trans>Add new office</Trans>
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} size="5xl" onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {selectedId ? <Trans>Update office</Trans> : <Trans>Add new office</Trans>}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody pb={6}>
            <FormProvider {...methods}>
              {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form id="addOffice" onSubmit={handleSubmit}>
                <Box maxW="6xl">
                  <Stack spacing="4" divider={<StackDivider />}>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Name" name="name" />
                      <FormInputHook label={t("Main")} name="main" type="checkbox" />
                      <FormInputHook label={t("Not active")} name="notActive" type="checkbox" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="Address" name="address" />
                      <FormInputHook label="City" name="city" />
                      <FormInputHook label="Zip" name="zip" />
                    </HStack>
                    <HStack alignItems="flex-end">
                      <FormInputHook label="State" name="state" />
                      <FormInputHook label="Country" name="country" />
                    </HStack>
                  </Stack>
                </Box>
              </form>
            </FormProvider>
          </ModalBody>

          <ModalFooter>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <ButtonGroup pb={6} mt={2} display="block" textAlign="left" variant="outline">
              <Button variant="primary" onClick={handleSubmit} mr={1}>
                <Trans>Save</Trans>
              </Button>
              <Button onClick={onCloseModal}>
                <Trans>Cancel</Trans>
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OfficeForm;
