import { useTranslation } from "react-i18next";
import { Box, HStack, VStack,  Text } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import { ApolloError } from "@apollo/client";
import {
  CreateAssetMutation,
  UpdateAssetMutation,
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import AssetElementForm from './asset_elements/AssetElementForm';
interface PhysicalFeaturesNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  assetId: string;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean | undefined;
  updateAssetLoading: boolean | undefined;
}

const VOID_OPTION = [{ name: "-- Select --", value: "" }];



const PhysicalFeaturesNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  assetId,
  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
  category,
  children,
}: PhysicalFeaturesNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();



  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasBase = watch("hasBase");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasWoodFrame = watch("hasWoodFrame");

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isComposed = watch("isComposed");

  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}
          <Box alignItems="flex-start">
            <Text textAlign="left">Categoria {category}</Text>
          </Box>


          <FieldGroup title={t("Asset elements")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Asset is composed of several elements"
                  type="checkbox"
                  name="isComposed"
                />

                {isComposed && (
                  <FormInputHook label="" name="numberOfElements" placeholder="Number of elements" type="number" />
                )}
              </HStack>

              {isComposed && <AssetElementForm assetId={assetId} />}
            </VStack>
          </FieldGroup>



          <FieldGroup title={t("Surface characteristics")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label=""
                  name="surfaceCharacteristics"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup>



          <FieldGroup title={t("Base")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Has base"
                  type="checkbox"
                  name="hasBase"
                />

                {hasBase && (
                  <FormInputHook label="" placeholder="Base details" name="baseDetails" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>

          <FieldGroup title={t("Frame")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Has frame"
                  type="checkbox"
                  name="hasWoodFrame"
                />

                {hasWoodFrame && (
                  <FormInputHook label="" placeholder="Shield details" name="woodFrameDetails" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>



          {/* <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
          >
            {t("Save")}
          </Button>
          {children} */}
        {/* </Stack>
      </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default PhysicalFeaturesNewCategoryForm;
