import { Box, FormLabel, Select, Switch, Input, HStack, Text } from "@chakra-ui/react";
import React, { HTMLInputTypeAttribute, ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { Field, useField } from "formik";

interface FormInputProps {
  name: string;
  label: string;
  type?: HTMLInputTypeAttribute;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  value?: string | number | readonly string[] | boolean | null | undefined;
  options?: FormSelectOptionProps[];
  formikEnabled?: boolean;
}

interface FormSelectOptionProps {
  value: string;
  name: string;
}

export const Wrapper = ({ children }: { children: React.ReactNode }) => (
  <Box pb={4} pr={6}>
    {children}
  </Box>
);

const FormInput = ({
  name,
  label,
  type,
  onChange,
  value,
  options,
  formikEnabled = false,
}: FormInputProps) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);

  if (type === "checkbox")
    return (
      <HStack pb={8}>
        <FormLabel htmlFor={name} mb="0">
          {label}
        </FormLabel>
        {formikEnabled ? (
          <Field as={Switch} id={name} type="checkbox" />
        ) : (
          <Switch id={name} onChange={onChange} />
        )}
        <Text variant="muted">{value ? "(Yes)" : "(No)"}</Text>
      </HStack>
    );

  if (options && typeof value !== "boolean") {
    const optionsComponent = options.map(({ name: oName, value: oValue }) => (
      <option key={oValue} value={oValue}>
        {oName}
      </option>
    ));

    return (
      <Wrapper>
        <FormLabel htmlFor={name}>{t(label)}</FormLabel>
        {formikEnabled ? (
          <Field as={Select} width="auto" id={name} name={name} value={value}>
            {optionsComponent}
          </Field>
        ) : (
          <Select width="auto" id={name} name={name} onChange={onChange} value={value}>
            {optionsComponent}
          </Select>
        )}
      </Wrapper>
    );
  }

  return typeof value !== "boolean" ? (
    <Wrapper>
      <FormLabel htmlFor={name}>{t(label)}</FormLabel>
      {formikEnabled ? (
        <Field
          as={Input}
          width="auto"
          id={name}
          name={name}
          type={type}
          value={value}
          borderColor={meta.touched && meta.error ? "red.500" : "inherit"}
        />
      ) : (
        <Input
          width="auto"
          id={name}
          name={name}
          type={type}
          onChange={onChange}
          value={value}
          borderColor={meta.touched && meta.error ? "red.500" : "inherit"}
        />
      )}
      {meta.touched && meta.error && <Text color="red.500">{meta.error}</Text>}
    </Wrapper>
  ) : null;
};
export default FormInput;
