import { FormLabel, Icon, Input, InputGroup, Text, InputLeftElement } from "@chakra-ui/react";
import { LegacyRef, useRef } from "react";
import { Trans } from "react-i18next";
import { BiFile } from "react-icons/bi";
import { FormInputProps } from "./type";
import { Wrapper } from "./Wrapper";

const FileUpload = ({
  name,
  label,
  type,
  errors,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  watch,
  required,
}: FormInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  return (
    <Wrapper name={name} error={errors[name]}>
      {label && (
        <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
          {" "}
          <Trans>label</Trans>
          {required && (
            <Text px="1" color="red.500">
              <Trans>(required)</Trans>
            </Text>
          )}
        </FormLabel>
      )}
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={BiFile} />
        </InputLeftElement>
        <input
          type="file"
          multiple
          // onChange={(e) => {
          //   if (!e.currentTarget.files) return;
          //   formikUpdateSignature
          //     .setFieldValue("signature", e?.currentTarget?.files[0])
          //     .then((evt) => console.log(evt))
          //     .catch((err) => console.log(err));
          // }}
          accept="image/*"
          name="signature"
          ref={ref as unknown as LegacyRef<HTMLInputElement>}
          style={{ display: "none" }}
        />
        <Input
          width="auto"
          id={name}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          {...register(name)}
          borderRadius="md"
          borderWidth="2px"
          borderColor="gray.200"
          multiple
          onClick={(): void => {
            if (!ref || !ref?.current) return;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            ref?.current?.click();
          }}
        />
      </InputGroup>
    </Wrapper>
  );
};

export default FileUpload;
