import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFooter } from "components/ui";
import { useCreateRegistryMutation, RegistryInput } from "graphql/queries/generated/queries";
import { RegistryInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import RegistryFields from "../shared/RegistryFields";
const NewRegistryPage = () => {
  console.log("ciao");

  const [createRegistry, { data, loading, error }] = useCreateRegistryMutation();
  const methods = useForm<RegistryInput>({
    defaultValues: {
      isCompany: false,
    },
    resolver: yupResolver(RegistryInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof RegistryInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<RegistryInput> = async (formData, e) => {
    console.log(formData);

    await createRegistry({
      variables: {
        input: {
          registryData: {
            ...formData,
          },
        },
      },
    });
    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RegistryFields watch={methods.watch} />
          <FormFooter data={data} loading={loading} errors={[error]} title="Registry entry" />
        </form>
      </FormProvider>
    </Stack>
  );
};

export default NewRegistryPage;
