import { useMemo } from "react";
import { Select } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { useAuth } from "contexts/AuthProvider";

export const createdByFilterFunc = (rows, id, filterValue) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  rows.filter((row) => {
    const rowValue = row.values[id];
    if (filterValue === "createdByInternals")
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return row.original?.createdBy?.userType === "INTERNAL";
    if (filterValue === "createdByExternals")
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return row.original?.createdBy?.userType === "EXTERNAL";
    return filterValue.includes(rowValue);
  });

function CreatedByFilter({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) {
  const { user } = useAuth();
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      width="auto"
      bgColor="white"
      shadow={"sm"}
      size="sm"
      borderRadius="md"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">
        <Trans>Created by</Trans>
      </option>
      <option key="createdByMe" value={user.name}>
        <Trans>Created by me</Trans>
      </option>
      <option key="createdByInternals" value="createdByInternals">
        <Trans>Created by internals</Trans>
      </option>
      <option key="createdByExternals" value="createdByExternals">
        <Trans>Created by externals</Trans>
      </option>
    </Select>
  );
}

export default CreatedByFilter;
