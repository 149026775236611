import { useTranslation } from "react-i18next";
import { Box, FormLabel, HStack, VStack, Text } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import { ApolloError } from "@apollo/client";
import {
  CreateAssetMutation,
  UpdateAssetMutation,
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import ASSET_COSTANTS from "constants/asset";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface EnvironmentalConditionsNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean;
  updateAssetLoading: boolean;
}

const VOID_OPTION = [{ label: "-- Select --", value: "" }];

const EnvironmentalConditionsNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
  category,
  children,
}: EnvironmentalConditionsNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();


  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}
          <Box alignItems="flex-start">
            <Text textAlign="left">Categoria {category}</Text>
          </Box>


          <FieldGroup title={t("Environment details")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack alignItems="flex-end">
                <Box>
                  <FormInputHook label="" name="optimalLightLevelIntensity" placeholder="Optimal light" type="number" />
                  <FormInputHook
                    label=""
                    options={VOID_OPTION.concat(ASSET_COSTANTS.LIGHT_UNIT)}
                    name="lightUnit"
                  />
                </Box>

                <Box>
                  <FormInputHook label="" name="optimalTemperature" placeholder="Optimal temperature" type="number" />
                  <FormInputHook
                    label=""
                    options={VOID_OPTION.concat(ASSET_COSTANTS.TEMPERATURE_UNIT)}
                    name="temperatureUnit"
                  />
                </Box>
              </HStack>


              <HStack alignItems="flex-end" >
                <FormInputHook label="" name="optimalHumidity" placeholder="Optimal humidity" type="number"/>
                <FormLabel>&nbsp;%</FormLabel>

              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Notes")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label=""
                  name="enviromentalConditionsNotes"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup>




          {/* <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
          >
            {t("Save")}
          </Button>
          {children} */}
        {/* </Stack>
      </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default EnvironmentalConditionsNewCategoryForm;
