import { Text } from "@chakra-ui/react";
import { Table } from "components/ui";
import { PolicyAssetInput } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { UseFieldArrayRemove } from "react-hook-form";
import { Trans } from "react-i18next";
import { PolicyAssetInputExtended, PolicyType } from "../../../types";
import PCPolicyAssetSummary from "../../../pc-policies/PCPolicyAssetSummary";
import TEPolicyAssetSummary from "../../../te-policies/TEPolicyAssetSummary";
import { PC_CUSTOM_COLUMNS, PC_CUSTOM_FIELDS, PC_HIDDEN_FIELDS } from "./PCCustomColumns";
import { TE_CUSTOM_COLUMNS, TE_CUSTOM_FIELDS, TE_HIDDEN_FIELDS } from "./TECustomColumns";

interface PolicyAssetEditableTableProps {
  data: PolicyAssetInputExtended[];
  mode?: "view" | "edit";
  remove?: UseFieldArrayRemove | ((arg0: number) => void);
  type: PolicyType;
}

const OnViewPolicyAssetsTable = ({
  data,
  remove,
  type,
  mode = "edit",
}: PolicyAssetEditableTableProps) => {
  if (!data || data?.length === 0)
    return (
      <Text>
        <Trans>No assets has been added yet.</Trans>
      </Text>
    );

  const columns = getColumns<PolicyAssetInput>({
    data: data[0],
    customColumns: type === "PERMANENT_COLLECTION" ? PC_CUSTOM_COLUMNS : TE_CUSTOM_COLUMNS,
    customFields: type === "PERMANENT_COLLECTION" ? PC_CUSTOM_FIELDS : TE_CUSTOM_FIELDS,
  });

  return (
    <Table
      //   batchActions={BATCH_ACTIONS}
      hiddenColumns={type === "PERMANENT_COLLECTION" ? PC_HIDDEN_FIELDS : TE_HIDDEN_FIELDS}
      columns={columns}
      data={data}
      deleteAction={(id: number) => {
        remove(id);
      }}
      // TODO: this is a shitshow, need to refactor
      renderRowSubComponent={
        type === "PERMANENT_COLLECTION" ? PCPolicyAssetSummary : TEPolicyAssetSummary
      }
    />
  );
};

export default OnViewPolicyAssetsTable;
