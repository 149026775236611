import { ClientNode, useGetCurrentClientQuery } from "graphql/queries/generated/queries";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const TenantContext = createContext(null);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const useTenantContext = (): ContextValue => useContext(TenantContext);

interface ContextValue {
  tenant: ClientNode;
}
interface TenantProviderProps {
  children: JSX.Element;
  tenantId: string;
}

export const TenantProvider = ({ children, tenantId }: TenantProviderProps) => {
  const [tenant, setTenant] = useState<ClientNode | null>(null);
  const { data, error, loading } = useGetCurrentClientQuery({
    variables: {
      id: tenantId,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data?.client) setTenant(data.client);
  }, [data]);

  const value = useMemo(
    () => ({
      tenant,
      setTenant,
    }),
    [tenant]
  );

  //   if (data) setTenant(data.client);
  // Not used in the demo, but maybe usefull
  // if we ever need the described schema

  // const value = useMemo(() => ({ schema, describeSchema, describedSchema }), []);
  // eslint-disable-next-line react/jsx-no-constructed-context-values, @typescript-eslint/no-unsafe-assignment
  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
};
