import palette from "./palette";

export default {
  EXPIRED: {
    backgroundColor: palette.lightRed,
    color: palette.red,
  },
  CURRENT: {
    backgroundColor: palette.lightPrimary,
    color: palette.primary,
  },
  PENDING: {
    backgroundColor: palette.lightOrange,
    color: palette.orange,
  },
  APPROVED: {
    backgroundColor: palette.lightGreen,
    color: palette.green,
  },
  PERMANENT_COLLECTION: {
    backgroundColor: palette.lightYellow,
    color: palette.orange,
  },
  TEMPORARY_EXHIBITION: {
    backgroundColor: palette.lightEmerald,
    color: palette.emerald,
  },
  NAIL_TO_NAIL: {
    backgroundColor: "#d2eed5",
    color: "#4ebc59",
  },
  STAY_RISK: {
    backgroundColor: "#d0e2f1",
    color: "#438bc7",
  },
  TRAVEL_RISK: {
    backgroundColor: "#f7bfd6",
    color: "#e72371",
  },
  ALL_RISK: {
    backgroundColor: "#cfe9f2",
    color: "#3fa7ca",
  },
  FIRST_RISK: {
    backgroundColor: "#e3d9e7",
    color: "#9168a1",
  },
  AGREED_VALUE: {
    backgroundColor: "#E6FAC7",
    color: "#77BC10",
  },
  DECLARED_VALUE: {
    backgroundColor: "#F2D3BA",
    color: "#2F9D50",
  },
  AGREED_AND_DECLARED_VALUE: {
    backgroundColor: "#D0F1DA",
    color: "#2A8D48",
  },
  //   NAIL_TO_NAIL: {
  //     backgroundColor: "black",
  //     color: "white",
  //   },
  //   NAIL_TO_NAIL: {
  //     backgroundColor: "black",
  //     color: "white",
  //   },
  //   NAIL_TO_NAIL: {
  //     backgroundColor: "black",
  //     color: "white",
  //   },
  //   NAIL_TO_NAIL: {
  //     backgroundColor: "black",
  //     color: "white",
  //   },
  //   NAIL_TO_NAIL: {
  //     backgroundColor: "black",
  //     color: "white",
  //   },
};
