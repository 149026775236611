import { useTranslation } from "react-i18next";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import { ApolloError } from "@apollo/client";
import {
  CreateAssetMutation,
  UpdateAssetMutation,
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import ASSET_COSTANTS from "constants/asset";

// interface DimensionsPanelNewCategoryFormProps {
//     handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
//     handleChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
//     handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void
//     values: {
//         netWidth: string
//         netHeight: string
//         netDepth: string
//     }
//     category: string
//     children?: React.ReactNode
//     createAssetData: CreateAssetMutation | null | undefined
//     updateAssetData: UpdateAssetMutation | null | undefined
//     createAssetError: ApolloError | undefined
//     updateAssetError: ApolloError | undefined
//     createAssetLoading: boolean
//     updateAssetLoading: boolean
// }

interface DimensionsPanelNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean | undefined;
  updateAssetLoading: boolean | undefined;
}

const VOID_OPTION = [{ name: "-- Select --", value: "" }];

const DimensionsPanelNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
  category,
  children,
}: DimensionsPanelNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();
  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}
          <Box alignItems="flex-start">
            <Text textAlign="left">Categoria {category}</Text>
          </Box>

          <FieldGroup title={t("Net dimensions")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Length unit -", value: "" }].concat(ASSET_COSTANTS.LENGTH_UNIT)}
                  name="netLengthUnit"
                />
                <FormInputHook label="" name="netWidth" placeholder="Width" type="number" />X
                <FormInputHook label="" name="netHeight" placeholder="Height" type="number" />
                <FormInputHook label="" name="netDepth" placeholder="Depth" type="number" />
                <FormInputHook label="" name="netDiameter" placeholder="Diameter" type="number" />
              </HStack>
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Weight unit -", value: "" }].concat(ASSET_COSTANTS.WEIGHT_UNIT)}
                  name="netWeightUnit"
                />
                <FormInputHook label="" name="netWeight" placeholder="Weight" type="number" />
              </HStack>
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Dimensions acquisition -", value: "" }].concat(
                    ASSET_COSTANTS.DIMENSIONS_ACQUISITIONS
                  )}
                  name="netDimensionsAcquisition"
                />
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Gross dimensions")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Length unit -", value: "" }].concat(ASSET_COSTANTS.LENGTH_UNIT)}
                  name="grossLengthUnit"
                />
                <FormInputHook label="" name="grossWidth" placeholder="Width" type="number" />X
                <FormInputHook label="" name="grossHeight" placeholder="Height" type="number" />
                <FormInputHook label="" name="grossDepth" placeholder="Depth" type="number" />
                <FormInputHook label="" placeholder="Diameter" name="grossDiameter" type="number" />
              </HStack>
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Weight unit -", value: "" }].concat(ASSET_COSTANTS.WEIGHT_UNIT)}
                  name="grossWeightUnit"
                />
                <FormInputHook label="" name="grossWeight" placeholder="Weight" type="number" />
              </HStack>
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  options={[{ label: "- Dimensions acquisition -", value: "" }].concat(
                    ASSET_COSTANTS.DIMENSIONS_ACQUISITIONS
                  )}
                  name="grossDimensionsAcquisition"
                />
              </HStack>
            </VStack>
          </FieldGroup>



          <FieldGroup title={t("Notes")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="" name="dimensionsNotes" type="textarea" />
              </HStack>
            </VStack>
          </FieldGroup>



      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default DimensionsPanelNewCategoryForm;
