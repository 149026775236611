import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Dot, TextWithTooltip } from "components/ui";
import {
  CertificateNode,
  useDeleteCertificateMutation,
  UserNode,
  useSignCertificateMutation,
} from "graphql/queries/generated/queries";
import { useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RiCheckFill, RiErrorWarningLine, RiFile2Fill } from "react-icons/ri";

interface CertificateCardProps {
  certificate: Partial<
    Omit<CertificateNode, "signedBy"> & { signedBy: Pick<UserNode, "name" | "id"> }
  >;
  refetchCertificates: () => void;
}

const CertificateCard = ({ certificate, refetchCertificates }: CertificateCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const { id, isValid, uuid, signed, signedBy, signedAt, language, creationDate, file } =
    certificate || {};
  const { t } = useTranslation();
  const toast = useToast();
  const [
    signCertificate,
    { data: signCertificateData, error: signCertificateError, loading: signCertificateLoading },
  ] = useSignCertificateMutation();
  const [
    deleteCertificate,
    {
      data: deleteCertificateData,
      error: deleteCertificateError,
      loading: deleteCertificateLoading,
    },
  ] = useDeleteCertificateMutation();

  useEffect(() => {
    if (signCertificateData) {
      toast({
        title: t("Certificate has been signed."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (signCertificateError)
      toast({
        title: t("Certificate couldn't be signed."),
        description: t(`Error: ${signCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [signCertificateData, signCertificateError]);

  useEffect(() => {
    if (deleteCertificateData) {
      toast({
        title: t("Certificate has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deleteCertificateError)
      toast({
        title: t("Certificate couldn't be deleteed."),
        description: t(`Error: ${deleteCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deleteCertificateData, deleteCertificateError]);

  const handleSign = async (id: string) => {
    await signCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    refetchCertificates();
  };

  const handleDelete = async (id: string) => {
    await deleteCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    refetchCertificates();
  };

  return (
    <>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete certificate
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can&apos;t undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button variant="danger" onClick={() => handleDelete(id)} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Box rounded="md" shadow="lg" p="8">
        <VStack justify="stretch" align="flex-start" h="full">
          <VStack alignItems="flex-start" pr={8}>
            <Dot status={isValid} positiveLabel="Valid" negativeLabel="Invalid" />
            <Heading size="md">
              <Trans>Certificate</Trans>
            </Heading>
            <Text>{uuid}</Text>
          </VStack>

          {signed && (
            <VStack my="4" bg="black" p="4" borderRadius="lg" align="flex-start" w="full">
              <HStack w="full">
                <RiCheckFill size="1.5rem" color="white" />
                <Text color="white" fontWeight="bold">
                  <Trans>Certificate correctly signed</Trans>
                </Text>
              </HStack>
              <HStack justify="space-between" w="full">
                <VStack align="flex-start">
                  <Text variant="muted">
                    <Trans>Signed by</Trans>
                  </Text>
                  <Text color="white">
                    <Trans>{signedBy?.name}</Trans>
                  </Text>
                </VStack>
                <VStack align="flex-start">
                  <Text variant="muted">
                    <Trans>Signed at</Trans>
                  </Text>
                  <Text color="white">{new Date(signedAt).toLocaleString("it-IT")}</Text>
                </VStack>
              </HStack>
            </VStack>
          )}
          {!signed && (
            <VStack py="4" borderRadius="lg" align="flex-start" w="full">
              <HStack w="full" justify="flex-start">
                <RiErrorWarningLine size="1.5rem" color="orange" />
                <TextWithTooltip tooltipText="Certificate has been generated but still needs to be signed.">
                  Certificate not signed
                </TextWithTooltip>
              </HStack>
            </VStack>
          )}
          <HStack my={4} align="flex-start">
            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Language</Trans>
              </Text>
              <Text>{language}</Text>
            </VStack>

            <VStack alignItems="flex-start" pr={8}>
              <Text variant="muted">
                <Trans>Issued at</Trans>
              </Text>
              <Text>{new Date(creationDate).toLocaleString("it-IT")}</Text>
            </VStack>
          </HStack>
          <VStack alignItems="flex-start" pr={8} justifySelf="flex-end">
            <Text variant="muted">Files</Text>
            <a href={file} target="_blank" rel="noreferrer">
              <HStack>
                <RiFile2Fill />
                <Text>Open in new tab</Text>
              </HStack>
            </a>
            <HStack>
              {!signed && (
                <Button isLoading={signCertificateLoading} onClick={() => handleSign(id)}>
                  Sign certificate
                </Button>
              )}
              <Button isLoading={deleteCertificateLoading} variant="danger" onClick={onOpen}>
                <Trans>Delete certificate</Trans>
              </Button>
            </HStack>
          </VStack>
        </VStack>
      </Box>
    </>
  );
};

export default CertificateCard;
