import { Heading, VStack, Text } from "@chakra-ui/react";
import { FieldGroup, FormInputHook } from "components/ui";

import { Trans, useTranslation } from "react-i18next";

const PolicyNotesFields = () => {
  const { t } = useTranslation();

  return (
    <VStack mt={8} align="flex-start" w="100%">
      <Heading>
        <Trans>Add notes to this policy</Trans>
      </Heading>
      <Text variant="muted">Lorem ipsum dolor sit amet</Text>
      <FieldGroup title={t("Policy Notes")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <FormInputHook label="Note" name="policyNotes.0.content" type="textarea" />
        </VStack>
      </FieldGroup>
    </VStack>
  );
};

export default PolicyNotesFields;
