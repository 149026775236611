import { Box, Heading, HStack, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { JSXElementConstructor, ReactElement } from "react";

export interface Action {
  name: string;
  icon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  href?: never;
  action?: (arg0?: any) => void | Promise<void>;
  intent?: string;
  loading?: boolean;
}

export interface QuickLink {
  name: string;
  icon?: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  href?: string;
  action?: (arg0?: any) => void | Promise<void>;
  intent?: string;
  loading?: boolean;
}

export interface QuickActionsProps {
  actions: (Partial<Action> | Partial<QuickLink>)[];
  noTitle?: boolean;
}

const QuickActions = ({ noTitle = false, actions }: QuickActionsProps) => (
  <Box py={2}>
    {!noTitle && (
      <Heading size="sm" color="gray.500">
        Quick actions
      </Heading>
    )}
    <HStack mt={2}>
      {actions.map((action) =>
        action?.href ? (
          <Link to={action.href}>
            <Button
              leftIcon={action.icon}
              size="sm"
              variant={action.intent ?? "outline"}
              isLoading={action?.loading}
            >
              <Trans>{action.name}</Trans>
            </Button>
          </Link>
        ) : (
          <Button
            variant={action.intent ?? "outline"}
            leftIcon={action.icon}
            size="sm"
            isLoading={action?.loading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={action.action}
          >
            <Trans>{action.name}</Trans>
          </Button>
        )
      )}
    </HStack>
  </Box>
);

export default QuickActions;
