import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AssetInput, useCreateAssetMutation } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";
import { AssetInputSchema } from "graphql/queries/generated/validation-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import ShortAssetForm from "pages/mgmt/ShortAssetForm";
import cleanObject from "helpers/cleanObject";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";

interface NewAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  addPolicyAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
}

const defaultValues = {
  validated: false,
};

const NewAssetDrawer = ({ show, onClose, addPolicyAsset }: NewAssetDrawerProps) => {
  const methods = useForm<AssetInput>({
    defaultValues,
    resolver: yupResolver(AssetInputSchema()),
  });

  const [createAsset, { data, error, loading }] = useCreateAssetMutation();

  const onSubmit: SubmitHandler<AssetInput> = async (formData, e) => {
    const assetData = cleanObject(formData) as AssetInput;
    const { data: createAssetData } = await createAsset({
      variables: {
        input: { assetData },
      },
    });

    addPolicyAsset(createAssetData.createAsset.asset);
  };

  const {
    formState: { errors },
  } = methods;

  return (
    <Drawer
      isOpen={show}
      placement="right"
      onClose={onClose}
      size="xl"
      // finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader px={10} mt={6}>
          <Heading>
            <Trans>Create a new asset</Trans>
          </Heading>
          <Text fontSize="md" variant="muted">
            <Trans>
              Here you can create a new asset and immediately add it to the policy. Note that the
              asset will be inserted even if the policy is not saved.
            </Trans>
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <FormProvider {...methods}>
            <ShortAssetForm
              onSubmit={methods.handleSubmit(onSubmit)}
              data={data}
              errors={error && [error]}
              loading={loading}
              reset={() => methods.reset()}
            />
          </FormProvider>
        </DrawerBody>

        <DrawerFooter>
          {/* <Button variant='outline' mr={3} onClick={onClose}>
          Cancel
        </Button> */}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NewAssetDrawer;
