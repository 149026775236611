import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { Trans } from "react-i18next";

const NoDataPlaceholder = () => (
  <Alert
    status="info"
    colorScheme="gray"
    flexDirection="column"
    alignItems="flex-start"
    justifyContent="center"
    p={12}
  >
    <AlertIcon boxSize="40px" mr={0} />
    <AlertTitle mt={4} mb={1} fontSize="lg">
      <Trans>No data!</Trans>
    </AlertTitle>
    <AlertDescription maxWidth="sm" textAlign="left">
      <Trans>Either you&apos;re not able to see these data or there&apos;s no data to see.</Trans>
    </AlertDescription>
  </Alert>
);

export default NoDataPlaceholder;
