import { Box, HStack, SkeletonCircle, SkeletonText, Text, useToast } from "@chakra-ui/react";
import {
  CertificateNode,
  useDeleteCertificateMutation,
  UserNode,
  useSignCertificateMutation,
} from "graphql/queries/generated/queries";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import CertificateCard from "./CertificateCard";

const PolicyCertificatesSummary = ({
  policyCertificates,
  refetchCertificates,
  loading,
}: {
  policyCertificates: Partial<
    Omit<CertificateNode, "signedBy"> & { signedBy: Pick<UserNode, "name" | "id"> }
  >[];
  refetchCertificates?: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [
    signCertificate,
    { data: signCertificateData, error: signCertificateError, loading: signCertificateLoading },
  ] = useSignCertificateMutation();
  const [
    deleteCertificate,
    {
      data: deleteCertificateData,
      error: deleteCertificateError,
      loading: deleteCertificateLoading,
    },
  ] = useDeleteCertificateMutation();

  useEffect(() => {
    if (signCertificateData) {
      toast({
        title: t("Certificate has been signed."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (signCertificateError)
      toast({
        title: t("Certificate couldn't be signed."),
        description: t(`Error: ${signCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [signCertificateData, signCertificateError]);

  useEffect(() => {
    if (deleteCertificateData) {
      toast({
        title: t("Certificate has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deleteCertificateError)
      toast({
        title: t("Certificate couldn't be deleteed."),
        description: t(`Error: ${deleteCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deleteCertificateData, deleteCertificateError]);

  if (loading) {
    return (
      <Box padding="6" boxShadow="lg" bg="white">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>
    );
  }

  if (!policyCertificates || policyCertificates.length === 0)
    return (
      <Text>
        <Trans>No certificates found.</Trans>
      </Text>
    );

  return (
    <HStack overflowX="scroll" w="100%" py={4} spacing={4}>
      {policyCertificates.map((certificate) => (
        <CertificateCard certificate={certificate} refetchCertificates={refetchCertificates} />
      ))}
    </HStack>
  );
};

export default PolicyCertificatesSummary;
