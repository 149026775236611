import { useEffect, useMemo, useState } from "react";
import { Button, Text, Select } from "@chakra-ui/react";
import { Trans } from "react-i18next";

const BooleanFilter = ({ title, column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const [isEnabled, setIsEnabled] = useState(null);
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  useEffect(() => {
    setFilter(isEnabled);
  }, [isEnabled]);
  // Render a multi-select box
  return (
    <>
      {/* // <select
    //   value={filterValue}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined);
    //   }}
    // >
    //   <option value="">All</option>
    //   {options.map((option, i) => (
    //     <option key={i} value={option}>
    //       {option}
    //     </option>
    //   ))}
    // </select>
  */}
      {/* <Button
        mr={2}
        size="sm"
        bgColor={isEnabled ? "black" : "white"}
        onClick={() => setIsEnabled((prevState) => (prevState === true ? null : true))}
      >
        <Text color={isEnabled ? "white" : "black"} fontWeight="400">
          <Trans>{title ?? id}</Trans>
        </Text>
      </Button> */}
      <Select onChange={(e) => setIsEnabled(e?.target?.value)} w="auto">
        <option value="">
          <Trans>Choose {title}</Trans>
        </option>
        <option value={true}>
          <Trans>{title}</Trans>
        </option>
        <option value={false}>
          <Trans>not {title}</Trans>
        </option>
      </Select>
    </>
  );
};

export default BooleanFilter;
