import { HStack, IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useSearchBox } from "react-instantsearch-hooks-web";
import { useTranslation } from "react-i18next";
import { RiCloseFill } from "react-icons/ri";
import { ChangeEvent, useState } from "react";

const CustomSearchBox = () => {
  const { t } = useTranslation();
  const { clear, query, refine, isSearchStalled } = useSearchBox();
  const [searchQuery, setSearchQuery] = useState("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearchQuery(value);
    refine(value);
  };

  return (
    <HStack>
      <InputGroup size="md">
        <Input
          onChange={onChange}
          borderRadius="md"
          borderWidth="2px"
          borderColor="gray.200"
          bg="white"
          size="md"
          placeholder={t("Start typing here...")}
          minW="30vw"
          value={searchQuery}
        />
        {searchQuery && (
          <InputRightElement aria-label="">
            <IconButton
              variant="ghost"
              onClick={() => {
                clear();
                setSearchQuery("");
              }}
              aria-label=""
              icon={<RiCloseFill size={20} />}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </HStack>
  );
};

export default CustomSearchBox;
