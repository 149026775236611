import { Box, HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import CopyButton from "../CopyButton";

const HiddenHoverableCell = ({ cell: { value } }: { cell: { value: string } }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box>
      <HStack>
        <HStack onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <Text>{value?.slice(0, 12)}</Text>
          <Box
            position="absolute"
            display={isHovered ? "block" : "none"}
            zIndex="modal"
            bg="white"
            p="2"
          >
            {value}
          </Box>
        </HStack>
        <CopyButton value={value} />
      </HStack>
    </Box>
  );
};

export default HiddenHoverableCell;
