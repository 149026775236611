/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation, Trans } from "react-i18next";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Badge, Tabs, TabList, Tab, TabPanel, TabPanels, Heading, useToast } from "@chakra-ui/react";
import {
  GetAssetByIdQuery,
  useUpdateAssetMutation,
  AssetInput,
  UpdateAssetInput,
} from "graphql/queries/generated/queries";
import { FormFooter } from "components/ui";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// import cleanObject from "helpers/cleanObject";
import { AssetInputSchema } from "graphql/queries/generated/validation-schema";
import { ValidationProvider } from "components/form/ValidationContext";
import PanelNewCategoryForm from "../new/PanelNewCategoryForm";
import OriginNewCategoryForm from '../new/origin/OriginNewCategoryForm';
import BibliographyNewCategoryForm from '../new/origin/BibliographyNewCategoryForm';
import AssetCarryingValueForm from '../new/values/AssetCarryingValueForm';
import AssetPresentValueForm from '../new/values/AssetPresentValueForm';

import clearHistoricDateValues from '../utils/ClearHistoricDateValues';


interface EditAssetFormProps {
  data: GetAssetByIdQuery;
}



// interface NewCategoryFormProps {
//   category: string;
//   children?: React.ReactNode;
//   resetCategory:  (e: React.MouseEvent<HTMLButtonElement>) => void

// }

const steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "note", label: "Notes" },
];

const EditAssetForm = ({ data }: EditAssetFormProps) => {

  const {
    asset: {
      policyAsset,
      createdAt,
      updatedAt,
      createdBy,
      updatedBy,
      uuid,
      audioVideoLength,
      audioVideoLengthUnit,
      fileSizeUnit,
      fileSize,
      resolutionUnit,
      resolution,
      isFirstDraft,
      firstDraftNotes,
      isEdition,
      editionNotes,
      isMaster,
      masterLocation,
      mechanism,
      carat,
      isDynamic,
      hasHumanAnimalPresence,
      humanAnimalPresenceNotes,
      isAudio,
      isVideo,
      hasLoop,
      coloring,
      variants,
      nominalValue,
      fiscalValue,
      currentValue,
      currency,
      score,
      circulation,
      manufactoringTechniques,
      mintingLastDate,
      mintingLastDateNotes,
      reminting,
      hasFrame,
      hasOriginalFrame,
      frameMaterial,
      isValuableFrame,
      frameManufacturer,
      frameDescription,
      compositionDescription,
      usage,
      finishing,
      hasContainer,
      containerDetails,
      replacedParts,
      methodOfUse,
      relatedPhysicalWork,
      bookContainer,
      dustJacket,
      frontCover,
      rearCover,
      topEdge,
      foreEdge,
      footEdge,
      bookbinding,
      interiorDecorations,
      externalDecorations,
      flyleaf,
      glueing,
      headband,
      headcap,
      corner,
      square,
      slots,
      spine,
      clips,
      raisedBands,
      label,
      clothCovering,
      distribution,
      edge,
      orientation,
      shape,
      rim,
      rotationAxes,
      legend,
      fineness,
      mintMadeErrors,
      signings,
      relief,
      watermark,
      holograficStripe,
      securityThread,
      printingErrors,
      conservationMethod,
      color,
      postageMark,
      rubber,
      stampPrintingTechnique,
      stampRealization,
      stampIndentationType,
      stampCenteringAndMargins,
      stampFormat,
      discoveryPlace,
      discoverySite,
      discoveryDate,
      discoveryNotes,
      discoveryAuthor,
      catalogingAuthor,
      objectSetupAuthor,
      carryingValueAmountCurrency,
      authorEntity,
      collection,
      exhibition,
      media,
      technique,
      genericDimensions,
      presentValues,
      physicalFeaturesElements,

      __typename,
      deletedByCascade,
      deleted,
      id,
      ...asset
      },
  } = data;



  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const { nextStep: nextStepOrigin, prevStep: prevStepOrigin, activeStep: activeStepOrigin, setStep: setStepOrigin } = useSteps({
    initialStep: 0,
  });

  const { nextStep: nextStepValue, prevStep: prevStepValue, activeStep: activeStepValue, setStep: setStepValue } = useSteps({
    initialStep: 0,
  });

  const toast = useToast();

  const { t } = useTranslation();



  // const [id, setId] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState(0);

  const [nextStepName, setNextStepName] = useState("nextStep");

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setNextStepName("nextStep");
        break;
      case 1:
        setNextStepName("nextStepOrigin");
        break;
      case 2:
        setNextStepName("nextStepValue");
        break;
      default:
        setNextStepName(null);
    }

    setTabIndex(index);
  };

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  };

  // const [
  //   createAsset,
  //   { data: createAssetData, loading: createAssetLoading, error: createAssetError },
  // ] = useCreateAssetMutation();

  const [
    updateAsset,
    { data: updateAssetData, loading: updateAssetLoading, error: updateAssetError },
  ] = useUpdateAssetMutation();


  // useEffect(() => {
  //   if (createAssetData) {
  //     toast({
  //       title: t("Asset has been created."),
  //       description: t(""),
  //       status: "success",
  //       duration: 9000,
  //       isClosable: true,
  //     })
  //   }

  //   if (createAssetError)  {
  //     toast({
  //       title: t("Asset couldn't be created."),
  //       description: t(`Error: ${createAssetError.message}`),
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   }
  // }, [createAssetData, createAssetError]);


  useEffect(() => {
    if (updateAssetData) {
      toast({
        title: t("Asset has been saved."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }

    if (updateAssetError)  {
      toast({
        title: t("Asset hasn't been saved."),
        description: t(`Error: ${updateAssetError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [updateAssetData, updateAssetError]);




  const methods = useForm<AssetInput>({
    defaultValues: {
      ...asset,

      carryingValueAmount: {
        amount: asset?.carryingValueAmount?.amount,
        currency: asset?.carryingValueAmount?.currency.code
      },
      authorEntityId: authorEntity.id,
      // authorEntityId: asset?.authorEntity?.id,
      hasBarriers: asset.hasBarriers === null ? Boolean(false) : asset.hasBarriers,
      hasBase: asset.hasBase === null ? Boolean(false) : asset.hasBase,
      hasLabelsStamps: asset.hasLabelsStamps === null ? Boolean(false) : asset.hasLabelsStamps,
      hasOtherAutographed: asset.hasOtherAutographed === null ? Boolean(false) : asset.hasOtherAutographed,
      hasWoodFrame: asset.hasWoodFrame === null ? Boolean(false) : asset.hasWoodFrame,
      isArtMultiple: asset.isArtMultiple === null ? Boolean(false) : asset.isArtMultiple,
      isArtistProof: asset.isArtistProof === null ? Boolean(false) : asset.isArtistProof,
      isComposed: asset.isComposed === null ? Boolean(false) : asset.isComposed,
      isDatedObject: asset.isDatedObject === null ? Boolean(false) : asset.isDatedObject,
      isNotifiedCulturalProperty: asset.isNotifiedCulturalProperty === null ? Boolean(false) : asset.isNotifiedCulturalProperty,
      isSignedObject: asset.isSignedObject === null ? Boolean(false) : asset.isSignedObject,
      isTitledObject: asset.isTitledObject === null ? Boolean(false) : asset.isTitledObject,
    },
    resolver: yupResolver(AssetInputSchema()),
    mode: "onChange",
  });

  const onError = (errors: any, e: any) => console.log(errors, e);



  const onSubmit: SubmitHandler<AssetInput> = async (values, e) => {
    const assetData = clearHistoricDateValues(values);

    assetData.carryingValueAmount = {...assetData.carryingValueAmount,
      // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
      currency: assetData.carryingValueAmount.currency || 'EUR'
    };

    // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
    // if ('file' in assetData.carryingValueFile && (assetData.carryingValueFile.file === '')) {
    //   assetData.carryingValueFile = "";

    // }

    // console.log((assetData as UpdateAssetInput["assetData"]))

    // const response = await updateAsset({
    //     variables: {
    //       input: {
    //         assetData: {
    //           title: assetData.title,
    //           author: assetData.author,
    //           authorEntityId: assetData.authorEntityId,
    //           // creationDate: (assetData.creationDate === "" || assetData.creationDate === undefined) ? null : `${assetData.creationDate as string}T00:00:00`,
    //           // creationPeriod: assetData.creationPeriod === "" ? null : assetData.creationPeriod,

    //           datingType: assetData.datingType,
    //           creationPeriod: assetData.creationPeriod,
    //           isDateInterval: assetData.isDateInterval,
    //           dateStartDate: assetData.dateStartDate,
    //           dateEndDate: assetData.dateEndDate,
    //           isDateStartDateBc: assetData.isDateStartDateBc,
    //           isDateEndDateBc: assetData.isDateEndDateBc,
    //           isDateApprox: assetData.isDateApprox,
    //           isYearInterval: assetData.isYearInterval,
    //           yearStartDate: assetData.yearStartDate,
    //           yearEndDate: assetData.yearEndDate,
    //           isYearStartDateBc: assetData.isYearStartDateBc,
    //           isYearEndDateBc: assetData.isYearEndDateBc,
    //           isYearApprox: assetData.isYearApprox,
    //           isCenturyInterval: assetData.isCenturyInterval,
    //           centuryStartDate: assetData.centuryStartDate,
    //           centuryEndDate: assetData.centuryEndDate,
    //           isCenturyStartDateBc: assetData.isCenturyStartDateBc,
    //           isCenturyEndDateBc: assetData.isCenturyEndDateBc,
    //           isCenturyApprox: assetData.isCenturyApprox,
    //           centuryStartDateWindow: assetData.centuryStartDateWindow,
    //           centuryEndDateWindow: assetData.centuryEndDateWindow,

    //           hasFullDatetime: assetData.hasFullDatetime,
    //           hasPeriod: assetData.hasPeriod,
    //           hasYear: assetData.hasYear,
    //           category: asset.category,
    //           subcategory: asset.subcategory,
    //           series: assetData.series,
    //           seriesDetails:  assetData.seriesDetails,
    //           description: assetData.description,
    //           inventoryNumber: assetData.inventoryNumber,
    //           archiveNumber: assetData.archiveNumber,
    //           catalogNumber: assetData.catalogNumber,
    //           criticalCatalogue: assetData.criticalCatalogue,
    //           nft: assetData.nft,
    //           nftNotes: assetData.nftNotes,
    //           netWidth: assetData?.netWidth ?? null,
    //           netHeight: assetData?.netHeight ?? null,
    //           netDepth: assetData?.netDepth ?? null,
    //           netDiameter: assetData?.netDiameter ?? null,
    //           netLengthUnit: assetData.netLengthUnit,
    //           netWeightUnit: assetData.netWeightUnit,
    //           netWeight: assetData?.netWeight ?? null,
    //           netDimensionsAcquisition: assetData.netDimensionsAcquisition,

    //           grossWidth: assetData?.grossWidth ?? null,
    //           grossHeight: assetData?.grossHeight ?? null,
    //           grossDepth: assetData?.grossDepth ?? null,
    //           grossDiameter: assetData?.grossDiameter ?? null,
    //           grossLengthUnit: assetData.grossLengthUnit,
    //           grossWeightUnit: assetData.grossWeightUnit,
    //           grossWeight: assetData?.grossWeight,
    //           grossDimensionsAcquisition: assetData.grossDimensionsAcquisition,

    //           dimensionsNotes: assetData.dimensionsNotes,

    //           isNotifiedCulturalProperty: assetData.isNotifiedCulturalProperty,
    //           notifiedPropertyNote: assetData.notifiedPropertyNote,
    //           legalStatus: assetData.legalStatus,
    //           copyrightAndPropertyRestrictions: assetData.copyrightAndPropertyRestrictions,


    //           isArtistProof: assetData.isArtistProof,
    //           artistProofNotes: assetData.artistProofNotes,
    //           isArtMultiple: assetData.isArtMultiple,
    //           multipleNumber: assetData.multipleNumber,
    //           isSignedObject: assetData.isSignedObject,
    //           signedObjectNotes: assetData.signedObjectNotes,
    //           isTitledObject: assetData.isTitledObject,
    //           titledObjectNotes: assetData.titledObjectNotes,
    //           isDatedObject: assetData.isDatedObject,
    //           datedObjectNotes: assetData.datedObjectNotes,
    //           hasLabelsStamps: assetData.hasLabelsStamps,
    //           labelsStampsNotes: assetData.labelsStampsNotes,
    //           hasOtherAutographed: assetData.hasOtherAutographed,
    //           otherAutographedNotes: assetData.otherAutographedNotes,
    //           relevantAwards: assetData.relevantAwards,

    //           isComposed: assetData.isComposed,
    //           surfaceCharacteristics: assetData.surfaceCharacteristics,
    //           hasBase: assetData.hasBase,
    //           baseDetails: assetData.baseDetails,
    //           hasWoodFrame: assetData.hasWoodFrame,
    //           woodFrameDetails: assetData.woodFrameDetails,

    //           hasBarriers: assetData.hasBarriers,
    //           barriersDetails: assetData.barriersDetails,
    //           handlingRequirements: assetData.handlingRequirements,
    //           injuryRisk: assetData.injuryRisk,
    //           injuryRiskDescription: assetData.injuryRiskDescription,
    //           mouldingSupportBase: assetData.mouldingSupportBase,
    //           hardwareToolsInstallation: assetData.hardwareToolsInstallation,
    //           conservation: assetData.conservation,
    //           lenderRequests: assetData.lenderRequests,
    //           settingNotes: assetData.settingNotes,

    //           optimalLightLevelIntensity: assetData?.optimalLightLevelIntensity ?? null,
    //           lightUnit: assetData.lightUnit,
    //           optimalTemperature: assetData?.optimalTemperature ?? null,
    //           temperatureUnit: assetData.temperatureUnit,
    //           optimalHumidity: assetData?.optimalHumidity ?? null,
    //           enviromentalConditionsNotes: assetData.enviromentalConditionsNotes,

    //           infoNotes: assetData.infoNotes,

    //           originNotes: assetData.originNotes,
    //           bibliographyNotes: assetData.bibliographyNotes,


    //           carryingValueOrigin: assetData.carryingValueOrigin,
    //           carryingValueAcquisition: assetData.carryingValueAcquisition,
    //           carryingValueNotes: assetData.carryingValueNotes,
    //           carryingValueLastAcquisitionDate: assetData.carryingValueLastAcquisitionDate,
    //           carryingValueAmount: assetData.carryingValueAmount,
    //           carryingValueFileTitle: assetData.carryingValueFileTitle,
    //           carryingValueFile: assetData.carryingValueFile,



    //           validated: false,
    //         },
    //         id: String(asset.id),
    //       },
    //     },
    // });


    const response = await updateAsset({
      variables: {
        input: {
          assetData: {
            ...(assetData as UpdateAssetInput["assetData"]),
            validated: false,
            // dateStartDate: (assetData.dateStartDate === "" || assetData.dateStartDate === undefined) ? null : `${assetData.dateStartDate as string}T00:00:00`,
            // dateEndDate: (assetData.dateEndDate === "" || assetData.dateEndDate === undefined) ? null : `${assetData.dateEndDate as string}T00:00:00`,
          },
          id: String(data.asset.id),
        }
      }
    })

    setTimeout(() => {
      // nextStep();
      switch(nextStepName) {
        case 'nextStep':
          if (activeStep !== steps.length - 1) {
            nextStep();
          }
          break;
        case 'nextStepOrigin':
          if (activeStepOrigin !== 1) {
            nextStepOrigin();
          }
          break;
        case 'nextStepValue':
          if (activeStepValue !== 1) {
            nextStepValue();
          }
          break;
          default:
      }

    }, 500);
  };

  const handleSubmit = methods.handleSubmit(onSubmit, onError);
  // const handleSubmitStep = async (nextStepFunction: string) => {
  //   setNextStepName(nextStepFunction);
  //   await handleSubmit();
  // }
  // return <div>Category: {category}, step : {activeStep}</div>;
  return (
    <FormProvider {...methods}>
      <ValidationProvider schema={AssetInputSchema()}>
        <form
          id="update_asset"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit}
        >
          <Tabs colorScheme="brand" variant="line" mt="4" index={tabIndex} onChange={handleTabChange}>
            <TabList>
              <Tab mr={8}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  1
                </Badge>
                <Trans>Info</Trans>
              </Tab>

              <Tab mr={8} isDisabled={data.asset.id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  2
                </Badge>
                <Trans>Origin and bibliography</Trans>
              </Tab>

              <Tab mr={8} isDisabled={data.asset.id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  3
                </Badge>
                <Trans>Value</Trans>
              </Tab>
              <Tab mr={8} isDisabled={data.asset.id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  4
                </Badge>
                <Trans>Chronology</Trans>
              </Tab>
              <Tab mr={8} isDisabled={data.asset.id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  5
                </Badge>
                <Trans>Documents</Trans>
              </Tab>
              <Tab mr={8} isDisabled={data.asset.id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  6
                </Badge>
                <Trans>Media</Trans>
              </Tab>
            </TabList>
                <TabPanels>
                  <TabPanel p={3}>
                    <Steps colorScheme="whiteAlpha" size="sm" onClickStep={(step) => setStep(step)}  pt={3} orientation="vertical" activeStep={activeStep}>
                      {steps.map(({ label, key }, index) => {
                        if (
                          key === "info_generali" ||
                          key === "dimensioni" ||
                          key === "aspetti_legali" ||
                          key === "dettagli_tecnici" ||
                          key === "caratteristiche_fisiche" ||
                          key === "allestimento" ||
                          key === "condizioni_ambientali" ||
                          key === "note"
                        ) {
                          return (
                            <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>{label}</Trans></Heading>} key={key}>
                              <PanelNewCategoryForm
                                steps={steps}
                                activeStep={activeStep}
                                prevStep={prevStep}
                                nextStep={nextStep}
                                category={asset.category}
                                id={data.asset.id}
                                panel={key}
                                handleSubmit={handleSubmit}
                                getValues={methods.getValues}
                                register={methods.register}
                                setValue={methods.setValue}
                                setError={methods.setError}
                                watch={methods.watch}
                                createAssetData={null}
                                updateAssetData={updateAssetData}
                                createAssetError={undefined}
                                updateAssetError={updateAssetError}
                                createAssetLoading={undefined}
                                updateAssetLoading={updateAssetLoading}
                              >
                                {/* <Button onClick={nextStep}>
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button> */}
                                {/* {children} */}

                                {/* <Button type="reset" onClick={(e) => resetCategory(e)}>
                                  Annulla
                                </Button> */}
                              </PanelNewCategoryForm>
                            </Step>
                          );
                        }
                        return (
                          <Step isCompletedStep={false} textAlign="left" width="100%" label={label} key={key}>
                            {/* <Button
                              isActive={activeStep !== 0}
                              isDisabled={activeStep === 0}
                              mr={4}
                              onClick={prevStep}
                              variant="ghost"
                            >
                              Prev
                            </Button> */}
                            <div>
                              Category: {asset.category}, step : {activeStep}
                            </div>
                          </Step>
                        );
                      })}
                    </Steps>
                  </TabPanel>
                  <TabPanel p={3}>

                      <Steps colorScheme="whiteAlpha" size="sm" onClickStep={(step) => setStepOrigin(step)} orientation="vertical" activeStep={activeStepOrigin}>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Origin</Trans></Heading>} key={0}>
                          <OriginNewCategoryForm
                            // handleSubmit={formik.handleSubmit}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}

                            createAssetData={null}
                            updateAssetData={updateAssetData}
                            createAssetError={undefined}
                            updateAssetError={updateAssetError}
                            createAssetLoading={undefined}
                            updateAssetLoading={updateAssetLoading}
                            category={asset.category}
                          >

                            {/* {data.asset.id && (
                              <Button
                                mr={4}
                                onClick={nextStepOrigin}
                                variant="ghost"
                              >
                                Next
                              </Button>
                            )} */}
                          </OriginNewCategoryForm>
                        </Step>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Bibliography</Trans></Heading>} key={1}>
                          <BibliographyNewCategoryForm
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}

                            createAssetData={null}
                            updateAssetData={updateAssetData}
                            createAssetError={undefined}
                            updateAssetError={updateAssetError}
                            createAssetLoading={undefined}
                            updateAssetLoading={updateAssetLoading}
                            category={asset.category}
                          >

                            {/* <Button mr={4} onClick={prevStepOrigin} variant="ghost">
                              Prev
                            </Button> */}
                          </BibliographyNewCategoryForm>
                        </Step>
                      </Steps>
                  </TabPanel>
                  <TabPanel p={3}>
                      <Steps colorScheme="whiteAlpha" size="sm" onClickStep={(step) => setStepValue(step)} orientation="vertical" activeStep={activeStepValue}>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Carrying Value</Trans></Heading>} key={0}>

                        {/* <FieldGroup title={t("Carrying value")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}> */}


                          <AssetCarryingValueForm
                            // handleSubmit={formik.handleSubmit}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}

                            createAssetData={null}
                              updateAssetData={updateAssetData}
                              createAssetError={undefined}
                              updateAssetError={updateAssetError}
                              createAssetLoading={undefined}
                              updateAssetLoading={updateAssetLoading}
                            category={asset.category}
                          >

                            {/* {data.asset.id && (
                              <Button
                                mr={4}
                                onClick={nextStepValue}
                                variant="ghost"
                              >
                                Next
                              </Button>
                            )} */}
                          </AssetCarryingValueForm>


                        {/* </FieldGroup> */}
                        </Step>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Present values</Trans></Heading>} key={1}>
                          <AssetPresentValueForm assetId={data.asset.id} />
                            {/* <Button mr={4} onClick={prevStepValue} variant="ghost">
                              Prev
                            </Button>
                          </AssetPresentValueForm> */}



                        </Step>
                      </Steps>
                  </TabPanel>
                </TabPanels>




          </Tabs>
          <FormFooter data={updateAssetData} errors={[updateAssetError]} loading={updateAssetLoading} title="Asset entry" />
        </form>
      </ValidationProvider>
    </FormProvider>
  );
};

export default EditAssetForm;
