import { Badge, Image } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { RegistryAwareField } from "components/ui";
import placeholderPNG from "assets/placeholder.png";

export const CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "image",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,
    weight: 13,
    disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 12,
    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 11,
    disableFilters: true,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lenderId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },
  {
    Header: "Shipper",
    Footer: "",
    accessor: "shipperId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },
  {
    Header: "Lender address",
    Footer: "",
    accessor: "lenderAddress.raw",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Pickup address",
    Footer: "",
    accessor: "pickupAddress.raw",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Delivery address",
    Footer: "",
    accessor: "deliveryAddress.raw",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
];
