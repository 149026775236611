// import { OnChangeValue, Select as CustomSelect } from "chakra-react-select";
import { MultiValue, Select as CustomSelect } from "chakra-react-select";
import { Box, FormLabel, Text } from "@chakra-ui/react";
import { useController, useFormContext } from "react-hook-form";
import { Trans } from "react-i18next";
import { Wrapper } from "components/ui/FormInputHook/Wrapper";
import { FormSelectOptionProps } from "components/ui/FormInputHook/type";
import { useState } from "react";

interface MultiSelectProps {
  name: string;
  label: string;
  placeholder: string;
  options: FormSelectOptionProps[];
  disabled: boolean;
  required: boolean;
}

const MultiSelect = ({
  name,
  label,
  placeholder,
  options,
  disabled = false,
  required,
}: MultiSelectProps) => {
  const {
    watch,
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const controller = useController({
    name,
    control,
    defaultValue: "",
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { value },
  } = controller;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

  const selectedOptionsValue = options?.filter(({ value: optionValue }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (value != null) return value.includes(optionValue);

    return null;
  });

  const [selectedValue, setSelectedValue] = useState<MultiValue<any>>(selectedOptionsValue ?? []);

  return (
    <Wrapper name={name} error={errors[name]}>
      <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
      <Box minW="12rem" background="white">
        <CustomSelect
          size="sm"
          isMulti
          // eslint-disable-next-line @typescript-eslint/no-shadow
          options={options}
          useBasicStyles
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={selectedValue}
          onChange={(newValue) => {
            setSelectedValue(newValue);
            const parsedValue = newValue.map(({ value }) => value as string);
            controller.field.onChange(parsedValue);
          }}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          // onChange={(newValue) => {
          //   console.log(newValue);

          //   const keys: string[] = [];

          //   newValue.forEach((element) => {
          //     keys.push(element.value);
          //   });

          //   console.log(keys);

          //   onChange(keys);
          //   // setValue(name, keys);
          // }}
        />
      </Box>
    </Wrapper>
  );
};

export default MultiSelect;
