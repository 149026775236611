import { Heading, VStack, Text } from "@chakra-ui/react";
import { FieldGroup, FormInputHook } from "components/ui";

import { Trans, useTranslation } from "react-i18next";

const PolicyDocumentsFields = () => {
  const { t } = useTranslation();

  return (
    <VStack mt={8} align="flex-start" w="100%">
      <Heading>
        <Trans>Attach documents to this policy</Trans>
      </Heading>
      <Text variant="muted">Lorem ipsum dolor sit amet</Text>
      <FieldGroup title={t("Policy Documents")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
        <VStack width="full" align="left">
          <FormInputHook label="Upload documents" name="policyDocuments.0.file" type="file" />
        </VStack>
      </FieldGroup>
    </VStack>
  );
};

export default PolicyDocumentsFields;
