import { Select as CustomSelect } from "chakra-react-select";
import { Box, FormLabel, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { Trans } from "react-i18next";
import { Wrapper } from "./Wrapper";
import { FormInputProps } from "./type";

const Select = ({
  name,
  label,
  type,
  errors,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  watch,
  control,
  required,
}: FormInputProps) => (
  <Wrapper name={name} error={errors[name]}>
    <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
      <Text>
        <Trans>{label}</Trans>
      </Text>
      {required && (
        <Text px="1" color="red.500">
          <Trans>(required)</Trans>
        </Text>
      )}
    </FormLabel>
    <Box minW="12rem" background="white">
      <Controller
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        control={control}
        name={name}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        render={({ field: { onChange, value, name, ref } }) => (
          <CustomSelect
            isDisabled={disabled}
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            options={options}
            useBasicStyles
            value={options.find(({ value: optionValue }) => value === optionValue)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => onChange(newValue.value)}
          />
        )}
      />
    </Box>
  </Wrapper>
);

export default Select;
