import { BaseSyntheticEvent } from "react";
import { VStack, HStack } from "@chakra-ui/react";
import { FieldGroup, FormFooter, FormInputHook } from "components/ui";
import { useTranslation } from "react-i18next";
import ASSET_CONSTANTS from "constants/asset";
import { AssetInput, CreateAssetMutation } from "graphql/queries/generated/queries";
import { ApolloError } from "@apollo/client";
import { UseFormReset } from "react-hook-form";

interface ShortAssetFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  data: CreateAssetMutation;
  loading: boolean;
  errors: ApolloError[];
  reset: UseFormReset<AssetInput>;
}

const ShortAssetForm = ({ onSubmit, data, loading, errors, reset }: ShortAssetFormProps) => {
  const { t } = useTranslation();
  console.log({ errors });
  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={onSubmit} id="new-asset-form">
      <VStack width="full" spacing="6" align="left" p="4">
        <FieldGroup title={t("Asset category")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <FormInputHook name="category" label="category" options={ASSET_CONSTANTS.CATEGORY} />

            <FormInputHook
              name="subcategory"
              label="subcategory"
              options={ASSET_CONSTANTS.SUBCATEGORY}
            />
          </HStack>
        </FieldGroup>
        <FieldGroup title={t("Asset main details")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack justify="space-between">
            <FormInputHook name="author" label="author" />
            <FormInputHook name="title" label="title" />
          </HStack>
        </FieldGroup>

        <FieldGroup title={t("Dates")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <FormInputHook name="creationDate" label="creationDate" type="datetime-local" />
            <FormInputHook name="creationPeriod" label="creationPeriod" />
          </HStack>

          {/* <HStack justify="space-between" mt={4}>
            <FormInputHook name="hasFullDatetime" label="hasFullDatetime" type="checkbox" />
            <FormInputHook name="hasPeriod" label="hasPeriod" type="checkbox" />
            <FormInputHook name="hasYear" label="hasYear" type="checkbox" />
          </HStack> */}
        </FieldGroup>
        <FieldGroup title={t("Series")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <HStack>
            <FormInputHook label="series" name="series" />
            <FormInputHook label="seriesDetails" name="seriesDetails" />
          </HStack>

          <HStack>
            <FormInputHook label="sku" name="sku" />
          </HStack>
          {/* <FormInputHook label="validated" name="validated" type="checkbox" /> */}
        </FieldGroup>
        <FieldGroup title={t("NFT")} subtitle={t("Lorem ipsum dolor sit amet")}>
          <VStack align="flex-start">
            <FormInputHook label="nft" type="checkbox" name="nft" />
            <FormInputHook label="nftNotes" name="nftNotes" />
          </VStack>
        </FieldGroup>
        <FieldGroup
          title={t("Technique and dimensions")}
          subtitle={t("Lorem ipsum dolor sit amet")}
        >
          <VStack>
            <FormInputHook label="genericDimensions" name="genericDimensions" />
            <FormInputHook label="technique" name="technique" />
          </VStack>
          {/* <FormInputHook label="validated" name="validated" type="checkbox" /> */}
        </FieldGroup>
        <HStack>
          <FormFooter
            data={data}
            loading={loading}
            errors={errors}
            title="Asset"
            cancelAction={reset}
          />
        </HStack>
      </VStack>
    </form>
  );
};

export default ShortAssetForm;
