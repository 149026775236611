import {
    Box,
    HStack,
    VStack,
    Text,
  } from "@chakra-ui/react";
  import { ApolloError } from "@apollo/client";
  import { useTranslation } from "react-i18next";
  // import { CreateAsset } from 'graphql/queries/types/CreateAsset';
  // import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
  import {
    AssetInput,
    CreateAssetMutation,
    UpdateAssetMutation,
  } from "graphql/queries/generated/queries";
  import {
    UseFormGetValues,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
  } from "react-hook-form";
  import { FormInputHook, MoneyInputHook, FieldGroup } from "components/ui";
  import { BaseSyntheticEvent } from "react";
  import ASSET_COSTANTS from "constants/asset";



  interface AssetCarryingValueFormProps {
    onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
    register: UseFormRegister<AssetInput>;
    getValues: UseFormGetValues<AssetInput>;
    watch: any; // UseFormWatch<AssetInput>,
    setValue: UseFormSetValue<AssetInput>;
    setError: UseFormSetError<AssetInput>;
    category: string;
    children?: React.ReactNode;
    createAssetData: CreateAssetMutation | null | undefined;
    updateAssetData: UpdateAssetMutation | null | undefined;
    createAssetError: ApolloError | undefined;
    updateAssetError: ApolloError | undefined;
    createAssetLoading: boolean | undefined;
    updateAssetLoading: boolean | undefined;
  }

  const AssetCarryingValueForm = ({
    // eslint-disable-next-line react/prop-types
    onSubmit,
    // handleChange,
    // handleRadioButtons,
    // values,
    register,
    setValue,
    setError,
    getValues,
    watch,
    createAssetData,
    updateAssetData,
    createAssetError,
    updateAssetError,
    createAssetLoading,
    updateAssetLoading,
    category,
    children,
  }: AssetCarryingValueFormProps) => {
    const { t } = useTranslation();
    const { title } = getValues();



    return (
      <>
        {/* <Box p={6} maxW="6xl">
          <Stack spacing="4" divider={<StackDivider />}> */}
            <Box alignItems="flex-start">
              <Text textAlign="left">Categoria {category}</Text>
            </Box>

            <FieldGroup title={t("Origin and acquisition")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack>
                  <FormInputHook
                    label={t("Asset origin")}
                    name="carryingValueOrigin"
                  />
                </HStack>

                <HStack>
                  <FormInputHook
                    label=""
                    options={[{ label: "- Acquisition -", value: "" }].concat(ASSET_COSTANTS.ASSET_VALUES_ACQUISITIONS)}
                    name="carryingValueAcquisition"
                  />


                  <FormInputHook
                    label={t("Notes")}
                    name="carryingValueNotes"
                    type="textarea"
                  />
                </HStack>


                <HStack>
                  <FormInputHook label="Last acquisition date" type="date" name="carryingValueLastAcquisitionDate" />
                </HStack>
              </VStack>
            </FieldGroup>

            <FieldGroup title={t("Value")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
              <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                  <MoneyInputHook name="carryingValueAmount.amount" label="Amount" />
                </HStack>
              </VStack>
            </FieldGroup>



            {/* <HStack alignItems="flex-end">
              <FormInputHook label="File title" name="carryingValueFileTitle" />
              <FormInputHook label="File" name="carryingValueFile.file" type="file" />

            </HStack> */}


            {/* <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
            >
              {t("Save")}
            </Button>
            {children} */}
          {/* </Stack>
        </Box> */}
        {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
          <Button
              isLoading={createAssetLoading || updateAssetLoading}
              loadingText="Loading"
              type="submit"
              variant="primary"
            >
              {t("Update")}
          </Button>
          {children}
        </ButtonGroup> */}
      </>
    );
  };

  export default AssetCarryingValueForm;
