import { useState } from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { BiCopy } from "react-icons/bi";

const CopyButton = ({ value }: { value: string }) => {
  const [copyText, setCopyText] = useState("Copy value");

  return (
    <Tooltip label={copyText} closeOnClick={false} placement="top">
      <IconButton
        aria-label={`Copy ${value}`}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => {
          navigator.clipboard
            .writeText(value)
            .then((_) => setCopyText("Copied!"))
            .finally(() => {
              setTimeout(() => setCopyText("Copy value"), 2000);
            });
        }}
        icon={<BiCopy />}
        _hover={{ color: "primaryFuchsia" }}
        variant="ghost"
        bg="transparent"
      />
    </Tooltip>
  );
};

export default CopyButton;
