export default {
  POLICY_TYPE: ["PERMANENT_COLLECTION", "TEMPORARY_EXHIBITION"],
  POLICY_STATUS: [
    {
      label: "Offer",
      value: "PENDING",
    },
    {
      label: "Policy",
      value: "APPROVED",
    },
  ],
  POLICY_STATUS_EXTENDED: [
    {
      label: "Rejected",
      value: "REJECTED",
    },
  ],
  RISK_TYPE: [
    {
      label: "First Risk",
      value: "FIRST_RISK",
    },
    {
      label: "All Risk",
      value: "ALL_RISK",
    },
  ],
  COVER_TYPE: [
    {
      label: "Nail to nail",
      value: "NAIL_TO_NAIL",
    },
    {
      label: "Stay Risk",
      value: "STAY_RISK",
    },
    {
      label: "Travel Risk",
      value: "TRAVEL_RISK",
    },
  ],
  EVALUATION_TYPE: [
    { value: "AGREED_VALUE", label: "Agreed value" },
    { value: "DECLARED_VALUE", label: "Declared value" },
  ],
};
