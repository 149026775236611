/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
import { FormLabel, HStack, Switch as ChakraSwitch, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { FormInputProps } from "./type";
import { Wrapper } from "./Wrapper";

const Switch = ({
  name,
  label,
  type,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  register,
  errors,
  watch,
  required,
}: FormInputProps) => (
  <Wrapper name={name} error={errors[name]}>
    <HStack pb={3}>
      <FormLabel htmlFor={name} mb="0">
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
      <ChakraSwitch isDisabled={disabled} id={name} {...register(name)} />
      <Text variant="muted">{watch(name) ? "(Yes)" : "(No)"}</Text>
    </HStack>
  </Wrapper>
);

export default Switch;
