/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
import { ObjectSchema } from "yup";
import { ObjectShape } from "yup/lib/object";
import * as yup from "yup";

export default function isFieldRequired<T extends ObjectShape>(
  schema: ObjectSchema<T>,
  path: string
): boolean {
  try {
    const required = yup
      .reach(schema, path)
      .describe()
      .tests.some((test: { name: string }) => test.name === "required" || test.name === "defined");

    return required;
  } catch (err) {
    return false;
  }
}
