/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FormHelperText, FormLabel, Input, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useValidationContext } from "components/form/ValidationContext";
import isFieldRequired from "helpers/isFieldRequired";
import { MultiSelect } from "components/form";
import { FormInputProps } from "./type";
import Switch from "./Switch";
import TextArea from "./TextArea";
import FileUpload from "./FileUpload";
import Select from "./Select";
import { Wrapper } from "./Wrapper";
import DateTime from "./DateTime";
import Registry from "./Registry";
import Date from "./Date";

// this needs to have FormProvider from 'react-hook-form' to consume its context
const FormInputHook = ({
  name,
  label,
  type,
  placeholder,
  onChange,
  value,
  options,
  disabled = false,
  hidden = false,
  helperText,
  isMulti = false,
}: FormInputProps) => {
  const { t } = useTranslation();
  const { schema } = useValidationContext() || {};

  const isRequired = schema ? isFieldRequired(schema, name) : false;
  const {
    watch,
    register,
    formState: { errors },
    control,
  } = useFormContext();

  if (type === "registry") {
    return (
      <Registry
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
        value={value}
      />
    );
  }

  if (type === "file") {
    return (
      <FileUpload
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
      />
    );
  }

  if (type === "textarea") {
    return (
      <TextArea
        required={isRequired}
        name={name}
        label={label}
        errors={errors}
        placeholder={placeholder}
        disabled={disabled}
        register={register}
      />
    );
  }

  // if we have to render a switch
  if (type === "checkbox")
    return (
      <Switch
        errors={errors}
        register={register}
        watch={watch}
        name={name}
        label={label}
        disabled={disabled}
        required={isRequired}
      />
    );

  // If is a select
  if (options && typeof value !== "boolean") {
    if (isMulti) {
      return (
        <MultiSelect
          name={name}
          label={label}
          options={options}
          placeholder={placeholder}
          disabled={disabled}
          required={isRequired}
        />
      );
    }
    return (
      <Select
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        required={isRequired}
        control={control}
      />
    );
  }

  if (type === "datetime-local") {
    return (
      <DateTime
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        required={isRequired}
        control={control}
      />
    );
  }
  if (type === "date") {
    return (
      <Date
        name={name}
        label={label}
        errors={errors}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        control={control}
        required={isRequired}
      />
    );
  }
  // if all else but value isn't boolean
  return typeof value !== "boolean" ? (
    <Wrapper name={name} error={errors[name]} visibility={hidden ? "hidden" : "visible"}>
      {label && (
        <FormLabel htmlFor={name} mb="1" fontSize="xs" display="flex">
          <Text>{t(label)}</Text> {isRequired && <Text color="red">*</Text>}
        </FormLabel>
      )}
      <Input
        width="full"
        id={name}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        {...register(name)}
        value={value}
        borderRadius="md"
        borderWidth="2px"
        borderColor="gray.200"
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Wrapper>
  ) : null;
};
export default FormInputHook;
