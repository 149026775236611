import { Button, Heading, HStack, VStack, Text } from "@chakra-ui/react";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import {
  AssetInput,
  PolicyNode,
  useCreateAssetMutation,
  useCreatePolicyAssetMutation,
  useUpdatePolicyAssetMutation,
} from "graphql/queries/generated/queries";
import { PolicyAssetInputExtended, PolicyType } from "pages/spin/types";
import { useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { scroller } from "react-scroll";
import placeholderPNG from "assets/placeholder.png";
import { FormFooter } from "components/ui";
import { BaseRow } from "react-csv-importer";
import NewAssetDrawer from "../NewAssetDrawer";
import PolicyAssetEditableTable from "../PolicyAssetsEditableTable";
import SearchAssetsDrawer from "./SearchAssetsDrawer";
import ImportAssetsDrawer from "../ImportAssetsDrawer";
interface PolicyAssetsFormProps {
  type: PolicyType;
  policy: Partial<PolicyNode>;
  data: Partial<PolicyAssetInputExtended>[];
  policyAssetFields: any;
}

const PolicyAssetsForm = ({ policy, type, data, policyAssetFields }: PolicyAssetsFormProps) => {
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [showNewAssetDrawer, setShowNewAssetDrawer] = useState<boolean>(false);
  const [createAsset, { data: createAssetData, error: createAssetError }] =
    useCreateAssetMutation();
  const [userWantsToAddAssets, setUserWantsToAddAssets] = useState(false);
  const [
    createPolicyAsset,
    {
      data: createPolicyAssetData,
      error: createPolicyAssetError,
      loading: createPolicyAssetLoading,
    },
  ] = useCreatePolicyAssetMutation();
  const [
    updatePolicyAsset,
    {
      data: updatePolicyAssetData,
      error: updatePolicyAssetError,
      loading: updatePolicyAssetLoading,
    },
  ] = useUpdatePolicyAssetMutation();

  const methods = useForm<{ policyAssets: PolicyAssetInputExtended[] }>({
    defaultValues: {
      policyAssets: data,
    },
  });

  const onSubmit: SubmitHandler<{ policyAssets: PolicyAssetInputExtended[] }> = (formData, e) => {
    const { policyAssets } = formData;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const cleanPolicyAssets = policyAssets.map((policyAsset) => {
      const { title, creationPeriod, image, author, ...policyAssetData } = policyAsset;
      return policyAssetData;
    });

    const newlyAddedPolicyAssets = cleanPolicyAssets.filter(({ status }) => status === "NEW");
    const updatedPolicyAssets = cleanPolicyAssets.filter(({ id }) => id);

    newlyAddedPolicyAssets.every(async (policyAsset) => {
      await createPolicyAsset({
        variables: {
          input: {
            policyAssetData: {
              policy: policy.id,
              ...policyAsset,
            },
          },
        },
      });
    });

    updatedPolicyAssets.every(async (policyAsset) => {
      await updatePolicyAsset({
        variables: {
          input: {
            id: policyAsset.id,
            policyAssetData: {
              ...policyAsset,
            },
          },
        },
      });
    });
  };

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "policyAssets",
  });

  const addPolicyAsset = ({
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => {
    const { evaluationType, catNat, coverTerrorism, coveredRiskType, coverType } = policy;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    append({
      ...policyAssetFields,
      asset: gqlId,
      title,
      image: image ?? placeholderPNG,
      author,
      creationPeriod,
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      status: "NEW",
    });
  };

  const watchPolicyAssets = methods.watch("policyAssets");

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    const result = await Promise.all(
      rows.map(async (row: AssetInput) => {
        const copiedRow = row;
        // if (!row.category) {
        //   copiedRow.category = "---";
        //   copiedRow.subcategory = "";
        // }
        const data = await createAsset({
          variables: {
            input: {
              assetData: {
                ...copiedRow,
                validated: false,
              },
            },
          },
        });
        return data;
      })
    )
      .then((result) => {
        if (!result) throw new Error("Error importing asset");
        // eslint-disable-next-line array-callback-return
        result.map(({ data }) => {
          if (!data.createAsset) throw new Error("Error importing asset");
          const {
            createAsset: { asset },
          } = data;

          addPolicyAsset({
            ...asset,
          });
        });

        // const { createAsset } = result;
        // addPolicyAsset({});
      })
      .catch((e) => console.error(e));
  };
  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <VStack alignItems="flex-start" mb={8}>
            <Heading>Assets currently in policy</Heading>
            <Text variant="muted">
              <Trans>Lorem ipsum dolor sit amet</Trans>
            </Text>
            <HStack pt={2} pb={8}>
              <Button
                onClick={() => {
                  setUserWantsToAddAssets(true);
                  setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                    scroller.scrollTo("userWantsToAddAssetsRefToScrollTo", {
                      duration: 800,
                      delay: 100,
                      smooth: "easeInOutQuart",
                    });
                  }, 500);
                }}
              >
                <Trans>Select an existing asset</Trans>
              </Button>

              <Button onClick={() => setShowNewAssetDrawer(true)}>
                <Trans>Create new asset</Trans>
              </Button>
              <Button onClick={() => setShowImportAssetsDrawer(true)}>
                <Trans>Import from csv</Trans>
              </Button>
            </HStack>
          </VStack>
          <PolicyAssetEditableTable data={watchPolicyAssets} type={type} />
          <SearchAssetsDrawer
            show={userWantsToAddAssets}
            onClose={() => setUserWantsToAddAssets(false)}
            addPolicyAsset={addPolicyAsset}
            policyAssets={watchPolicyAssets}
          />
          <NewAssetDrawer
            show={showNewAssetDrawer}
            onClose={() => setShowNewAssetDrawer(false)}
            addPolicyAsset={addPolicyAsset}
          />
          <FormFooter
            errors={[createPolicyAssetError, updatePolicyAssetError]}
            data={[createPolicyAssetData, updatePolicyAssetData]}
            title="Policy asset set"
            loading={createPolicyAssetLoading && updatePolicyAssetLoading}
          />
        </form>
      </FormProvider>

      <ImportAssetsDrawer
        show={showImportAssetsDrawer}
        onClose={() => setShowImportAssetsDrawer(false)}
        processChunk={processCsvRows}
      />
    </>
  );
};

export default PolicyAssetsForm;
