import i18n from "i18next";
// import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";

const isDevelopmentEnv = process.env.NODE_ENV === "development";

const locizeOptions = {
  projectId: "5845c913-0cc0-4968-afc6-2ef20cdda31c",
  apiKey: "01597f65-f860-403e-9a1a-ec379993b7a3", // YOU should not expose your apps API key to production!!!
  referenceLng: "en",
};

// // eslint-disable-next-line @typescript-eslint/no-floating-promises
// i18n
//   // i18next-http-backend
//   // loads translations from your server
//   // https://github.com/i18next/i18next-http-backend
//   .use(Backend)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   // .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init(i18nextOptions);

const i18nextOptions = {
  // backend: {
  //   loadPath: "/locales/{{lng}}/{{ns}}.json",
  //   addPath: "/locales/add/{{lng}}/{{ns}}.json",
  // },
  backend: locizeOptions,
  saveMissing: isDevelopmentEnv, // save missing keys just when node env. is development
  saveMissingTo: "all" as "all" | "current" | "fallback" | undefined,
  debug: isDevelopmentEnv, // debug just for development env.
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  fallbackLng: "en",
  whitelist: ["en", "fr", "it", "de", "es"],
  ns: "common",
  react: {
    useSuspense: true,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    // format: (value, format, lng) => { // legacy usage
    //   if (value instanceof Date) {
    //     return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
    //   }
    //   return value;
    // }
  },
};

if (!i18n.isInitialized) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init(i18nextOptions)
    .then((c) =>
      i18n.changeLanguage("en", (err, t) => {
        if (err) {
          return console.log("[i18n] couldn't change language", err);
        }
        return null;
      })
    )
    .catch((e) => console.log(e));
  // .then((value) => {
  //   i18n
  //     .changeLanguage(config.fallbackLng, (err, t) => {
  //       if (err) {
  //         return console.log("couldn't change language");
  //       }
  //       return null;
  //     })
  //     .catch((e) => console.log(e));
  // })
  // .catch((e) => console.log(e));
}

export default i18n;
