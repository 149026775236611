/* eslint-disable @typescript-eslint/no-misused-promises */
import "./App.scss";
// import "instantsearch.css/themes/algolia.css";

import { ApolloClient, ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { AuthProvider } from "contexts";
import theme from "globalStyles/theme";
import { useApollo } from "graphql/client";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Routing } from "routes";
import { useEffect } from "react";
import { TenantProvider } from "contexts/TenantContext";
type LocationProps = {
  state: {
    from: Location;
  };
};
interface I18NNamespace {
  pages: {
    hello: string;
  };
  common: string;
}

function App() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const client: ApolloClient<unknown> = useApollo();

  const { i18n } = useTranslation();

  const location = useLocation() as unknown as LocationProps;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const from = location.state?.from?.pathname || "/";

  const changeLanguage = (language: string) => {
    i18n
      .changeLanguage(language)
      .then((d) => d)
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        window.addEventListener("storage", (event) => {
          if (event.key === "logout") {
            sessionStorage.clear();
            localStorage.clear();
            return;
          }
          // console.log("storage event", event);
          if (
            event.key === "getSessionStorage" &&
            sessionStorage.length > 0 &&
            sessionStorage.getItem("jwtToken")
          ) {
            // Some tab asked for the sessionStorage -> send it
            localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
            console.log("[AUTH] Setting session storage for a brief moment.");
            localStorage.removeItem("sessionStorage");
          } else if (
            (event.key === "sessionStorage" && !sessionStorage.length) ||
            !sessionStorage.getItem("jwtToken")
          ) {
            localStorage.removeItem("getSessionStorage");
            console.log("[AUTH] Received session storage where missing", from);
            // sessionStorage is empty -> fill it
            if (!event.newValue) return;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const data = JSON.parse(event.newValue);

            // eslint-disable-next-line guard-for-in
            for (const key in data) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              sessionStorage.setItem(key, data[key] as string);
            }

            // We should redirect here, but -idky- from is undefined here, and defined in login
            // which actually run before app: correct from : Login => undefined : App
            // navigate(from, { replace: true });
          }
        });

        const jwtTokenFromSession: unknown = sessionStorage.getItem("jwtToken");
        const logoutToken: unknown = localStorage.getItem("logout");

        if (!sessionStorage.length || !jwtTokenFromSession || !logoutToken) {
          // Ask other tabs for session storage
          console.log(
            "Calling getSessionStorage",
            !sessionStorage.length || !jwtTokenFromSession || !logoutToken,
            !sessionStorage.length,
            !jwtTokenFromSession,
            !logoutToken
          );

          localStorage.setItem("getSessionStorage", String(Date.now()));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  if (!process.env.REACT_APP_TENANT_ID) throw new Error("Tenant ID must be set.");

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={client}>
        <TenantProvider tenantId={process.env.REACT_APP_TENANT_ID}>
          <AuthProvider>
            <Routing setCurrentLocale={changeLanguage} />
          </AuthProvider>
        </TenantProvider>
      </ApolloProvider>
    </ChakraProvider>
  );
}

export default App;
