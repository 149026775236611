/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { VStack, Text, HStack, Box, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { Dot } from "components/ui";
import { PolicyAssetNode } from "graphql/queries/generated/queries";
import { Trans } from "react-i18next";

interface PCPolicyAssetSummaryProps {
  data: PolicyAssetNode & {
    author: string;
    title: string;
    creationPeriod: string;
  };
}

const PCPolicyAssetSummary = ({ data }: PCPolicyAssetSummaryProps) => {
  const {
    asset,
    author,
    title,
    creationPeriod,
    catNat,
    coverTerrorism,
    coverType,
    coveredRiskType,
    evaluationType,
    exemption,
    objectLocationEntity,
    objectLocationOffice,
    insuredValue,
  } = data;
  return (
    <VStack width="full" spacing="6" align="left" p="4">
      <HStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Author</Trans>
          </Text>
          <Text>{author}</Text>
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Creation period</Trans>
          </Text>
          <Text>{creationPeriod}</Text>
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Title</Trans>
          </Text>
          <Text>{title}</Text>
        </VStack>
      </HStack>
      <HStack mb="8">
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Natural disasters</Trans>
          </Text>
          <Dot status={catNat as boolean} />
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Cover Terrorism</Trans>
          </Text>
          <Dot status={coverTerrorism as boolean} />
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Exemption</Trans>
          </Text>
          <Dot status={exemption as boolean} />
        </VStack>
      </HStack>
      <HStack mb="8">
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Cover type</Trans>
          </Text>
          <Text>
            <Trans>{coverType}</Trans>
          </Text>
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Covered risk type</Trans>
          </Text>
          <Text>
            <Trans>{coveredRiskType}</Trans>
          </Text>
        </VStack>
        <VStack alignItems="flex-start">
          <Text variant="muted">
            <Trans>Evalutation Type</Trans>
          </Text>
          <Text>
            <Trans>{evaluationType}</Trans>
          </Text>
        </VStack>
      </HStack>
      <HStack mb="8">
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Museum/Institution</Trans>
          </Text>
          <Text>{objectLocationEntity?.fullName}</Text>
        </VStack>
        <VStack alignItems="flex-start" pr={8}>
          <Text variant="muted">
            <Trans>Object location</Trans>
          </Text>
          <Text>{objectLocationOffice?.name}</Text>
        </VStack>
      </HStack>
      <Box>
        <Stat>
          <StatLabel>
            <Trans>Insured value</Trans>
          </StatLabel>
          <StatNumber fontSize="sm">
            <>
              {insuredValue.amount}&nbsp;
              {insuredValue.currency?.code}
            </>
          </StatNumber>
        </Stat>
      </Box>
    </VStack>
  );
};
export default PCPolicyAssetSummary;
