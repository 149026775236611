export default [
  "policyStatus",
  "totalInsuredValue",
  "totalInsuredPremium",
  "coveredRiskType",
  "coverType",
  "evaluationType",
  "insuranceCompany",
  "contractingParty",
  "broker",
  "issuedAt",
  "expiresAt",
  "policyCode",
  "offerCode",
  "createdBy",
  "isExpired",
  "assetsCount",
  "hasValidCertificate",
];
