import { Badge, Image, Text } from "@chakra-ui/react";
import { RegistryAwareField, Table } from "components/ui";
import { PolicyAssetInput } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { UseFieldArrayRemove } from "react-hook-form";
import placeholderPNG from "assets/placeholder.png";
import { Trans } from "react-i18next";
import { PolicyAssetInputExtended, PolicyType } from "../types";
import PCPolicyAssetFields from "../pc-policies/PCPolicyAssetFields";
import TEPolicyAssetFields from "../te-policies/TEPolicyAssetFields";
import PCPolicyAssetSummary from "../pc-policies/PCPolicyAssetSummary";
import TEPolicyAssetSummary from "../te-policies/TEPolicyAssetSummary";

interface PolicyAssetEditableTableProps {
  data: PolicyAssetInputExtended[];
  mode?: "view" | "edit";
  remove?: UseFieldArrayRemove | ((arg0: number) => void);
  type: PolicyType;
}

const CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "image",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,
    weight: 13,
    disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 12,
    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 11,
    disableFilters: true,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lender.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Shipper",
    Footer: "",
    accessor: "shipper.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lenderId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Shipper",
    Footer: "",
    accessor: "shipperId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <RegistryAwareField id={value} />,
    disableFilters: true,
  },
];

const PolicyAssetEditableTable = ({
  data,
  remove,
  type,
  mode = "edit",
}: PolicyAssetEditableTableProps) => {
  if (!data || data?.length === 0)
    return (
      <Text>
        <Trans>No assets has been added yet.</Trans>
      </Text>
    );

  const columns = getColumns<PolicyAssetInput>({
    data: data[0],
    customColumns: CUSTOM_COLUMNS,
    customFields: [
      "title",
      "author",
      "creationPeriod",
      "coveredRiskType",
      "evaluationType",
      "coverType",
      "image",
      "lender",
      "shipper",
      "objectLocationEntity",
      "objectLocationOffice",
    ],
  });

  return (
    <Table
      //   batchActions={BATCH_ACTIONS}
      hiddenColumns={[
        "pickupAddress",
        "lenderAddress",
        "deliveryAddress",
        "catNat",
        "coverTerrorism",
        "exemption",
        "objectLocationEntity",
        "objectLocationOffice",
      ]}
      columns={columns}
      data={data}
      deleteAction={(id: number) => {
        remove(id);
      }}
      // TODO: this is a shitshow, need to refactor
      renderRowSubComponent={
        // eslint-disable-next-line no-nested-ternary
        mode === "edit"
          ? type === "PERMANENT_COLLECTION"
            ? PCPolicyAssetFields
            : TEPolicyAssetFields
          : type === "PERMANENT_COLLECTION"
          ? PCPolicyAssetSummary
          : TEPolicyAssetSummary
      }
    />
  );
};

export default PolicyAssetEditableTable;
