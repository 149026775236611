import { HStack, Link, Skeleton, Text } from "@chakra-ui/react";
import { useGetRegistryByIdQuery } from "graphql/queries/generated/queries";
import { RiExternalLinkLine } from "react-icons/ri";

const RegistryAwareField = ({
  id,
  highContrast = false,
}: {
  id?: string;
  highContrast?: boolean;
}) => {
  const { data, loading, error, refetch } = useGetRegistryByIdQuery({
    variables: {
      id,
    },
  });

  if (error) return <Text>...</Text>;

  if (loading) return <Skeleton w="10rem" h="2rem" />;
  const { registry } = data;
  return (
    <HStack>
      <Text color={highContrast ? "white" : "black"}>{registry?.fullName}</Text>
      {registry?.fullName && (
        <Link href={`/o/registry/edit/${id}`} color="brand.900">
          <RiExternalLinkLine />
        </Link>
      )}
    </HStack>
  );
};
export default RegistryAwareField;
