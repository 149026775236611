import { Box, Icon, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  BiAward,
  BiCollection,
  BiMoney,
  BiPaint,
  BiPaperclip,
  BiQuestionMark,
} from "react-icons/bi";
import { BsFillCollectionFill } from "react-icons/bs";
import { MdOutlineLocalShipping, MdOutlineMuseum, MdOutlinePolicy } from "react-icons/md";
import { RiMapPinTimeLine, RiNewspaperLine, RiPassportLine } from "react-icons/ri";

const topMenuEntries = [
  {
    to: "/mgmt/assets",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Management system</Trans>,
  },
  {
    to: "/spin/",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Spin</Trans>,
  },
  {
    to: "/o/registry/",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Registry</Trans>,
  },
  {
    to: "/o/analysis",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Analysis</Trans>,
  },
  {
    to: "/o/users",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Users management</Trans>,
  },
];

const mgmtMenuEntries = [
  {
    to: "/mgmt/assets",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>All assets</Trans>,
    children: [
      {
        to: "/spin/te-policies/new",
        name: <Trans>Add new policy</Trans>,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
      },
    ],
  },
  {
    to: "/mgmt/assets/new",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Add new asset</Trans>,
  },
  {
    to: "/mgmt/collections",
    icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="black" />,
    name: <Trans>Collections</Trans>,
  },
  {
    to: "/mgmt/collections/new",
    icon: <Icon mr={3} w={6} h={6} as={BiCollection} color="black" />,
    name: <Trans>Add new collection</Trans>,
  },
  {
    to: "/mgmt/exhibitions",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineMuseum} color="black" />,
    name: <Trans>Exhibitions</Trans>,
  },
  {
    to: "/mgmt/logistic",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlineLocalShipping} color="black" />,
    name: <Trans>Logistic</Trans>,
  },
  {
    to: "/mgmt/insurance",
    icon: <Icon mr={3} w={6} h={6} as={MdOutlinePolicy} color="black" />,
    name: <Trans>Insurance</Trans>,
  },
  {
    to: "/mgmt/appraisal",
    icon: <Icon mr={3} w={6} h={6} as={BiMoney} color="black" />,
    name: <Trans>Appraisal</Trans>,
  },
  {
    to: "/mgmt/cr-sr",
    icon: <Icon mr={3} w={6} h={6} as={BiAward} color="black" />,
    name: <Trans>CR/SR</Trans>,
  },
  {
    to: "/mgmt/quotation",
    icon: <Icon mr={3} w={6} h={6} as={BiQuestionMark} color="black" />,
    name: <Trans>Quotation</Trans>,
  },
  {
    to: "/mgmt/docs",
    icon: <Icon mr={3} w={6} h={6} as={BiPaperclip} color="black" />,
    name: <Trans>Documentation</Trans>,
  },
];

const spinMenuEntries = [
  {
    to: "/spin/te-policies",
    icon: <Icon mr={3} w={6} h={6} as={RiMapPinTimeLine} color="black" />,
    name: <Trans>Temporary Exhibition Policies</Trans>,
    children: [
      {
        to: "/spin/te-policies/new",
        name: <Trans>Add new policy</Trans>,
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
      },
    ],
  },
  {
    to: "/spin/certificates",
    icon: <Icon mr={3} w={6} h={6} as={RiPassportLine} color="black" />,
    name: <Trans>Certificates</Trans>,
  },
  {
    to: "/spin/te-policies-objects",
    icon: (
      <Box position="relative">
        <Icon mr={3} w={6} h={6} as={RiMapPinTimeLine} color="black" />
        <Text fontWeight={500} position="absolute" top="1.2rem">
          OBJ
        </Text>
      </Box>
    ),
    name: <Trans>Temporary Exhibition Objects</Trans>,
  },
  {
    to: "/spin/pc-policies",
    icon: <Icon mr={3} w={6} h={6} as={BsFillCollectionFill} color="black" />,
    name: <Trans>Permanent Collection Policies</Trans>,
    children: [
      {
        to: "/spin/pc-policies/new",
        icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
        name: <Trans>Add new policy</Trans>,
      },
    ],
  },
  {
    to: "/spin/inventories",
    icon: <Icon mr={3} w={6} h={6} as={RiNewspaperLine} color="black" />,
    name: <Trans>Inventories</Trans>,
  },
  {
    to: "/spin/pc-policies-objects",
    icon: (
      <Box position="relative">
        <Icon mr={3} w={6} h={6} as={BsFillCollectionFill} color="black" />
        <Text fontWeight={500} position="absolute" top="1.2rem">
          OBJ
        </Text>
      </Box>
    ),
    name: <Trans>Permanent Collection Objects</Trans>,
  },
];

const genericMenuEntries = [
  {
    to: "/o/analysis",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Analysis</Trans>,
  },
  {
    to: "/o/registry",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Registry</Trans>,
  },
  {
    to: "/o/users",
    icon: <Icon mr={3} w={6} h={6} as={BiPaint} color="black" />,
    name: <Trans>Users</Trans>,
  },
];

export { genericMenuEntries, mgmtMenuEntries, spinMenuEntries, topMenuEntries };
