import {
  Box,
  Heading,
  HStack,
  VStack,
  Skeleton,
  Text
} from "@chakra-ui/react";
import { CopyButton, QuickActions } from "components/ui";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import {
  useGetRegistryByIdQuery,
} from "graphql/queries/generated/queries";

const categoryMap = {
  "collector" : "Collector",
  "advisor" : "Advisor",
  "restorer" : "Restorer",
  "registrar" : "Registrar",
  "publisher" : "Publisher",
  "photo_service" : "Photo service",
  "artisan_service" : "Artisan Service",
  "print_service" : "Print service",
  "exhibition_organizer" : "Exhibition Organizer",
  "other_category" : "Other",
  "art_critic_curator" : "Art Critic/Curator",
  "expert" : "Expert",
  "museum" : "Museum",
  "private_institution_foundation" : "Private Istitution/Foundation",
  "public_institution" : "Public istitution",
  "archive" : "Archive",
  "gallery" : "Gallery",
  "auction_house" : "Auction house",
  "insurance_company" : "Insurance company",
  "broker_seller_agency" : "Broker/Seller/Agency",
  "liquidator" : "Liquidator",
  "warehouse" : "Warehouse",
  "logistics" : "Logistics" 
}

const authorTypeMap = {
  "painter" : "Painter",
  "sculptor" : "Sculptor",
  "designer" : "Designer",
  "digital_artist" : "Digital Artist",
  "crypto_artist" : "Crypto Artist",
  "videomaker" : "Videomaker",
  "director" : "Director",
  "composer_musician" : "Composer/Musician",
  "photographer" : "Photographer",
  "carpenter" : "Carpenter",
  "artisan" : "Artisan",
  "architect" : "Architect",
  "writer" : "Writer",
  "graphic_illustrator" : "Graphic/Illustrator",
  "scriptwriter" : "Scriptwriter",
  "scenographer" : "Scenographer",
  "goldsmith" : "Goldsmith",
  "miniaturist_calligrapher" : "Miniaturist/Calligrapher",
  "glassworker" : "Glassworker",
  "potter" : "Potter",
  "other_author" : "Other"
}

const RegistryViewPage = () => {

  const { t } = useTranslation();
  const { registryId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const { data, loading, error, refetch } = useGetRegistryByIdQuery({
    variables: { id: registryId ?? "" },
  });

  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  if (!data || !data.registry) return null;
  if (data.registry != null && data.registry.authorType == null) data.registry.authorType = [];
  if (data.registry != null && data.registry.categories == null) data.registry.categories = [];
  if (data.registry != null && data.registry.isAuthor == null) data.registry.isAuthor = false;

  const {
    registry: {
      isCompany,
      fullName,
      businessName,
      alias,
      webSite,
      note,
      countryCode,
      taxId,
      billingAddress,
      printName,
      printCode,
      phone,
      mobilePhone,
      email,
      isAuthor,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      birthDate,
      birthPlace,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      deathDate,
      deathPlace,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      activityPeriod,
      activityPlace,
      artisticMovement,
      foundation,
      // categories,
      // authorType,
      id,
    },
  } = data;

  const QUICK_ACTIONS = [
    {
      name: t("Edit Registry"),
      href: `/o/registry/edit/${id}`,
      icon: <BiEdit />,
    },
  ];

  return (
    <Box bgColor="white" p={8} rounded="lg" shadow="md">
      <Skeleton isLoaded={!loading}>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <VStack alignItems="flex-start" mb={8}>
            <Heading>{fullName} {businessName}</Heading>
            <Text fontSize="lg" variant="muted">
              #{registryId ?? ""} <CopyButton value={registryId ?? ""} />
            </Text>
            <Text>{isCompany ? "Company" : ""}</Text>
            <Text>{data.registry.categories.map(categoryCode => ( String(categoryMap[categoryCode]).concat(" ") ) ) } </Text>
          </VStack>

          <QuickActions noTitle actions={QUICK_ACTIONS} />
        </HStack>

        <HStack w="100%" my={4}>            
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Alias</Trans>
            </Text>
            <Text>{alias}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Web Site</Trans>
            </Text>
            <Text>{webSite}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Note</Trans>
            </Text>
            <Text>{note}</Text>
          </VStack>
        </HStack>
        
        <HStack w="100%" my={4}>            
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Country</Trans>
            </Text>
            <Text>{countryCode}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Tax ID</Trans>
            </Text>
            <Text>{taxId}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Billing Address</Trans>
            </Text>
            <Text>{billingAddress}</Text>
          </VStack>
        </HStack>

        <HStack w="100%" my={4}>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Phone</Trans>
            </Text>
            <Text>{phone}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Mobile Phone</Trans>
            </Text>
            <Text>{mobilePhone}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Email</Trans>
            </Text>
            <Text>{email}</Text>
          </VStack>
        </HStack>

        <HStack w="100%" my={4}>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Print Code</Trans>
            </Text>
            <Text>{printName}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Print name</Trans>
            </Text>
            <Text>{printCode}</Text>
          </VStack>
        </HStack>

        {isAuthor && <HStack w="100%" my={4}>
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Author</Trans>
            </Text>
            <Text>{data.registry.authorType.map(typeCode => ( String(authorTypeMap[typeCode]).concat(" ") ) ) } </Text>
          </VStack>
          
          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Artistic Movement</Trans>
            </Text>
            <Text>{artisticMovement}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Archive/Foundation</Trans>
            </Text>
            <Text>{foundation}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Birth Date</Trans>
            </Text>
            <Text>{birthDate}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Birth Place</Trans>
            </Text>
            <Text>{birthPlace}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Death Date</Trans>
            </Text>
            <Text>{deathDate}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Death Place</Trans>
            </Text>
            <Text>{deathPlace}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Activity Period</Trans>
            </Text>
            <Text>{activityPeriod}</Text>
          </VStack>

          <VStack alignItems="flex-start" pr={8}>
            <Text variant="muted">
              <Trans>Activity Place</Trans>
            </Text>
            <Text>{activityPlace}</Text>
          </VStack>

        </HStack> }
        
      </Skeleton>
    </Box>      
  );
};

export default RegistryViewPage;
