import { Box, Heading, HStack, VStack, Text, Badge, Divider, Skeleton } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropdownFilter, NoDataPlaceholder, QuickActions, Table } from "components/ui";
import { RiAddLine } from "react-icons/ri";
import getColumns from "helpers/getColumns";
import { useGetAllPoliciesQuery } from "graphql/queries/generated/queries";

const CUSTOM_FIELDS = [
  "policyType",
  "policyStatus",
  "totalInsuredValue",
  "totalInsuredPremium",
  "coveredRiskType",
  "coverType",
  "evaluationType",
  "contractingParty",
  "insuranceCompany",
  "broker",
  "policyCode",
  "offerCode",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Policy Type",
    accessor: "policyType",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      const label = value === "TEMPORARY_EXHIBITION" ? "TE" : "PC";
      return <Badge variant={value}>{label}</Badge>;
    },
    weight: 10,
  },
  {
    Header: "Type",
    accessor: "policyStatus",
    Filter: DropdownFilter,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      const label = value === "PENDING" ? "O" : "P";
      return <Badge variant={value}>{label}</Badge>;
    },
    weight: 10,
  },
  {
    Header: "Policy #",
    Footer: "Policy #",
    accessor: "policyCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
  },
  {
    Header: "Offer #",
    Footer: "Offer #",
    accessor: "offerCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    weight: 10,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "totalInsuredValue.amount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Contracting party",
    Footer: "Contracting party",
    disableFilters: true,
    accessor: "contractingParty.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "broker",
    Footer: "broker",
    disableFilters: true,
    accessor: "broker.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "insuranceCompany",
    Footer: "insurancecompany",
    disableFilters: true,
    accessor: "insuranceCompany.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "TIP",
    Footer: "TIP",
    disableFilters: true,
    accessor: "totalInsuredPremium.amount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  {
    Header: "Covered Risk Type", // No header
    Footer: "",
    accessor: "coveredRiskType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Cover Type", // No header
    Footer: "",
    accessor: "coverType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    weight: 10,
    disableFilters: true,
  },
  {
    Header: "Evaluation Type", // No header
    Footer: "",
    accessor: "evaluationType", // It needs an ID
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
];

const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new Temporary Exhibition Policy",
    href: "/te-policies/new",
    icon: <RiAddLine />,
  },
  {
    name: "Add new Permanent Collection Policy",
    href: "/pc-policies/new",
    icon: <RiAddLine />,
  },
];

const SpinIndexPage = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useGetAllPoliciesQuery();

  if (loading) return <Skeleton isLoaded={!loading} />;
  if (error) return <p>{error.toString()}</p>;
  if (!data || !data.allPolicies || data.allPolicies.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicies: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onDelete = async (id: string) => {
    // await toggleIsActiveUser({
    //   variables: {
    //     input: {
    //       id,
    //     },
    //   },
    // });
    // await refetch();
  };

  return (
    <VStack align="flex-start">
      <HStack spacing="12" justify="flex-start">
        <Link to="/spin/pc-policies/new">
          <Box p="4" rounded="md" shadow="md">
            <Heading size="md">
              <Trans>Insure a permanent collection</Trans>
            </Heading>

            <Text>
              <Trans>Insure a permanent collection button description</Trans>
            </Text>
          </Box>
        </Link>
        <Link to="/spin/te-policies/new">
          <Box p="4" rounded="md" shadow="md">
            <Heading size="md">
              <Trans>Insure a temporary exhibition</Trans>
            </Heading>
            <Text>
              <Trans>Insure a temporary exhibition button description</Trans>
            </Text>
          </Box>
        </Link>
      </HStack>
      <Table
        //   batchActions={BATCH_ACTIONS}
        hiddenColumns={[
          "coveredRiskType",
          "coverType",
          "totalInsuredValue.amount",
          "totalInsuredPremium.amount",
        ]}
        columns={columns}
        data={data.allPolicies.edges.flatMap((e) => e?.node)}
        pathname="/spin/pc-policies/"
        deleteAction={(id: string) => onDelete(id)}
        //   deleteActionLoading={toggleIsActiveUserLoading}
      />
    </VStack>
  );
};

export default SpinIndexPage;
