import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Input,
  FormLabel,
  Box,
  HStack,
} from "@chakra-ui/react";
import { Trans } from "react-i18next";

import { ChangeEvent, useRef, useState } from "react";

interface DateDialogProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  onSubmit: ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => void | Promise<void>;
  title?: string;
  message?: string;
  onlyEndDate?: boolean;
}

const DateDialog = ({
  onOpen,
  onClose,
  isOpen,
  onSubmit,
  title = "",
  message = "",
  onlyEndDate = true,
}: DateDialogProps) => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const cancelRef = useRef();

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Trans>{title}</Trans>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Trans>{message}</Trans>
            <HStack mt={4}>
              <Box>
                <FormLabel>
                  <Trans>Start date</Trans>
                </FormLabel>
                <Input
                  type="datetime-local"
                  name="startDate"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setStartDate(e.target.value)}
                />
              </Box>
              <Box>
                <FormLabel>
                  <Trans>End date</Trans>
                </FormLabel>
                <Input
                  type="datetime-local"
                  name="endDate"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setEndDate(e.target.value)}
                />
              </Box>
            </HStack>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              ml={3}
              variant="outline"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                onSubmit({ startDate, endDate });
                onClose();
              }}
            >
              Continue
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default DateDialog;
