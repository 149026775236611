import React, { createContext, useContext } from "react";
import { ObjectSchema } from "yup";
import { ObjectShape } from "yup/lib/object";
import { SchemaObjectDescription } from "yup/lib/schema";

export const ValidationContext = createContext(null);

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const useValidationContext = <T,>(): ContextValue<
  T extends ObjectShape ? ObjectShape : any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
> => useContext(ValidationContext);

interface ContextValue<T extends ObjectShape> {
  schema: ObjectSchema<T>;
}
interface ValidationProviderProps<T extends ObjectShape> {
  children: JSX.Element;
  schema: ObjectSchema<T>;
}

export const ValidationProvider = <T,>({
  children,
  schema,
}: ValidationProviderProps<T extends ObjectShape ? ObjectShape : any>) => {
  const [describedSchema, setDescribedSchema] = React.useState<null | SchemaObjectDescription>();
  // Not used in the demo, but maybe usefull
  // if we ever need the described schema

  // const value = useMemo(() => ({ schema, describeSchema, describedSchema }), []);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ValidationContext.Provider value={{ schema }}>{children}</ValidationContext.Provider>
  );
};
