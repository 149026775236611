import { useTranslation } from "react-i18next";
import { HStack, VStack, Stack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
// import { ApolloError } from "@apollo/client";
// import {
//   CreateAssetMutation,
//   UpdateAssetMutation,
//   AssetInput,
// } from "graphql/queries/generated/queries";
import { ApolloError } from "@apollo/client";
import { FormInputHook } from "components/ui";
import { BaseSyntheticEvent, useEffect } from "react";
import {
    UseFormGetValues,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
  } from "react-hook-form";


// import {
//   UseFormRegister,
//   UseFormGetValues,
//   UseFormSetError,
//   UseFormSetValue,
// } from "react-hook-form";
import ASSET_COSTANTS from "constants/asset";
import {
    AssetInput,
    CreateAssetMutation,
    UpdateAssetMutation,
  } from "graphql/queries/generated/queries";

interface HistoricDateFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean;
  updateAssetLoading: boolean;
}



const HistoricDateForm = ({
    // eslint-disable-next-line react/prop-types
    onSubmit,
    // handleChange,
    // handleRadioButtons,
    // values,
    register,
    setValue,
    setError,
    getValues,
    watch,
    createAssetData,
    updateAssetData,
    createAssetError,
    updateAssetError,
    createAssetLoading,
    updateAssetLoading,
    category,
    children,
  }: HistoricDateFormProps) => {
    const { t } = useTranslation();
  // const { title } = getValues();

    // const [dateType, setDateType] = useState("period");


    // const [isVisiblePeriod, serIsVisiblePeriod] = useState<number>(1);
    // const [isVisibleDate, serIsVisibleDate] = useState<number>(0);
    // const [isVisibleYear, serIsVisibleYear] = useState<number>(0);
    // const [isVisibleCentury, serIsVisibleCentury] = useState<number>(0);
    // const handleRadioButtons = (e: React.FormEvent<HTMLInputElement>) => {
    //     setDateType((e.target as HTMLInputElement).value);
    //   };

    // const handleRadioButtons = (e: React.FormEvent<HTMLInputElement>) => {
    //     setDateType((e.target as HTMLInputElement).value);
    //   };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const datingType = watch("datingType");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const isDateInterval = watch("isDateInterval");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const isYearInterval = watch("isYearInterval");
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const isCenturyInterval = watch("isCenturyInterval");

    // const onChangeIsInterval = (type: string) => {
    //     switch(type) {
    //         case 'date':
    //             setValue("dateEndDate", "")
    //             setValue("isDateEndDateBc", Boolean(false));
    //             break;
    //         case 'year':
    //             setValue("yearEndDate", undefined)
    //             setValue("isYearEndDateBc", Boolean(false));
    //             break;
    //         case 'century':
    //             setValue("isCenturyEndDateBc", Boolean(false));
    //             setValue("centuryEndDateWindow", "");
    //             setValue("centuryEndDate", undefined)
    //             break;
    //             default:
    //     }
    // }

    const onChangeDatingType = () => {
        // datingType
        setValue("creationPeriod", "");
        setValue("isDateInterval", Boolean(false));
        setValue("dateStartDate", "");
        setValue("dateEndDate", "");
        setValue("isDateStartDateBc", Boolean(false));
        setValue("isDateEndDateBc", Boolean(false));
        setValue("isDateApprox", Boolean(false));
        setValue("isYearInterval", Boolean(false));
        setValue("yearStartDate",  undefined);
        setValue("yearEndDate", undefined);
        setValue("isYearStartDateBc", Boolean(false));
        setValue("isYearEndDateBc", Boolean(false));
        setValue("isYearApprox", Boolean(false));
        setValue("isCenturyInterval", Boolean(false));
        setValue("centuryStartDate", undefined);
        setValue("centuryEndDate", undefined);
        setValue("isCenturyStartDateBc", Boolean(false));
        setValue("isCenturyEndDateBc", Boolean(false));
        setValue("isCenturyApprox", Boolean(false));
        setValue("centuryStartDateWindow", "");
        setValue("centuryEndDateWindow", "");
    }

    useEffect( () => {
        onChangeDatingType();
        return undefined;
   } , [datingType]);

    // useEffect( () => {
    //     onChangeIsInterval('date');
    //     return undefined;
    // } , [isDateInterval]);
    // useEffect( () => {
    //     onChangeIsInterval('year');
    //     return undefined;
    // } , [isYearInterval]);

    // useEffect( () => {
    //     onChangeIsInterval('century');
    //     return undefined;
    // } , [isCenturyInterval]);



    return (
            <HStack>
                {/* <RadioGroup onChange={setDateType} value={dateType}>
                    <Radio checked={dateType === 'period'} value="period" onChange={(e) => handleRadioButtons(e)}>
                        <Trans>Period</Trans>
                    </Radio>
                    <Radio checked={dateType === 'date'} value="date" onChange={(e) => handleRadioButtons(e)}>
                        <Trans>Date</Trans>
                    </Radio>
                    <Radio checked={dateType === 'year'} value="year" onChange={(e) => handleRadioButtons(e)}>
                        <Trans>Year</Trans>
                    </Radio>
                    <Radio checked={dateType === 'century'} value="century" onChange={(e) => handleRadioButtons(e)}>
                        <Trans>Century</Trans>
                    </Radio>
                </RadioGroup> */}
                <FormInputHook
                    label=""
                    options={[{ label: "- Select  -", value: "" }].concat(
                        ASSET_COSTANTS.ASSET_DATING_TYPES
                    )}
                    name="datingType"
                />
                { datingType === 'PERIOD' && <Stack>
                    <FormInputHook
                        label=""
                        options={[{ label: "- Select period -", value: "" }].concat(
                            ASSET_COSTANTS.ASSET_DATE_ERAS
                        )}
                        name="creationPeriod"
                    />
                </Stack>}
                { datingType === 'DATE' && <HStack>
                        <VStack>
                            <FormInputHook name="isDateInterval" label="Is interval" type="checkbox" />
                            <HStack alignItems="flex-end">
                                <FormInputHook label="Start date" type="date" name="dateStartDate" />
                                <FormInputHook name="isDateStartDateBc" label="B.C." type="checkbox" />
                            </HStack>
                            <HStack alignItems="flex-end">
                                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                                <FormInputHook disabled={!isDateInterval} label="End date" type="date" name="dateEndDate" />
                                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                                <FormInputHook disabled={!isDateInterval} name="isDateEndDateBc" label="B.C." type="checkbox" />
                            </HStack>
                        </VStack>
                        <FormInputHook name="isDateApprox" label="Circa" type="checkbox" />
                    </HStack>}
                { datingType === 'YEAR' && <HStack>
                        <VStack>
                            <FormInputHook name="isYearInterval" label="Is interval" type="checkbox" />
                            <HStack alignItems="flex-end">
                                {/* <FormInputHook label="" type="date" name="yearStartDate" /> */}
                                <FormInputHook label="Start year" type="number" name="yearStartDate" />
                                <FormInputHook name="isYearStartDateBc" label="B.C." type="checkbox" />
                            </HStack>
                            <HStack alignItems="flex-end">
                                {/* <FormInputHook label="" type="date" name="yearEndDate" /> */}
                                <FormInputHook disabled={!isYearInterval} label="End year" type="number" name="yearEndDate" />
                                <FormInputHook disabled={!isYearInterval} name="isYearEndDateBc" label="B.C." type="checkbox" />
                            </HStack>
                        </VStack>
                        <FormInputHook name="isYearApprox" label="Circa" type="checkbox" />
                    </HStack>}
                { datingType === 'CENTURY' && <HStack>
                    <VStack>
                        <FormInputHook name="isCenturyInterval" label="Is interval" type="checkbox" />
                        <HStack alignItems="flex-end">
                            {/* <FormInputHook label="" type="date" name="centuryStartDate" /> */}
                            <FormInputHook label="Start century" name="centuryStartDate"
                                options = {[{ label: "- Select  -", value: "" }].concat(
                                    [
                                        {label: "1", value: "1"},{label: "2", value: "2"},
                                        {label: "3", value: "3"},{label: "4", value: "4"},
                                        {label: "5", value: "5"},{label: "6", value: "6"},
                                        {label: "7", value: "7"},{label: "8", value: "8"},
                                        {label: "9", value: "9"},{label: "10", value: "10"},
                                        {label: "11", value: "11"},{label: "12", value: "12"},
                                        {label: "13", value: "13"},{label: "14", value: "14"},
                                        {label: "15", value: "15"},{label: "16", value: "16"},
                                        {label: "17", value: "17"},{label: "18", value: "18"},
                                        {label: "19", value: "19"},{label: "20", value: "20"},
                                        {label: "21", value: "21"}
                                    ]
                                )}
                            />
                            <FormInputHook  name="isCenturyStartDateBc" label="B.C." type="checkbox" />
                            <FormInputHook
                                label=""
                                options={[{ label: "- Select  -", value: "" }].concat(
                                    ASSET_COSTANTS.ASSET_DATE_WINDOWS
                                )}
                                name="centuryStartDateWindow"
                            />
                        </HStack>
                        <HStack alignItems="flex-end">
                            {/* <FormInputHook label="" type="date" name="centuryEndDate" /> */}
                            <FormInputHook disabled={!isCenturyInterval} label="End century" name="centuryEndDate"
                                options = {[{ label: "- Select  -", value: "" }].concat(
                                    [
                                        {label: "1", value: "1"},{label: "2", value: "2"},
                                        {label: "3", value: "3"},{label: "4", value: "4"},
                                        {label: "5", value: "5"},{label: "6", value: "6"},
                                        {label: "7", value: "7"},{label: "8", value: "8"},
                                        {label: "9", value: "9"},{label: "10", value: "10"},
                                        {label: "11", value: "11"},{label: "12", value: "12"},
                                        {label: "13", value: "13"},{label: "14", value: "14"},
                                        {label: "15", value: "15"},{label: "16", value: "16"},
                                        {label: "17", value: "17"},{label: "18", value: "18"},
                                        {label: "19", value: "19"},{label: "20", value: "20"},
                                        {label: "21", value: "21"}
                                    ]
                                )}
                            />
                            <FormInputHook disabled={!isCenturyInterval} name="isCenturyEndDateBc" label="B.C." type="checkbox" />
                            <FormInputHook
                                disabled={!isCenturyInterval}
                                label=""
                                options={[{ label: "- Select  -", value: "" }].concat(
                                    ASSET_COSTANTS.ASSET_DATE_WINDOWS
                                )}
                                name="centuryEndDateWindow"
                            />
                        </HStack>
                    </VStack>
                    <FormInputHook name="isCenturyApprox" label="Circa" type="checkbox" />
                </HStack>}

            {/* <FieldGroup title={t("Notes")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
                <VStack width="full" align="left">
                <HStack alignItems="flex-end">
                    <FormInputHook
                    label=""
                    name="infoNotes"
                    type="textarea"
                    />
                </HStack>
                </VStack>
            </FieldGroup> */}

        {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
            <Button
                isLoading={createAssetLoading || updateAssetLoading}
                loadingText="Loading"
                type="submit"
                variant="primary"
            >
                {t("Update")}
            </Button>
            {children}
        </ButtonGroup> */}
        </HStack>
    );
};

export default HistoricDateForm;
