import { useMemo } from "react";
import { Select } from "@chakra-ui/react";
import { Trans } from "react-i18next";

function DropdownFilter({ column: { filterValue, setFilter, preFilteredRows, id, Header } }) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      width="auto"
      bgColor="white"
      shadow={"sm"}
      size="sm"
      borderRadius="md"
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">
        <Trans>{Header}</Trans>
      </option>
      {options.map((option, i) => {
        let text = "";
        switch (option) {
          case true:
            text = "Yes";
            break;
          case false:
            text = "No";
            break;
          case "PENDING":
            text = "Offer";
            break;
          case "APPROVED":
            text = "Policy";
            break;
          default:
            text = option;
        }

        return (
          <option key={i} value={option}>
            <Trans>{text}</Trans>
          </option>
        );
      })}
    </Select>
  );
}

export default DropdownFilter;
