/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation, Trans } from "react-i18next";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Badge, Button, Tabs, TabList, Tab, TabPanel, TabPanels, Heading, useToast } from "@chakra-ui/react";
import {
  useCreateAssetMutation,
  useUpdateAssetMutation,
  AssetInput,
  UpdateAssetInput,
  CreateAssetInput
  ,
} from "graphql/queries/generated/queries";
import { SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
// import cleanObject from "helpers/cleanObject";
import { AssetInputSchema } from "graphql/queries/generated/validation-schema";
import { FormFooter } from "components/ui";

import { ValidationProvider } from "components/form/ValidationContext";
import PanelNewCategoryForm from "./PanelNewCategoryForm";
import OriginNewCategoryForm from './origin/OriginNewCategoryForm';
import BibliographyNewCategoryForm from './origin/BibliographyNewCategoryForm';
import AssetCarryingValueForm from './values/AssetCarryingValueForm';
import AssetPresentValueForm from './values/AssetPresentValueForm';


import defaultValues from '../utils/AssetDefaultValues';
import clearHistoricDateValues from '../utils/ClearHistoricDateValues';



interface NewCategoryFormProps {
  category: string;
  children?: React.ReactNode;
  // resetCategory:  (e: React.MouseEvent<HTMLButtonElement>) => void
  resetCategory:  () => void

  // goToNextTab: () => void;
  // handleTabChange: (index: number) => void;
  // tabIndex: number;
}

const steps = [
  { key: "info_generali", label: "General info" },
  { key: "dimensioni", label: "Dimensions" },
  { key: "aspetti_legali", label: "Legal aspects" },
  { key: "dettagli_tecnici", label: "Technical details" },
  { key: "caratteristiche_fisiche", label: "Physical features" },
  { key: "allestimento", label: "Setting" },
  { key: "condizioni_ambientali", label: "Environmental conditions" },
  { key: "note", label: "Notes" },
];

const NewCategoryForm = ({ category, children, resetCategory }: NewCategoryFormProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const { nextStep, prevStep, activeStep, setStep } = useSteps({
    initialStep: 0,
  });

  const { nextStep: nextStepOrigin, prevStep: prevStepOrigin, activeStep: activeStepOrigin, setStep: setStepOrigin } = useSteps({
    initialStep: 0,
  });

  const { nextStep: nextStepValue, prevStep: prevStepValue, activeStep: activeStepValue, setStep: setStepValue } = useSteps({
    initialStep: 0,
  });

  const toast = useToast();

  const { t } = useTranslation();

  const [id, setId] = useState<string | null>(null);

  const [tabIndex, setTabIndex] = useState(0);

  const [nextStepName, setNextStepName] = useState("nextStep");

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setNextStepName("nextStep");
        break;
      case 1:
        setNextStepName("nextStepOrigin");
        break;
      case 2:
        setNextStepName("nextStepValue");
        break;
      default:
        setNextStepName(null);
    }

    setTabIndex(index);
  };

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  };

  const [
    createAsset,
    { data: createAssetData, loading: createAssetLoading, error: createAssetError },
  ] = useCreateAssetMutation();

  const [
    updateAsset,
    { data: updateAssetData, loading: updateAssetLoading, error: updateAssetError },
  ] = useUpdateAssetMutation();


  useEffect(() => {
    if (createAssetData) {
      toast({
        title: t("Asset has been created."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }

    if (createAssetError)  {
      toast({
        title: t("Asset couldn't be created."),
        description: t(`Error: ${createAssetError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [createAssetData, createAssetError]);


  useEffect(() => {
    if (updateAssetData) {
      toast({
        title: t("Asset has been saved."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }

    if (updateAssetError)  {
      toast({
        title: t("Asset hasn't been saved."),
        description: t(`Error: ${updateAssetError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [updateAssetData, updateAssetError]);




  const methods = useForm<AssetInput>({
    defaultValues: {
      ...defaultValues,
      category,
      subcategory: category,
      validated: false,
    },
    resolver: yupResolver(AssetInputSchema()),
    mode: "onChange",
  });

  const onError = (errors: any, e: any) => console.log(errors, e);



  const onSubmit: SubmitHandler<AssetInput> = async (values, e) => {
    let response;
    const assetData = clearHistoricDateValues(values);


    assetData.carryingValueAmount = {...assetData.carryingValueAmount,
      // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
      currency: assetData.carryingValueAmount.currency || 'EUR'
    };

    // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
    // if ('file' in assetData.carryingValueFile && (assetData.carryingValueFile.file === '')) {
    //   assetData.carryingValueFile = "";

    // }


    if (id !== null) {
      // response = await updateAsset({
      //   variables: {
      //     input: {
      //       assetData: {
      //         title: assetData.title,
      //         author: assetData.author,
      //         authorEntityId: assetData.authorEntityId,
      //         // creationDate: (assetData.creationDate === "" || assetData.creationDate === undefined) ? null : `${assetData.creationDate as string}T00:00:00`,
      //         // creationPeriod: assetData.creationPeriod === "" ? null : assetData.creationPeriod,

      //         datingType: assetData.datingType,
      //         creationPeriod: assetData.creationPeriod,
      //         isDateInterval: assetData.isDateInterval,
      //         dateStartDate: assetData.dateStartDate,
      //         dateEndDate: assetData.dateEndDate,
      //         isDateStartDateBc: assetData.isDateStartDateBc,
      //         isDateEndDateBc: assetData.isDateEndDateBc,
      //         isDateApprox: assetData.isDateApprox,
      //         isYearInterval: assetData?.isYearInterval ?? null,
      //         yearStartDate: assetData?.yearStartDate ?? null,
      //         yearEndDate: assetData.yearEndDate,
      //         isYearStartDateBc: assetData.isYearStartDateBc,
      //         isYearEndDateBc: assetData.isYearEndDateBc,
      //         isYearApprox: assetData.isYearApprox,
      //         isCenturyInterval: assetData.isCenturyInterval,
      //         centuryStartDate: assetData?.centuryStartDate ?? null,
      //         centuryEndDate: assetData?.centuryEndDate ?? null,
      //         isCenturyStartDateBc: assetData.isCenturyStartDateBc,
      //         isCenturyEndDateBc: assetData.isCenturyEndDateBc,
      //         isCenturyApprox: assetData.isCenturyApprox,
      //         centuryStartDateWindow: assetData.centuryStartDateWindow,
      //         centuryEndDateWindow: assetData.centuryEndDateWindow,

      //         hasFullDatetime: assetData.hasFullDatetime,
      //         hasPeriod: assetData.hasPeriod,
      //         hasYear: assetData.hasYear,
      //         category: category.toLowerCase(),
      //         subcategory: category.toLowerCase(),
      //         series: assetData.series,
      //         seriesDetails: assetData.seriesDetails,
      //         description: assetData.description,
      //         inventoryNumber: assetData.inventoryNumber,
      //         archiveNumber: assetData.archiveNumber,
      //         catalogNumber: assetData.catalogNumber,
      //         criticalCatalogue: assetData.criticalCatalogue,
      //         nft: assetData.nft,
      //         nftNotes: assetData.nftNotes,
      //         netWidth: assetData?.netWidth ?? null,
      //         netHeight: assetData?.netHeight ?? null,
      //         netDepth: assetData?.netDepth ?? null,
      //         netDiameter: assetData?.netDiameter ?? null,
      //         netLengthUnit: assetData.netLengthUnit,
      //         netWeightUnit: assetData.netWeightUnit,
      //         netWeight: assetData.netWeight,
      //         netDimensionsAcquisition: assetData.netDimensionsAcquisition,

      //         grossWidth: assetData?.grossWidth ?? null,
      //         grossHeight: assetData?.grossHeight ?? null,
      //         grossDepth: assetData?.grossDepth ?? null,
      //         grossDiameter: assetData?.grossDiameter ?? null,
      //         grossLengthUnit: assetData.grossLengthUnit,
      //         grossWeightUnit: assetData.grossWeightUnit,
      //         grossWeight: assetData.grossWeight,
      //         grossDimensionsAcquisition: assetData.grossDimensionsAcquisition,

      //         dimensionsNotes: assetData.dimensionsNotes,

      //         isNotifiedCulturalProperty: assetData.isNotifiedCulturalProperty,
      //         notifiedPropertyNote: assetData.notifiedPropertyNote,
      //         legalStatus: assetData.legalStatus,
      //         copyrightAndPropertyRestrictions: assetData.copyrightAndPropertyRestrictions,


      //         isArtistProof: assetData.isArtistProof,
      //         artistProofNotes: assetData.artistProofNotes,
      //         isArtMultiple: assetData.isArtMultiple,
      //         multipleNumber: assetData.multipleNumber,
      //         isSignedObject: assetData.isSignedObject,
      //         signedObjectNotes: assetData.signedObjectNotes,
      //         isTitledObject: assetData.isTitledObject,
      //         titledObjectNotes: assetData.titledObjectNotes,
      //         isDatedObject: assetData.isDatedObject,
      //         datedObjectNotes: assetData.datedObjectNotes,
      //         hasLabelsStamps: assetData.hasLabelsStamps,
      //         labelsStampsNotes: assetData.labelsStampsNotes,
      //         hasOtherAutographed: assetData.hasOtherAutographed,
      //         otherAutographedNotes: assetData.otherAutographedNotes,
      //         relevantAwards: assetData.relevantAwards,

      //         isComposed: assetData.isComposed,
      //         surfaceCharacteristics: assetData.surfaceCharacteristics,
      //         hasBase: assetData.hasBase,
      //         baseDetails: assetData.baseDetails,
      //         hasWoodFrame: assetData.hasWoodFrame,
      //         woodFrameDetails: assetData.woodFrameDetails,

      //         hasBarriers: assetData.hasBarriers,
      //         barriersDetails: assetData.barriersDetails,
      //         handlingRequirements: assetData.handlingRequirements,
      //         injuryRisk: assetData.injuryRisk,
      //         injuryRiskDescription: assetData.injuryRiskDescription,
      //         mouldingSupportBase: assetData.mouldingSupportBase,
      //         hardwareToolsInstallation: assetData.hardwareToolsInstallation,
      //         conservation: assetData.conservation,
      //         lenderRequests: assetData.lenderRequests,
      //         settingNotes: assetData.settingNotes,

      //         optimalLightLevelIntensity: assetData?.optimalLightLevelIntensity ?? null,
      //         lightUnit: assetData.lightUnit,
      //         optimalTemperature: assetData?.optimalTemperature ?? null,
      //         temperatureUnit: assetData.temperatureUnit,
      //         optimalHumidity: assetData?.optimalHumidity ?? null,
      //         enviromentalConditionsNotes: assetData.enviromentalConditionsNotes,

      //         infoNotes: assetData.infoNotes,

      //         originNotes: assetData.originNotes,
      //         bibliographyNotes: assetData.bibliographyNotes,


      //         carryingValueOrigin: assetData.carryingValueOrigin,
      //         carryingValueAcquisition: assetData.carryingValueAcquisition,
      //         carryingValueNotes: assetData.carryingValueNotes,
      //         carryingValueLastAcquisitionDate: assetData?.carryingValueLastAcquisitionDate ?? null,
      //         carryingValueAmount: assetData.carryingValueAmount,
      //         carryingValueFileTitle: assetData.carryingValueFileTitle,
      //         carryingValueFile: assetData.carryingValueFile,



      //         validated: false,
      //       },
      //       id: String(id),
      //     },
      //   },
      // });
      response = await updateAsset({
        variables: {
          input: {
            assetData: {
              ...(assetData as UpdateAssetInput["assetData"]),
              category: category.toLowerCase(),
              subcategory: category.toLowerCase(),
              validated: false,
            },
            id: String(id),
          }
        }
      })
    } else {
      // response = await createAsset({
      //   variables: {
      //     input: {
      //       assetData: {
      //         title: assetData.title,
      //         author: assetData.author,
      //         authorEntityId: assetData.authorEntityId,
      //         // creationDate: (assetData.creationDate === "" || assetData.creationDate === undefined) ? null : `${assetData.creationDate as string}T00:00:00`,
      //         // creationPeriod: assetData.creationPeriod === "" ? null : assetData.creationPeriod,

      //         datingType: assetData.datingType,
      //         creationPeriod: assetData.creationPeriod,
      //         isDateInterval: assetData.isDateInterval,
      //         dateStartDate: assetData.dateStartDate,
      //         dateEndDate: assetData.dateEndDate,
      //         isDateStartDateBc: assetData.isDateStartDateBc,
      //         isDateEndDateBc: assetData.isDateEndDateBc,
      //         isDateApprox: assetData.isDateApprox,
      //         isYearInterval: assetData.isYearInterval,
      //         yearStartDate: assetData?.yearStartDate ?? null,
      //         yearEndDate: assetData?.yearEndDate ?? null,
      //         isYearStartDateBc: assetData.isYearStartDateBc,
      //         isYearEndDateBc: assetData.isYearEndDateBc,
      //         isYearApprox: assetData.isYearApprox,
      //         isCenturyInterval: assetData.isCenturyInterval,
      //         centuryStartDate: assetData?.centuryStartDate ?? null,
      //         centuryEndDate: assetData?.centuryEndDate ?? null,
      //         isCenturyStartDateBc: assetData.isCenturyStartDateBc,
      //         isCenturyEndDateBc: assetData.isCenturyEndDateBc,
      //         isCenturyApprox: assetData.isCenturyApprox,
      //         centuryStartDateWindow: assetData.centuryStartDateWindow,
      //         centuryEndDateWindow: assetData.centuryEndDateWindow,

      //         hasFullDatetime: assetData.hasFullDatetime,
      //         hasPeriod: assetData.hasPeriod,
      //         hasYear: assetData.hasYear,
      //         category: category.toLowerCase(),
      //         subcategory: category.toLowerCase(),
      //         series: assetData.series,
      //         seriesDetails: assetData.seriesDetails,
      //         description: assetData.description,
      //         inventoryNumber: assetData.inventoryNumber,
      //         archiveNumber: assetData.archiveNumber,
      //         catalogNumber: assetData.catalogNumber,
      //         criticalCatalogue: assetData.criticalCatalogue,
      //         nft: assetData.nft,
      //         nftNotes: assetData.nftNotes,
      //         validated: false,
      //       },
      //     },
      //   },
      // });
      response = await createAsset({
        variables: {
          input: {
            assetData: {
              ...(assetData as CreateAssetInput['assetData']),
              validated: false,
              category: category.toLowerCase(),
              subcategory: category.toLowerCase(),
            }
          }
        }
      });
      if (
        response.data &&
        response.data.createAsset &&
        response.data.createAsset.asset &&
        response.data.createAsset.asset.id
      ) {
        // eslint-disable-next-line prefer-template, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-shadow
        setId(response.data.createAsset.asset.id);
      }
    }
    setTimeout(() => {
      // nextStep();
      switch(nextStepName) {
        case 'nextStep':
          if (activeStep !== steps.length - 1) {
            nextStep();
          }
          break;
        case 'nextStepOrigin':
          if (activeStepOrigin !== 1) {
            nextStepOrigin();
          }
          break;
        case 'nextStepValue':
          if (activeStepValue !== 1) {
            nextStepValue();
          }
          break;
          default:
      }

    }, 500);
  };

  const handleSubmit = methods.handleSubmit(onSubmit, onError);
  // const handleSubmitStep = async (nextStepFunction: string) => {
  //   setNextStepName(nextStepFunction);
  //   await handleSubmit();
  // }
  // return <div>Category: {category}, step : {activeStep}</div>;
  return (
    <FormProvider {...methods}>
      <ValidationProvider schema={AssetInputSchema()}>
        <form
          id="info_generali"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit}
        >
          <Tabs colorScheme="brand" variant="line" mt="4" index={tabIndex} onChange={handleTabChange}>
            <TabList>
              <Tab mr={8}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  1
                </Badge>
                <Trans>Info</Trans>
              </Tab>

              <Tab mr={8} isDisabled={id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  2
                </Badge>
                <Trans>Origin and bibliography</Trans>
              </Tab>

              <Tab mr={8} isDisabled={id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  3
                </Badge>
                <Trans>Value</Trans>
              </Tab>
              <Tab mr={8} isDisabled={id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  4
                </Badge>
                <Trans>Chronology</Trans>
              </Tab>
              <Tab mr={8} isDisabled={id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  5
                </Badge>
                <Trans>Documents</Trans>
              </Tab>
              <Tab mr={8} isDisabled={id === null}>
                <Badge mr={2} px={2} rounded="full" fontWeight={700}>
                  6
                </Badge>
                <Trans>Media</Trans>
              </Tab>
            </TabList>
                <TabPanels>
                  <TabPanel p={3}>
                    <Steps colorScheme="whiteAlpha" onClickStep={(step) => { if (id) {setStep(step);} }} size="sm" pt={3} orientation="vertical" activeStep={activeStep}>
                      {steps.map(({ label, key }, index) => {
                        if (
                          key === "info_generali" ||
                          key === "dimensioni" ||
                          key === "aspetti_legali" ||
                          key === "dettagli_tecnici" ||
                          key === "caratteristiche_fisiche" ||
                          key === "allestimento" ||
                          key === "condizioni_ambientali" ||
                          key === "note"
                        ) {
                          return (
                            <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>{label}</Trans></Heading>} key={key}>
                              <PanelNewCategoryForm
                                steps={steps}
                                activeStep={activeStep}
                                prevStep={prevStep}
                                nextStep={nextStep}
                                category={category}
                                id={id}
                                panel={key}
                                handleSubmit={handleSubmit}
                                getValues={methods.getValues}
                                register={methods.register}
                                setValue={methods.setValue}
                                setError={methods.setError}
                                watch={methods.watch}
                                createAssetData={createAssetData}
                                updateAssetData={updateAssetData}
                                createAssetError={createAssetError}
                                updateAssetError={updateAssetError}
                                createAssetLoading={createAssetLoading}
                                updateAssetLoading={updateAssetLoading}
                              >
                                {/* <Button onClick={nextStep}>
                                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                                </Button> */}
                                {children}

                                {/* <Button type="reset" onClick={() => resetCategory()}>
                                  Annulla
                                </Button> */}
                              </PanelNewCategoryForm>
                            </Step>
                          );
                        }
                        return (
                          <Step isCompletedStep={false} textAlign="left" width="100%" label={label} key={key}>
                            <Button
                              isActive={activeStep !== 0}
                              isDisabled={activeStep === 0}
                              mr={4}
                              onClick={prevStep}
                              variant="ghost"
                            >
                              Prev
                            </Button>
                            <div>
                              Category: {category}, step : {activeStep}
                            </div>
                          </Step>
                        );
                      })}
                    </Steps>
                  </TabPanel>
                  <TabPanel p={3}>

                      <Steps colorScheme="whiteAlpha" onClickStep={(step) => { if (id) {setStepOrigin(step);} }} size="sm" orientation="vertical" activeStep={activeStepOrigin}>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Origin</Trans></Heading>} key={0}>
                          <OriginNewCategoryForm
                            // handleSubmit={formik.handleSubmit}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}
                            createAssetData={createAssetData}
                            updateAssetData={updateAssetData}
                            createAssetError={createAssetError}
                            updateAssetError={updateAssetError}
                            createAssetLoading={createAssetLoading}
                            updateAssetLoading={updateAssetLoading}
                            category={category}
                          >
                            {children}
                            {/* {id && (
                              <Button
                                mr={4}
                                onClick={nextStepOrigin}
                                variant="ghost"
                              >
                                Next
                              </Button>
                            )} */}
                          </OriginNewCategoryForm>
                        </Step>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Bibliography</Trans></Heading>} key={1}>
                          <BibliographyNewCategoryForm
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            createAssetData={createAssetData}
                            updateAssetData={updateAssetData}
                            createAssetError={createAssetError}
                            updateAssetError={updateAssetError}
                            createAssetLoading={createAssetLoading}
                            updateAssetLoading={updateAssetLoading}
                            category={category}
                          >
                            {children}
                            {/* <Button mr={4} onClick={prevStepOrigin} variant="ghost">
                              Prev
                            </Button> */}
                          </BibliographyNewCategoryForm>
                        </Step>
                      </Steps>
                  </TabPanel>
                  <TabPanel p={3}>
                      <Steps colorScheme="whiteAlpha" onClickStep={(step) => { if (id) {setStepValue(step);} }} size="sm" orientation="vertical" activeStep={activeStepValue}>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Carrying Value</Trans></Heading>} key={0}>

                        {/* <FieldGroup title={t("Carrying value")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}> */}


                          <AssetCarryingValueForm
                            // handleSubmit={formik.handleSubmit}
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            // handleChange={formik.handleChange}
                            // handleRadioButtons={handleRadioButtons}
                            // values={formik.values}
                            createAssetData={createAssetData}
                            updateAssetData={updateAssetData}
                            createAssetError={createAssetError}
                            updateAssetError={updateAssetError}
                            createAssetLoading={createAssetLoading}
                            updateAssetLoading={updateAssetLoading}
                            category={category}
                          >
                            {children}
                            {/* {id && (
                              <Button
                                mr={4}
                                onClick={nextStepValue}
                                variant="ghost"
                              >
                                Next
                              </Button>
                            )} */}
                          </AssetCarryingValueForm>


                        {/* </FieldGroup> */}
                        </Step>
                        <Step isCompletedStep={false} textAlign="left" width="100%" label={<Heading as="h2" size="sm"><Trans>Present values</Trans></Heading>} key={1}>
                          <AssetPresentValueForm assetId={id} />
                            {/* <Button mr={4} onClick={prevStepValue} variant="ghost">
                              Prev
                            </Button>
                          </AssetPresentValueForm> */}


                          {/* <BibliographyNewCategoryForm
                            onSubmit={handleSubmit}
                            getValues={methods.getValues}
                            register={methods.register}
                            setValue={methods.setValue}
                            setError={methods.setError}
                            watch={methods.watch}
                            createAssetData={createAssetData}
                            updateAssetData={updateAssetData}
                            createAssetError={createAssetError}
                            updateAssetError={updateAssetError}
                            createAssetLoading={createAssetLoading}
                            updateAssetLoading={updateAssetLoading}
                            category={category}
                          >
                            {children}
                            <Button mr={4} onClick={prevStepOrigin} variant="ghost">
                              Prev
                            </Button>
                          </BibliographyNewCategoryForm> */}
                        </Step>
                      </Steps>
                  </TabPanel>
                </TabPanels>




          </Tabs>
          {id && <FormFooter cancelAction={resetCategory} data={updateAssetData} errors={[updateAssetError]} loading={updateAssetLoading} title="Asset entry" />}
          {!id && <FormFooter cancelAction={resetCategory} data={createAssetData} errors={[createAssetError]} loading={createAssetLoading} title="Asset entry" />}
        </form>
      </ValidationProvider>
    </FormProvider>
  );
};

export default NewCategoryForm;
