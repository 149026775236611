import { Trans, useTranslation } from "react-i18next";
import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import { NoDataPlaceholder, QuickActions, Table } from "components/ui";
import { RiAddLine } from "react-icons/ri";
import getColumns from "helpers/getColumns";
import {
  PolicyNode,
  useDeletePolicyMutation,
  useGetAllPoliciesByTypeQuery,
} from "graphql/queries/generated/queries";
import { isArray } from "@chakra-ui/utils";
import { useEffect } from "react";
import CUSTOM_FIELDS from "../shared/constants/allPoliciesTableCustomFields";
import CUSTOM_COLUMNS from "../shared/constants/allPoliciesTableCustomColumns";
import HIDDEN_COLUMNS from "../shared/constants/allPoliciesTableHiddenColumns";
// const BATCH_ACTIONS = [
//   {
//     name: <Trans>Disable selected users</Trans>,
//     action: (selectedRows: GetAllUsers_allUsers_edges_node) => console.log(selectedRows),
//   },
// ];
const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new policy",
    href: "new",
    icon: <RiAddLine />,
  },
];

const PCPoliciesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllPoliciesByTypeQuery({
    variables: {
      policyType: "PERMANENT_COLLECTION",
    },
  });

  const [
    deletePolicy,
    { data: deletePolicyData, error: deletePolicyError, loading: deletePolicyLoading },
  ] = useDeletePolicyMutation();

  useEffect(() => {
    if (deletePolicyData) {
      toast({
        title: t("Policy has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deletePolicyError)
      toast({
        title: t("Policy couldn't be deleted."),
        description: t(`Error: ${deletePolicyError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deletePolicyData, deletePolicyError]);

  if (loading) return <Skeleton w="100%" h="20vh" isLoaded={!loading} />;
  if (error) return <p>{error.toString()}</p>;
  if (!data || !data.allPolicies || data.allPolicies.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicies: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onBatchDelete = async (selectedRows: { original: PolicyNode }[]) => {
    if (isArray(selectedRows) && selectedRows.length > 0) {
      await Promise.all(
        selectedRows.map(async ({ original: { id } }) => {
          await deletePolicy({
            variables: {
              input: {
                id,
              },
            },
          });
        })
      );
      await refetch();
    }
  };

  const onDelete = async (id: string) => {
    await deletePolicy({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Delete policies</Trans>,
      action: (selectedRows: { original: PolicyNode }[]) => onBatchDelete(selectedRows),
    },
  ];

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          batchActions={BATCH_ACTIONS}
          columns={columns}
          hiddenColumns={HIDDEN_COLUMNS}
          data={data.allPolicies.edges.flatMap((e) => e?.node)}
          pathname="/spin/pc-policies/"
          deleteAction={(id: string) => onDelete(id)}
          refetch={() => refetch()}
          isLoading={deletePolicyLoading}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default PCPoliciesPage;
