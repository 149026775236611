import { Box, FormControl, FormErrorMessage } from "@chakra-ui/react";

// eslint-disable-next-line import/prefer-default-export
const InputWrapper = ({
  children,
  name,
  error,
  visibility = "visible",
}: {
  children: React.ReactNode;
  name?: string;
  error?: any;
  visibility?: "visible" | "hidden";
}) => (
  <Box pb={2} pr={6} visibility={visibility}>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
    <FormControl isInvalid={error}>
      {children}
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {error && name && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  </Box>
);
export default InputWrapper;
