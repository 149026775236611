import { FormFooter } from "components/ui";
import { PolicyDocumentInput, PolicyDocumentNode } from "graphql/queries/generated/queries";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import PolicyDocumentsFields from "../PolicyDocumentsFields";

interface PolicyDocumentsFormProps {
  data: Partial<PolicyDocumentNode>[];
}

const PolicyDocumentsForm = ({ data }: PolicyDocumentsFormProps) => {
  const methods = useForm<PolicyDocumentInput>({
    defaultValues: {
      ...data[0],
    },
  });

  const onSubmit: SubmitHandler<PolicyDocumentInput> = (formData, e) => {
    console.log(formData);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PolicyDocumentsFields />
        <FormFooter disabled data={[]} errors={[]} title="Policy document" loading={false} />
      </form>
    </FormProvider>
  );
};

export default PolicyDocumentsForm;
