import { Stack, useToast } from "@chakra-ui/react";
import {
  AssetInput,
  PolicyAssetNode,
  useCreateAssetMutation,
  useCreatePolicyDocumentMutation,
  useCreatePolicyMutation,
  useCreatePolicyNoteMutation,
  useCreateRegistryMutation,
  useGetRegistriesBySearchLazyQuery,
} from "graphql/queries/generated/queries";
import { NewPolicyForm, NewAssetDrawer, ImportAssetsDrawer } from "pages/spin/shared";
import { useForm, FormProvider, useFieldArray, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import placeholderPNG from "assets/placeholder.png";
import { PolicyInputExtended } from "pages/spin/types";
import { PolicyInputSchema } from "graphql/queries/overrides";
import { ValidationProvider } from "components/form/ValidationContext";
import defaultValues from "pages/spin/shared/defaultValues";
import { BaseRow } from "react-csv-importer";
import { useTenantContext } from "contexts/TenantContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetOrCreateRegistry from "hooks/useGetOrCreateRegistry";
import useGetAssetCurrentValue from "hooks/useGetAssetCurrentValue";

const policyAssetFields = {
  asset: "",
  catNat: false,
  coverTerrorism: false,
  coveredRiskType: "",
  coverType: "",
  evaluationType: "",
  exemption: false,
  title: "",
  author: "",
  creationPeriod: "",
  objectLocationEntityId: "",
  objectLocationOfficeId: "",
  insuredValue: {
    amount: null as number | null,
    currency: "EUR",
  },
};

const IMPORT_FIELDS = [
  { name: "category", optional: true },
  { name: "title", optional: false },
  { name: "author", optional: true },
  { name: "creationPeriod", optional: true },
  { name: "technique", optional: true },
  { name: "genericDimensions", optional: true },
  { name: "inventorynumber", optional: true },
  { name: "assetPresentValue.amout", optional: true },
  { name: "coveredRiskType", optional: true },
  { name: "riskType", optional: true },
  { name: "evaluationType", optional: true },
  { name: "exemption", optional: true },
  { name: "coverTerrorism", optional: true },
  { name: "catNat", optional: true },
];

const PCPoliciesNewPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [showNewAssetDrawer, setShowNewAssetDrawer] = useState<boolean>(false);
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { tenant } = useTenantContext();
  const getAssetCurrentValue = useGetAssetCurrentValue();

  const methods = useForm<PolicyInputExtended>({
    defaultValues,
    resolver: yupResolver(PolicyInputSchema()),
  });

  const getOrCreateRegistry = useGetOrCreateRegistry();
  const [createAsset, { data: createAssetData, error: createAssetError }] =
    useCreateAssetMutation();
  const [createPolicy, { data: createPolicyData, error: createPolicyError }] =
    useCreatePolicyMutation();
  const [
    createPolicyDocument,
    { data: createPolicyDocumentData, error: createPolicyDocumentError },
  ] = useCreatePolicyDocumentMutation();
  const [createPolicyNote, { data: createPolicyNoteData, error: createPolicyNoteError }] =
    useCreatePolicyNoteMutation();

  const [searchRegistry] = useGetRegistriesBySearchLazyQuery();
  const [createRegistry] = useCreateRegistryMutation();

  const {
    formState: { errors },
    setFocus,
    setValue,
  } = methods;

  useEffect(() => {
    if (createPolicyData) {
      toast({
        title: t("Policy has been created."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      const {
        createPolicy: { policy },
      } = createPolicyData;
      setTimeout(() => {
        navigate(`/spin/pc-policies/${policy.id}`);
      }, Number(process.env.REACT_APP_REDIRECT_TIMEOUT) ?? 3000);

      return;
    }

    if (createPolicyError)
      toast({
        title: t("Policy couldn't be created."),
        description: t(`Error: ${createPolicyError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [createPolicyData, createPolicyError]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof PolicyInputExtended);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const { fields, append, remove, update } = useFieldArray({
    control: methods.control,
    name: "policyAssets",
  });
  const {
    fields: noteFields,
    append: appendNote,
    remove: removeNote,
  } = useFieldArray({
    control: methods.control,
    name: "policyNote",
  });
  const {
    fields: documentFields,
    append: appendDocument,
    remove: removeDocument,
  } = useFieldArray({
    control: methods.control,
    name: "policyDocuments",
  });

  const watchPolicyAssets = methods.watch("policyAssets");

  useEffect(() => {
    console.log(watchPolicyAssets);
    if (!watchPolicyAssets || watchPolicyAssets.length === 0) return;
    let totalInsuredValue = 0;
    // eslint-disable-next-line array-callback-return
    const totalValue = watchPolicyAssets.map(({ insuredValue }) => {
      if (!Number.isNaN(insuredValue.amount)) {
        totalInsuredValue += Number(insuredValue.amount);
        return insuredValue.amount;
      }
      return 0;
    });

    console.log({ totalInsuredValue });
    setValue("totalInsuredValue.amount", totalInsuredValue, {
      shouldTouch: true,
      shouldDirty: true,
    });
  }, [watchPolicyAssets]);

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  };
  const goToPreviousTab = () => {
    setTabIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const addPolicyAsset = async ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => {
    const {
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      eventEntityId,
      eventLocationEntityId,
    } = methods.getValues();

    const { amount, currency } = (await getAssetCurrentValue(gqlId)) || {};

    append({
      ...policyAssetFields,
      asset: gqlId,
      title,
      image: image ?? placeholderPNG,
      author,
      creationPeriod,
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      insuredValue: {
        amount: amount ?? null,
        currency: currency ?? "EUR",
      },
      objectLocationEntityId: eventEntityId,
      objectLocationOfficeId: eventLocationEntityId,
    });
  };

  const onSubmit: SubmitHandler<PolicyInputExtended> = async (formData, e) => {
    const { policyAssets, policyDocuments, policyNote, ...policyData } = formData;

    const cleanPolicyAssets = policyAssets.map((policyAsset) => {
      const { title, creationPeriod, image, author, ...policyAssetData } = policyAsset;
      return policyAssetData;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const hasFile = policyDocuments.some(({ file }) => (file as unknown as FileList).length! > 0);

    const { data: createPolicyEvalutedData, errors: createPolicyEvaluatedErrors } =
      await createPolicy({
        variables: {
          input: {
            policyData: {
              ...policyData,
              policyAssets: cleanPolicyAssets,
              policyDocuments: hasFile ? policyDocuments : null,
              policyNote,
            },
          },
        },
      });

    if (createPolicyEvaluatedErrors) return;

    const {
      createPolicy: {
        policy: { id: policyId },
      },
    } = createPolicyEvalutedData;
  };

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    const result = await Promise.all(
      // eslint-disable-next-line consistent-return
      rows.map(async (row: AssetInput & PolicyAssetNode, index: number) => {
        const copiedRow = row;
        // if (!row.category) {
        //   copiedRow.category = "---";
        // }

        if (!row.subcategory) {
          copiedRow.subcategory = "";
        }

        if (!row.title.trim()) {
          toast({
            title: t(`Asset at row ${index} couldn't be uploaded`),
            description: t(`Error: Asset is missing title, which is a required field.`),
            status: "error",
            duration: 9000,
            isClosable: true,
          });

          return null;
        }
        const authorEntityId = await getOrCreateRegistry(row.author);

        const { data } = await createAsset({
          variables: {
            input: {
              assetData: {
                ...copiedRow,
                authorEntityId,
                validated: false,
              },
            },
          },
        });

        if (!data.createAsset) throw new Error("Error importing asset");
        const {
          createAsset: { asset },
        } = data;
        console.log({ asset });

        const { id, title, author, creationPeriod } = asset;

        const {
          evaluationType,
          catNat,
          coverTerrorism,
          coveredRiskType,
          coverType,
          eventEntityId,
          eventLocationEntityId,
        } = methods.getValues();

        append({
          ...policyAssetFields,
          id,
          title,
          author,
          creationPeriod,
          evaluationType: row.evaluationType ?? evaluationType,
          catNat: row.catNat ?? catNat,
          coverTerrorism: row.coverTerrorism ?? coverTerrorism,
          coveredRiskType: row.coveredRiskType ?? coveredRiskType,
          coverType: row.coverType ?? coverType,
          objectLocationEntityId: eventEntityId,
          objectLocationOfficeId: eventLocationEntityId,
        });
      })
    );
  };

  return (
    <Stack spacing="4">
      <ValidationProvider schema={PolicyInputSchema()}>
        <FormProvider {...methods}>
          <NewPolicyForm
            getValues={methods.getValues}
            policyAssets={watchPolicyAssets}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={methods.handleSubmit(onSubmit)}
            goToNextTab={goToNextTab}
            goToPreviousTab={goToPreviousTab}
            handleTabChange={handleTabChange}
            tabIndex={tabIndex}
            data={createPolicyData}
            loading={methods.formState.isSubmitting}
            error={[createPolicyError, createPolicyNoteError, createPolicyDocumentError]}
            extraFields={{ fields, append, remove, update }}
            setShowNewAssetDrawer={setShowNewAssetDrawer}
            setShowImportAssetsDrawer={setShowImportAssetsDrawer}
            addPolicyAsset={addPolicyAsset}
            policyType="PERMANENT_COLLECTION"
          />
        </FormProvider>
      </ValidationProvider>
      <NewAssetDrawer
        show={showNewAssetDrawer}
        onClose={() => setShowNewAssetDrawer(false)}
        addPolicyAsset={addPolicyAsset}
      />
      <ImportAssetsDrawer
        show={showImportAssetsDrawer}
        onClose={() => setShowImportAssetsDrawer(false)}
        processChunk={processCsvRows}
        fields={IMPORT_FIELDS}
      />
    </Stack>
  );
};
export default PCPoliciesNewPage;
