export default {
    author: "",
    authorEntityId: "",
    title: "",
    // creationDate: "",

    // campi datazione
    datingType: "",
    creationPeriod: "",
    isDateInterval: Boolean(false),
    dateStartDate: null,
    dateEndDate: null,
    isDateStartDateBc: Boolean(false),
    isDateEndDateBc: Boolean(false),
    isDateApprox: Boolean(false),
    isYearInterval: Boolean(false),
    yearStartDate: undefined,
    yearEndDate: undefined,
    isYearStartDateBc: Boolean(false),
    isYearEndDateBc: Boolean(false),
    isYearApprox: Boolean(false),
    isCenturyInterval: Boolean(false),
    centuryStartDate: undefined,
    centuryEndDate: undefined,
    isCenturyStartDateBc: Boolean(false),
    isCenturyEndDateBc: Boolean(false),
    isCenturyApprox: Boolean(false),
    centuryStartDateWindow: "",
    centuryEndDateWindow: "",
    // fine campi datazione

    // creationPeriod: "",
    hasFullDatetime: Boolean(false),
    hasPeriod: Boolean(false),
    hasYear: Boolean(false),
    series: "",
    seriesDetails: "",
    description: "",
    inventoryNumber: "",
    archiveNumber: "",
    catalogNumber: "",
    criticalCatalogue: Boolean(false),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nft: Boolean(false),
    nftNotes: "",
    netWidth: undefined,
    netHeight: undefined,
    netDepth: undefined,
    netDiameter: undefined,
    netLengthUnit: "",

    netWeightUnit: "",
    netWeight: undefined,
    netDimensionsAcquisition: "",

    grossWidth: undefined,
    grossHeight: undefined,
    grossDepth: undefined,
    grossDiameter: undefined,
    grossLengthUnit: "",

    grossWeightUnit: "",
    grossWeight: undefined,
    grossDimensionsAcquisition: "",

    dimensionsNotes: "",

    isNotifiedCulturalProperty: Boolean(false),
    notifiedPropertyNote: "",
    legalStatus: "",
    copyrightAndPropertyRestrictions: "",

    isArtistProof: Boolean(false),
    artistProofNotes: "",
    isArtMultiple: Boolean(false),
    multipleNumber: "",
    isSignedObject: Boolean(false),
    signedObjectNotes: "",
    isTitledObject: Boolean(false),
    titledObjectNotes: "",
    isDatedObject: Boolean(false),
    datedObjectNotes: "",
    hasLabelsStamps: Boolean(false),
    labelsStampsNotes: "",
    hasOtherAutographed: Boolean(false),
    otherAutographedNotes: "",
    relevantAwards: "",
    surfaceCharacteristics: "",
    hasBase: Boolean(false),
    baseDetails: "",
    hasWoodFrame: Boolean(false),
    woodFrameDetails: "",

    hasBarriers: Boolean(false),
    barriersDetails: "",
    handlingRequirements: "",
    injuryRisk: "",
    injuryRiskDescription: "",
    mouldingSupportBase: "",
    hardwareToolsInstallation: "",
    conservation: "",
    lenderRequests: "",
    settingNotes: "",

    optimalLightLevelIntensity: undefined,
    lightUnit: "",
    optimalTemperature: undefined,
    temperatureUnit: "",
    optimalHumidity: undefined,
    enviromentalConditionsNotes: "",
    infoNotes: "",

    originNotes: "",
    bibliographyNotes: "",

    carryingValueOrigin: "",
    carryingValueAcquisition: "",
    carryingValueNotes: "",
    carryingValueLastAcquisitionDate: null,
    carryingValueAmount: {
        amount: 0,
        currency: "EUR",
    },
    carryingValueFileTitle: "",
    carryingValueFile: "",


    validated: false,
}