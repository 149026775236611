import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Footer, LanguageSelector } from "components/ui";
import { useAuth } from "contexts/AuthProvider";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import { RiSettingsFill, RiUser5Fill } from "react-icons/ri";
import { ImBell } from "react-icons/im";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import arteGeneraliPNG from "assets/arte-generali-data.png";
import styles from "../scss/Layout.module.scss";
import {
  genericMenuEntries,
  mgmtMenuEntries,
  spinMenuEntries,
  topMenuEntries,
} from "../sidebarMenuEntries";

interface LayoutProps {
  onLocaleChange?: (locale: string) => void;
}

interface NavLinkActiveAwareProps {
  to: string;
  icon: React.ReactNode;
  name: React.ReactNode;
  onClick?: () => void;
  hidden?: boolean;
}

const NavLinkActiveAwareTop = ({ to, icon, name, onClick, hidden }: NavLinkActiveAwareProps) => (
  <Link
    as={NavLink}
    to={to}
    className={styles["with-sidebar__sidebar__navItem__top"]}
    _activeLink={{
      "& > button": {
        backgroundColor: "primaryFuchsia",
        color: "white",
      },
      "& > svg": {
        color: "primaryFuchsia",
      },
      "& > p": {
        color: "white",
      },
    }}
  >
    <Button size="sm" variant="ghost" hidden={hidden}>
      {name}
    </Button>
  </Link>
);
const NavLinkActiveAware = ({ to, icon, name, onClick, hidden }: NavLinkActiveAwareProps) => (
  <VStack alignItems="flex-start" onClick={onClick}>
    <Link
      as={NavLink}
      to={to}
      className={styles["with-sidebar__sidebar__navItem"]}
      _activeLink={{
        borderLeft: "2px solid var(--chakra-colors-primaryFuchsia)",
        "& > svg": {
          color: "primaryFuchsia",
        },
        "& > p": {
          fontWeight: "700",
          color: "primaryFuchsia",
        },
      }}
    >
      {icon}
      <Text fontSize="sm" fontWeight={400} hidden={hidden} pl="2">
        {name}
      </Text>
    </Link>
  </VStack>
);

const Layout = ({ onLocaleChange }: LayoutProps) => {
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const { t } = useTranslation();
  const { user, signout } = useAuth();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showSubMenu, setShowSubMenu] = useState<string | null>(null);
  const onLanguageSelect = (e: React.FormEvent<HTMLSelectElement>): void => {
    const {
      currentTarget: { value },
    } = e;
    if (!onLocaleChange) return;
    onLocaleChange(value);
  };

  return (
    <Flex direction="column" h="100%" w="100vw">
      <VStack position="sticky" top="0" w="full" spacing="0" zIndex="sticky">
        <Flex px={20} borderBottom="1px" borderColor="gray.200" bgColor="#24292f" w="full" h="4rem">
          <Flex>
            <Stack direction="row" alignItems="center">
              <Image src={arteGeneraliPNG} h="2.5rem" mr={4} />
              {/* <Avatar name={user?.name} src="https://bit.ly/broken-link" size="xs" />
            <Text fontSize="md">{user?.name}</Text> */}
            </Stack>
          </Flex>
          <Spacer />
          <Stack direction="row" alignItems="center">
            {/* <Button variant="outline" mx={4}>
            Make an action
          </Button> */}
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<BiChevronDown />}
                size="xs"
                mx={12}
                variant="outline"
              >
                {t("Add new")}
              </MenuButton>
              <MenuList>
                <MenuItem>{t("Add an asset")}</MenuItem>
                <MenuItem>{t("Create a policy")}</MenuItem>
                <MenuItem>{t("Generate certificates")}</MenuItem>
              </MenuList>
            </Menu>

            <LanguageSelector onLanguageSelect={onLanguageSelect} />

            <HStack px={8} spacing={-4}>
              <IconButton
                aria-label="Search database"
                icon={<ImBell size={18} />}
                variant="ghost"
                bgColor="transparent"
                colorScheme="whiteAlpha"
                size="lg"
                px={2}
              />{" "}
              <IconButton
                aria-label="Search database"
                icon={<RiSettingsFill size={20} />}
                variant="ghost"
                bgColor="transparent"
                size="lg"
                colorScheme="whiteAlpha"
                px={2}
              />{" "}
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Search database"
                  icon={<RiUser5Fill size={20} />}
                  variant="ghost"
                  bgColor="transparent"
                  colorScheme="whiteAlpha"
                  size="lg"
                  px={2}
                />
                <MenuList>
                  <MenuItem onClick={(e) => signout(() => navigate("/login"))}>
                    {t("Log out")}
                  </MenuItem>
                </MenuList>
              </Menu>
              <Stack direction="row" alignItems="center" pl="8">
                <Text fontSize="md" color="whiteAlpha.900">
                  {user?.name}
                </Text>
                <Avatar name={user?.name} src="https://bit.ly/broken-link" size="xs" />
              </Stack>
            </HStack>
            <Button leftIcon={<Icon />} variant="link" mx={8} bgColor="whiteAlpha">
              Help
            </Button>
          </Stack>
        </Flex>
        <HStack
          spacing="6px"
          px={16}
          py={2}
          borderBottom="1px"
          borderColor="gray.100"
          w="full"
          bgColor="white"
        >
          {topMenuEntries &&
            topMenuEntries.map(({ to, icon, name }) => (
              <NavLinkActiveAwareTop key={to} to={to} icon={icon} name={name} />
            ))}
        </HStack>
      </VStack>

      <div className={styles.wrapper}>
        <div className={`"bp4-dark" ${styles["with-sidebar"]}`}>
          {/* <!-- sidebar --> */}
          <Box
            pl="5rem"
            pr="1rem"
            mt={8}
            position="sticky"
            top="15vh"
            alignSelf="flex-start"
            style={{
              flexBasis: isSidebarHidden ? "100px" : "250px",
              paddingLeft: isSidebarHidden ? "1rem" : "5rem",
              paddingRight: isSidebarHidden ? "1rem" : "5rem",
            }}
            transition="all 1s ease"
          >
            {pathname.includes("spin") && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  Policies
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            {pathname.includes("mgmt") && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  Management
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            {pathname.includes("/o/") && (
              <HStack>
                <Text
                  fontSize="md"
                  fontWeight={700}
                  borderLeftColor="white"
                  hidden={isSidebarHidden}
                >
                  Others
                </Text>
                <Button
                  my="4"
                  variant="ghost"
                  onClick={() => setIsSidebarHidden((prevState) => !prevState)}
                  size="xs"
                >
                  ({isSidebarHidden ? <Trans>Show</Trans> : <Trans>Hide</Trans>})
                </Button>
              </HStack>
            )}
            <Stack direction="column" spacing="4" mt={3} borderLeft="1px" borderColor="gray.100">
              {mgmtMenuEntries &&
                pathname.includes("mgmt") &&
                mgmtMenuEntries.map(({ to, icon, name, children }) => (
                  <>
                    <NavLinkActiveAware
                      key={to}
                      to={to}
                      icon={icon}
                      name={name}
                      onClick={() => setShowSubMenu(to)}
                      hidden={isSidebarHidden}
                    />
                    {children?.map(
                      ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                        showSubMenu &&
                        childrenTo.includes(showSubMenu) && (
                          <Link
                            key={childrenTo}
                            as={NavLink}
                            pl="6"
                            to={childrenTo}
                            className="child-link"
                            hidden={isSidebarHidden}
                          >
                            <Text fontSize="xs">{childrenName}</Text>
                          </Link>
                        )
                    )}
                  </>
                ))}
              {spinMenuEntries && pathname.includes("spin") && (
                <>
                  {spinMenuEntries.map(({ to, icon, name, children }) => (
                    <>
                      <NavLinkActiveAware
                        to={to}
                        key={to}
                        icon={icon}
                        name={name}
                        onClick={() => setShowSubMenu(to)}
                        hidden={isSidebarHidden}
                      />
                      {children?.map(
                        ({ name: childrenName, to: childrenTo, icon: childrenIcon }) =>
                          showSubMenu &&
                          (childrenTo.includes(showSubMenu) || childrenTo === showSubMenu) && (
                            <Link
                              key={childrenTo}
                              as={NavLink}
                              pl="6"
                              to={childrenTo}
                              className="child-link"
                              hidden={isSidebarHidden}
                            >
                              <Text fontSize="xs">{childrenName}</Text>
                            </Link>
                          )
                      )}
                    </>
                  ))}
                </>
              )}
              {genericMenuEntries &&
                pathname.includes("/o/") &&
                genericMenuEntries.map(({ to, icon, name }) => (
                  <NavLinkActiveAware
                    to={to}
                    icon={icon}
                    key={to}
                    name={name}
                    hidden={isSidebarHidden}
                  />
                ))}
            </Stack>
          </Box>

          <div>
            {/* <!-- non-sidebar --> */}
            <Box pr={24} py={6}>
              {/* <HStack spacing="24px">
                <BCrumb path={pathname} title="test" />
              </HStack> */}
              <Outlet />
            </Box>
          </div>
        </div>
      </div>
      <Footer />
    </Flex>
  );
};

export default Layout;
