import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Heading,
  HStack,
  VStack,
  Text,
  Badge,
} from "@chakra-ui/react";
import { Element } from "react-scroll";
import { Trans } from "react-i18next";
import { PolicyAssetSearchBox } from "components/ui";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import { PolicyAssetInputExtended } from "pages/spin/types";
interface NewAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  addPolicyAsset: ({
    id,
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => void;
  policyAssets: PolicyAssetInputExtended[] | [];
}

const SearchAssetsDrawer = ({
  show,
  onClose,
  addPolicyAsset,
  policyAssets,
}: NewAssetDrawerProps) => (
  <Drawer
    isOpen={show}
    placement="right"
    onClose={onClose}
    size="xl"
    // finalFocusRef={btnRef}
  >
    <DrawerOverlay maxW="80vw" w="80vw" />
    <DrawerContent maxW="80vw" w="80vw">
      <DrawerCloseButton />

      <DrawerBody>
        <HStack justifyContent="space-between" align="flex-start" py={8}>
          <VStack align="flex-start" pr={16} id="test">
            <Element name="userWantsToAddAssetsRefToScrollTo">
              <Badge mr="2" mb="1">
                Step 1
              </Badge>
            </Element>
            <Heading size="lg">Search and select assets</Heading>{" "}
            <Text mt={0}>
              <Trans>
                Puoi prelevarlo dai tuoi assets digitando qui sotto, oppure creane uno nuovo
                cliccando su &quot;Crea nuovo asset&quot; o importa una lista di assets da un .csv
              </Trans>
            </Text>
          </VStack>
        </HStack>

        <VStack align="flex-start">
          <PolicyAssetSearchBox
            index={`${process.env.REACT_APP_TENANT}_asset`}
            addPolicyAsset={addPolicyAsset}
            policyAssets={policyAssets}
          />
        </VStack>
      </DrawerBody>

      {/* <DrawerFooter>
       
        <Button colorScheme="blue">Add asset to </Button>
      </DrawerFooter> */}
    </DrawerContent>
  </Drawer>
);

export default SearchAssetsDrawer;
