import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: string;
  Decimal: number;
  GenericScalar: any;
  StringMoney: string;
  UUID: string;
  Upload: unknown;
};

export type AddAssetMediaInput = {
  assetId: Scalars["ID"];
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
};

export type AddAssetMediaPayload = {
  __typename?: "AddAssetMediaPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  media?: Maybe<MediaNode>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type AddUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissionNames?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type AddUserPermissionPayload = {
  __typename?: "AddUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type AddUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

export type AddUserSignaturePayload = {
  __typename?: "AddUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  userSignature?: Maybe<UserSignatureType>;
};

export type Address = {
  __typename?: "Address";
  formatted?: Maybe<Scalars["String"]>;
  latitude?: Maybe<Scalars["Float"]>;
  longitude?: Maybe<Scalars["Float"]>;
  raw?: Maybe<Scalars["String"]>;
  route?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  formatted?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  raw?: InputMaybe<Scalars["String"]>;
  route?: InputMaybe<Scalars["String"]>;
  streetNumber?: InputMaybe<Scalars["String"]>;
};

export type AssetElementInput = {
  assetId?: InputMaybe<Scalars["ID"]>;
  description?: InputMaybe<Scalars["String"]>;
  materials?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetElementNode = Node & {
  __typename?: "AssetElementNode";
  asset: AssetNode;
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  materials?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
};

export type AssetElementNodeConnection = {
  __typename?: "AssetElementNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetElementNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetElementNode` and its cursor. */
export type AssetElementNodeEdge = {
  __typename?: "AssetElementNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetElementNode>;
};

export type AssetInput = {
  archiveNumber?: InputMaybe<Scalars["String"]>;
  artistProofNotes?: InputMaybe<Scalars["String"]>;
  assetPresentValues?: InputMaybe<Array<InputMaybe<AssetPresentValueInput>>>;
  author: Scalars["String"];
  authorEntityId: Scalars["ID"];
  barriersDetails?: InputMaybe<Scalars["String"]>;
  baseDetails?: InputMaybe<Scalars["String"]>;
  bibliographyNotes?: InputMaybe<Scalars["String"]>;
  carryingValueAcquisition?: InputMaybe<Scalars["String"]>;
  carryingValueAmount?: InputMaybe<MoneyInput>;
  carryingValueFile?: InputMaybe<Scalars["Upload"]>;
  carryingValueFileTitle?: InputMaybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: InputMaybe<Scalars["Date"]>;
  carryingValueNotes?: InputMaybe<Scalars["String"]>;
  carryingValueOrigin?: InputMaybe<Scalars["String"]>;
  catalogNumber?: InputMaybe<Scalars["String"]>;
  category: Scalars["String"];
  centuryEndDate?: InputMaybe<Scalars["Int"]>;
  centuryEndDateWindow?: InputMaybe<Scalars["String"]>;
  centuryStartDate?: InputMaybe<Scalars["Int"]>;
  centuryStartDateWindow?: InputMaybe<Scalars["String"]>;
  conservation?: InputMaybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  creationPeriod?: InputMaybe<Scalars["String"]>;
  criticalCatalogue?: InputMaybe<Scalars["Boolean"]>;
  dateEndDate?: InputMaybe<Scalars["Date"]>;
  dateStartDate?: InputMaybe<Scalars["Date"]>;
  datedObjectNotes?: InputMaybe<Scalars["String"]>;
  datingType?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dimensionsNotes?: InputMaybe<Scalars["String"]>;
  enviromentalConditionsNotes?: InputMaybe<Scalars["String"]>;
  grossDepth?: InputMaybe<Scalars["Int"]>;
  grossDiameter?: InputMaybe<Scalars["Int"]>;
  grossDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  grossHeight?: InputMaybe<Scalars["Int"]>;
  grossLengthUnit?: InputMaybe<Scalars["String"]>;
  grossWeight?: InputMaybe<Scalars["Int"]>;
  grossWeightUnit?: InputMaybe<Scalars["String"]>;
  grossWidth?: InputMaybe<Scalars["Int"]>;
  handlingRequirements?: InputMaybe<Scalars["String"]>;
  hardwareToolsInstallation?: InputMaybe<Scalars["String"]>;
  hasBarriers?: InputMaybe<Scalars["Boolean"]>;
  hasBase?: InputMaybe<Scalars["Boolean"]>;
  hasFullDatetime?: InputMaybe<Scalars["Boolean"]>;
  hasLabelsStamps?: InputMaybe<Scalars["Boolean"]>;
  hasOtherAutographed?: InputMaybe<Scalars["Boolean"]>;
  hasPeriod?: InputMaybe<Scalars["Boolean"]>;
  hasWoodFrame?: InputMaybe<Scalars["Boolean"]>;
  hasYear?: InputMaybe<Scalars["Boolean"]>;
  infoNotes?: InputMaybe<Scalars["String"]>;
  injuryRisk?: InputMaybe<Scalars["String"]>;
  injuryRiskDescription?: InputMaybe<Scalars["String"]>;
  inventoryNumber?: InputMaybe<Scalars["String"]>;
  isArtMultiple?: InputMaybe<Scalars["Boolean"]>;
  isArtistProof?: InputMaybe<Scalars["Boolean"]>;
  isCenturyApprox?: InputMaybe<Scalars["Boolean"]>;
  isCenturyEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isCenturyInterval?: InputMaybe<Scalars["Boolean"]>;
  isCenturyStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isComposed?: InputMaybe<Scalars["Boolean"]>;
  isDateApprox?: InputMaybe<Scalars["Boolean"]>;
  isDateEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDateInterval?: InputMaybe<Scalars["Boolean"]>;
  isDateStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  isDatedObject?: InputMaybe<Scalars["Boolean"]>;
  isNotifiedCulturalProperty?: InputMaybe<Scalars["Boolean"]>;
  isSignedObject?: InputMaybe<Scalars["Boolean"]>;
  isTitledObject?: InputMaybe<Scalars["Boolean"]>;
  isYearApprox?: InputMaybe<Scalars["Boolean"]>;
  isYearEndDateBc?: InputMaybe<Scalars["Boolean"]>;
  isYearInterval?: InputMaybe<Scalars["Boolean"]>;
  isYearStartDateBc?: InputMaybe<Scalars["Boolean"]>;
  labelsStampsNotes?: InputMaybe<Scalars["String"]>;
  legalStatus?: InputMaybe<Scalars["String"]>;
  lenderRequests?: InputMaybe<Scalars["String"]>;
  lightUnit?: InputMaybe<Scalars["String"]>;
  mouldingSupportBase?: InputMaybe<Scalars["String"]>;
  multipleNumber?: InputMaybe<Scalars["String"]>;
  netDepth?: InputMaybe<Scalars["Int"]>;
  netDiameter?: InputMaybe<Scalars["Int"]>;
  netDimensionsAcquisition?: InputMaybe<Scalars["String"]>;
  netHeight?: InputMaybe<Scalars["Int"]>;
  netLengthUnit?: InputMaybe<Scalars["String"]>;
  netWeight?: InputMaybe<Scalars["Int"]>;
  netWeightUnit?: InputMaybe<Scalars["String"]>;
  netWidth?: InputMaybe<Scalars["Int"]>;
  nft?: InputMaybe<Scalars["Boolean"]>;
  nftNotes?: InputMaybe<Scalars["String"]>;
  notifiedPropertyNote?: InputMaybe<Scalars["String"]>;
  numberOfElements?: InputMaybe<Scalars["Int"]>;
  optimalHumidity?: InputMaybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: InputMaybe<Scalars["Int"]>;
  optimalTemperature?: InputMaybe<Scalars["Int"]>;
  originNotes?: InputMaybe<Scalars["String"]>;
  otherAutographedNotes?: InputMaybe<Scalars["String"]>;
  physicalFeaturesElements?: InputMaybe<Array<InputMaybe<AssetElementInput>>>;
  relevantAwards?: InputMaybe<Scalars["String"]>;
  series?: InputMaybe<Scalars["String"]>;
  seriesDetails?: InputMaybe<Scalars["String"]>;
  settingNotes?: InputMaybe<Scalars["String"]>;
  signedObjectNotes?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  subcategory: Scalars["String"];
  surfaceCharacteristics?: InputMaybe<Scalars["String"]>;
  temperatureUnit?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
  titledObjectNotes?: InputMaybe<Scalars["String"]>;
  validated: Scalars["Boolean"];
  woodFrameDetails?: InputMaybe<Scalars["String"]>;
  yearEndDate?: InputMaybe<Scalars["Int"]>;
  yearStartDate?: InputMaybe<Scalars["Int"]>;
};

export type AssetNode = Node & {
  __typename?: "AssetNode";
  archiveNumber?: Maybe<Scalars["String"]>;
  artistProofNotes?: Maybe<Scalars["String"]>;
  assetCurrentValue?: Maybe<Scalars["String"]>;
  audioVideoLength?: Maybe<Scalars["Int"]>;
  audioVideoLengthUnit?: Maybe<ManagementAssetAudioVideoLengthUnitChoices>;
  author: Scalars["String"];
  authorEntity?: Maybe<RegistryNode>;
  barriersDetails?: Maybe<Scalars["String"]>;
  baseDetails?: Maybe<Scalars["String"]>;
  bibliographyNotes?: Maybe<Scalars["String"]>;
  bookContainer?: Maybe<Scalars["String"]>;
  bookbinding?: Maybe<Scalars["String"]>;
  carat?: Maybe<Scalars["String"]>;
  carryingValueAcquisition?: Maybe<ManagementAssetCarryingValueAcquisitionChoices>;
  carryingValueAmount?: Maybe<Money>;
  carryingValueAmountCurrency: ManagementAssetCarryingValueAmountCurrencyChoices;
  carryingValueFile?: Maybe<Scalars["String"]>;
  carryingValueFileTitle?: Maybe<Scalars["String"]>;
  carryingValueLastAcquisitionDate?: Maybe<Scalars["Date"]>;
  carryingValueNotes?: Maybe<Scalars["String"]>;
  carryingValueOrigin?: Maybe<Scalars["String"]>;
  catalogNumber?: Maybe<Scalars["String"]>;
  catalogingAuthor?: Maybe<Scalars["String"]>;
  category: ManagementAssetCategoryChoices;
  centuryEndDate?: Maybe<Scalars["Int"]>;
  centuryEndDateWindow?: Maybe<ManagementAssetCenturyEndDateWindowChoices>;
  centuryStartDate?: Maybe<Scalars["Int"]>;
  centuryStartDateWindow?: Maybe<ManagementAssetCenturyStartDateWindowChoices>;
  circulation?: Maybe<Scalars["String"]>;
  clips?: Maybe<Scalars["String"]>;
  clothCovering?: Maybe<Scalars["String"]>;
  collection: CollectionNodeConnection;
  color?: Maybe<Scalars["String"]>;
  coloring?: Maybe<Scalars["String"]>;
  compositionDescription?: Maybe<Scalars["String"]>;
  conservation?: Maybe<Scalars["String"]>;
  conservationMethod?: Maybe<Scalars["String"]>;
  containerDetails?: Maybe<Scalars["String"]>;
  copyrightAndPropertyRestrictions?: Maybe<Scalars["String"]>;
  corner?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  creationDate?: Maybe<Scalars["DateTime"]>;
  creationPeriod?: Maybe<Scalars["String"]>;
  criticalCatalogue: Scalars["Boolean"];
  currency?: Maybe<Scalars["String"]>;
  currentValue?: Maybe<Scalars["Decimal"]>;
  dateEndDate?: Maybe<Scalars["Date"]>;
  dateStartDate?: Maybe<Scalars["Date"]>;
  datedObjectNotes?: Maybe<Scalars["String"]>;
  datingType?: Maybe<ManagementAssetDatingTypeChoices>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  dimensionsNotes?: Maybe<Scalars["String"]>;
  discoveryAuthor?: Maybe<Scalars["String"]>;
  discoveryDate?: Maybe<Scalars["Date"]>;
  discoveryNotes?: Maybe<Scalars["String"]>;
  discoveryPlace?: Maybe<Scalars["String"]>;
  discoverySite?: Maybe<Scalars["String"]>;
  distribution?: Maybe<Scalars["String"]>;
  dustJacket?: Maybe<Scalars["String"]>;
  edge?: Maybe<Scalars["String"]>;
  editionNotes?: Maybe<Scalars["String"]>;
  enviromentalConditionsNotes?: Maybe<Scalars["String"]>;
  exhibition: ExhibitionNodeConnection;
  externalDecorations?: Maybe<Scalars["String"]>;
  fileSize?: Maybe<Scalars["Int"]>;
  fileSizeUnit?: Maybe<ManagementAssetFileSizeUnitChoices>;
  fineness?: Maybe<Scalars["String"]>;
  finishing?: Maybe<Scalars["String"]>;
  firstDraftNotes?: Maybe<Scalars["String"]>;
  fiscalValue?: Maybe<Scalars["Decimal"]>;
  flyleaf?: Maybe<Scalars["String"]>;
  footEdge?: Maybe<Scalars["String"]>;
  foreEdge?: Maybe<Scalars["String"]>;
  frameDescription?: Maybe<Scalars["String"]>;
  frameManufacturer?: Maybe<Scalars["String"]>;
  frameMaterial: ManagementAssetFrameMaterialChoices;
  frontCover?: Maybe<Scalars["String"]>;
  genericDimensions?: Maybe<Scalars["String"]>;
  glueing?: Maybe<Scalars["String"]>;
  grossDepth?: Maybe<Scalars["Int"]>;
  grossDiameter?: Maybe<Scalars["Int"]>;
  grossDimensionsAcquisition?: Maybe<ManagementAssetGrossDimensionsAcquisitionChoices>;
  grossHeight?: Maybe<Scalars["Int"]>;
  grossLengthUnit?: Maybe<ManagementAssetGrossLengthUnitChoices>;
  grossWeight?: Maybe<Scalars["Int"]>;
  grossWeightUnit?: Maybe<ManagementAssetGrossWeightUnitChoices>;
  grossWidth?: Maybe<Scalars["Int"]>;
  handlingRequirements?: Maybe<Scalars["String"]>;
  hardwareToolsInstallation?: Maybe<Scalars["String"]>;
  hasBarriers?: Maybe<Scalars["Boolean"]>;
  hasBase?: Maybe<Scalars["Boolean"]>;
  hasContainer?: Maybe<Scalars["Boolean"]>;
  hasFrame?: Maybe<Scalars["Boolean"]>;
  hasFullDatetime: Scalars["Boolean"];
  hasHumanAnimalPresence?: Maybe<Scalars["Boolean"]>;
  hasLabelsStamps?: Maybe<Scalars["Boolean"]>;
  hasLoop: Scalars["Boolean"];
  hasOriginalFrame?: Maybe<Scalars["Boolean"]>;
  hasOtherAutographed?: Maybe<Scalars["Boolean"]>;
  hasPeriod: Scalars["Boolean"];
  hasWoodFrame?: Maybe<Scalars["Boolean"]>;
  hasYear: Scalars["Boolean"];
  headband?: Maybe<Scalars["String"]>;
  headcap?: Maybe<Scalars["String"]>;
  holograficStripe?: Maybe<Scalars["String"]>;
  humanAnimalPresenceNotes?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  infoNotes?: Maybe<Scalars["String"]>;
  injuryRisk?: Maybe<ManagementAssetInjuryRiskChoices>;
  injuryRiskDescription?: Maybe<Scalars["String"]>;
  interiorDecorations?: Maybe<Scalars["String"]>;
  inventoryNumber?: Maybe<Scalars["String"]>;
  isArtMultiple?: Maybe<Scalars["Boolean"]>;
  isArtistProof?: Maybe<Scalars["Boolean"]>;
  isAudio?: Maybe<Scalars["Boolean"]>;
  isCenturyApprox: Scalars["Boolean"];
  isCenturyEndDateBc: Scalars["Boolean"];
  isCenturyInterval: Scalars["Boolean"];
  isCenturyStartDateBc: Scalars["Boolean"];
  isComposed?: Maybe<Scalars["Boolean"]>;
  isDateApprox: Scalars["Boolean"];
  isDateEndDateBc: Scalars["Boolean"];
  isDateInterval: Scalars["Boolean"];
  isDateStartDateBc: Scalars["Boolean"];
  isDatedObject?: Maybe<Scalars["Boolean"]>;
  isDynamic?: Maybe<Scalars["Boolean"]>;
  isEdition?: Maybe<Scalars["Boolean"]>;
  isFirstDraft?: Maybe<Scalars["Boolean"]>;
  isMaster: Scalars["Boolean"];
  isNotifiedCulturalProperty?: Maybe<Scalars["Boolean"]>;
  isSignedObject?: Maybe<Scalars["Boolean"]>;
  isTitledObject?: Maybe<Scalars["Boolean"]>;
  isValuableFrame?: Maybe<Scalars["Boolean"]>;
  isVideo?: Maybe<Scalars["Boolean"]>;
  isYearApprox: Scalars["Boolean"];
  isYearEndDateBc: Scalars["Boolean"];
  isYearInterval: Scalars["Boolean"];
  isYearStartDateBc: Scalars["Boolean"];
  label?: Maybe<Scalars["String"]>;
  labelsStampsNotes?: Maybe<Scalars["String"]>;
  legalStatus?: Maybe<Scalars["String"]>;
  legend?: Maybe<Scalars["String"]>;
  lenderRequests?: Maybe<Scalars["String"]>;
  lightUnit?: Maybe<ManagementAssetLightUnitChoices>;
  manufactoringTechniques?: Maybe<Scalars["String"]>;
  masterLocation?: Maybe<Scalars["String"]>;
  mechanism?: Maybe<Scalars["String"]>;
  media: MediaNodeConnection;
  methodOfUse?: Maybe<Scalars["String"]>;
  mintMadeErrors?: Maybe<Scalars["String"]>;
  mintingLastDate?: Maybe<Scalars["Date"]>;
  mintingLastDateNotes?: Maybe<Scalars["String"]>;
  mouldingSupportBase?: Maybe<Scalars["String"]>;
  multipleNumber?: Maybe<Scalars["String"]>;
  netDepth?: Maybe<Scalars["Int"]>;
  netDiameter?: Maybe<Scalars["Int"]>;
  netDimensionsAcquisition?: Maybe<ManagementAssetNetDimensionsAcquisitionChoices>;
  netHeight?: Maybe<Scalars["Int"]>;
  netLengthUnit?: Maybe<ManagementAssetNetLengthUnitChoices>;
  netWeight?: Maybe<Scalars["Int"]>;
  netWeightUnit?: Maybe<ManagementAssetNetWeightUnitChoices>;
  netWidth?: Maybe<Scalars["Int"]>;
  nft?: Maybe<Scalars["Boolean"]>;
  nftNotes?: Maybe<Scalars["String"]>;
  nominalValue?: Maybe<Scalars["Decimal"]>;
  notifiedPropertyNote?: Maybe<Scalars["String"]>;
  numberOfElements?: Maybe<Scalars["Int"]>;
  objectSetupAuthor?: Maybe<Scalars["String"]>;
  optimalHumidity?: Maybe<Scalars["Int"]>;
  optimalLightLevelIntensity?: Maybe<Scalars["Int"]>;
  optimalTemperature?: Maybe<Scalars["Int"]>;
  orientation?: Maybe<Scalars["String"]>;
  originNotes?: Maybe<Scalars["String"]>;
  otherAutographedNotes?: Maybe<Scalars["String"]>;
  physicalFeaturesElements: AssetElementNodeConnection;
  policyAsset: PolicyAssetNodeConnection;
  postageMark?: Maybe<Scalars["String"]>;
  presentValues: AssetPresentValueNodeConnection;
  printingErrors?: Maybe<Scalars["String"]>;
  raisedBands?: Maybe<Scalars["String"]>;
  rearCover?: Maybe<Scalars["String"]>;
  relatedPhysicalWork?: Maybe<Scalars["String"]>;
  relevantAwards?: Maybe<Scalars["String"]>;
  relief?: Maybe<Scalars["String"]>;
  reminting?: Maybe<Scalars["String"]>;
  replacedParts?: Maybe<Scalars["String"]>;
  resolution?: Maybe<Scalars["Int"]>;
  resolutionUnit?: Maybe<ManagementAssetResolutionUnitChoices>;
  rim?: Maybe<Scalars["String"]>;
  rotationAxes?: Maybe<Scalars["String"]>;
  rubber?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["String"]>;
  securityThread?: Maybe<Scalars["String"]>;
  series?: Maybe<Scalars["String"]>;
  seriesDetails?: Maybe<Scalars["String"]>;
  settingNotes?: Maybe<Scalars["String"]>;
  shape?: Maybe<Scalars["String"]>;
  signedObjectNotes?: Maybe<Scalars["String"]>;
  signings?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  slots?: Maybe<Scalars["String"]>;
  spine?: Maybe<Scalars["String"]>;
  square?: Maybe<Scalars["String"]>;
  stampCenteringAndMargins: ManagementAssetStampCenteringAndMarginsChoices;
  stampFormat: ManagementAssetStampFormatChoices;
  stampIndentationType: ManagementAssetStampIndentationTypeChoices;
  stampPrintingTechnique: ManagementAssetStampPrintingTechniqueChoices;
  stampRealization: ManagementAssetStampRealizationChoices;
  subcategory: ManagementAssetSubcategoryChoices;
  surfaceCharacteristics?: Maybe<Scalars["String"]>;
  technique?: Maybe<Scalars["String"]>;
  temperatureUnit?: Maybe<ManagementAssetTemperatureUnitChoices>;
  title: Scalars["String"];
  titledObjectNotes?: Maybe<Scalars["String"]>;
  topEdge?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  usage?: Maybe<Scalars["String"]>;
  uuid: Scalars["UUID"];
  validated: Scalars["Boolean"];
  variants?: Maybe<Scalars["String"]>;
  watermark?: Maybe<Scalars["String"]>;
  woodFrameDetails?: Maybe<Scalars["String"]>;
  yearEndDate?: Maybe<Scalars["Int"]>;
  yearStartDate?: Maybe<Scalars["Int"]>;
};

export type AssetNodeCollectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeExhibitionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeMediaArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePhysicalFeaturesElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodePresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type AssetNodeConnection = {
  __typename?: "AssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetNode` and its cursor. */
export type AssetNodeEdge = {
  __typename?: "AssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetNode>;
};

export type AssetPresentValueInput = {
  amount?: InputMaybe<MoneyInput>;
  assetId?: InputMaybe<Scalars["ID"]>;
  authorId?: InputMaybe<Scalars["ID"]>;
  estimateDate?: InputMaybe<Scalars["Date"]>;
  estimateReason?: InputMaybe<Scalars["String"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  fileTitle?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type AssetPresentValueNode = Node & {
  __typename?: "AssetPresentValueNode";
  amount: Money;
  amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
  asset: AssetNode;
  author?: Maybe<RegistryNode>;
  estimateDate?: Maybe<Scalars["Date"]>;
  estimateReason: ManagementAssetPresentValueEstimateReasonChoices;
  file?: Maybe<Scalars["String"]>;
  fileTitle?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
};

export type AssetPresentValueNodeConnection = {
  __typename?: "AssetPresentValueNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssetPresentValueNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `AssetPresentValueNode` and its cursor. */
export type AssetPresentValueNodeEdge = {
  __typename?: "AssetPresentValueNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<AssetPresentValueNode>;
};

export type CertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  language?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  printTemplate?: InputMaybe<Scalars["String"]>;
};

export type CertificateNode = Node & {
  __typename?: "CertificateNode";
  cancelReplace: Scalars["Boolean"];
  cancelReplaceNotes?: Maybe<Scalars["String"]>;
  certificateType: SpinCertificateCertificateTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  creationDate: Scalars["DateTime"];
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isValid: Scalars["Boolean"];
  language: Scalars["String"];
  lender?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  policyAsset: PolicyAssetNodeConnection;
  printTemplate: CertificateTemplateNode;
  progressiveNumber?: Maybe<Scalars["Int"]>;
  shipper?: Maybe<Scalars["String"]>;
  signed: Scalars["Boolean"];
  signedAt: Scalars["DateTime"];
  signedBy?: Maybe<UserNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CertificateNodePolicyAssetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateNodeConnection = {
  __typename?: "CertificateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CertificateNode` and its cursor. */
export type CertificateNodeEdge = {
  __typename?: "CertificateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateNode>;
};

export type CertificateTemplateNode = Node & {
  __typename?: "CertificateTemplateNode";
  certificateSet: CertificateNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CertificateTemplateNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CertificateTemplateNodeConnection = {
  __typename?: "CertificateTemplateNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateTemplateNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CertificateTemplateNode` and its cursor. */
export type CertificateTemplateNodeEdge = {
  __typename?: "CertificateTemplateNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CertificateTemplateNode>;
};

export type ChangePasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword: Scalars["String"];
  email: Scalars["String"];
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type ChangePasswordPayload = {
  __typename?: "ChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ClientNode = Node & {
  __typename?: "ClientNode";
  createdOn: Scalars["Date"];
  customerType: RbacClientCustomerTypeChoices;
  dashboardBaseUrl: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  location: Scalars["String"];
  logo?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  onTrial: Scalars["Boolean"];
  paidUntil: Scalars["Date"];
  schemaName: Scalars["String"];
  storageName: Scalars["String"];
  tier: RbacClientTierChoices;
};

export type ClientNodeConnection = {
  __typename?: "ClientNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ClientNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ClientNode` and its cursor. */
export type ClientNodeEdge = {
  __typename?: "ClientNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ClientNode>;
};

export type CollectionNode = Node & {
  __typename?: "CollectionNode";
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type CollectionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CollectionNodeConnection = {
  __typename?: "CollectionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CollectionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CollectionNode` and its cursor. */
export type CollectionNodeEdge = {
  __typename?: "CollectionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<CollectionNode>;
};

export type ContentTypeNode = Node & {
  __typename?: "ContentTypeNode";
  appLabel: Scalars["String"];
  /** The ID of the object */
  id: Scalars["ID"];
  model: Scalars["String"];
  permissionSet: PermissionNodeConnection;
};

export type ContentTypeNodePermissionSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ContentTypeNodeConnection = {
  __typename?: "ContentTypeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ContentTypeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ContentTypeNode` and its cursor. */
export type ContentTypeNodeEdge = {
  __typename?: "ContentTypeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ContentTypeNode>;
};

export type Country = {
  __typename?: "Country";
  /** ISO 3166-1 three character country code */
  alpha3?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 two character country code */
  code?: Maybe<Scalars["String"]>;
  /** International Olympic Committee country code */
  iocCode?: Maybe<Scalars["String"]>;
  /** Country name */
  name?: Maybe<Scalars["String"]>;
  /** ISO 3166-1 numeric country code */
  numeric?: Maybe<Scalars["Int"]>;
};

export type CreateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetElementPayload = {
  __typename?: "CreateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPayload = {
  __typename?: "CreateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateAssetPresentValuePayload = {
  __typename?: "CreateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type CreateCertificatePayload = {
  __typename?: "CreateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type CreateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type CreateInsurancePayload = {
  __typename?: "CreateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type CreateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  officeData?: InputMaybe<OfficeInput>;
};

export type CreateOfficePayload = {
  __typename?: "CreateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type CreatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type CreatePolicyAssetPayload = {
  __typename?: "CreatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type CreatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
};

export type CreatePolicyDocumentPayload = {
  __typename?: "CreatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type CreatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyData?: InputMaybe<PolicyInput>;
};

export type CreatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type CreatePolicyNotePayload = {
  __typename?: "CreatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type CreatePolicyPayload = {
  __typename?: "CreatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type CreateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  registryData?: InputMaybe<RegistryInput>;
};

export type CreateRegistryPayload = {
  __typename?: "CreateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type CreateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  userData?: InputMaybe<UserInput>;
};

export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type Currency = {
  __typename?: "Currency";
  /** A ISO-421 3-letter currency. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  code: Scalars["String"];
  /** A human readable name, e.g. US Dollar */
  name: Scalars["String"];
  /** A ISO-421 numeric code. See https://en.wikipedia.org/wiki/ISO_4217#Active_codes */
  numeric: Scalars["String"];
  /** The currency's prefix, e.g. $ for USD */
  prefix: Scalars["String"];
  /** The currency's symbol, e.g. € for EUR */
  suffix: Scalars["String"];
  /** The currency's symbol, e.g. $ for USD */
  symbol: Scalars["String"];
};

export type DeleteAssetElementInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetElementPayload = {
  __typename?: "DeleteAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteAssetPayload = {
  __typename?: "DeleteAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteAssetPresentValueInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteAssetPresentValuePayload = {
  __typename?: "DeleteAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeleteCertificatePayload = {
  __typename?: "DeleteCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type DeleteInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteInsurancePayload = {
  __typename?: "DeleteInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type DeleteOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteOfficePayload = {
  __typename?: "DeleteOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeNode>;
};

export type DeletePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyAssetPayload = {
  __typename?: "DeletePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type DeletePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type DeletePolicyDocumentPayload = {
  __typename?: "DeletePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type DeletePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeletePolicyNotePayload = {
  __typename?: "DeletePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type DeletePolicyPayload = {
  __typename?: "DeletePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type DeleteRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteRegistryPayload = {
  __typename?: "DeleteRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type DeleteUserSignatureInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type DeleteUserSignaturePayload = {
  __typename?: "DeleteUserSignaturePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  userSignature?: Maybe<UserSignatureNode>;
};

export type ExhibitionNode = Node & {
  __typename?: "ExhibitionNode";
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocation?: Maybe<Address>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  image?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type ExhibitionNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type ExhibitionNodeConnection = {
  __typename?: "ExhibitionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExhibitionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ExhibitionNode` and its cursor. */
export type ExhibitionNodeEdge = {
  __typename?: "ExhibitionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<ExhibitionNode>;
};

export type FilteredInsuranceNode = Node & {
  __typename?: "FilteredInsuranceNode";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type FilteredInsuranceNodeConnection = {
  __typename?: "FilteredInsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FilteredInsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `FilteredInsuranceNode` and its cursor. */
export type FilteredInsuranceNodeEdge = {
  __typename?: "FilteredInsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<FilteredInsuranceNode>;
};

export type GenerateCertificateInput = {
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateTemplateId?: InputMaybe<Scalars["ID"]>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  hasPdf?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  language?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type GenerateCertificatePayload = {
  __typename?: "GenerateCertificatePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  filesUrl?: Maybe<Array<Maybe<Scalars["String"]>>>;
  policy?: Maybe<PolicyNode>;
  s3Load?: Maybe<Scalars["Boolean"]>;
};

export type InsuranceInput = {
  catNat: Scalars["Boolean"];
  contractType: Scalars["String"];
  coverageType: Scalars["String"];
  currencyInsurancePremium: Scalars["String"];
  currencyInsuredValue: Scalars["String"];
  evaluationType: Scalars["String"];
  insurancePremium: Scalars["String"];
  insuranceReason: Scalars["String"];
  insuredValue: Scalars["String"];
  note: Scalars["String"];
  policyNumber: Scalars["String"];
  riskCoveredType: Scalars["String"];
  terrorism: Scalars["Boolean"];
};

export type InsuranceNode = Node & {
  __typename?: "InsuranceNode";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  /** The ID of the object */
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type InsuranceNodeConnection = {
  __typename?: "InsuranceNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InsuranceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InsuranceNode` and its cursor. */
export type InsuranceNodeEdge = {
  __typename?: "InsuranceNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<InsuranceNode>;
};

export type InsuranceType = {
  __typename?: "InsuranceType";
  catNat: Scalars["Boolean"];
  contractType: ManagementInsuranceContractTypeChoices;
  coverageType: ManagementInsuranceCoverageTypeChoices;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  currencyInsurancePremium?: Maybe<Scalars["String"]>;
  currencyInsuredValue?: Maybe<Scalars["String"]>;
  evaluationType: ManagementInsuranceEvaluationTypeChoices;
  id: Scalars["ID"];
  insurancePremium?: Maybe<Scalars["String"]>;
  insuranceReason: ManagementInsuranceInsuranceReasonChoices;
  insuredValue?: Maybe<Scalars["String"]>;
  note: Scalars["String"];
  policyNumber?: Maybe<Scalars["String"]>;
  riskCoveredType: ManagementInsuranceRiskCoveredTypeChoices;
  terrorism: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type InviteUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type InviteUserPayload = {
  __typename?: "InviteUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

/** An enumeration. */
export enum ManagementAssetAudioVideoLengthUnitChoices {
  /** Other */
  A = "A_",
  /** h */
  H = "H",
  /** m */
  M = "M",
  /** s */
  S = "S",
}

/** An enumeration. */
export enum ManagementAssetCarryingValueAcquisitionChoices {
  /** None */
  A = "A_",
  /** received as a gift */
  Gift = "GIFT",
  /** inherited */
  Inherited = "INHERITED",
  /** other */
  Other = "OTHER",
  /** purchased */
  Purchased = "PURCHASED",
}

/** An enumeration. */
export enum ManagementAssetCarryingValueAmountCurrencyChoices {
  /** Euro */
  Eur = "EUR",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementAssetCategoryChoices {
  /** archeologic */
  Archeologic = "ARCHEOLOGIC",
  /** design_or_antique */
  DesignOrAntique = "DESIGN_OR_ANTIQUE",
  /** digital */
  Digital = "DIGITAL",
  /** installation */
  Installation = "INSTALLATION",
  /** naturalistic */
  Naturalistic = "NATURALISTIC",
  /** --- */
  NotValidated = "NOT_VALIDATED",
  /** numismatic */
  Numismatic = "NUMISMATIC",
  /** other */
  Other = "OTHER",
  /** painting */
  Painting = "PAINTING",
  /** philately */
  Philately = "PHILATELY",
  /** print_or_photo */
  PrintOrPhoto = "PRINT_OR_PHOTO",
  /** sculpture */
  Sculpture = "SCULPTURE",
  /** urban */
  Urban = "URBAN",
}

/** An enumeration. */
export enum ManagementAssetCenturyEndDateWindowChoices {
  /** Other */
  A = "A_",
  /** Begin */
  Begin = "BEGIN",
  /** End */
  End = "END",
  /** First half */
  FirstHalf = "FIRST_HALF",
  /** Half */
  Half = "HALF",
  /** Second half */
  SecondHalf = "SECOND_HALF",
}

/** An enumeration. */
export enum ManagementAssetCenturyStartDateWindowChoices {
  /** Other */
  A = "A_",
  /** Begin */
  Begin = "BEGIN",
  /** End */
  End = "END",
  /** First half */
  FirstHalf = "FIRST_HALF",
  /** Half */
  Half = "HALF",
  /** Second half */
  SecondHalf = "SECOND_HALF",
}

/** An enumeration. */
export enum ManagementAssetDatingTypeChoices {
  /** Other */
  A = "A_",
  /** Century */
  Century = "CENTURY",
  /** Date */
  Date = "DATE",
  /** Period */
  Period = "PERIOD",
  /** Year */
  Year = "YEAR",
}

/** An enumeration. */
export enum ManagementAssetFileSizeUnitChoices {
  /** Other */
  A = "A_",
  /** kb */
  Kb = "KB",
  /** mb */
  Mb = "MB",
  /** tb */
  Tb = "TB",
}

/** An enumeration. */
export enum ManagementAssetFrameMaterialChoices {
  /** Other */
  A = "A_",
  /** fabric */
  Fabric = "FABRIC",
  /** laminate */
  Laminate = "LAMINATE",
  /** metal */
  Metal = "METAL",
  /** multi-material */
  MultiMaterial = "MULTI_MATERIAL",
  /** paper */
  Paper = "PAPER",
  /** perspex */
  Perspex = "PERSPEX",
  /** plastic */
  Plastic = "PLASTIC",
  /** stone */
  Stone = "STONE",
  /** wood */
  Wood = "WOOD",
}

/** An enumeration. */
export enum ManagementAssetGrossDimensionsAcquisitionChoices {
  /** given */
  Given = "GIVEN",
  /** measured */
  Measured = "MEASURED",
  /** not measured */
  NotMeasured = "NOT_MEASURED",
}

/** An enumeration. */
export enum ManagementAssetGrossLengthUnitChoices {
  /** cm */
  Cm = "CM",
  /** foot */
  Ft = "FT",
  /** inch */
  In = "IN",
  /** m */
  M = "M",
  /** mm */
  Mm = "MM",
}

/** An enumeration. */
export enum ManagementAssetGrossWeightUnitChoices {
  /** g */
  G = "G",
  /** hg */
  Hg = "HG",
  /** kg */
  Kg = "KG",
  /** lb */
  Lb = "LB",
  /** oz */
  Oz = "OZ",
}

/** An enumeration. */
export enum ManagementAssetInjuryRiskChoices {
  /** average */
  Average = "AVERAGE",
  /** high */
  High = "HIGH",
  /** low */
  Low = "LOW",
  /** none */
  None = "NONE",
}

/** An enumeration. */
export enum ManagementAssetLightUnitChoices {
  /** foot candles */
  FootCandles = "FOOT_CANDLES",
  /** lux */
  Lux = "LUX",
}

/** An enumeration. */
export enum ManagementAssetNetDimensionsAcquisitionChoices {
  /** given */
  Given = "GIVEN",
  /** measured */
  Measured = "MEASURED",
  /** not measured */
  NotMeasured = "NOT_MEASURED",
}

/** An enumeration. */
export enum ManagementAssetNetLengthUnitChoices {
  /** cm */
  Cm = "CM",
  /** foot */
  Ft = "FT",
  /** inch */
  In = "IN",
  /** m */
  M = "M",
  /** mm */
  Mm = "MM",
}

/** An enumeration. */
export enum ManagementAssetNetWeightUnitChoices {
  /** g */
  G = "G",
  /** hg */
  Hg = "HG",
  /** kg */
  Kg = "KG",
  /** lb */
  Lb = "LB",
  /** oz */
  Oz = "OZ",
}

/** An enumeration. */
export enum ManagementAssetPresentValueAmountCurrencyChoices {
  /** Euro */
  Eur = "EUR",
  /** US Dollar */
  Usd = "USD",
}

/** An enumeration. */
export enum ManagementAssetPresentValueEstimateReasonChoices {
  /** alienation */
  Alienation = "ALIENATION",
  /** asset analysis */
  AssetAnalysis = "ASSET_ANALYSIS",
  /** CR */
  Cr = "CR",
  /** insurance quote */
  InsuranceQuote = "INSURANCE_QUOTE",
  /** legal dispute */
  LegalDispute = "LEGAL_DISPUTE",
}

/** An enumeration. */
export enum ManagementAssetResolutionUnitChoices {
  /** Other */
  A = "A_",
  /** K */
  K = "K",
  /** p */
  P = "P",
}

/** An enumeration. */
export enum ManagementAssetStampCenteringAndMarginsChoices {
  /** average */
  Average = "AVERAGE",
  /** Other */
  A = "A_",
  /** extra_fine */
  ExtraFine = "EXTRA_FINE",
  /** fine */
  Fine = "FINE",
  /** poor */
  Poor = "POOR",
  /** superb */
  Superb = "SUPERB",
}

/** An enumeration. */
export enum ManagementAssetStampFormatChoices {
  /** Other */
  A = "A_",
  /** booklet */
  Booklet = "BOOKLET",
  /** coil */
  Coil = "COIL",
  /** sheets */
  Sheets = "SHEETS",
}

/** An enumeration. */
export enum ManagementAssetStampIndentationTypeChoices {
  /** Other */
  A = "A_",
  /** block */
  Block = "BLOCK",
  /** comb */
  Comb = "COMB",
  /** linear */
  Linear = "LINEAR",
}

/** An enumeration. */
export enum ManagementAssetStampPrintingTechniqueChoices {
  /** Other */
  A = "A_",
  /** chalcography */
  Chalcography = "CHALCOGRAPHY",
  /** letterpress_printing */
  LetterpressPrinting = "LETTERPRESS_PRINTING",
  /** lithography */
  Lithography = "LITHOGRAPHY",
  /** offset */
  Offset = "OFFSET",
  /** photogravure */
  Photogravure = "PHOTOGRAVURE",
  /** relief_printing */
  ReliefPrinting = "RELIEF_PRINTING",
  /** rotogravure */
  Rotogravure = "ROTOGRAVURE",
  /** serigraphy */
  Serigraphy = "SERIGRAPHY",
}

/** An enumeration. */
export enum ManagementAssetStampRealizationChoices {
  /** automatic */
  Automatic = "AUTOMATIC",
  /** Other */
  A = "A_",
  /** indented */
  Indented = "INDENTED",
  /** not_indented */
  NotIndented = "NOT_INDENTED",
  /** self-adhesive */
  SelfAdhesive = "SELF_ADHESIVE",
}

/** An enumeration. */
export enum ManagementAssetSubcategoryChoices {
  /** Antiques/Design */
  AntiquesDesign = "ANTIQUES_DESIGN",
  /** Archeology */
  Archeology = "ARCHEOLOGY",
  /** Artist Proof */
  ArtistProof = "ARTIST_PROOF",
  /** Audio */
  Audio = "AUDIO",
  /** Automobile */
  Automobile = "AUTOMOBILE",
  A = "A_",
  /** Clothes */
  Clothes = "CLOTHES",
  /** Drawing */
  Drawing = "DRAWING",
  /** Dynamic Work */
  DynamicWork = "DYNAMIC_WORK",
  /** Graphic */
  Graphic = "GRAPHIC",
  /** Historical Objects */
  HistoricalObjects = "HISTORICAL_OBJECTS",
  /** Installation */
  Installation = "INSTALLATION",
  /** Jewelry */
  Jewelry = "JEWELRY",
  /** Lithography */
  Lithography = "LITHOGRAPHY",
  /** Memorabilia */
  Memorabilia = "MEMORABILIA",
  /** Musical instruments */
  MusicalInstruments = "MUSICAL_INSTRUMENTS",
  /** Natural Site */
  NaturalSite = "NATURAL_SITE",
  /** Numismatic and Banknotes */
  NumismaticAndBanknotes = "NUMISMATIC_AND_BANKNOTES",
  /** Numismatic, Coins and Medals */
  NumismaticCoinsAndMedals = "NUMISMATIC_COINS_AND_MEDALS",
  /** Opera su Carta */
  OperaSuCarta = "OPERA_SU_CARTA",
  /** Other Bidimensional Works */
  OtherBidimensionalWorks = "OTHER_BIDIMENSIONAL_WORKS",
  /** Painting */
  Painting = "PAINTING",
  /** Philately */
  Philately = "PHILATELY",
  /** Photography */
  Photography = "PHOTOGRAPHY",
  /** Prints */
  Prints = "PRINTS",
  /** Sculpture */
  Sculpture = "SCULPTURE",
  /** Serigraphy */
  Serigraphy = "SERIGRAPHY",
  /** Static Work */
  StaticWork = "STATIC_WORK",
  /** Tapestries */
  Tapestries = "TAPESTRIES",
  /** Toys */
  Toys = "TOYS",
  /** Urban Art */
  UrbanArt = "URBAN_ART",
  /** Video */
  Video = "VIDEO",
  /** Watches */
  Watches = "WATCHES",
  /** Wine */
  Wine = "WINE",
  /** Xylography */
  Xylography = "XYLOGRAPHY",
}

/** An enumeration. */
export enum ManagementAssetTemperatureUnitChoices {
  /** C° */
  C = "C",
  /** °F */
  F = "F",
}

/** An enumeration. */
export enum ManagementInsuranceContractTypeChoices {
  /** basket */
  Basket = "BASKET",
  /** punctual */
  Punctual = "PUNCTUAL",
}

/** An enumeration. */
export enum ManagementInsuranceCoverageTypeChoices {
  /** nail_to_nail */
  NailToNail = "NAIL_TO_NAIL",
  /** stay_risk */
  StayRisk = "STAY_RISK",
  /** travel_risk */
  TravelRisk = "TRAVEL_RISK",
}

/** An enumeration. */
export enum ManagementInsuranceEvaluationTypeChoices {
  /** all */
  All = "ALL",
  /** declared_value */
  DeclaredValue = "DECLARED_VALUE",
  /** estimate_accepted */
  EstimateAccepted = "ESTIMATE_ACCEPTED",
}

/** An enumeration. */
export enum ManagementInsuranceInsuranceReasonChoices {
  /** basket */
  Exhibition = "EXHIBITION",
  /** punctual */
  ExhibitionTransport = "EXHIBITION_TRANSPORT",
  /** loan */
  Loan = "LOAN",
  /** punctual */
  Restauration = "RESTAURATION",
  /** punctual */
  RestaurationTransport = "RESTAURATION_TRANSPORT",
  /** punctual */
  StayInPlace = "STAY_IN_PLACE",
  /** punctual */
  Transport = "TRANSPORT",
}

/** An enumeration. */
export enum ManagementInsuranceRiskCoveredTypeChoices {
  /** all_risk */
  AllRisk = "ALL_RISK",
  /** first_risk */
  FirstRisk = "FIRST_RISK",
  /** none */
  None = "NONE",
}

export type MediaNode = Node & {
  __typename?: "MediaNode";
  assetSet: AssetNodeConnection;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type MediaNodeAssetSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type MediaNodeConnection = {
  __typename?: "MediaNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MediaNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MediaNode` and its cursor. */
export type MediaNodeEdge = {
  __typename?: "MediaNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<MediaNode>;
};

export type Money = {
  __typename?: "Money";
  /** The numerical amount. */
  amount: Scalars["Decimal"];
  /** The string version of the numerical amount. */
  amountStr: Scalars["String"];
  asString: Scalars["StringMoney"];
  currency: Currency;
  formatAmount: Scalars["String"];
};

export type MoneyFormatAmountArgs = {
  decimals?: InputMaybe<Scalars["Int"]>;
};

export type MoneyInput = {
  /** The numerical amount. */
  amount?: InputMaybe<Scalars["Decimal"]>;
  /** The ISO-421 3-letter currency code. */
  currency: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addAssetMedia?: Maybe<AddAssetMediaPayload>;
  addUserPermission?: Maybe<AddUserPermissionPayload>;
  addUserSignature?: Maybe<AddUserSignaturePayload>;
  changePassword?: Maybe<ChangePasswordPayload>;
  createAsset?: Maybe<CreateAssetPayload>;
  createAssetElement?: Maybe<CreateAssetElementPayload>;
  createAssetPresentValue?: Maybe<CreateAssetPresentValuePayload>;
  createCertificate?: Maybe<CreateCertificatePayload>;
  createInsurance?: Maybe<CreateInsurancePayload>;
  createOffice?: Maybe<CreateOfficePayload>;
  createPolicy?: Maybe<CreatePolicyPayload>;
  createPolicyAsset?: Maybe<CreatePolicyAssetPayload>;
  createPolicyDocument?: Maybe<CreatePolicyDocumentPayload>;
  createPolicyNote?: Maybe<CreatePolicyNotePayload>;
  createRegistry?: Maybe<CreateRegistryPayload>;
  createUser?: Maybe<CreateUserPayload>;
  deleteAsset?: Maybe<DeleteAssetPayload>;
  deleteAssetElement?: Maybe<DeleteAssetElementPayload>;
  deleteAssetPresentValue?: Maybe<DeleteAssetPresentValuePayload>;
  deleteCertificate?: Maybe<DeleteCertificatePayload>;
  deleteInsurance?: Maybe<DeleteInsurancePayload>;
  deleteOffice?: Maybe<DeleteOfficePayload>;
  deletePolicy?: Maybe<DeletePolicyPayload>;
  deletePolicyAsset?: Maybe<DeletePolicyAssetPayload>;
  deletePolicyDocument?: Maybe<DeletePolicyDocumentPayload>;
  deletePolicyNote?: Maybe<DeletePolicyNotePayload>;
  deleteRegistry?: Maybe<DeleteRegistryPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserSignature?: Maybe<DeleteUserSignaturePayload>;
  generateCertificate?: Maybe<GenerateCertificatePayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  refreshToken?: Maybe<Refresh>;
  requestUserResetPassword?: Maybe<RequestUserResetPasswordPayload>;
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  revokeToken?: Maybe<Revoke>;
  signCertificate?: Maybe<SignCertificatePayload>;
  toggleUserIsactive?: Maybe<ToggleUserIsActivePayload>;
  toggleUserUserType?: Maybe<ToggleUserUserTypePayload>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateAsset?: Maybe<UpdateAssetPayload>;
  updateAssetElement?: Maybe<UpdateAssetElementPayload>;
  updateAssetPresentValue?: Maybe<UpdateAssetPresentValuePayload>;
  updateCertificate?: Maybe<UpdateCertificatePayload>;
  updateInsurance?: Maybe<UpdateInsurancePayload>;
  updateOffice?: Maybe<UpdateOfficePayload>;
  updatePolicy?: Maybe<UpdatePolicyPayload>;
  updatePolicyAsset?: Maybe<UpdatePolicyAssetPayload>;
  updatePolicyDocument?: Maybe<UpdatePolicyDocumentPayload>;
  updatePolicyNote?: Maybe<UpdatePolicyNotePayload>;
  updateRegistry?: Maybe<UpdateRegistryPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserPermissions?: Maybe<UpdateUserPermissionPayload>;
  verifyToken?: Maybe<Verify>;
};

export type MutationAddAssetMediaArgs = {
  input: AddAssetMediaInput;
};

export type MutationAddUserPermissionArgs = {
  input: AddUserPermissionInput;
};

export type MutationAddUserSignatureArgs = {
  input: AddUserSignatureInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCreateAssetArgs = {
  input: CreateAssetInput;
};

export type MutationCreateAssetElementArgs = {
  input: CreateAssetElementInput;
};

export type MutationCreateAssetPresentValueArgs = {
  input: CreateAssetPresentValueInput;
};

export type MutationCreateCertificateArgs = {
  input: CreateCertificateInput;
};

export type MutationCreateInsuranceArgs = {
  input: CreateInsuranceInput;
};

export type MutationCreateOfficeArgs = {
  input: CreateOfficeInput;
};

export type MutationCreatePolicyArgs = {
  input: CreatePolicyInput;
};

export type MutationCreatePolicyAssetArgs = {
  input: CreatePolicyAssetInput;
};

export type MutationCreatePolicyDocumentArgs = {
  input: CreatePolicyDocumentInput;
};

export type MutationCreatePolicyNoteArgs = {
  input: CreatePolicyNoteInput;
};

export type MutationCreateRegistryArgs = {
  input: CreateRegistryInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteAssetArgs = {
  input: DeleteAssetInput;
};

export type MutationDeleteAssetElementArgs = {
  input: DeleteAssetElementInput;
};

export type MutationDeleteAssetPresentValueArgs = {
  input: DeleteAssetPresentValueInput;
};

export type MutationDeleteCertificateArgs = {
  input: DeleteCertificateInput;
};

export type MutationDeleteInsuranceArgs = {
  input: DeleteInsuranceInput;
};

export type MutationDeleteOfficeArgs = {
  input: DeleteOfficeInput;
};

export type MutationDeletePolicyArgs = {
  input: DeletePolicyInput;
};

export type MutationDeletePolicyAssetArgs = {
  input: DeletePolicyAssetInput;
};

export type MutationDeletePolicyDocumentArgs = {
  input: DeletePolicyDocumentInput;
};

export type MutationDeletePolicyNoteArgs = {
  input: DeletePolicyNoteInput;
};

export type MutationDeleteRegistryArgs = {
  input: DeleteRegistryInput;
};

export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type MutationDeleteUserSignatureArgs = {
  input: DeleteUserSignatureInput;
};

export type MutationGenerateCertificateArgs = {
  input: GenerateCertificateInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationRequestUserResetPasswordArgs = {
  input: RequestUserResetPasswordInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars["String"]>;
};

export type MutationSignCertificateArgs = {
  input: SignCertificateInput;
};

export type MutationToggleUserIsactiveArgs = {
  input: ToggleUserIsActiveInput;
};

export type MutationToggleUserUserTypeArgs = {
  input: ToggleUserUserTypeInput;
};

export type MutationTokenAuthArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type MutationUpdateAssetArgs = {
  input: UpdateAssetInput;
};

export type MutationUpdateAssetElementArgs = {
  input: UpdateAssetElementInput;
};

export type MutationUpdateAssetPresentValueArgs = {
  input: UpdateAssetPresentValueInput;
};

export type MutationUpdateCertificateArgs = {
  input: UpdateCertificateInput;
};

export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};

export type MutationUpdateOfficeArgs = {
  input: UpdateOfficeInput;
};

export type MutationUpdatePolicyArgs = {
  input: UpdatePolicyInput;
};

export type MutationUpdatePolicyAssetArgs = {
  input: UpdatePolicyAssetInput;
};

export type MutationUpdatePolicyDocumentArgs = {
  input: UpdatePolicyDocumentInput;
};

export type MutationUpdatePolicyNoteArgs = {
  input: UpdatePolicyNoteInput;
};

export type MutationUpdateRegistryArgs = {
  input: UpdateRegistryInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserPermissionsArgs = {
  input: UpdateUserPermissionInput;
};

export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars["String"]>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars["ID"];
};

export type ObtainJsonWebToken = {
  __typename?: "ObtainJSONWebToken";
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
  user?: Maybe<UserType>;
};

export type OfficeInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  main: Scalars["Boolean"];
  name?: InputMaybe<Scalars["String"]>;
  notActive: Scalars["Boolean"];
  registry: Scalars["ID"];
  state?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type OfficeNode = Node & {
  __typename?: "OfficeNode";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  /** The ID of the object */
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  notActive: Scalars["Boolean"];
  objectLocationOffice: PolicyAssetNodeConnection;
  policyEventLocationEntity: PolicyNodeConnection;
  registry: RegistryNode;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeNodeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeNodeConnection = {
  __typename?: "OfficeNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OfficeNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `OfficeNode` and its cursor. */
export type OfficeNodeEdge = {
  __typename?: "OfficeNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<OfficeNode>;
};

export type OfficeType = {
  __typename?: "OfficeType";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  id: Scalars["ID"];
  main: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  notActive: Scalars["Boolean"];
  objectLocationOffice: PolicyAssetNodeConnection;
  policyEventLocationEntity: PolicyNodeConnection;
  registry: RegistryNode;
  state?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  zip?: Maybe<Scalars["String"]>;
};

export type OfficeTypeObjectLocationOfficeArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type OfficeTypePolicyEventLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type PermissionNode = Node & {
  __typename?: "PermissionNode";
  codename: Scalars["String"];
  contentType: ContentTypeNode;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  /** Specific permissions for this user. */
  userSet: UserNodeConnection;
};

export type PermissionNodeUserSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PermissionNodeConnection = {
  __typename?: "PermissionNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PermissionNode` and its cursor. */
export type PermissionNodeEdge = {
  __typename?: "PermissionNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
};

export type PolicyAssetInput = {
  asset?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  deliveryAddress?: InputMaybe<AddressInput>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  insuredValue?: InputMaybe<MoneyInput>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  lenderAddress?: InputMaybe<AddressInput>;
  lenderId?: InputMaybe<Scalars["ID"]>;
  objectLocationEntityId?: InputMaybe<Scalars["ID"]>;
  objectLocationOfficeId?: InputMaybe<Scalars["ID"]>;
  pickupAddress?: InputMaybe<AddressInput>;
  policy?: InputMaybe<Scalars["ID"]>;
  shipperId?: InputMaybe<Scalars["ID"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type PolicyAssetNode = Node & {
  __typename?: "PolicyAssetNode";
  asset: AssetNode;
  catNat: Scalars["Boolean"];
  certificateSet: CertificateNodeConnection;
  coverTerrorism: Scalars["Boolean"];
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  deliveryAddress?: Maybe<Address>;
  evaluationType: Scalars["String"];
  exemption: Scalars["Boolean"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  insuredValue?: Maybe<Money>;
  insuredValueCurrency: Scalars["String"];
  issuedAt?: Maybe<Scalars["DateTime"]>;
  lender?: Maybe<RegistryNode>;
  lenderAddress?: Maybe<Address>;
  objectLocationEntity?: Maybe<RegistryNode>;
  objectLocationOffice?: Maybe<OfficeNode>;
  pickupAddress?: Maybe<Address>;
  policy: PolicyNode;
  shipper?: Maybe<RegistryNode>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyAssetNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyAssetNodeConnection = {
  __typename?: "PolicyAssetNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyAssetNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyAssetNode` and its cursor. */
export type PolicyAssetNodeEdge = {
  __typename?: "PolicyAssetNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyAssetNode>;
};

export type PolicyDocumentInput = {
  description?: InputMaybe<Scalars["String"]>;
  extension?: InputMaybe<Scalars["String"]>;
  file: Scalars["Upload"];
  name?: InputMaybe<Scalars["String"]>;
  weight?: InputMaybe<Scalars["Float"]>;
};

export type PolicyDocumentNode = Node & {
  __typename?: "PolicyDocumentNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  description?: Maybe<Scalars["String"]>;
  extension?: Maybe<Scalars["String"]>;
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  name: Scalars["String"];
  policy: PolicyNode;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  weight?: Maybe<Scalars["Float"]>;
};

export type PolicyDocumentNodeConnection = {
  __typename?: "PolicyDocumentNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyDocumentNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyDocumentNode` and its cursor. */
export type PolicyDocumentNodeEdge = {
  __typename?: "PolicyDocumentNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyDocumentNode>;
};

export type PolicyInput = {
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  brokerId: Scalars["ID"];
  catNat?: InputMaybe<Scalars["Boolean"]>;
  contractingPartyId: Scalars["ID"];
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntityId: Scalars["ID"];
  eventFrom?: InputMaybe<Scalars["String"]>;
  eventLocationEntityId: Scalars["ID"];
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt: Scalars["DateTime"];
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompanyId: Scalars["ID"];
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyAssets?: InputMaybe<Array<InputMaybe<PolicyAssetInput>>>;
  policyCode: Scalars["String"];
  policyDocuments?: InputMaybe<Array<InputMaybe<PolicyDocumentInput>>>;
  policyNote?: InputMaybe<Array<InputMaybe<PolicyNoteInput>>>;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  totalInsuredPremium?: InputMaybe<MoneyInput>;
  totalInsuredValue?: InputMaybe<MoneyInput>;
};

export type PolicyNode = Node & {
  __typename?: "PolicyNode";
  assetsCount?: Maybe<Scalars["Int"]>;
  autoRenewal: Scalars["Boolean"];
  broker: RegistryNode;
  catNat: Scalars["Boolean"];
  certificateSet: CertificateNodeConnection;
  collection?: Maybe<CollectionNode>;
  contractingParty: RegistryNode;
  coverTerrorism: Scalars["Boolean"];
  coverType: Scalars["String"];
  coveredRiskType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  evaluationType: Scalars["String"];
  eventEntity?: Maybe<RegistryNode>;
  eventFrom?: Maybe<Scalars["DateTime"]>;
  eventLocationEntity?: Maybe<OfficeNode>;
  eventName?: Maybe<Scalars["String"]>;
  eventTo?: Maybe<Scalars["DateTime"]>;
  exemption: Scalars["Boolean"];
  exhibition?: Maybe<ExhibitionNode>;
  expiresAt: Scalars["DateTime"];
  hasValidCertificate: Scalars["Boolean"];
  hasVariableBasket: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  insuranceCompany: RegistryNode;
  isExpired?: Maybe<Scalars["Boolean"]>;
  issuedAt: Scalars["DateTime"];
  offerCode: Scalars["String"];
  policyAssets: PolicyAssetNodeConnection;
  policyCode: Scalars["String"];
  policyDocuments: PolicyDocumentNodeConnection;
  policyNotes: PolicyNoteNodeConnection;
  policyStatus: Scalars["String"];
  policyType: Scalars["String"];
  totalInsuredPremium?: Maybe<Money>;
  totalInsuredPremiumCurrency: Scalars["String"];
  totalInsuredValue?: Maybe<Money>;
  totalInsuredValueCurrency: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
};

export type PolicyNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodePolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PolicyNodeConnection = {
  __typename?: "PolicyNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyNode` and its cursor. */
export type PolicyNodeEdge = {
  __typename?: "PolicyNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNode>;
};

export type PolicyNoteInput = {
  content?: InputMaybe<Scalars["String"]>;
  policyId?: InputMaybe<Scalars["ID"]>;
};

export type PolicyNoteNode = Node & {
  __typename?: "PolicyNoteNode";
  content?: Maybe<Scalars["String"]>;
  createdBy: UserNode;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  /** The ID of the object */
  id: Scalars["ID"];
  policy: PolicyNode;
  uuid: Scalars["UUID"];
};

export type PolicyNoteNodeConnection = {
  __typename?: "PolicyNoteNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PolicyNoteNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PolicyNoteNode` and its cursor. */
export type PolicyNoteNodeEdge = {
  __typename?: "PolicyNoteNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<PolicyNoteNode>;
};

export type Query = {
  __typename?: "Query";
  allAssetElements?: Maybe<AssetElementNodeConnection>;
  allAssetPresentValues?: Maybe<AssetPresentValueNodeConnection>;
  allAssets?: Maybe<AssetNodeConnection>;
  allCertificateTemplates?: Maybe<CertificateTemplateNodeConnection>;
  allCertificates?: Maybe<CertificateNodeConnection>;
  allClient?: Maybe<ClientNodeConnection>;
  allCollections?: Maybe<CollectionNodeConnection>;
  allContenttypes?: Maybe<ContentTypeNodeConnection>;
  allExhibitions?: Maybe<ExhibitionNodeConnection>;
  allInsurances?: Maybe<InsuranceNodeConnection>;
  allOffices?: Maybe<OfficeNodeConnection>;
  allPermissions?: Maybe<PermissionNodeConnection>;
  allPolicies?: Maybe<PolicyNodeConnection>;
  allPolicyAssets?: Maybe<PolicyAssetNodeConnection>;
  allPolicyDocuments?: Maybe<PolicyDocumentNodeConnection>;
  allPolicyNotes?: Maybe<PolicyNoteNodeConnection>;
  allRegistries?: Maybe<RegistryNodeConnection>;
  allUsers?: Maybe<UserNodeConnection>;
  asset?: Maybe<AssetNode>;
  assetElement?: Maybe<AssetElementNode>;
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  certificate?: Maybe<CertificateNode>;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  client?: Maybe<ClientNode>;
  collection?: Maybe<CollectionNode>;
  contenttype?: Maybe<ContentTypeNode>;
  exhibition?: Maybe<ExhibitionNode>;
  filteredInsurances?: Maybe<FilteredInsuranceNodeConnection>;
  insurance?: Maybe<InsuranceNode>;
  insurances?: Maybe<Array<Maybe<InsuranceNode>>>;
  office?: Maybe<OfficeNode>;
  offices?: Maybe<Array<Maybe<OfficeNode>>>;
  permission?: Maybe<PermissionNode>;
  policy?: Maybe<PolicyNode>;
  policyAsset?: Maybe<PolicyAssetNode>;
  policyDocument?: Maybe<PolicyDocumentNode>;
  policyNote?: Maybe<PolicyNoteNode>;
  registries?: Maybe<Array<Maybe<RegistryNode>>>;
  registry?: Maybe<RegistryNode>;
  searchAssets?: Maybe<AssetNodeConnection>;
  user?: Maybe<UserNode>;
};

export type QueryAllAssetElementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetPresentValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCertificateTemplatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCertificatesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  cancelReplace?: InputMaybe<Scalars["Boolean"]>;
  cancelReplaceNotes?: InputMaybe<Scalars["String"]>;
  certificateType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  creationDate?: InputMaybe<Scalars["DateTime"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  isValid?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policyAsset?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  printTemplate?: InputMaybe<Scalars["ID"]>;
  progressiveNumber?: InputMaybe<Scalars["Int"]>;
  signed?: InputMaybe<Scalars["Boolean"]>;
  signedAt?: InputMaybe<Scalars["DateTime"]>;
  signedBy?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllClientArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  createdOn?: InputMaybe<Scalars["Date"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  dashboardBaseUrl?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onTrial?: InputMaybe<Scalars["Boolean"]>;
  paidUntil?: InputMaybe<Scalars["Date"]>;
  schemaName?: InputMaybe<Scalars["String"]>;
  storageName?: InputMaybe<Scalars["String"]>;
  tier?: InputMaybe<Scalars["String"]>;
};

export type QueryAllCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Contains?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllContenttypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  appLabel?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  model?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllExhibitionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  name_Contains?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllOfficesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  registry?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  codename?: InputMaybe<Scalars["String"]>;
  contentType?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllPoliciesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  autoRenewal?: InputMaybe<Scalars["Boolean"]>;
  before?: InputMaybe<Scalars["String"]>;
  broker?: InputMaybe<Scalars["ID"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  collection?: InputMaybe<Scalars["ID"]>;
  contractingParty?: InputMaybe<Scalars["ID"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  eventEntity?: InputMaybe<Scalars["ID"]>;
  eventFrom?: InputMaybe<Scalars["DateTime"]>;
  eventLocationEntity?: InputMaybe<Scalars["ID"]>;
  eventName?: InputMaybe<Scalars["String"]>;
  eventTo?: InputMaybe<Scalars["DateTime"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  exhibition?: InputMaybe<Scalars["ID"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  hasValidCertificate?: InputMaybe<Scalars["Boolean"]>;
  hasVariableBasket?: InputMaybe<Scalars["Boolean"]>;
  insuranceCompany?: InputMaybe<Scalars["ID"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offerCode?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyCode?: InputMaybe<Scalars["String"]>;
  policyStatus?: InputMaybe<Scalars["String"]>;
  policyType?: InputMaybe<Scalars["String"]>;
  totalInsuredPremium?: InputMaybe<Scalars["String"]>;
  totalInsuredPremiumCurrency?: InputMaybe<Scalars["String"]>;
  totalInsuredValue?: InputMaybe<Scalars["String"]>;
  totalInsuredValueCurrency?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  asset?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["String"]>;
  catNat?: InputMaybe<Scalars["Boolean"]>;
  coverTerrorism?: InputMaybe<Scalars["Boolean"]>;
  coverType?: InputMaybe<Scalars["String"]>;
  coveredRiskType?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  deliveryAddress?: InputMaybe<Scalars["ID"]>;
  evaluationType?: InputMaybe<Scalars["String"]>;
  exemption?: InputMaybe<Scalars["Boolean"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  insuredValue?: InputMaybe<Scalars["String"]>;
  insuredValueCurrency?: InputMaybe<Scalars["String"]>;
  issuedAt?: InputMaybe<Scalars["DateTime"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lender?: InputMaybe<Scalars["ID"]>;
  lenderAddress?: InputMaybe<Scalars["ID"]>;
  objectLocationEntity?: InputMaybe<Scalars["ID"]>;
  objectLocationOffice?: InputMaybe<Scalars["ID"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  pickupAddress?: InputMaybe<Scalars["ID"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  policy_PolicyType?: InputMaybe<Scalars["String"]>;
  shipper?: InputMaybe<Scalars["ID"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllPolicyDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
};

export type QueryAllPolicyNotesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  content?: InputMaybe<Scalars["String"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  deleted?: InputMaybe<Scalars["DateTime"]>;
  deletedByCascade?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policy?: InputMaybe<Scalars["ID"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAllRegistriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
};

export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  createdBy?: InputMaybe<Scalars["ID"]>;
  currency?: InputMaybe<Scalars["String"]>;
  dateJoined?: InputMaybe<Scalars["DateTime"]>;
  email?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  first?: InputMaybe<Scalars["Int"]>;
  groups?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isStaff?: InputMaybe<Scalars["Boolean"]>;
  isSuperuser?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  lastLogin?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
  resetTokenExpiry?: InputMaybe<Scalars["DateTime"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  updatedBy?: InputMaybe<Scalars["ID"]>;
  userPermissions?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  userType?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
  uuid?: InputMaybe<Scalars["UUID"]>;
};

export type QueryAssetArgs = {
  id: Scalars["ID"];
};

export type QueryAssetElementArgs = {
  id: Scalars["ID"];
};

export type QueryAssetPresentValueArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateArgs = {
  id: Scalars["ID"];
};

export type QueryCertificateTemplateArgs = {
  id: Scalars["ID"];
};

export type QueryClientArgs = {
  id: Scalars["ID"];
};

export type QueryCollectionArgs = {
  id: Scalars["ID"];
};

export type QueryContenttypeArgs = {
  id: Scalars["ID"];
};

export type QueryExhibitionArgs = {
  id: Scalars["ID"];
};

export type QueryFilteredInsurancesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type QueryInsuranceArgs = {
  id: Scalars["ID"];
};

export type QueryInsurancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryOfficeArgs = {
  id: Scalars["ID"];
};

export type QueryOfficesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  registryId?: InputMaybe<Scalars["ID"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryPermissionArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyAssetArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyDocumentArgs = {
  id: Scalars["ID"];
};

export type QueryPolicyNoteArgs = {
  id: Scalars["ID"];
};

export type QueryRegistriesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryRegistryArgs = {
  id: Scalars["ID"];
};

export type QuerySearchAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  title_Contains?: InputMaybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

/** An enumeration. */
export enum RbacClientCustomerTypeChoices {
  /** broker */
  Broker = "BROKER",
  /** collector */
  Collector = "COLLECTOR",
  /** insurance_company */
  InsuranceCompany = "INSURANCE_COMPANY",
  /** museum */
  Museum = "MUSEUM",
}

/** An enumeration. */
export enum RbacClientTierChoices {
  /** 1 */
  A_1 = "A_1",
  /** 2 */
  A_2 = "A_2",
  /** 3 */
  A_3 = "A_3",
}

export type Refresh = {
  __typename?: "Refresh";
  payload: Scalars["GenericScalar"];
  refreshExpiresIn: Scalars["Int"];
  refreshToken: Scalars["String"];
  token: Scalars["String"];
};

export type RegistryInput = {
  activityPeriod?: InputMaybe<Scalars["String"]>;
  activityPlace?: InputMaybe<Scalars["String"]>;
  alias?: InputMaybe<Scalars["String"]>;
  artisticMovement?: InputMaybe<Scalars["String"]>;
  authorType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  billingAddress?: InputMaybe<Scalars["String"]>;
  birthDate?: InputMaybe<Scalars["Date"]>;
  birthPlace?: InputMaybe<Scalars["String"]>;
  businessName?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  company?: InputMaybe<Scalars["ID"]>;
  confidentialNotes?: InputMaybe<Scalars["String"]>;
  contactDescription?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  deathDate?: InputMaybe<Scalars["Date"]>;
  deathPlace?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  foundation?: InputMaybe<Scalars["String"]>;
  fullName: Scalars["String"];
  isAuthor?: InputMaybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  isCompany?: InputMaybe<Scalars["Boolean"]>;
  isInsuranceCompanyTenantDefault?: InputMaybe<Scalars["Boolean"]>;
  mainContact?: InputMaybe<Scalars["Boolean"]>;
  mobilePhone?: InputMaybe<Scalars["String"]>;
  note?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  printCode?: InputMaybe<Scalars["String"]>;
  printName?: InputMaybe<Scalars["String"]>;
  taxId?: InputMaybe<Scalars["String"]>;
  webSite?: InputMaybe<Scalars["String"]>;
};

export type RegistryNode = Node & {
  __typename?: "RegistryNode";
  activityPeriod?: Maybe<Scalars["String"]>;
  activityPlace?: Maybe<Scalars["String"]>;
  alias?: Maybe<Scalars["String"]>;
  artisticMovement?: Maybe<Scalars["String"]>;
  assetAuthor: AssetNodeConnection;
  assetPresentValueAuthor: AssetPresentValueNodeConnection;
  authorType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  billingAddress?: Maybe<Scalars["String"]>;
  birthDate?: Maybe<Scalars["Date"]>;
  birthPlace?: Maybe<Scalars["String"]>;
  businessName?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<Scalars["String"]>>>;
  code?: Maybe<Scalars["String"]>;
  company?: Maybe<RegistryNode>;
  confidentialNotes?: Maybe<Scalars["String"]>;
  contactDescription?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deathDate?: Maybe<Scalars["Date"]>;
  deathPlace?: Maybe<Scalars["String"]>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  foundation?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  isAuthor?: Maybe<Scalars["Boolean"]>;
  isBrokerDefaultTenantDefault?: Maybe<Scalars["Boolean"]>;
  isCompany: Scalars["Boolean"];
  isInsuranceCompanyTenantDefault?: Maybe<Scalars["Boolean"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  objectLocationEntity: PolicyAssetNodeConnection;
  officeRegistry: OfficeNodeConnection;
  phone?: Maybe<Scalars["String"]>;
  policyAssetLender: PolicyAssetNodeConnection;
  policyAssetShipper: PolicyAssetNodeConnection;
  policyBroker: PolicyNodeConnection;
  policyContractingParty: PolicyNodeConnection;
  policyEventEntity: PolicyNodeConnection;
  policyInsuranceCompany: PolicyNodeConnection;
  printCode?: Maybe<Scalars["String"]>;
  printName?: Maybe<Scalars["String"]>;
  registryCompany: RegistryNodeConnection;
  taxId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  uuid: Scalars["UUID"];
  webSite?: Maybe<Scalars["String"]>;
};

export type RegistryNodeAssetAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeAssetPresentValueAuthorArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeObjectLocationEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeOfficeRegistryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetLenderArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyAssetShipperArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyBrokerArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyContractingPartyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyEventEntityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodePolicyInsuranceCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeRegistryCompanyArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type RegistryNodeConnection = {
  __typename?: "RegistryNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RegistryNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RegistryNode` and its cursor. */
export type RegistryNodeEdge = {
  __typename?: "RegistryNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<RegistryNode>;
};

export type RequestUserResetPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

export type RequestUserResetPasswordPayload = {
  __typename?: "RequestUserResetPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ResetUserPasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  confirmPassword?: InputMaybe<Scalars["String"]>;
  newPassword?: InputMaybe<Scalars["String"]>;
  resetPasswordToken?: InputMaybe<Scalars["String"]>;
};

export type ResetUserPasswordPayload = {
  __typename?: "ResetUserPasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type Revoke = {
  __typename?: "Revoke";
  revoked: Scalars["Int"];
};

export type SignCertificateInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type SignCertificatePayload = {
  __typename?: "SignCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** An enumeration. */
export enum SpinCertificateCertificateTypeChoices {
  /** Permanent Collection */
  PermanentCollection = "PERMANENT_COLLECTION",
  /** Temporary Exhibition */
  TemporaryExhibition = "TEMPORARY_EXHIBITION",
}

export type ToggleUserIsActiveInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type ToggleUserIsActivePayload = {
  __typename?: "ToggleUserIsActivePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type ToggleUserUserTypeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  expiration?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
};

export type ToggleUserUserTypePayload = {
  __typename?: "ToggleUserUserTypePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UpdateAssetElementInput = {
  assetElementData?: InputMaybe<AssetElementInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetElementPayload = {
  __typename?: "UpdateAssetElementPayload";
  assetElement?: Maybe<AssetElementNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetInput = {
  assetData?: InputMaybe<AssetInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPayload = {
  __typename?: "UpdateAssetPayload";
  asset?: Maybe<AssetNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateAssetPresentValueInput = {
  assetPresentValueData?: InputMaybe<AssetPresentValueInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
};

export type UpdateAssetPresentValuePayload = {
  __typename?: "UpdateAssetPresentValuePayload";
  assetPresentValue?: Maybe<AssetPresentValueNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateCertificateInput = {
  certificateData?: InputMaybe<CertificateInput>;
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
};

export type UpdateCertificatePayload = {
  __typename?: "UpdateCertificatePayload";
  certificate?: Maybe<CertificateNode>;
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type UpdateInsuranceInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  insuranceData?: InputMaybe<InsuranceInput>;
};

export type UpdateInsurancePayload = {
  __typename?: "UpdateInsurancePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  insurance?: Maybe<InsuranceType>;
};

export type UpdateOfficeInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  officeData?: InputMaybe<OfficeInput>;
};

export type UpdateOfficePayload = {
  __typename?: "UpdateOfficePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  office?: Maybe<OfficeType>;
};

export type UpdatePolicyAssetInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyAssetData?: InputMaybe<PolicyAssetInput>;
};

export type UpdatePolicyAssetPayload = {
  __typename?: "UpdatePolicyAssetPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyAsset?: Maybe<PolicyAssetNode>;
};

export type UpdatePolicyDocumentInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyDocumentData?: InputMaybe<PolicyDocumentInput>;
};

export type UpdatePolicyDocumentPayload = {
  __typename?: "UpdatePolicyDocumentPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyDocument?: Maybe<PolicyDocumentNode>;
};

export type UpdatePolicyInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyData: PolicyInput;
};

export type UpdatePolicyNoteInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  policyNoteData?: InputMaybe<PolicyNoteInput>;
};

export type UpdatePolicyNotePayload = {
  __typename?: "UpdatePolicyNotePayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policyNote?: Maybe<PolicyNoteNode>;
};

export type UpdatePolicyPayload = {
  __typename?: "UpdatePolicyPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  policy?: Maybe<PolicyNode>;
};

export type UpdateRegistryInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  registryData?: InputMaybe<RegistryInput>;
};

export type UpdateRegistryPayload = {
  __typename?: "UpdateRegistryPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  registry?: Maybe<RegistryNode>;
};

export type UpdateUserInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  userData: UserInput;
};

export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UpdateUserPermissionInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  permissions?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UpdateUserPermissionPayload = {
  __typename?: "UpdateUserPermissionPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
  user?: Maybe<UserNode>;
};

export type UserInput = {
  country?: InputMaybe<Scalars["String"]>;
  currency?: InputMaybe<Scalars["String"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  language?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  userType?: InputMaybe<Scalars["String"]>;
};

export type UserNode = Node & {
  __typename?: "UserNode";
  certificateSet: CertificateNodeConnection;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdusersUserRelated: UserNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedusersUserRelated: UserNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
};

export type UserNodeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserNodeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserNodeConnection = {
  __typename?: "UserNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: "UserNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
};

export type UserSignatureNode = Node & {
  __typename?: "UserSignatureNode";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file?: Maybe<Scalars["String"]>;
  /** The ID of the object */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  user: UserNode;
  uuid: Scalars["UUID"];
};

export type UserSignatureNodeConnection = {
  __typename?: "UserSignatureNodeConnection";
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserSignatureNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserSignatureNode` and its cursor. */
export type UserSignatureNodeEdge = {
  __typename?: "UserSignatureNodeEdge";
  /** A cursor for use in pagination */
  cursor: Scalars["String"];
  /** The item at the end of the edge */
  node?: Maybe<UserSignatureNode>;
};

export type UserSignatureType = {
  __typename?: "UserSignatureType";
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  deleted?: Maybe<Scalars["DateTime"]>;
  deletedByCascade: Scalars["Boolean"];
  file: Scalars["String"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  user: UserNode;
  uuid: Scalars["UUID"];
};

export type UserType = {
  __typename?: "UserType";
  certificateSet: CertificateNodeConnection;
  country?: Maybe<Country>;
  createdAt: Scalars["DateTime"];
  createdBy?: Maybe<UserNode>;
  createdbaseMediaRelated: MediaNodeConnection;
  createdbaseOfficeRelated: OfficeNodeConnection;
  createdbaseRegistryRelated: RegistryNodeConnection;
  createdmanagementAssetRelated: AssetNodeConnection;
  createdmanagementCollectionRelated: CollectionNodeConnection;
  createdmanagementExhibitionRelated: ExhibitionNodeConnection;
  createdmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  createdspinCertificateRelated: CertificateNodeConnection;
  createdspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  createdspinPolicyRelated: PolicyNodeConnection;
  createdspinPolicyassetRelated: PolicyAssetNodeConnection;
  createdspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  createdusersUserRelated: UserNodeConnection;
  createdusersUsersignatureRelated: UserSignatureNodeConnection;
  currency?: Maybe<Scalars["String"]>;
  dateJoined: Scalars["DateTime"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars["Boolean"];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars["Boolean"];
  isSuperuser: Scalars["Boolean"];
  language?: Maybe<Scalars["String"]>;
  lastLogin?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  password: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  policyNotesUser: PolicyNoteNodeConnection;
  resetPasswordToken?: Maybe<Scalars["String"]>;
  resetTokenExpiry?: Maybe<Scalars["DateTime"]>;
  timezone?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
  updatedBy?: Maybe<UserNode>;
  updatedbaseMediaRelated: MediaNodeConnection;
  updatedbaseOfficeRelated: OfficeNodeConnection;
  updatedbaseRegistryRelated: RegistryNodeConnection;
  updatedmanagementAssetRelated: AssetNodeConnection;
  updatedmanagementCollectionRelated: CollectionNodeConnection;
  updatedmanagementExhibitionRelated: ExhibitionNodeConnection;
  updatedmanagementInsuranceRelated: FilteredInsuranceNodeConnection;
  updatedspinCertificateRelated: CertificateNodeConnection;
  updatedspinCertificatetemplateRelated: CertificateTemplateNodeConnection;
  updatedspinPolicyRelated: PolicyNodeConnection;
  updatedspinPolicyassetRelated: PolicyAssetNodeConnection;
  updatedspinPolicydocumentRelated: PolicyDocumentNodeConnection;
  updatedusersUserRelated: UserNodeConnection;
  updatedusersUsersignatureRelated: UserSignatureNodeConnection;
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  userType?: Maybe<UsersUserUserTypeChoices>;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars["String"];
  usersignatureSet: UserSignatureNodeConnection;
  uuid: Scalars["UUID"];
};

export type UserTypeCertificateSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeCreatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeCreatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypePolicyNotesUserArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseMediaRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseOfficeRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedbaseRegistryRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementAssetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementCollectionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementExhibitionRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedmanagementInsuranceRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  note?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["String"]>;
  policyNumber?: InputMaybe<Scalars["String"]>;
};

export type UserTypeUpdatedspinCertificateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinCertificatetemplateRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicyassetRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedspinPolicydocumentRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUserRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUpdatedusersUsersignatureRelatedArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type UserTypeUsersignatureSetArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** An enumeration. */
export enum UsersUserUserTypeChoices {
  /** Default */
  Default = "DEFAULT",
  /** External */
  External = "EXTERNAL",
  /** Internal */
  Internal = "INTERNAL",
}

export type Verify = {
  __typename?: "Verify";
  payload: Scalars["GenericScalar"];
};

export type AssetFieldsFragment = {
  __typename: "AssetNode";
  id: string;
  deleted?: string | null;
  deletedByCascade: boolean;
  createdAt: string;
  updatedAt: string;
  uuid: string;
  netWeightUnit?: ManagementAssetNetWeightUnitChoices | null;
  grossWeightUnit?: ManagementAssetGrossWeightUnitChoices | null;
  netLengthUnit?: ManagementAssetNetLengthUnitChoices | null;
  grossLengthUnit?: ManagementAssetGrossLengthUnitChoices | null;
  netHeight?: number | null;
  grossHeight?: number | null;
  netWidth?: number | null;
  grossWidth?: number | null;
  netDepth?: number | null;
  grossDepth?: number | null;
  netDiameter?: number | null;
  grossDiameter?: number | null;
  netWeight?: number | null;
  grossWeight?: number | null;
  netDimensionsAcquisition?: ManagementAssetNetDimensionsAcquisitionChoices | null;
  grossDimensionsAcquisition?: ManagementAssetGrossDimensionsAcquisitionChoices | null;
  dimensionsNotes?: string | null;
  audioVideoLengthUnit?: ManagementAssetAudioVideoLengthUnitChoices | null;
  audioVideoLength?: number | null;
  fileSizeUnit?: ManagementAssetFileSizeUnitChoices | null;
  fileSize?: number | null;
  resolutionUnit?: ManagementAssetResolutionUnitChoices | null;
  resolution?: number | null;
  optimalLightLevelIntensity?: number | null;
  lightUnit?: ManagementAssetLightUnitChoices | null;
  optimalTemperature?: number | null;
  temperatureUnit?: ManagementAssetTemperatureUnitChoices | null;
  optimalHumidity?: number | null;
  enviromentalConditionsNotes?: string | null;
  isNotifiedCulturalProperty?: boolean | null;
  notifiedPropertyNote?: string | null;
  legalStatus?: string | null;
  copyrightAndPropertyRestrictions?: string | null;
  hasBarriers?: boolean | null;
  barriersDetails?: string | null;
  handlingRequirements?: string | null;
  injuryRisk?: ManagementAssetInjuryRiskChoices | null;
  injuryRiskDescription?: string | null;
  mouldingSupportBase?: string | null;
  hardwareToolsInstallation?: string | null;
  conservation?: string | null;
  lenderRequests?: string | null;
  settingNotes?: string | null;
  infoNotes?: string | null;
  isArtistProof?: boolean | null;
  artistProofNotes?: string | null;
  isFirstDraft?: boolean | null;
  firstDraftNotes?: string | null;
  isEdition?: boolean | null;
  editionNotes?: string | null;
  isMaster: boolean;
  masterLocation?: string | null;
  isArtMultiple?: boolean | null;
  multipleNumber?: string | null;
  isSignedObject?: boolean | null;
  signedObjectNotes?: string | null;
  isTitledObject?: boolean | null;
  titledObjectNotes?: string | null;
  isDatedObject?: boolean | null;
  datedObjectNotes?: string | null;
  hasLabelsStamps?: boolean | null;
  labelsStampsNotes?: string | null;
  hasOtherAutographed?: boolean | null;
  otherAutographedNotes?: string | null;
  relevantAwards?: string | null;
  mechanism?: string | null;
  carat?: string | null;
  isDynamic?: boolean | null;
  hasHumanAnimalPresence?: boolean | null;
  humanAnimalPresenceNotes?: string | null;
  isAudio?: boolean | null;
  isVideo?: boolean | null;
  hasLoop: boolean;
  coloring?: string | null;
  variants?: string | null;
  nominalValue?: number | null;
  fiscalValue?: number | null;
  currentValue?: number | null;
  currency?: string | null;
  score?: string | null;
  circulation?: string | null;
  manufactoringTechniques?: string | null;
  mintingLastDate?: any | null;
  mintingLastDateNotes?: string | null;
  reminting?: string | null;
  isComposed?: boolean | null;
  numberOfElements?: number | null;
  surfaceCharacteristics?: string | null;
  hasBase?: boolean | null;
  baseDetails?: string | null;
  hasWoodFrame?: boolean | null;
  woodFrameDetails?: string | null;
  hasFrame?: boolean | null;
  hasOriginalFrame?: boolean | null;
  frameMaterial: ManagementAssetFrameMaterialChoices;
  isValuableFrame?: boolean | null;
  frameManufacturer?: string | null;
  frameDescription?: string | null;
  compositionDescription?: string | null;
  usage?: string | null;
  finishing?: string | null;
  hasContainer?: boolean | null;
  containerDetails?: string | null;
  replacedParts?: string | null;
  methodOfUse?: string | null;
  relatedPhysicalWork?: string | null;
  bookContainer?: string | null;
  dustJacket?: string | null;
  frontCover?: string | null;
  rearCover?: string | null;
  topEdge?: string | null;
  foreEdge?: string | null;
  footEdge?: string | null;
  bookbinding?: string | null;
  interiorDecorations?: string | null;
  externalDecorations?: string | null;
  flyleaf?: string | null;
  glueing?: string | null;
  headband?: string | null;
  headcap?: string | null;
  corner?: string | null;
  square?: string | null;
  slots?: string | null;
  spine?: string | null;
  clips?: string | null;
  raisedBands?: string | null;
  label?: string | null;
  clothCovering?: string | null;
  distribution?: string | null;
  edge?: string | null;
  orientation?: string | null;
  shape?: string | null;
  rim?: string | null;
  rotationAxes?: string | null;
  legend?: string | null;
  fineness?: string | null;
  mintMadeErrors?: string | null;
  signings?: string | null;
  relief?: string | null;
  watermark?: string | null;
  holograficStripe?: string | null;
  securityThread?: string | null;
  printingErrors?: string | null;
  conservationMethod?: string | null;
  color?: string | null;
  postageMark?: string | null;
  rubber?: string | null;
  stampPrintingTechnique: ManagementAssetStampPrintingTechniqueChoices;
  stampRealization: ManagementAssetStampRealizationChoices;
  stampIndentationType: ManagementAssetStampIndentationTypeChoices;
  stampCenteringAndMargins: ManagementAssetStampCenteringAndMarginsChoices;
  stampFormat: ManagementAssetStampFormatChoices;
  discoveryPlace?: string | null;
  discoverySite?: string | null;
  discoveryDate?: any | null;
  discoveryNotes?: string | null;
  discoveryAuthor?: string | null;
  catalogingAuthor?: string | null;
  objectSetupAuthor?: string | null;
  originNotes?: string | null;
  bibliographyNotes?: string | null;
  carryingValueOrigin?: string | null;
  carryingValueAcquisition?: ManagementAssetCarryingValueAcquisitionChoices | null;
  carryingValueNotes?: string | null;
  carryingValueLastAcquisitionDate?: any | null;
  carryingValueAmountCurrency: ManagementAssetCarryingValueAmountCurrencyChoices;
  carryingValueFileTitle?: string | null;
  carryingValueFile?: string | null;
  title: string;
  creationDate?: string | null;
  category: ManagementAssetCategoryChoices;
  subcategory: ManagementAssetSubcategoryChoices;
  author: string;
  sku?: string | null;
  series?: string | null;
  seriesDetails?: string | null;
  hasPeriod: boolean;
  hasYear: boolean;
  hasFullDatetime: boolean;
  datingType?: ManagementAssetDatingTypeChoices | null;
  creationPeriod?: string | null;
  isDateInterval: boolean;
  dateStartDate?: any | null;
  dateEndDate?: any | null;
  isDateStartDateBc: boolean;
  isDateEndDateBc: boolean;
  isDateApprox: boolean;
  isYearInterval: boolean;
  yearStartDate?: number | null;
  yearEndDate?: number | null;
  isYearStartDateBc: boolean;
  isYearEndDateBc: boolean;
  isYearApprox: boolean;
  isCenturyInterval: boolean;
  centuryStartDate?: number | null;
  centuryEndDate?: number | null;
  isCenturyStartDateBc: boolean;
  isCenturyEndDateBc: boolean;
  isCenturyApprox: boolean;
  centuryStartDateWindow?: ManagementAssetCenturyStartDateWindowChoices | null;
  centuryEndDateWindow?: ManagementAssetCenturyEndDateWindowChoices | null;
  nft?: boolean | null;
  nftNotes?: string | null;
  description?: string | null;
  inventoryNumber?: string | null;
  archiveNumber?: string | null;
  catalogNumber?: string | null;
  criticalCatalogue: boolean;
  technique?: string | null;
  genericDimensions?: string | null;
  validated: boolean;
  createdBy?: { __typename?: "UserNode"; id: string } | null;
  updatedBy?: { __typename?: "UserNode"; id: string } | null;
  carryingValueAmount?: {
    __typename?: "Money";
    amount: number;
    currency: { __typename?: "Currency"; code: string };
  } | null;
  authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
  collection: {
    __typename?: "CollectionNodeConnection";
    edges: Array<{
      __typename?: "CollectionNodeEdge";
      node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
    } | null>;
  };
  exhibition: {
    __typename?: "ExhibitionNodeConnection";
    edges: Array<{
      __typename?: "ExhibitionNodeEdge";
      node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
    } | null>;
  };
  media: {
    __typename?: "MediaNodeConnection";
    edges: Array<{
      __typename?: "MediaNodeEdge";
      node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
    } | null>;
  };
  physicalFeaturesElements: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: { __typename?: "AssetElementNode"; id: string; name: string } | null;
    } | null>;
  };
  presentValues: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        amount: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        };
      } | null;
    } | null>;
  };
  policyAsset: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type GetAllAssetsByTitleQueryVariables = Exact<{
  title_Contains?: InputMaybe<Scalars["String"]>;
}>;

export type GetAllAssetsByTitleQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        author: string;
        creationDate?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetByIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename: "AssetNode";
    id: string;
    deleted?: string | null;
    deletedByCascade: boolean;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    netWeightUnit?: ManagementAssetNetWeightUnitChoices | null;
    grossWeightUnit?: ManagementAssetGrossWeightUnitChoices | null;
    netLengthUnit?: ManagementAssetNetLengthUnitChoices | null;
    grossLengthUnit?: ManagementAssetGrossLengthUnitChoices | null;
    netHeight?: number | null;
    grossHeight?: number | null;
    netWidth?: number | null;
    grossWidth?: number | null;
    netDepth?: number | null;
    grossDepth?: number | null;
    netDiameter?: number | null;
    grossDiameter?: number | null;
    netWeight?: number | null;
    grossWeight?: number | null;
    netDimensionsAcquisition?: ManagementAssetNetDimensionsAcquisitionChoices | null;
    grossDimensionsAcquisition?: ManagementAssetGrossDimensionsAcquisitionChoices | null;
    dimensionsNotes?: string | null;
    audioVideoLengthUnit?: ManagementAssetAudioVideoLengthUnitChoices | null;
    audioVideoLength?: number | null;
    fileSizeUnit?: ManagementAssetFileSizeUnitChoices | null;
    fileSize?: number | null;
    resolutionUnit?: ManagementAssetResolutionUnitChoices | null;
    resolution?: number | null;
    optimalLightLevelIntensity?: number | null;
    lightUnit?: ManagementAssetLightUnitChoices | null;
    optimalTemperature?: number | null;
    temperatureUnit?: ManagementAssetTemperatureUnitChoices | null;
    optimalHumidity?: number | null;
    enviromentalConditionsNotes?: string | null;
    isNotifiedCulturalProperty?: boolean | null;
    notifiedPropertyNote?: string | null;
    legalStatus?: string | null;
    copyrightAndPropertyRestrictions?: string | null;
    hasBarriers?: boolean | null;
    barriersDetails?: string | null;
    handlingRequirements?: string | null;
    injuryRisk?: ManagementAssetInjuryRiskChoices | null;
    injuryRiskDescription?: string | null;
    mouldingSupportBase?: string | null;
    hardwareToolsInstallation?: string | null;
    conservation?: string | null;
    lenderRequests?: string | null;
    settingNotes?: string | null;
    infoNotes?: string | null;
    isArtistProof?: boolean | null;
    artistProofNotes?: string | null;
    isFirstDraft?: boolean | null;
    firstDraftNotes?: string | null;
    isEdition?: boolean | null;
    editionNotes?: string | null;
    isMaster: boolean;
    masterLocation?: string | null;
    isArtMultiple?: boolean | null;
    multipleNumber?: string | null;
    isSignedObject?: boolean | null;
    signedObjectNotes?: string | null;
    isTitledObject?: boolean | null;
    titledObjectNotes?: string | null;
    isDatedObject?: boolean | null;
    datedObjectNotes?: string | null;
    hasLabelsStamps?: boolean | null;
    labelsStampsNotes?: string | null;
    hasOtherAutographed?: boolean | null;
    otherAutographedNotes?: string | null;
    relevantAwards?: string | null;
    mechanism?: string | null;
    carat?: string | null;
    isDynamic?: boolean | null;
    hasHumanAnimalPresence?: boolean | null;
    humanAnimalPresenceNotes?: string | null;
    isAudio?: boolean | null;
    isVideo?: boolean | null;
    hasLoop: boolean;
    coloring?: string | null;
    variants?: string | null;
    nominalValue?: number | null;
    fiscalValue?: number | null;
    currentValue?: number | null;
    currency?: string | null;
    score?: string | null;
    circulation?: string | null;
    manufactoringTechniques?: string | null;
    mintingLastDate?: any | null;
    mintingLastDateNotes?: string | null;
    reminting?: string | null;
    isComposed?: boolean | null;
    numberOfElements?: number | null;
    surfaceCharacteristics?: string | null;
    hasBase?: boolean | null;
    baseDetails?: string | null;
    hasWoodFrame?: boolean | null;
    woodFrameDetails?: string | null;
    hasFrame?: boolean | null;
    hasOriginalFrame?: boolean | null;
    frameMaterial: ManagementAssetFrameMaterialChoices;
    isValuableFrame?: boolean | null;
    frameManufacturer?: string | null;
    frameDescription?: string | null;
    compositionDescription?: string | null;
    usage?: string | null;
    finishing?: string | null;
    hasContainer?: boolean | null;
    containerDetails?: string | null;
    replacedParts?: string | null;
    methodOfUse?: string | null;
    relatedPhysicalWork?: string | null;
    bookContainer?: string | null;
    dustJacket?: string | null;
    frontCover?: string | null;
    rearCover?: string | null;
    topEdge?: string | null;
    foreEdge?: string | null;
    footEdge?: string | null;
    bookbinding?: string | null;
    interiorDecorations?: string | null;
    externalDecorations?: string | null;
    flyleaf?: string | null;
    glueing?: string | null;
    headband?: string | null;
    headcap?: string | null;
    corner?: string | null;
    square?: string | null;
    slots?: string | null;
    spine?: string | null;
    clips?: string | null;
    raisedBands?: string | null;
    label?: string | null;
    clothCovering?: string | null;
    distribution?: string | null;
    edge?: string | null;
    orientation?: string | null;
    shape?: string | null;
    rim?: string | null;
    rotationAxes?: string | null;
    legend?: string | null;
    fineness?: string | null;
    mintMadeErrors?: string | null;
    signings?: string | null;
    relief?: string | null;
    watermark?: string | null;
    holograficStripe?: string | null;
    securityThread?: string | null;
    printingErrors?: string | null;
    conservationMethod?: string | null;
    color?: string | null;
    postageMark?: string | null;
    rubber?: string | null;
    stampPrintingTechnique: ManagementAssetStampPrintingTechniqueChoices;
    stampRealization: ManagementAssetStampRealizationChoices;
    stampIndentationType: ManagementAssetStampIndentationTypeChoices;
    stampCenteringAndMargins: ManagementAssetStampCenteringAndMarginsChoices;
    stampFormat: ManagementAssetStampFormatChoices;
    discoveryPlace?: string | null;
    discoverySite?: string | null;
    discoveryDate?: any | null;
    discoveryNotes?: string | null;
    discoveryAuthor?: string | null;
    catalogingAuthor?: string | null;
    objectSetupAuthor?: string | null;
    originNotes?: string | null;
    bibliographyNotes?: string | null;
    carryingValueOrigin?: string | null;
    carryingValueAcquisition?: ManagementAssetCarryingValueAcquisitionChoices | null;
    carryingValueNotes?: string | null;
    carryingValueLastAcquisitionDate?: any | null;
    carryingValueAmountCurrency: ManagementAssetCarryingValueAmountCurrencyChoices;
    carryingValueFileTitle?: string | null;
    carryingValueFile?: string | null;
    title: string;
    creationDate?: string | null;
    category: ManagementAssetCategoryChoices;
    subcategory: ManagementAssetSubcategoryChoices;
    author: string;
    sku?: string | null;
    series?: string | null;
    seriesDetails?: string | null;
    hasPeriod: boolean;
    hasYear: boolean;
    hasFullDatetime: boolean;
    datingType?: ManagementAssetDatingTypeChoices | null;
    creationPeriod?: string | null;
    isDateInterval: boolean;
    dateStartDate?: any | null;
    dateEndDate?: any | null;
    isDateStartDateBc: boolean;
    isDateEndDateBc: boolean;
    isDateApprox: boolean;
    isYearInterval: boolean;
    yearStartDate?: number | null;
    yearEndDate?: number | null;
    isYearStartDateBc: boolean;
    isYearEndDateBc: boolean;
    isYearApprox: boolean;
    isCenturyInterval: boolean;
    centuryStartDate?: number | null;
    centuryEndDate?: number | null;
    isCenturyStartDateBc: boolean;
    isCenturyEndDateBc: boolean;
    isCenturyApprox: boolean;
    centuryStartDateWindow?: ManagementAssetCenturyStartDateWindowChoices | null;
    centuryEndDateWindow?: ManagementAssetCenturyEndDateWindowChoices | null;
    nft?: boolean | null;
    nftNotes?: string | null;
    description?: string | null;
    inventoryNumber?: string | null;
    archiveNumber?: string | null;
    catalogNumber?: string | null;
    criticalCatalogue: boolean;
    technique?: string | null;
    genericDimensions?: string | null;
    validated: boolean;
    createdBy?: { __typename?: "UserNode"; id: string } | null;
    updatedBy?: { __typename?: "UserNode"; id: string } | null;
    carryingValueAmount?: {
      __typename?: "Money";
      amount: number;
      currency: { __typename?: "Currency"; code: string };
    } | null;
    authorEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    collection: {
      __typename?: "CollectionNodeConnection";
      edges: Array<{
        __typename?: "CollectionNodeEdge";
        node?: { __typename?: "CollectionNode"; id: string; name: string } | null;
      } | null>;
    };
    exhibition: {
      __typename?: "ExhibitionNodeConnection";
      edges: Array<{
        __typename?: "ExhibitionNodeEdge";
        node?: { __typename?: "ExhibitionNode"; id: string; name: string } | null;
      } | null>;
    };
    media: {
      __typename?: "MediaNodeConnection";
      edges: Array<{
        __typename?: "MediaNodeEdge";
        node?: { __typename?: "MediaNode"; id: string; name?: string | null } | null;
      } | null>;
    };
    physicalFeaturesElements: {
      __typename?: "AssetElementNodeConnection";
      edges: Array<{
        __typename?: "AssetElementNodeEdge";
        node?: { __typename?: "AssetElementNode"; id: string; name: string } | null;
      } | null>;
    };
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          id: string;
          amount: {
            __typename?: "Money";
            amount: number;
            currency: { __typename?: "Currency"; code: string };
          };
        } | null;
      } | null>;
    };
    policyAsset: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: { __typename?: "PolicyAssetNode"; id: string } | null;
      } | null>;
    };
  } | null;
};

export type GetAllAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAssetsQuery = {
  __typename?: "Query";
  allAssets?: {
    __typename?: "AssetNodeConnection";
    edges: Array<{
      __typename?: "AssetNodeEdge";
      node?: {
        __typename?: "AssetNode";
        id: string;
        title: string;
        category: ManagementAssetCategoryChoices;
        subcategory: ManagementAssetSubcategoryChoices;
        author: string;
        inventoryNumber?: string | null;
        catalogNumber?: string | null;
        archiveNumber?: string | null;
        description?: string | null;
        creationDate?: string | null;
        netHeight?: number | null;
        netWidth?: number | null;
        netDepth?: number | null;
        sku?: string | null;
        nft?: boolean | null;
        netLengthUnit?: ManagementAssetNetLengthUnitChoices | null;
        deleted?: string | null;
        validated: boolean;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetMutationVariables = Exact<{
  input: CreateAssetInput;
}>;

export type CreateAssetMutation = {
  __typename?: "Mutation";
  createAsset?: {
    __typename?: "CreateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author: string;
      creationPeriod?: string | null;
    } | null;
  } | null;
};

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAssetInput;
}>;

export type UpdateAssetMutation = {
  __typename?: "Mutation";
  updateAsset?: {
    __typename?: "UpdateAssetPayload";
    asset?: {
      __typename?: "AssetNode";
      id: string;
      title: string;
      author: string;
      creationPeriod?: string | null;
    } | null;
  } | null;
};

export type DeleteAssetMutationVariables = Exact<{
  input: DeleteAssetInput;
}>;

export type DeleteAssetMutation = {
  __typename?: "Mutation";
  deleteAsset?: {
    __typename?: "DeleteAssetPayload";
    asset?: { __typename?: "AssetNode"; id: string } | null;
  } | null;
};

export type GetAssetElementsByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetElementsByAssetIdQuery = {
  __typename?: "Query";
  allAssetElements?: {
    __typename?: "AssetElementNodeConnection";
    edges: Array<{
      __typename?: "AssetElementNodeEdge";
      node?: {
        __typename?: "AssetElementNode";
        id: string;
        name: string;
        description?: string | null;
        notes?: string | null;
        materials?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAssetPresentValuesByAssetIdQueryVariables = Exact<{
  assetId: Scalars["ID"];
}>;

export type GetAssetPresentValuesByAssetIdQuery = {
  __typename?: "Query";
  allAssetPresentValues?: {
    __typename?: "AssetPresentValueNodeConnection";
    edges: Array<{
      __typename?: "AssetPresentValueNodeEdge";
      node?: {
        __typename?: "AssetPresentValueNode";
        id: string;
        estimateDate?: any | null;
        estimateReason: ManagementAssetPresentValueEstimateReasonChoices;
        notes?: string | null;
        fileTitle?: string | null;
        file?: string | null;
        amount: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        };
        author?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type CreateAssetElementMutationVariables = Exact<{
  input: CreateAssetElementInput;
}>;

export type CreateAssetElementMutation = {
  __typename?: "Mutation";
  createAssetElement?: {
    __typename?: "CreateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type CreateAssetPresentValueMutationVariables = Exact<{
  input: CreateAssetPresentValueInput;
}>;

export type CreateAssetPresentValueMutation = {
  __typename?: "Mutation";
  createAssetPresentValue?: {
    __typename?: "CreateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type UpdateAssetElementMutationVariables = Exact<{
  input: UpdateAssetElementInput;
}>;

export type UpdateAssetElementMutation = {
  __typename?: "Mutation";
  updateAssetElement?: {
    __typename?: "UpdateAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type UpdateAssetPresentValueMutationVariables = Exact<{
  input: UpdateAssetPresentValueInput;
}>;

export type UpdateAssetPresentValueMutation = {
  __typename?: "Mutation";
  updateAssetPresentValue?: {
    __typename?: "UpdateAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type DeleteAssetElementMutationVariables = Exact<{
  input: DeleteAssetElementInput;
}>;

export type DeleteAssetElementMutation = {
  __typename?: "Mutation";
  deleteAssetElement?: {
    __typename?: "DeleteAssetElementPayload";
    assetElement?: { __typename?: "AssetElementNode"; id: string } | null;
  } | null;
};

export type DeleteAssetPresentValueMutationVariables = Exact<{
  input: DeleteAssetPresentValueInput;
}>;

export type DeleteAssetPresentValueMutation = {
  __typename?: "Mutation";
  deleteAssetPresentValue?: {
    __typename?: "DeleteAssetPresentValuePayload";
    assetPresentValue?: { __typename?: "AssetPresentValueNode"; id: string } | null;
  } | null;
};

export type GetCurrentClientQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetCurrentClientQuery = {
  __typename?: "Query";
  client?: {
    __typename?: "ClientNode";
    createdOn: any;
    customerType: RbacClientCustomerTypeChoices;
    dashboardBaseUrl: string;
    id: string;
    location: string;
    logo?: string | null;
    name: string;
    onTrial: boolean;
    paidUntil: any;
    schemaName: string;
    storageName: string;
    tier: RbacClientTierChoices;
  } | null;
};

export type PolicyAssetFieldsFragment = {
  __typename?: "PolicyAssetNode";
  catNat: boolean;
  coverTerrorism: boolean;
  coverType: string;
  coveredRiskType: string;
  evaluationType: string;
  exemption: boolean;
  id: string;
  lender?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
  lenderAddress?: { __typename?: "Address"; raw?: string | null } | null;
  shipper?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
  insuredValue?: {
    __typename?: "Money";
    amount: number;
    currency: { __typename?: "Currency"; code: string };
  } | null;
};

export type PolicyCoreFieldsFragment = {
  __typename?: "PolicyNode";
  id: string;
  offerCode: string;
  policyCode: string;
  policyStatus: string;
  issuedAt: string;
  expiresAt: string;
  policyType: string;
  contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
  insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
  broker: { __typename?: "RegistryNode"; fullName?: string | null };
};

export type PolicyAssetOnPolicyFragment = {
  __typename?: "PolicyNode";
  policyAssets: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: { __typename?: "PolicyAssetNode"; id: string } | null;
    } | null>;
  };
};

export type PolicyMoneyFieldsFragment = {
  __typename?: "PolicyNode";
  evaluationType: string;
  totalInsuredPremium?: {
    __typename?: "Money";
    amount: number;
    currency: { __typename?: "Currency"; code: string };
  } | null;
  totalInsuredValue?: {
    __typename?: "Money";
    amount: number;
    currency: { __typename?: "Currency"; code: string };
  } | null;
};

export type PolicyDriverFieldsFragment = {
  __typename?: "PolicyNode";
  catNat: boolean;
  coverTerrorism: boolean;
  coverType: string;
  coveredRiskType: string;
  exemption: boolean;
  hasVariableBasket: boolean;
};

export type GetAllPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPoliciesQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt: string;
        expiresAt: string;
        policyType: string;
        evaluationType: string;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker: { __typename?: "RegistryNode"; fullName?: string | null };
        totalInsuredPremium?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPoliciesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
}>;

export type GetAllPoliciesByTypeQuery = {
  __typename?: "Query";
  allPolicies?: {
    __typename?: "PolicyNodeConnection";
    edges: Array<{
      __typename?: "PolicyNodeEdge";
      node?: {
        __typename?: "PolicyNode";
        isExpired?: boolean | null;
        assetsCount?: number | null;
        hasValidCertificate: boolean;
        id: string;
        offerCode: string;
        policyCode: string;
        policyStatus: string;
        issuedAt: string;
        expiresAt: string;
        policyType: string;
        evaluationType: string;
        createdBy?: {
          __typename?: "UserNode";
          name: string;
          userType?: UsersUserUserTypeChoices | null;
        } | null;
        contractingParty: { __typename?: "RegistryNode"; fullName?: string | null };
        insuranceCompany: { __typename?: "RegistryNode"; fullName?: string | null };
        broker: { __typename?: "RegistryNode"; fullName?: string | null };
        totalInsuredPremium?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
        totalInsuredValue?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetPolicyByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetPolicyByIdQuery = {
  __typename?: "Query";
  policy?: {
    __typename?: "PolicyNode";
    autoRenewal: boolean;
    eventName?: string | null;
    eventFrom?: string | null;
    eventTo?: string | null;
    catNat: boolean;
    hasVariableBasket: boolean;
    coverTerrorism: boolean;
    coverType: string;
    coveredRiskType: string;
    exemption: boolean;
    id: string;
    offerCode: string;
    policyCode: string;
    policyStatus: string;
    issuedAt: string;
    expiresAt: string;
    policyType: string;
    evaluationType: string;
    broker: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    insuranceCompany: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    contractingParty: { __typename?: "RegistryNode"; id: string; fullName?: string | null };
    eventEntity?: { __typename?: "RegistryNode"; id: string; fullName?: string | null } | null;
    eventLocationEntity?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
    policyAssets: {
      __typename?: "PolicyAssetNodeConnection";
      edges: Array<{
        __typename?: "PolicyAssetNodeEdge";
        node?: {
          __typename?: "PolicyAssetNode";
          catNat: boolean;
          coverTerrorism: boolean;
          coverType: string;
          coveredRiskType: string;
          evaluationType: string;
          exemption: boolean;
          id: string;
          asset: {
            __typename?: "AssetNode";
            creationPeriod?: string | null;
            author: string;
            title: string;
            category: ManagementAssetCategoryChoices;
            genericDimensions?: string | null;
            technique?: string | null;
            inventoryNumber?: string | null;
            validated: boolean;
            createdBy?: { __typename?: "UserNode"; name: string } | null;
          };
          objectLocationEntity?: {
            __typename?: "RegistryNode";
            id: string;
            fullName?: string | null;
          } | null;
          objectLocationOffice?: {
            __typename?: "OfficeNode";
            id: string;
            name?: string | null;
          } | null;
          lender?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
          lenderAddress?: { __typename?: "Address"; raw?: string | null } | null;
          shipper?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
          insuredValue?: {
            __typename?: "Money";
            amount: number;
            currency: { __typename?: "Currency"; code: string };
          } | null;
        } | null;
      } | null>;
    };
    policyNotes: {
      __typename?: "PolicyNoteNodeConnection";
      edges: Array<{
        __typename?: "PolicyNoteNodeEdge";
        node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
      } | null>;
    };
    policyDocuments: {
      __typename?: "PolicyDocumentNodeConnection";
      edges: Array<{
        __typename?: "PolicyDocumentNodeEdge";
        node?: { __typename?: "PolicyDocumentNode"; id: string; file?: string | null } | null;
      } | null>;
    };
    totalInsuredPremium?: {
      __typename?: "Money";
      amount: number;
      currency: { __typename?: "Currency"; code: string };
    } | null;
    totalInsuredValue?: {
      __typename?: "Money";
      amount: number;
      currency: { __typename?: "Currency"; code: string };
    } | null;
  } | null;
};

export type GetPolicyNotesByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyNotesByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyNotes?: {
    __typename?: "PolicyNoteNodeConnection";
    edges: Array<{
      __typename?: "PolicyNoteNodeEdge";
      node?: { __typename?: "PolicyNoteNode"; id: string; content?: string | null } | null;
    } | null>;
  } | null;
};

export type GetPolicyDocumentsByPolicyIdQueryVariables = Exact<{
  policyId: Scalars["ID"];
}>;

export type GetPolicyDocumentsByPolicyIdQuery = {
  __typename?: "Query";
  allPolicyDocuments?: {
    __typename?: "PolicyDocumentNodeConnection";
    edges: Array<{
      __typename?: "PolicyDocumentNodeEdge";
      node?: { __typename?: "PolicyDocumentNode"; id: string; file?: string | null } | null;
    } | null>;
  } | null;
};

export type CreatePolicyMutationVariables = Exact<{
  input: CreatePolicyInput;
}>;

export type CreatePolicyMutation = {
  __typename?: "Mutation";
  createPolicy?: {
    __typename?: "CreatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type CreatePolicyAssetMutationVariables = Exact<{
  input: CreatePolicyAssetInput;
}>;

export type CreatePolicyAssetMutation = {
  __typename?: "Mutation";
  createPolicyAsset?: {
    __typename?: "CreatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type CreatePolicyNoteMutationVariables = Exact<{
  input: CreatePolicyNoteInput;
}>;

export type CreatePolicyNoteMutation = {
  __typename?: "Mutation";
  createPolicyNote?: {
    __typename?: "CreatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type CreatePolicyDocumentMutationVariables = Exact<{
  input: CreatePolicyDocumentInput;
}>;

export type CreatePolicyDocumentMutation = {
  __typename?: "Mutation";
  createPolicyDocument?: {
    __typename?: "CreatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyMutationVariables = Exact<{
  input: UpdatePolicyInput;
}>;

export type UpdatePolicyMutation = {
  __typename?: "Mutation";
  updatePolicy?: {
    __typename?: "UpdatePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyAssetMutationVariables = Exact<{
  input: UpdatePolicyAssetInput;
}>;

export type UpdatePolicyAssetMutation = {
  __typename?: "Mutation";
  updatePolicyAsset?: {
    __typename?: "UpdatePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyNoteMutationVariables = Exact<{
  input: UpdatePolicyNoteInput;
}>;

export type UpdatePolicyNoteMutation = {
  __typename?: "Mutation";
  updatePolicyNote?: {
    __typename?: "UpdatePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type UpdatePolicyDocumentMutationVariables = Exact<{
  input: UpdatePolicyDocumentInput;
}>;

export type UpdatePolicyDocumentMutation = {
  __typename?: "Mutation";
  updatePolicyDocument?: {
    __typename?: "UpdatePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type DeletePolicyMutationVariables = Exact<{
  input: DeletePolicyInput;
}>;

export type DeletePolicyMutation = {
  __typename?: "Mutation";
  deletePolicy?: {
    __typename?: "DeletePolicyPayload";
    policy?: { __typename?: "PolicyNode"; id: string } | null;
  } | null;
};

export type DeletePolicyAssetMutationVariables = Exact<{
  input: DeletePolicyAssetInput;
}>;

export type DeletePolicyAssetMutation = {
  __typename?: "Mutation";
  deletePolicyAsset?: {
    __typename?: "DeletePolicyAssetPayload";
    policyAsset?: { __typename?: "PolicyAssetNode"; id: string } | null;
  } | null;
};

export type DeletePolicyNoteMutationVariables = Exact<{
  input: DeletePolicyNoteInput;
}>;

export type DeletePolicyNoteMutation = {
  __typename?: "Mutation";
  deletePolicyNote?: {
    __typename?: "DeletePolicyNotePayload";
    policyNote?: { __typename?: "PolicyNoteNode"; id: string } | null;
  } | null;
};

export type DeletePolicyDocumentMutationVariables = Exact<{
  input: DeletePolicyDocumentInput;
}>;

export type DeletePolicyDocumentMutation = {
  __typename?: "Mutation";
  deletePolicyDocument?: {
    __typename?: "DeletePolicyDocumentPayload";
    policyDocument?: { __typename?: "PolicyDocumentNode"; id: string } | null;
  } | null;
};

export type GenerateCertificateMutationVariables = Exact<{
  input: GenerateCertificateInput;
}>;

export type GenerateCertificateMutation = {
  __typename?: "Mutation";
  generateCertificate?: {
    __typename?: "GenerateCertificatePayload";
    s3Load?: boolean | null;
    filesUrl?: Array<string | null> | null;
  } | null;
};

export type DeleteCertificateMutationVariables = Exact<{
  input: DeleteCertificateInput;
}>;

export type DeleteCertificateMutation = {
  __typename?: "Mutation";
  deleteCertificate?: {
    __typename?: "DeleteCertificatePayload";
    certificate?: { __typename?: "CertificateNode"; id: string } | null;
  } | null;
};

export type SignCertificateMutationVariables = Exact<{
  input: SignCertificateInput;
}>;

export type SignCertificateMutation = {
  __typename?: "Mutation";
  signCertificate?: {
    __typename?: "SignCertificatePayload";
    certificate?: {
      __typename?: "CertificateNode";
      file?: string | null;
      signed: boolean;
      signedAt: string;
      signedBy?: { __typename?: "UserNode"; name: string } | null;
    } | null;
  } | null;
};

export type GetAllCertificatesByPolicyIdAndIsActiveQueryVariables = Exact<{
  policy: Scalars["ID"];
  isValid: Scalars["Boolean"];
}>;

export type GetAllCertificatesByPolicyIdAndIsActiveQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        file?: string | null;
        uuid: string;
        creationDate: string;
        isValid: boolean;
        language: string;
        signed: boolean;
        signedAt: string;
        signedBy?: { __typename?: "UserNode"; id: string; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificatesByTypeQueryVariables = Exact<{
  policyType: Scalars["String"];
}>;

export type GetAllCertificatesByTypeQuery = {
  __typename?: "Query";
  allCertificates?: {
    __typename?: "CertificateNodeConnection";
    edges: Array<{
      __typename?: "CertificateNodeEdge";
      node?: {
        __typename?: "CertificateNode";
        id: string;
        uuid: string;
        certificateType: SpinCertificateCertificateTypeChoices;
        file?: string | null;
        isValid: boolean;
        language: string;
        updatedAt: string;
        createdAt: string;
        progressiveNumber?: number | null;
        creationDate: string;
        lender?: string | null;
        shipper?: string | null;
        signed: boolean;
        policy?: { __typename?: "PolicyNode"; offerCode: string; policyCode: string } | null;
        createdBy?: { __typename?: "UserNode"; name: string } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllCertificateTemplatesByNameQueryVariables = Exact<{
  name: Scalars["String"];
}>;

export type GetAllCertificateTemplatesByNameQuery = {
  __typename?: "Query";
  allCertificateTemplates?: {
    __typename?: "CertificateTemplateNodeConnection";
    edges: Array<{
      __typename?: "CertificateTemplateNodeEdge";
      node?: {
        __typename?: "CertificateTemplateNode";
        id: string;
        file: string;
        name?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPolicyAssetsQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat: boolean;
        coverTerrorism: boolean;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption: boolean;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author: string;
          title: string;
        };
        policy: { __typename?: "PolicyNode"; offerCode: string; policyCode: string };
        lender?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: { __typename?: "Address"; raw?: string | null } | null;
        shipper?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetAllPolicyAssetsByPolicyTypeQueryVariables = Exact<{
  policy_PolicyType: Scalars["String"];
}>;

export type GetAllPolicyAssetsByPolicyTypeQuery = {
  __typename?: "Query";
  allPolicyAssets?: {
    __typename?: "PolicyAssetNodeConnection";
    edges: Array<{
      __typename?: "PolicyAssetNodeEdge";
      node?: {
        __typename?: "PolicyAssetNode";
        catNat: boolean;
        coverTerrorism: boolean;
        coverType: string;
        coveredRiskType: string;
        evaluationType: string;
        exemption: boolean;
        id: string;
        asset: {
          __typename?: "AssetNode";
          creationPeriod?: string | null;
          author: string;
          title: string;
          category: ManagementAssetCategoryChoices;
          genericDimensions?: string | null;
          technique?: string | null;
          inventoryNumber?: string | null;
          validated: boolean;
          createdBy?: { __typename?: "UserNode"; name: string } | null;
        };
        policy: { __typename?: "PolicyNode"; id: string; offerCode: string; policyCode: string };
        lender?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        pickupAddress?: { __typename?: "Address"; raw?: string | null } | null;
        lenderAddress?: { __typename?: "Address"; raw?: string | null } | null;
        shipper?: { __typename?: "RegistryNode"; fullName?: string | null } | null;
        insuredValue?: {
          __typename?: "Money";
          amount: number;
          currency: { __typename?: "Currency"; code: string };
        } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetLastPresentValueByAssetIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetLastPresentValueByAssetIdQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    presentValues: {
      __typename?: "AssetPresentValueNodeConnection";
      edges: Array<{
        __typename?: "AssetPresentValueNodeEdge";
        node?: {
          __typename?: "AssetPresentValueNode";
          amountCurrency: ManagementAssetPresentValueAmountCurrencyChoices;
          amount: { __typename?: "Money"; amount: number };
        } | null;
      } | null>;
    };
  } | null;
};

export type GetAssetCarryingValueQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssetCarryingValueQuery = {
  __typename?: "Query";
  asset?: {
    __typename?: "AssetNode";
    carryingValueAmountCurrency: ManagementAssetCarryingValueAmountCurrencyChoices;
    carryingValueAmount?: { __typename?: "Money"; amount: number } | null;
  } | null;
};

export type RegistryAnagraphicFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  isCompany: boolean;
  fullName?: string | null;
  businessName?: string | null;
  alias?: string | null;
  displayName?: string | null;
  webSite?: string | null;
  note?: string | null;
  countryCode?: string | null;
  taxId?: string | null;
  billingAddress?: string | null;
  printName?: string | null;
  printCode?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  isAuthor?: boolean | null;
  birthDate?: any | null;
  birthPlace?: string | null;
  deathDate?: any | null;
  deathPlace?: string | null;
  activityPeriod?: string | null;
  activityPlace?: string | null;
  artisticMovement?: string | null;
  foundation?: string | null;
  categories?: Array<string | null> | null;
  authorType?: Array<string | null> | null;
};

export type RegistryFindingFieldsFragment = {
  __typename?: "RegistryNode";
  id: string;
  fullName?: string | null;
  businessName?: string | null;
  alias?: string | null;
  displayName?: string | null;
  webSite?: string | null;
  note?: string | null;
};

export type OfficeAnagraphicFieldsFragment = {
  __typename?: "OfficeNode";
  id: string;
  name?: string | null;
};

export type GetAllRegistriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllRegistriesQuery = {
  __typename?: "Query";
  allRegistries?: {
    __typename?: "RegistryNodeConnection";
    edges: Array<{
      __typename?: "RegistryNodeEdge";
      node?: {
        __typename?: "RegistryNode";
        id: string;
        fullName?: string | null;
        businessName?: string | null;
        alias?: string | null;
        displayName?: string | null;
        webSite?: string | null;
        note?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetRegistriesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
}>;

export type GetRegistriesBySearchQuery = {
  __typename?: "Query";
  registries?: Array<{
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    billingAddress?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: any | null;
    birthPlace?: string | null;
    deathDate?: any | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
  } | null> | null;
};

export type GetRegistryByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetRegistryByIdQuery = {
  __typename?: "Query";
  registry?: {
    __typename?: "RegistryNode";
    id: string;
    isCompany: boolean;
    fullName?: string | null;
    businessName?: string | null;
    alias?: string | null;
    displayName?: string | null;
    webSite?: string | null;
    note?: string | null;
    countryCode?: string | null;
    taxId?: string | null;
    billingAddress?: string | null;
    printName?: string | null;
    printCode?: string | null;
    phone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isAuthor?: boolean | null;
    birthDate?: any | null;
    birthPlace?: string | null;
    deathDate?: any | null;
    deathPlace?: string | null;
    activityPeriod?: string | null;
    activityPlace?: string | null;
    artisticMovement?: string | null;
    foundation?: string | null;
    categories?: Array<string | null> | null;
    authorType?: Array<string | null> | null;
  } | null;
};

export type CreateRegistryMutationVariables = Exact<{
  input: CreateRegistryInput;
}>;

export type CreateRegistryMutation = {
  __typename?: "Mutation";
  createRegistry?: {
    __typename?: "CreateRegistryPayload";
    registry?: {
      __typename?: "RegistryNode";
      id: string;
      fullName?: string | null;
      businessName?: string | null;
      alias?: string | null;
      displayName?: string | null;
    } | null;
  } | null;
};

export type UpdateRegistryMutationVariables = Exact<{
  input: UpdateRegistryInput;
}>;

export type UpdateRegistryMutation = {
  __typename?: "Mutation";
  updateRegistry?: {
    __typename?: "UpdateRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type DeleteRegistryMutationVariables = Exact<{
  input: DeleteRegistryInput;
}>;

export type DeleteRegistryMutation = {
  __typename?: "Mutation";
  deleteRegistry?: {
    __typename?: "DeleteRegistryPayload";
    registry?: { __typename?: "RegistryNode"; id: string } | null;
  } | null;
};

export type GetOfficesBySearchQueryVariables = Exact<{
  search: Scalars["String"];
  registryId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetOfficesBySearchQuery = {
  __typename?: "Query";
  offices?: Array<{ __typename?: "OfficeNode"; id: string; name?: string | null } | null> | null;
};

export type GetOfficeByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOfficeByIdQuery = {
  __typename?: "Query";
  office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
};

export type CreateOfficeMutationVariables = Exact<{
  input: CreateOfficeInput;
}>;

export type CreateOfficeMutation = {
  __typename?: "Mutation";
  createOffice?: {
    __typename?: "CreateOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string; name?: string | null } | null;
  } | null;
};

export type UpdateOfficeMutationVariables = Exact<{
  input: UpdateOfficeInput;
}>;

export type UpdateOfficeMutation = {
  __typename?: "Mutation";
  updateOffice?: {
    __typename?: "UpdateOfficePayload";
    office?: { __typename?: "OfficeType"; id: string } | null;
  } | null;
};

export type DeleteOfficeMutationVariables = Exact<{
  input: DeleteOfficeInput;
}>;

export type DeleteOfficeMutation = {
  __typename?: "Mutation";
  deleteOffice?: {
    __typename?: "DeleteOfficePayload";
    office?: { __typename?: "OfficeNode"; id: string } | null;
  } | null;
};

export type GetOfficesByRegistryIdQueryVariables = Exact<{
  registryId: Scalars["ID"];
}>;

export type GetOfficesByRegistryIdQuery = {
  __typename?: "Query";
  allOffices?: {
    __typename?: "OfficeNodeConnection";
    edges: Array<{
      __typename?: "OfficeNodeEdge";
      node?: {
        __typename?: "OfficeNode";
        id: string;
        name?: string | null;
        address?: string | null;
        city?: string | null;
        zip?: string | null;
        state?: string | null;
        country?: string | null;
        main: boolean;
        notActive: boolean;
      } | null;
    } | null>;
  } | null;
};

export type TokenAuthMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type TokenAuthMutation = {
  __typename?: "Mutation";
  tokenAuth?: {
    __typename?: "ObtainJSONWebToken";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
    user?: {
      __typename?: "UserType";
      name: string;
      currency?: string | null;
      dateJoined: string;
      email: string;
      id: string;
      isActive: boolean;
      isStaff: boolean;
      isSuperuser: boolean;
      language?: string | null;
      lastLogin?: string | null;
      phone?: string | null;
      timezone?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      username: string;
      uuid: string;
      country?: { __typename?: "Country"; code?: string | null } | null;
    } | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars["String"];
}>;

export type RefreshTokenMutation = {
  __typename?: "Mutation";
  refreshToken?: {
    __typename?: "Refresh";
    token: string;
    payload: any;
    refreshToken: string;
    refreshExpiresIn: number;
  } | null;
};

export type UserSignatureFieldsFragment = {
  __typename?: "UserNode";
  usersignatureSet: {
    __typename?: "UserSignatureNodeConnection";
    edges: Array<{
      __typename?: "UserSignatureNodeEdge";
      node?: {
        __typename?: "UserSignatureNode";
        createdAt: string;
        deleted?: string | null;
        deletedByCascade: boolean;
        file?: string | null;
        id: string;
        name?: string | null;
        updatedAt: string;
        uuid: string;
      } | null;
    } | null>;
  };
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = {
  __typename?: "Query";
  allUsers?: {
    __typename?: "UserNodeConnection";
    edges: Array<{
      __typename?: "UserNodeEdge";
      node?: {
        __typename?: "UserNode";
        id: string;
        name: string;
        email: string;
        phone?: string | null;
        userType?: UsersUserUserTypeChoices | null;
        isActive: boolean;
        country?: { __typename?: "Country"; code?: string | null } | null;
      } | null;
    } | null>;
  } | null;
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "UserNode";
    id: string;
    lastLogin?: string | null;
    dateJoined: string;
    name: string;
    isActive: boolean;
    email: string;
    userType?: UsersUserUserTypeChoices | null;
    phone?: string | null;
    language?: string | null;
    timezone?: string | null;
    currency?: string | null;
    country?: { __typename?: "Country"; code?: string | null } | null;
    userPermissions: {
      __typename?: "PermissionNodeConnection";
      edges: Array<{
        __typename?: "PermissionNodeEdge";
        node?: {
          __typename?: "PermissionNode";
          id: string;
          codename: string;
          name: string;
          contentType: { __typename?: "ContentTypeNode"; model: string; id: string };
        } | null;
      } | null>;
    };
    usersignatureSet: {
      __typename?: "UserSignatureNodeConnection";
      edges: Array<{
        __typename?: "UserSignatureNodeEdge";
        node?: {
          __typename?: "UserSignatureNode";
          createdAt: string;
          deleted?: string | null;
          deletedByCascade: boolean;
          file?: string | null;
          id: string;
          name?: string | null;
          updatedAt: string;
          uuid: string;
        } | null;
      } | null>;
    };
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword?: {
    __typename?: "ChangePasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: "Mutation";
  createUser?: {
    __typename?: "CreateUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser?: {
    __typename?: "InviteUserPayload";
    user?: { __typename?: "UserNode"; id: string; email: string; name: string } | null;
  } | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "UpdateUserPayload";
    user?: {
      __typename?: "UserNode";
      id: string;
      password: string;
      lastLogin?: string | null;
      username: string;
      dateJoined: string;
      name: string;
      email: string;
      phone?: string | null;
      language?: string | null;
      timezone?: string | null;
      currency?: string | null;
      userType?: UsersUserUserTypeChoices | null;
      isActive: boolean;
      country?: { __typename?: "Country"; code?: string | null } | null;
    } | null;
  } | null;
};

export type RequestUserResetPasswordMutationVariables = Exact<{
  input: RequestUserResetPasswordInput;
}>;

export type RequestUserResetPasswordMutation = {
  __typename?: "Mutation";
  requestUserResetPassword?: {
    __typename?: "RequestUserResetPasswordPayload";
    user?: { __typename?: "UserNode"; resetPasswordToken?: string | null } | null;
  } | null;
};

export type ResetUserPasswordMutationVariables = Exact<{
  input: ResetUserPasswordInput;
}>;

export type ResetUserPasswordMutation = {
  __typename?: "Mutation";
  resetUserPassword?: {
    __typename?: "ResetUserPasswordPayload";
    user?: { __typename?: "UserNode"; email: string } | null;
  } | null;
};

export type GetAllPermissionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPermissionQuery = {
  __typename?: "Query";
  allPermissions?: {
    __typename?: "PermissionNodeConnection";
    edges: Array<{
      __typename?: "PermissionNodeEdge";
      node?: {
        __typename?: "PermissionNode";
        codename: string;
        id: string;
        name: string;
        contentType: { __typename?: "ContentTypeNode"; model: string };
      } | null;
    } | null>;
  } | null;
};

export type UpdateUserPermissionsMutationVariables = Exact<{
  input: UpdateUserPermissionInput;
}>;

export type UpdateUserPermissionsMutation = {
  __typename?: "Mutation";
  updateUserPermissions?: {
    __typename?: "UpdateUserPermissionPayload";
    user?: {
      __typename?: "UserNode";
      userPermissions: {
        __typename?: "PermissionNodeConnection";
        edges: Array<{
          __typename?: "PermissionNodeEdge";
          node?: { __typename?: "PermissionNode"; codename: string; name: string } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type ToggleUserIsActiveMutationVariables = Exact<{
  input: ToggleUserIsActiveInput;
}>;

export type ToggleUserIsActiveMutation = {
  __typename?: "Mutation";
  toggleUserIsactive?: {
    __typename?: "ToggleUserIsActivePayload";
    user?: { __typename?: "UserNode"; isActive: boolean } | null;
  } | null;
};

export type ToggleUserUserTypeMutationVariables = Exact<{
  input: ToggleUserUserTypeInput;
}>;

export type ToggleUserUserTypeMutation = {
  __typename?: "Mutation";
  toggleUserUserType?: {
    __typename?: "ToggleUserUserTypePayload";
    user?: { __typename?: "UserNode"; userType?: UsersUserUserTypeChoices | null } | null;
  } | null;
};

export type AddUserSignatureMutationVariables = Exact<{
  input: AddUserSignatureInput;
}>;

export type AddUserSignatureMutation = {
  __typename?: "Mutation";
  addUserSignature?: {
    __typename?: "AddUserSignaturePayload";
    success?: boolean | null;
    userSignature?: { __typename?: "UserSignatureType"; file: string } | null;
  } | null;
};

export type DeleteUserSignatureMutationVariables = Exact<{
  input: DeleteUserSignatureInput;
}>;

export type DeleteUserSignatureMutation = {
  __typename?: "Mutation";
  deleteUserSignature?: {
    __typename?: "DeleteUserSignaturePayload";
    userSignature?: { __typename?: "UserSignatureNode"; id: string } | null;
  } | null;
};

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser?: {
    __typename?: "DeleteUserPayload";
    user?: { __typename?: "UserNode"; id: string } | null;
  } | null;
};

export const AssetFieldsFragmentDoc = gql`
  fragment AssetFields on AssetNode {
    id
    deleted
    deletedByCascade
    createdAt
    updatedAt
    createdBy {
      id
    }
    updatedBy {
      id
    }
    uuid
    netWeightUnit
    grossWeightUnit
    netLengthUnit
    grossLengthUnit
    netHeight
    grossHeight
    netWidth
    grossWidth
    netDepth
    grossDepth
    netDiameter
    grossDiameter
    netWeight
    grossWeight
    netDimensionsAcquisition
    grossDimensionsAcquisition
    dimensionsNotes
    audioVideoLengthUnit
    audioVideoLength
    fileSizeUnit
    fileSize
    resolutionUnit
    resolution
    optimalLightLevelIntensity
    lightUnit
    optimalTemperature
    temperatureUnit
    optimalHumidity
    enviromentalConditionsNotes
    isNotifiedCulturalProperty
    notifiedPropertyNote
    legalStatus
    copyrightAndPropertyRestrictions
    hasBarriers
    barriersDetails
    handlingRequirements
    injuryRisk
    injuryRiskDescription
    mouldingSupportBase
    hardwareToolsInstallation
    conservation
    lenderRequests
    settingNotes
    infoNotes
    isArtistProof
    artistProofNotes
    isFirstDraft
    firstDraftNotes
    isEdition
    editionNotes
    isMaster
    masterLocation
    isArtMultiple
    multipleNumber
    isSignedObject
    signedObjectNotes
    isTitledObject
    titledObjectNotes
    isDatedObject
    datedObjectNotes
    hasLabelsStamps
    labelsStampsNotes
    hasOtherAutographed
    otherAutographedNotes
    relevantAwards
    mechanism
    carat
    isDynamic
    hasHumanAnimalPresence
    humanAnimalPresenceNotes
    isAudio
    isVideo
    hasLoop
    coloring
    variants
    nominalValue
    fiscalValue
    currentValue
    currency
    score
    circulation
    manufactoringTechniques
    mintingLastDate
    mintingLastDateNotes
    reminting
    isComposed
    numberOfElements
    surfaceCharacteristics
    hasBase
    baseDetails
    hasWoodFrame
    woodFrameDetails
    hasFrame
    hasOriginalFrame
    frameMaterial
    isValuableFrame
    frameManufacturer
    frameDescription
    compositionDescription
    usage
    finishing
    hasContainer
    containerDetails
    replacedParts
    methodOfUse
    relatedPhysicalWork
    bookContainer
    dustJacket
    frontCover
    rearCover
    topEdge
    foreEdge
    footEdge
    bookbinding
    interiorDecorations
    externalDecorations
    flyleaf
    glueing
    headband
    headcap
    corner
    square
    slots
    spine
    clips
    raisedBands
    label
    clothCovering
    distribution
    edge
    orientation
    shape
    rim
    rotationAxes
    legend
    fineness
    mintMadeErrors
    signings
    relief
    watermark
    holograficStripe
    securityThread
    printingErrors
    conservationMethod
    color
    postageMark
    rubber
    stampPrintingTechnique
    stampRealization
    stampIndentationType
    stampCenteringAndMargins
    stampFormat
    discoveryPlace
    discoverySite
    discoveryDate
    discoveryNotes
    discoveryAuthor
    catalogingAuthor
    objectSetupAuthor
    originNotes
    bibliographyNotes
    carryingValueOrigin
    carryingValueAcquisition
    carryingValueNotes
    carryingValueLastAcquisitionDate
    carryingValueAmountCurrency
    carryingValueAmount {
      amount
      currency {
        code
      }
    }
    carryingValueFileTitle
    carryingValueFile
    title
    creationDate
    category
    subcategory
    author
    authorEntity {
      id
      fullName
    }
    sku
    series
    seriesDetails
    hasPeriod
    hasYear
    hasFullDatetime
    datingType
    creationPeriod
    isDateInterval
    dateStartDate
    dateEndDate
    isDateStartDateBc
    isDateEndDateBc
    isDateApprox
    isYearInterval
    yearStartDate
    yearEndDate
    isYearStartDateBc
    isYearEndDateBc
    isYearApprox
    isCenturyInterval
    centuryStartDate
    centuryEndDate
    isCenturyStartDateBc
    isCenturyEndDateBc
    isCenturyApprox
    centuryStartDateWindow
    centuryEndDateWindow
    nft
    nftNotes
    description
    inventoryNumber
    archiveNumber
    catalogNumber
    collection {
      edges {
        node {
          id
          name
        }
      }
    }
    exhibition {
      edges {
        node {
          id
          name
        }
      }
    }
    media {
      edges {
        node {
          id
          name
        }
      }
    }
    criticalCatalogue
    technique
    genericDimensions
    validated
    physicalFeaturesElements {
      edges {
        node {
          id
          name
        }
      }
    }
    presentValues {
      edges {
        node {
          id
          amount {
            amount
            currency {
              code
            }
          }
        }
      }
    }
    policyAsset {
      edges {
        node {
          id
        }
      }
    }
    __typename
  }
`;
export const PolicyAssetFieldsFragmentDoc = gql`
  fragment policyAssetFields on PolicyAssetNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    evaluationType
    exemption
    id
    lender {
      fullName
    }
    pickupAddress {
      raw
    }
    lenderAddress {
      raw
    }
    shipper {
      fullName
    }
    insuredValue {
      currency {
        code
      }
      amount
    }
  }
`;
export const PolicyCoreFieldsFragmentDoc = gql`
  fragment policyCoreFields on PolicyNode {
    id
    offerCode
    policyCode
    policyStatus
    contractingParty {
      fullName
    }
    insuranceCompany {
      fullName
    }
    broker {
      fullName
    }
    issuedAt
    expiresAt
    policyType
  }
`;
export const PolicyAssetOnPolicyFragmentDoc = gql`
  fragment policyAssetOnPolicy on PolicyNode {
    policyAssets {
      edges {
        node {
          id
        }
      }
    }
  }
`;
export const PolicyMoneyFieldsFragmentDoc = gql`
  fragment policyMoneyFields on PolicyNode {
    totalInsuredPremium {
      currency {
        code
      }
      amount
    }
    totalInsuredValue {
      currency {
        code
      }
      amount
    }
    evaluationType
  }
`;
export const PolicyDriverFieldsFragmentDoc = gql`
  fragment policyDriverFields on PolicyNode {
    catNat
    coverTerrorism
    coverType
    coveredRiskType
    exemption
    hasVariableBasket
  }
`;
export const RegistryAnagraphicFieldsFragmentDoc = gql`
  fragment RegistryAnagraphicFields on RegistryNode {
    id
    isCompany
    fullName
    businessName
    alias
    displayName
    webSite
    note
    countryCode
    taxId
    billingAddress
    printName
    printCode
    phone
    mobilePhone
    email
    isAuthor
    birthDate
    birthPlace
    deathDate
    deathPlace
    activityPeriod
    activityPlace
    artisticMovement
    foundation
    categories
    authorType
  }
`;
export const RegistryFindingFieldsFragmentDoc = gql`
  fragment RegistryFindingFields on RegistryNode {
    id
    fullName
    businessName
    alias
    displayName
    webSite
    note
  }
`;
export const OfficeAnagraphicFieldsFragmentDoc = gql`
  fragment OfficeAnagraphicFields on OfficeNode {
    id
    name
  }
`;
export const UserSignatureFieldsFragmentDoc = gql`
  fragment UserSignatureFields on UserNode {
    usersignatureSet {
      edges {
        node {
          createdAt
          deleted
          deletedByCascade
          file
          id
          name
          updatedAt
          uuid
        }
      }
    }
  }
`;
export const GetAllAssetsByTitleDocument = gql`
  query GetAllAssetsByTitle($title_Contains: String) {
    allAssets(title_Contains: $title_Contains) {
      edges {
        node {
          id
          title
          author
          creationDate
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsByTitleQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsByTitleQuery({
 *   variables: {
 *      title_Contains: // value for 'title_Contains'
 *   },
 * });
 */
export function useGetAllAssetsByTitleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export function useGetAllAssetsByTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAssetsByTitleQuery,
    GetAllAssetsByTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsByTitleQuery, GetAllAssetsByTitleQueryVariables>(
    GetAllAssetsByTitleDocument,
    options
  );
}
export type GetAllAssetsByTitleQueryHookResult = ReturnType<typeof useGetAllAssetsByTitleQuery>;
export type GetAllAssetsByTitleLazyQueryHookResult = ReturnType<
  typeof useGetAllAssetsByTitleLazyQuery
>;
export type GetAllAssetsByTitleQueryResult = Apollo.QueryResult<
  GetAllAssetsByTitleQuery,
  GetAllAssetsByTitleQueryVariables
>;
export const GetAssetByIdDocument = gql`
  query GetAssetById($id: ID!) {
    asset(id: $id) {
      ...AssetFields
    }
  }
  ${AssetFieldsFragmentDoc}
`;

/**
 * __useGetAssetByIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export function useGetAssetByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssetByIdQuery, GetAssetByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetByIdQuery, GetAssetByIdQueryVariables>(
    GetAssetByIdDocument,
    options
  );
}
export type GetAssetByIdQueryHookResult = ReturnType<typeof useGetAssetByIdQuery>;
export type GetAssetByIdLazyQueryHookResult = ReturnType<typeof useGetAssetByIdLazyQuery>;
export type GetAssetByIdQueryResult = Apollo.QueryResult<
  GetAssetByIdQuery,
  GetAssetByIdQueryVariables
>;
export const GetAllAssetsDocument = gql`
  query GetAllAssets {
    allAssets {
      edges {
        node {
          id
          title
          category
          subcategory
          author
          inventoryNumber
          catalogNumber
          archiveNumber
          description
          creationDate
          netHeight
          netWidth
          netDepth
          sku
          nft
          netLengthUnit
          deleted
          validated
        }
      }
    }
  }
`;

/**
 * __useGetAllAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export function useGetAllAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  );
}
export type GetAllAssetsQueryHookResult = ReturnType<typeof useGetAllAssetsQuery>;
export type GetAllAssetsLazyQueryHookResult = ReturnType<typeof useGetAllAssetsLazyQuery>;
export type GetAllAssetsQueryResult = Apollo.QueryResult<
  GetAllAssetsQuery,
  GetAllAssetsQueryVariables
>;
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
      }
    }
  }
`;
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options
  );
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>;
export const UpdateAssetDocument = gql`
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      asset {
        id
        title
        author
        creationPeriod
      }
    }
  }
`;
export type UpdateAssetMutationFn = Apollo.MutationFunction<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(
    UpdateAssetDocument,
    options
  );
}
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetMutation,
  UpdateAssetMutationVariables
>;
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      asset {
        id
      }
    }
  }
`;
export type DeleteAssetMutationFn = Apollo.MutationFunction<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(
    DeleteAssetDocument,
    options
  );
}
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>;
export const GetAssetElementsByAssetIdDocument = gql`
  query GetAssetElementsByAssetId($assetId: ID!) {
    allAssetElements(asset: $assetId) {
      edges {
        node {
          id
          name
          description
          notes
          materials
        }
      }
    }
  }
`;

/**
 * __useGetAssetElementsByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetElementsByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetElementsByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetElementsByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetElementsByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetElementsByAssetIdQuery, GetAssetElementsByAssetIdQueryVariables>(
    GetAssetElementsByAssetIdDocument,
    options
  );
}
export function useGetAssetElementsByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetElementsByAssetIdQuery,
    GetAssetElementsByAssetIdQueryVariables
  >(GetAssetElementsByAssetIdDocument, options);
}
export type GetAssetElementsByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdQuery
>;
export type GetAssetElementsByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetElementsByAssetIdLazyQuery
>;
export type GetAssetElementsByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetElementsByAssetIdQuery,
  GetAssetElementsByAssetIdQueryVariables
>;
export const GetAssetPresentValuesByAssetIdDocument = gql`
  query GetAssetPresentValuesByAssetId($assetId: ID!) {
    allAssetPresentValues(asset: $assetId) {
      edges {
        node {
          id
          amount {
            amount
            currency {
              code
            }
          }
          author {
            id
            fullName
          }
          estimateDate
          estimateReason
          notes
          fileTitle
          file
        }
      }
    }
  }
`;

/**
 * __useGetAssetPresentValuesByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetAssetPresentValuesByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPresentValuesByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPresentValuesByAssetIdQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useGetAssetPresentValuesByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export function useGetAssetPresentValuesByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAssetPresentValuesByAssetIdQuery,
    GetAssetPresentValuesByAssetIdQueryVariables
  >(GetAssetPresentValuesByAssetIdDocument, options);
}
export type GetAssetPresentValuesByAssetIdQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdQuery
>;
export type GetAssetPresentValuesByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetAssetPresentValuesByAssetIdLazyQuery
>;
export type GetAssetPresentValuesByAssetIdQueryResult = Apollo.QueryResult<
  GetAssetPresentValuesByAssetIdQuery,
  GetAssetPresentValuesByAssetIdQueryVariables
>;
export const CreateAssetElementDocument = gql`
  mutation CreateAssetElement($input: CreateAssetElementInput!) {
    createAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type CreateAssetElementMutationFn = Apollo.MutationFunction<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;

/**
 * __useCreateAssetElementMutation__
 *
 * To run a mutation, you first call `useCreateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetElementMutation, { data, loading, error }] = useCreateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetElementMutation,
    CreateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssetElementMutation, CreateAssetElementMutationVariables>(
    CreateAssetElementDocument,
    options
  );
}
export type CreateAssetElementMutationHookResult = ReturnType<typeof useCreateAssetElementMutation>;
export type CreateAssetElementMutationResult = Apollo.MutationResult<CreateAssetElementMutation>;
export type CreateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetElementMutation,
  CreateAssetElementMutationVariables
>;
export const CreateAssetPresentValueDocument = gql`
  mutation CreateAssetPresentValue($input: CreateAssetPresentValueInput!) {
    createAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type CreateAssetPresentValueMutationFn = Apollo.MutationFunction<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;

/**
 * __useCreateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useCreateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetPresentValueMutation, { data, loading, error }] = useCreateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAssetPresentValueMutation,
    CreateAssetPresentValueMutationVariables
  >(CreateAssetPresentValueDocument, options);
}
export type CreateAssetPresentValueMutationHookResult = ReturnType<
  typeof useCreateAssetPresentValueMutation
>;
export type CreateAssetPresentValueMutationResult =
  Apollo.MutationResult<CreateAssetPresentValueMutation>;
export type CreateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetPresentValueMutation,
  CreateAssetPresentValueMutationVariables
>;
export const UpdateAssetElementDocument = gql`
  mutation UpdateAssetElement($input: UpdateAssetElementInput!) {
    updateAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type UpdateAssetElementMutationFn = Apollo.MutationFunction<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;

/**
 * __useUpdateAssetElementMutation__
 *
 * To run a mutation, you first call `useUpdateAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetElementMutation, { data, loading, error }] = useUpdateAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetElementMutation,
    UpdateAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssetElementMutation, UpdateAssetElementMutationVariables>(
    UpdateAssetElementDocument,
    options
  );
}
export type UpdateAssetElementMutationHookResult = ReturnType<typeof useUpdateAssetElementMutation>;
export type UpdateAssetElementMutationResult = Apollo.MutationResult<UpdateAssetElementMutation>;
export type UpdateAssetElementMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetElementMutation,
  UpdateAssetElementMutationVariables
>;
export const UpdateAssetPresentValueDocument = gql`
  mutation UpdateAssetPresentValue($input: UpdateAssetPresentValueInput!) {
    updateAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type UpdateAssetPresentValueMutationFn = Apollo.MutationFunction<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;

/**
 * __useUpdateAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useUpdateAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetPresentValueMutation, { data, loading, error }] = useUpdateAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssetPresentValueMutation,
    UpdateAssetPresentValueMutationVariables
  >(UpdateAssetPresentValueDocument, options);
}
export type UpdateAssetPresentValueMutationHookResult = ReturnType<
  typeof useUpdateAssetPresentValueMutation
>;
export type UpdateAssetPresentValueMutationResult =
  Apollo.MutationResult<UpdateAssetPresentValueMutation>;
export type UpdateAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssetPresentValueMutation,
  UpdateAssetPresentValueMutationVariables
>;
export const DeleteAssetElementDocument = gql`
  mutation DeleteAssetElement($input: DeleteAssetElementInput!) {
    deleteAssetElement(input: $input) {
      assetElement {
        id
      }
    }
  }
`;
export type DeleteAssetElementMutationFn = Apollo.MutationFunction<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;

/**
 * __useDeleteAssetElementMutation__
 *
 * To run a mutation, you first call `useDeleteAssetElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetElementMutation, { data, loading, error }] = useDeleteAssetElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetElementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetElementMutation,
    DeleteAssetElementMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssetElementMutation, DeleteAssetElementMutationVariables>(
    DeleteAssetElementDocument,
    options
  );
}
export type DeleteAssetElementMutationHookResult = ReturnType<typeof useDeleteAssetElementMutation>;
export type DeleteAssetElementMutationResult = Apollo.MutationResult<DeleteAssetElementMutation>;
export type DeleteAssetElementMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetElementMutation,
  DeleteAssetElementMutationVariables
>;
export const DeleteAssetPresentValueDocument = gql`
  mutation DeleteAssetPresentValue($input: DeleteAssetPresentValueInput!) {
    deleteAssetPresentValue(input: $input) {
      assetPresentValue {
        id
      }
    }
  }
`;
export type DeleteAssetPresentValueMutationFn = Apollo.MutationFunction<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;

/**
 * __useDeleteAssetPresentValueMutation__
 *
 * To run a mutation, you first call `useDeleteAssetPresentValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetPresentValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetPresentValueMutation, { data, loading, error }] = useDeleteAssetPresentValueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetPresentValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAssetPresentValueMutation,
    DeleteAssetPresentValueMutationVariables
  >(DeleteAssetPresentValueDocument, options);
}
export type DeleteAssetPresentValueMutationHookResult = ReturnType<
  typeof useDeleteAssetPresentValueMutation
>;
export type DeleteAssetPresentValueMutationResult =
  Apollo.MutationResult<DeleteAssetPresentValueMutation>;
export type DeleteAssetPresentValueMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetPresentValueMutation,
  DeleteAssetPresentValueMutationVariables
>;
export const GetCurrentClientDocument = gql`
  query GetCurrentClient($id: ID!) {
    client(id: $id) {
      createdOn
      customerType
      dashboardBaseUrl
      id
      location
      logo
      name
      onTrial
      paidUntil
      schemaName
      storageName
      tier
    }
  }
`;

/**
 * __useGetCurrentClientQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentClientQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export function useGetCurrentClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentClientQuery, GetCurrentClientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export type GetCurrentClientQueryHookResult = ReturnType<typeof useGetCurrentClientQuery>;
export type GetCurrentClientLazyQueryHookResult = ReturnType<typeof useGetCurrentClientLazyQuery>;
export type GetCurrentClientQueryResult = Apollo.QueryResult<
  GetCurrentClientQuery,
  GetCurrentClientQueryVariables
>;
export const GetAllPoliciesDocument = gql`
  query GetAllPolicies {
    allPolicies {
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export function useGetAllPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesQuery, GetAllPoliciesQueryVariables>(
    GetAllPoliciesDocument,
    options
  );
}
export type GetAllPoliciesQueryHookResult = ReturnType<typeof useGetAllPoliciesQuery>;
export type GetAllPoliciesLazyQueryHookResult = ReturnType<typeof useGetAllPoliciesLazyQuery>;
export type GetAllPoliciesQueryResult = Apollo.QueryResult<
  GetAllPoliciesQuery,
  GetAllPoliciesQueryVariables
>;
export const GetAllPoliciesByTypeDocument = gql`
  query GetAllPoliciesByType($policyType: String!) {
    allPolicies(policyType: $policyType) {
      edges {
        node {
          ...policyCoreFields
          ...policyMoneyFields
          createdBy {
            name
            userType
          }
          isExpired
          assetsCount
          hasValidCertificate
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
`;

/**
 * __useGetAllPoliciesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPoliciesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPoliciesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPoliciesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *   },
 * });
 */
export function useGetAllPoliciesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export function useGetAllPoliciesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPoliciesByTypeQuery,
    GetAllPoliciesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPoliciesByTypeQuery, GetAllPoliciesByTypeQueryVariables>(
    GetAllPoliciesByTypeDocument,
    options
  );
}
export type GetAllPoliciesByTypeQueryHookResult = ReturnType<typeof useGetAllPoliciesByTypeQuery>;
export type GetAllPoliciesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPoliciesByTypeLazyQuery
>;
export type GetAllPoliciesByTypeQueryResult = Apollo.QueryResult<
  GetAllPoliciesByTypeQuery,
  GetAllPoliciesByTypeQueryVariables
>;
export const GetPolicyByIdDocument = gql`
  query GetPolicyById($id: ID!) {
    policy(id: $id) {
      ...policyCoreFields
      ...policyMoneyFields
      autoRenewal
      broker {
        id
        fullName
      }
      insuranceCompany {
        id
        fullName
      }
      contractingParty {
        id
        fullName
      }
      eventName
      eventFrom
      eventTo
      eventEntity {
        id
        fullName
      }
      eventLocationEntity {
        id
        name
      }
      catNat
      hasVariableBasket
      coverTerrorism
      coverType
      coveredRiskType
      exemption
      policyAssets {
        edges {
          node {
            asset {
              creationPeriod
              author
              title
              category
              genericDimensions
              technique
              inventoryNumber
              validated
              createdBy {
                name
              }
            }
            ...policyAssetFields
            objectLocationEntity {
              id
              fullName
            }
            objectLocationOffice {
              id
              name
            }
          }
        }
      }
      policyNotes {
        edges {
          node {
            id
            content
          }
        }
      }
      policyDocuments {
        edges {
          node {
            id
            file
          }
        }
      }
    }
  }
  ${PolicyCoreFieldsFragmentDoc}
  ${PolicyMoneyFieldsFragmentDoc}
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetPolicyByIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPolicyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export function useGetPolicyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyByIdQuery, GetPolicyByIdQueryVariables>(
    GetPolicyByIdDocument,
    options
  );
}
export type GetPolicyByIdQueryHookResult = ReturnType<typeof useGetPolicyByIdQuery>;
export type GetPolicyByIdLazyQueryHookResult = ReturnType<typeof useGetPolicyByIdLazyQuery>;
export type GetPolicyByIdQueryResult = Apollo.QueryResult<
  GetPolicyByIdQuery,
  GetPolicyByIdQueryVariables
>;
export const GetPolicyNotesByPolicyIdDocument = gql`
  query GetPolicyNotesByPolicyId($policyId: ID!) {
    allPolicyNotes(policy: $policyId) {
      edges {
        node {
          id
          content
        }
      }
    }
  }
`;

/**
 * __useGetPolicyNotesByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyNotesByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyNotesByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyNotesByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyNotesByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export function useGetPolicyNotesByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyNotesByPolicyIdQuery,
    GetPolicyNotesByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPolicyNotesByPolicyIdQuery, GetPolicyNotesByPolicyIdQueryVariables>(
    GetPolicyNotesByPolicyIdDocument,
    options
  );
}
export type GetPolicyNotesByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdQuery
>;
export type GetPolicyNotesByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyNotesByPolicyIdLazyQuery
>;
export type GetPolicyNotesByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyNotesByPolicyIdQuery,
  GetPolicyNotesByPolicyIdQueryVariables
>;
export const GetPolicyDocumentsByPolicyIdDocument = gql`
  query GetPolicyDocumentsByPolicyId($policyId: ID!) {
    allPolicyDocuments(policy: $policyId) {
      edges {
        node {
          id
          file
        }
      }
    }
  }
`;

/**
 * __useGetPolicyDocumentsByPolicyIdQuery__
 *
 * To run a query within a React component, call `useGetPolicyDocumentsByPolicyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPolicyDocumentsByPolicyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPolicyDocumentsByPolicyIdQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGetPolicyDocumentsByPolicyIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export function useGetPolicyDocumentsByPolicyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPolicyDocumentsByPolicyIdQuery,
    GetPolicyDocumentsByPolicyIdQueryVariables
  >(GetPolicyDocumentsByPolicyIdDocument, options);
}
export type GetPolicyDocumentsByPolicyIdQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdQuery
>;
export type GetPolicyDocumentsByPolicyIdLazyQueryHookResult = ReturnType<
  typeof useGetPolicyDocumentsByPolicyIdLazyQuery
>;
export type GetPolicyDocumentsByPolicyIdQueryResult = Apollo.QueryResult<
  GetPolicyDocumentsByPolicyIdQuery,
  GetPolicyDocumentsByPolicyIdQueryVariables
>;
export const CreatePolicyDocument = gql`
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type CreatePolicyMutationFn = Apollo.MutationFunction<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;

/**
 * __useCreatePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyMutation, { data, loading, error }] = useCreatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePolicyMutation, CreatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyMutation, CreatePolicyMutationVariables>(
    CreatePolicyDocument,
    options
  );
}
export type CreatePolicyMutationHookResult = ReturnType<typeof useCreatePolicyMutation>;
export type CreatePolicyMutationResult = Apollo.MutationResult<CreatePolicyMutation>;
export type CreatePolicyMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyMutation,
  CreatePolicyMutationVariables
>;
export const CreatePolicyAssetDocument = gql`
  mutation CreatePolicyAsset($input: CreatePolicyAssetInput!) {
    createPolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type CreatePolicyAssetMutationFn = Apollo.MutationFunction<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;

/**
 * __useCreatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useCreatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyAssetMutation, { data, loading, error }] = useCreatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyAssetMutation,
    CreatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyAssetMutation, CreatePolicyAssetMutationVariables>(
    CreatePolicyAssetDocument,
    options
  );
}
export type CreatePolicyAssetMutationHookResult = ReturnType<typeof useCreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationResult = Apollo.MutationResult<CreatePolicyAssetMutation>;
export type CreatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyAssetMutation,
  CreatePolicyAssetMutationVariables
>;
export const CreatePolicyNoteDocument = gql`
  mutation CreatePolicyNote($input: CreatePolicyNoteInput!) {
    createPolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type CreatePolicyNoteMutationFn = Apollo.MutationFunction<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;

/**
 * __useCreatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useCreatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyNoteMutation, { data, loading, error }] = useCreatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyNoteMutation,
    CreatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyNoteMutation, CreatePolicyNoteMutationVariables>(
    CreatePolicyNoteDocument,
    options
  );
}
export type CreatePolicyNoteMutationHookResult = ReturnType<typeof useCreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationResult = Apollo.MutationResult<CreatePolicyNoteMutation>;
export type CreatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyNoteMutation,
  CreatePolicyNoteMutationVariables
>;
export const CreatePolicyDocumentDocument = gql`
  mutation CreatePolicyDocument($input: CreatePolicyDocumentInput!) {
    createPolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type CreatePolicyDocumentMutationFn = Apollo.MutationFunction<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;

/**
 * __useCreatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPolicyDocumentMutation, { data, loading, error }] = useCreatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePolicyDocumentMutation,
    CreatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePolicyDocumentMutation, CreatePolicyDocumentMutationVariables>(
    CreatePolicyDocumentDocument,
    options
  );
}
export type CreatePolicyDocumentMutationHookResult = ReturnType<
  typeof useCreatePolicyDocumentMutation
>;
export type CreatePolicyDocumentMutationResult =
  Apollo.MutationResult<CreatePolicyDocumentMutation>;
export type CreatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreatePolicyDocumentMutation,
  CreatePolicyDocumentMutationVariables
>;
export const UpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type UpdatePolicyMutationFn = Apollo.MutationFunction<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;

/**
 * __useUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyMutation, { data, loading, error }] = useUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePolicyMutation, UpdatePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyMutation, UpdatePolicyMutationVariables>(
    UpdatePolicyDocument,
    options
  );
}
export type UpdatePolicyMutationHookResult = ReturnType<typeof useUpdatePolicyMutation>;
export type UpdatePolicyMutationResult = Apollo.MutationResult<UpdatePolicyMutation>;
export type UpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyMutation,
  UpdatePolicyMutationVariables
>;
export const UpdatePolicyAssetDocument = gql`
  mutation UpdatePolicyAsset($input: UpdatePolicyAssetInput!) {
    updatePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type UpdatePolicyAssetMutationFn = Apollo.MutationFunction<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;

/**
 * __useUpdatePolicyAssetMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyAssetMutation, { data, loading, error }] = useUpdatePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyAssetMutation,
    UpdatePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyAssetMutation, UpdatePolicyAssetMutationVariables>(
    UpdatePolicyAssetDocument,
    options
  );
}
export type UpdatePolicyAssetMutationHookResult = ReturnType<typeof useUpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationResult = Apollo.MutationResult<UpdatePolicyAssetMutation>;
export type UpdatePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyAssetMutation,
  UpdatePolicyAssetMutationVariables
>;
export const UpdatePolicyNoteDocument = gql`
  mutation UpdatePolicyNote($input: UpdatePolicyNoteInput!) {
    updatePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type UpdatePolicyNoteMutationFn = Apollo.MutationFunction<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;

/**
 * __useUpdatePolicyNoteMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyNoteMutation, { data, loading, error }] = useUpdatePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyNoteMutation,
    UpdatePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyNoteMutation, UpdatePolicyNoteMutationVariables>(
    UpdatePolicyNoteDocument,
    options
  );
}
export type UpdatePolicyNoteMutationHookResult = ReturnType<typeof useUpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationResult = Apollo.MutationResult<UpdatePolicyNoteMutation>;
export type UpdatePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyNoteMutation,
  UpdatePolicyNoteMutationVariables
>;
export const UpdatePolicyDocumentDocument = gql`
  mutation UpdatePolicyDocument($input: UpdatePolicyDocumentInput!) {
    updatePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type UpdatePolicyDocumentMutationFn = Apollo.MutationFunction<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;

/**
 * __useUpdatePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useUpdatePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePolicyDocumentMutation, { data, loading, error }] = useUpdatePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePolicyDocumentMutation,
    UpdatePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePolicyDocumentMutation, UpdatePolicyDocumentMutationVariables>(
    UpdatePolicyDocumentDocument,
    options
  );
}
export type UpdatePolicyDocumentMutationHookResult = ReturnType<
  typeof useUpdatePolicyDocumentMutation
>;
export type UpdatePolicyDocumentMutationResult =
  Apollo.MutationResult<UpdatePolicyDocumentMutation>;
export type UpdatePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePolicyDocumentMutation,
  UpdatePolicyDocumentMutationVariables
>;
export const DeletePolicyDocument = gql`
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      policy {
        id
      }
    }
  }
`;
export type DeletePolicyMutationFn = Apollo.MutationFunction<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;

/**
 * __useDeletePolicyMutation__
 *
 * To run a mutation, you first call `useDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyMutation, { data, loading, error }] = useDeletePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePolicyMutation, DeletePolicyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyMutation, DeletePolicyMutationVariables>(
    DeletePolicyDocument,
    options
  );
}
export type DeletePolicyMutationHookResult = ReturnType<typeof useDeletePolicyMutation>;
export type DeletePolicyMutationResult = Apollo.MutationResult<DeletePolicyMutation>;
export type DeletePolicyMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyMutation,
  DeletePolicyMutationVariables
>;
export const DeletePolicyAssetDocument = gql`
  mutation DeletePolicyAsset($input: DeletePolicyAssetInput!) {
    deletePolicyAsset(input: $input) {
      policyAsset {
        id
      }
    }
  }
`;
export type DeletePolicyAssetMutationFn = Apollo.MutationFunction<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;

/**
 * __useDeletePolicyAssetMutation__
 *
 * To run a mutation, you first call `useDeletePolicyAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyAssetMutation, { data, loading, error }] = useDeletePolicyAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyAssetMutation,
    DeletePolicyAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyAssetMutation, DeletePolicyAssetMutationVariables>(
    DeletePolicyAssetDocument,
    options
  );
}
export type DeletePolicyAssetMutationHookResult = ReturnType<typeof useDeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationResult = Apollo.MutationResult<DeletePolicyAssetMutation>;
export type DeletePolicyAssetMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyAssetMutation,
  DeletePolicyAssetMutationVariables
>;
export const DeletePolicyNoteDocument = gql`
  mutation DeletePolicyNote($input: DeletePolicyNoteInput!) {
    deletePolicyNote(input: $input) {
      policyNote {
        id
      }
    }
  }
`;
export type DeletePolicyNoteMutationFn = Apollo.MutationFunction<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;

/**
 * __useDeletePolicyNoteMutation__
 *
 * To run a mutation, you first call `useDeletePolicyNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyNoteMutation, { data, loading, error }] = useDeletePolicyNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyNoteMutation,
    DeletePolicyNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyNoteMutation, DeletePolicyNoteMutationVariables>(
    DeletePolicyNoteDocument,
    options
  );
}
export type DeletePolicyNoteMutationHookResult = ReturnType<typeof useDeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationResult = Apollo.MutationResult<DeletePolicyNoteMutation>;
export type DeletePolicyNoteMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyNoteMutation,
  DeletePolicyNoteMutationVariables
>;
export const DeletePolicyDocumentDocument = gql`
  mutation DeletePolicyDocument($input: DeletePolicyDocumentInput!) {
    deletePolicyDocument(input: $input) {
      policyDocument {
        id
      }
    }
  }
`;
export type DeletePolicyDocumentMutationFn = Apollo.MutationFunction<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;

/**
 * __useDeletePolicyDocumentMutation__
 *
 * To run a mutation, you first call `useDeletePolicyDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePolicyDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePolicyDocumentMutation, { data, loading, error }] = useDeletePolicyDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePolicyDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePolicyDocumentMutation,
    DeletePolicyDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePolicyDocumentMutation, DeletePolicyDocumentMutationVariables>(
    DeletePolicyDocumentDocument,
    options
  );
}
export type DeletePolicyDocumentMutationHookResult = ReturnType<
  typeof useDeletePolicyDocumentMutation
>;
export type DeletePolicyDocumentMutationResult =
  Apollo.MutationResult<DeletePolicyDocumentMutation>;
export type DeletePolicyDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeletePolicyDocumentMutation,
  DeletePolicyDocumentMutationVariables
>;
export const GenerateCertificateDocument = gql`
  mutation GenerateCertificate($input: GenerateCertificateInput!) {
    generateCertificate(input: $input) {
      s3Load
      filesUrl
    }
  }
`;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateCertificateMutation,
    GenerateCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(
    GenerateCertificateDocument,
    options
  );
}
export type GenerateCertificateMutationHookResult = ReturnType<
  typeof useGenerateCertificateMutation
>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<
  GenerateCertificateMutation,
  GenerateCertificateMutationVariables
>;
export const DeleteCertificateDocument = gql`
  mutation DeleteCertificate($input: DeleteCertificateInput!) {
    deleteCertificate(input: $input) {
      certificate {
        id
      }
    }
  }
`;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;

/**
 * __useDeleteCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCertificateMutation, { data, loading, error }] = useDeleteCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCertificateMutation,
    DeleteCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCertificateMutation, DeleteCertificateMutationVariables>(
    DeleteCertificateDocument,
    options
  );
}
export type DeleteCertificateMutationHookResult = ReturnType<typeof useDeleteCertificateMutation>;
export type DeleteCertificateMutationResult = Apollo.MutationResult<DeleteCertificateMutation>;
export type DeleteCertificateMutationOptions = Apollo.BaseMutationOptions<
  DeleteCertificateMutation,
  DeleteCertificateMutationVariables
>;
export const SignCertificateDocument = gql`
  mutation SignCertificate($input: SignCertificateInput!) {
    signCertificate(input: $input) {
      certificate {
        file
        signed
        signedAt
        signedBy {
          name
        }
      }
    }
  }
`;
export type SignCertificateMutationFn = Apollo.MutationFunction<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;

/**
 * __useSignCertificateMutation__
 *
 * To run a mutation, you first call `useSignCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signCertificateMutation, { data, loading, error }] = useSignCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignCertificateMutation,
    SignCertificateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignCertificateMutation, SignCertificateMutationVariables>(
    SignCertificateDocument,
    options
  );
}
export type SignCertificateMutationHookResult = ReturnType<typeof useSignCertificateMutation>;
export type SignCertificateMutationResult = Apollo.MutationResult<SignCertificateMutation>;
export type SignCertificateMutationOptions = Apollo.BaseMutationOptions<
  SignCertificateMutation,
  SignCertificateMutationVariables
>;
export const GetAllCertificatesByPolicyIdAndIsActiveDocument = gql`
  query GetAllCertificatesByPolicyIdAndIsActive($policy: ID!, $isValid: Boolean!) {
    allCertificates(policy: $policy, isValid: $isValid) {
      edges {
        node {
          id
          file
          uuid
          creationDate
          isValid
          language
          signed
          signedBy {
            id
            name
          }
          signedAt
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByPolicyIdAndIsActiveQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByPolicyIdAndIsActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByPolicyIdAndIsActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByPolicyIdAndIsActiveQuery({
 *   variables: {
 *      policy: // value for 'policy'
 *      isValid: // value for 'isValid'
 *   },
 * });
 */
export function useGetAllCertificatesByPolicyIdAndIsActiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export function useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificatesByPolicyIdAndIsActiveQuery,
    GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
  >(GetAllCertificatesByPolicyIdAndIsActiveDocument, options);
}
export type GetAllCertificatesByPolicyIdAndIsActiveQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByPolicyIdAndIsActiveLazyQuery
>;
export type GetAllCertificatesByPolicyIdAndIsActiveQueryResult = Apollo.QueryResult<
  GetAllCertificatesByPolicyIdAndIsActiveQuery,
  GetAllCertificatesByPolicyIdAndIsActiveQueryVariables
>;
export const GetAllCertificatesByTypeDocument = gql`
  query GetAllCertificatesByType($policyType: String!) {
    allCertificates(certificateType: $policyType) {
      edges {
        node {
          id
          uuid
          certificateType
          file
          isValid
          language
          updatedAt
          createdAt
          progressiveNumber
          creationDate
          policy {
            offerCode
            policyCode
          }
          lender
          shipper
          signed
          createdBy {
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificatesByTypeQuery__
 *
 * To run a query within a React component, call `useGetAllCertificatesByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificatesByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificatesByTypeQuery({
 *   variables: {
 *      policyType: // value for 'policyType'
 *   },
 * });
 */
export function useGetAllCertificatesByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export function useGetAllCertificatesByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificatesByTypeQuery,
    GetAllCertificatesByTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCertificatesByTypeQuery, GetAllCertificatesByTypeQueryVariables>(
    GetAllCertificatesByTypeDocument,
    options
  );
}
export type GetAllCertificatesByTypeQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeQuery
>;
export type GetAllCertificatesByTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificatesByTypeLazyQuery
>;
export type GetAllCertificatesByTypeQueryResult = Apollo.QueryResult<
  GetAllCertificatesByTypeQuery,
  GetAllCertificatesByTypeQueryVariables
>;
export const GetAllCertificateTemplatesByNameDocument = gql`
  query GetAllCertificateTemplatesByName($name: String!) {
    allCertificateTemplates(name: $name) {
      edges {
        node {
          id
          file
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllCertificateTemplatesByNameQuery__
 *
 * To run a query within a React component, call `useGetAllCertificateTemplatesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCertificateTemplatesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCertificateTemplatesByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAllCertificateTemplatesByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export function useGetAllCertificateTemplatesByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCertificateTemplatesByNameQuery,
    GetAllCertificateTemplatesByNameQueryVariables
  >(GetAllCertificateTemplatesByNameDocument, options);
}
export type GetAllCertificateTemplatesByNameQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameQuery
>;
export type GetAllCertificateTemplatesByNameLazyQueryHookResult = ReturnType<
  typeof useGetAllCertificateTemplatesByNameLazyQuery
>;
export type GetAllCertificateTemplatesByNameQueryResult = Apollo.QueryResult<
  GetAllCertificateTemplatesByNameQuery,
  GetAllCertificateTemplatesByNameQueryVariables
>;
export const GetAllPolicyAssetsDocument = gql`
  query GetAllPolicyAssets {
    allPolicyAssets {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
          }
          ...policyAssetFields
          policy {
            offerCode
            policyCode
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPolicyAssetsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export function useGetAllPolicyAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsQuery,
    GetAllPolicyAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPolicyAssetsQuery, GetAllPolicyAssetsQueryVariables>(
    GetAllPolicyAssetsDocument,
    options
  );
}
export type GetAllPolicyAssetsQueryHookResult = ReturnType<typeof useGetAllPolicyAssetsQuery>;
export type GetAllPolicyAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsLazyQuery
>;
export type GetAllPolicyAssetsQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsQuery,
  GetAllPolicyAssetsQueryVariables
>;
export const GetAllPolicyAssetsByPolicyTypeDocument = gql`
  query GetAllPolicyAssetsByPolicyType($policy_PolicyType: String!) {
    allPolicyAssets(policy_PolicyType: $policy_PolicyType) {
      edges {
        node {
          asset {
            creationPeriod
            author
            title
            category
            genericDimensions
            technique
            inventoryNumber
            validated
            createdBy {
              name
            }
          }
          ...policyAssetFields
          policy {
            id
            offerCode
            policyCode
          }
        }
      }
    }
  }
  ${PolicyAssetFieldsFragmentDoc}
`;

/**
 * __useGetAllPolicyAssetsByPolicyTypeQuery__
 *
 * To run a query within a React component, call `useGetAllPolicyAssetsByPolicyTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPolicyAssetsByPolicyTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPolicyAssetsByPolicyTypeQuery({
 *   variables: {
 *      policy_PolicyType: // value for 'policy_PolicyType'
 *   },
 * });
 */
export function useGetAllPolicyAssetsByPolicyTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export function useGetAllPolicyAssetsByPolicyTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPolicyAssetsByPolicyTypeQuery,
    GetAllPolicyAssetsByPolicyTypeQueryVariables
  >(GetAllPolicyAssetsByPolicyTypeDocument, options);
}
export type GetAllPolicyAssetsByPolicyTypeQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeQuery
>;
export type GetAllPolicyAssetsByPolicyTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllPolicyAssetsByPolicyTypeLazyQuery
>;
export type GetAllPolicyAssetsByPolicyTypeQueryResult = Apollo.QueryResult<
  GetAllPolicyAssetsByPolicyTypeQuery,
  GetAllPolicyAssetsByPolicyTypeQueryVariables
>;
export const GetLastPresentValueByAssetIdDocument = gql`
  query GetLastPresentValueByAssetId($id: ID!) {
    asset(id: $id) {
      presentValues(last: 1) {
        edges {
          node {
            amountCurrency
            amount {
              amount
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetLastPresentValueByAssetIdQuery__
 *
 * To run a query within a React component, call `useGetLastPresentValueByAssetIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPresentValueByAssetIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPresentValueByAssetIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLastPresentValueByAssetIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export function useGetLastPresentValueByAssetIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLastPresentValueByAssetIdQuery,
    GetLastPresentValueByAssetIdQueryVariables
  >(GetLastPresentValueByAssetIdDocument, options);
}
export type GetLastPresentValueByAssetIdQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdQuery
>;
export type GetLastPresentValueByAssetIdLazyQueryHookResult = ReturnType<
  typeof useGetLastPresentValueByAssetIdLazyQuery
>;
export type GetLastPresentValueByAssetIdQueryResult = Apollo.QueryResult<
  GetLastPresentValueByAssetIdQuery,
  GetLastPresentValueByAssetIdQueryVariables
>;
export const GetAssetCarryingValueDocument = gql`
  query GetAssetCarryingValue($id: ID!) {
    asset(id: $id) {
      carryingValueAmount {
        amount
      }
      carryingValueAmountCurrency
    }
  }
`;

/**
 * __useGetAssetCarryingValueQuery__
 *
 * To run a query within a React component, call `useGetAssetCarryingValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetCarryingValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetCarryingValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetCarryingValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export function useGetAssetCarryingValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetCarryingValueQuery,
    GetAssetCarryingValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetCarryingValueQuery, GetAssetCarryingValueQueryVariables>(
    GetAssetCarryingValueDocument,
    options
  );
}
export type GetAssetCarryingValueQueryHookResult = ReturnType<typeof useGetAssetCarryingValueQuery>;
export type GetAssetCarryingValueLazyQueryHookResult = ReturnType<
  typeof useGetAssetCarryingValueLazyQuery
>;
export type GetAssetCarryingValueQueryResult = Apollo.QueryResult<
  GetAssetCarryingValueQuery,
  GetAssetCarryingValueQueryVariables
>;
export const GetAllRegistriesDocument = gql`
  query GetAllRegistries {
    allRegistries {
      edges {
        node {
          ...RegistryFindingFields
        }
      }
    }
  }
  ${RegistryFindingFieldsFragmentDoc}
`;

/**
 * __useGetAllRegistriesQuery__
 *
 * To run a query within a React component, call `useGetAllRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRegistriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export function useGetAllRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllRegistriesQuery, GetAllRegistriesQueryVariables>(
    GetAllRegistriesDocument,
    options
  );
}
export type GetAllRegistriesQueryHookResult = ReturnType<typeof useGetAllRegistriesQuery>;
export type GetAllRegistriesLazyQueryHookResult = ReturnType<typeof useGetAllRegistriesLazyQuery>;
export type GetAllRegistriesQueryResult = Apollo.QueryResult<
  GetAllRegistriesQuery,
  GetAllRegistriesQueryVariables
>;
export const GetRegistriesBySearchDocument = gql`
  query GetRegistriesBySearch($search: String!) {
    registries(search: $search) {
      ...RegistryAnagraphicFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRegistriesBySearchQuery__
 *
 * To run a query within a React component, call `useGetRegistriesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetRegistriesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export function useGetRegistriesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesBySearchQuery,
    GetRegistriesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistriesBySearchQuery, GetRegistriesBySearchQueryVariables>(
    GetRegistriesBySearchDocument,
    options
  );
}
export type GetRegistriesBySearchQueryHookResult = ReturnType<typeof useGetRegistriesBySearchQuery>;
export type GetRegistriesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesBySearchLazyQuery
>;
export type GetRegistriesBySearchQueryResult = Apollo.QueryResult<
  GetRegistriesBySearchQuery,
  GetRegistriesBySearchQueryVariables
>;
export const GetRegistryByIdDocument = gql`
  query GetRegistryById($id: ID!) {
    registry(id: $id) {
      ...RegistryAnagraphicFields
    }
  }
  ${RegistryAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetRegistryByIdQuery__
 *
 * To run a query within a React component, call `useGetRegistryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRegistryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export function useGetRegistryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryByIdQuery, GetRegistryByIdQueryVariables>(
    GetRegistryByIdDocument,
    options
  );
}
export type GetRegistryByIdQueryHookResult = ReturnType<typeof useGetRegistryByIdQuery>;
export type GetRegistryByIdLazyQueryHookResult = ReturnType<typeof useGetRegistryByIdLazyQuery>;
export type GetRegistryByIdQueryResult = Apollo.QueryResult<
  GetRegistryByIdQuery,
  GetRegistryByIdQueryVariables
>;
export const CreateRegistryDocument = gql`
  mutation CreateRegistry($input: CreateRegistryInput!) {
    createRegistry(input: $input) {
      registry {
        id
        fullName
        businessName
        alias
        displayName
      }
    }
  }
`;
export type CreateRegistryMutationFn = Apollo.MutationFunction<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;

/**
 * __useCreateRegistryMutation__
 *
 * To run a mutation, you first call `useCreateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRegistryMutation, { data, loading, error }] = useCreateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRegistryMutation, CreateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRegistryMutation, CreateRegistryMutationVariables>(
    CreateRegistryDocument,
    options
  );
}
export type CreateRegistryMutationHookResult = ReturnType<typeof useCreateRegistryMutation>;
export type CreateRegistryMutationResult = Apollo.MutationResult<CreateRegistryMutation>;
export type CreateRegistryMutationOptions = Apollo.BaseMutationOptions<
  CreateRegistryMutation,
  CreateRegistryMutationVariables
>;
export const UpdateRegistryDocument = gql`
  mutation UpdateRegistry($input: UpdateRegistryInput!) {
    updateRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type UpdateRegistryMutationFn = Apollo.MutationFunction<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;

/**
 * __useUpdateRegistryMutation__
 *
 * To run a mutation, you first call `useUpdateRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistryMutation, { data, loading, error }] = useUpdateRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRegistryMutation, UpdateRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRegistryMutation, UpdateRegistryMutationVariables>(
    UpdateRegistryDocument,
    options
  );
}
export type UpdateRegistryMutationHookResult = ReturnType<typeof useUpdateRegistryMutation>;
export type UpdateRegistryMutationResult = Apollo.MutationResult<UpdateRegistryMutation>;
export type UpdateRegistryMutationOptions = Apollo.BaseMutationOptions<
  UpdateRegistryMutation,
  UpdateRegistryMutationVariables
>;
export const DeleteRegistryDocument = gql`
  mutation DeleteRegistry($input: DeleteRegistryInput!) {
    deleteRegistry(input: $input) {
      registry {
        id
      }
    }
  }
`;
export type DeleteRegistryMutationFn = Apollo.MutationFunction<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;

/**
 * __useDeleteRegistryMutation__
 *
 * To run a mutation, you first call `useDeleteRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRegistryMutation, { data, loading, error }] = useDeleteRegistryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRegistryMutation, DeleteRegistryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRegistryMutation, DeleteRegistryMutationVariables>(
    DeleteRegistryDocument,
    options
  );
}
export type DeleteRegistryMutationHookResult = ReturnType<typeof useDeleteRegistryMutation>;
export type DeleteRegistryMutationResult = Apollo.MutationResult<DeleteRegistryMutation>;
export type DeleteRegistryMutationOptions = Apollo.BaseMutationOptions<
  DeleteRegistryMutation,
  DeleteRegistryMutationVariables
>;
export const GetOfficesBySearchDocument = gql`
  query GetOfficesBySearch($search: String!, $registryId: ID) {
    offices(search: $search, registryId: $registryId) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficesBySearchQuery__
 *
 * To run a query within a React component, call `useGetOfficesBySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesBySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesBySearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesBySearchQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export function useGetOfficesBySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesBySearchQuery,
    GetOfficesBySearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesBySearchQuery, GetOfficesBySearchQueryVariables>(
    GetOfficesBySearchDocument,
    options
  );
}
export type GetOfficesBySearchQueryHookResult = ReturnType<typeof useGetOfficesBySearchQuery>;
export type GetOfficesBySearchLazyQueryHookResult = ReturnType<
  typeof useGetOfficesBySearchLazyQuery
>;
export type GetOfficesBySearchQueryResult = Apollo.QueryResult<
  GetOfficesBySearchQuery,
  GetOfficesBySearchQueryVariables
>;
export const GetOfficeByIdDocument = gql`
  query GetOfficeById($id: ID!) {
    office(id: $id) {
      ...OfficeAnagraphicFields
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;

/**
 * __useGetOfficeByIdQuery__
 *
 * To run a query within a React component, call `useGetOfficeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfficeByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export function useGetOfficeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficeByIdQuery, GetOfficeByIdQueryVariables>(
    GetOfficeByIdDocument,
    options
  );
}
export type GetOfficeByIdQueryHookResult = ReturnType<typeof useGetOfficeByIdQuery>;
export type GetOfficeByIdLazyQueryHookResult = ReturnType<typeof useGetOfficeByIdLazyQuery>;
export type GetOfficeByIdQueryResult = Apollo.QueryResult<
  GetOfficeByIdQuery,
  GetOfficeByIdQueryVariables
>;
export const CreateOfficeDocument = gql`
  mutation CreateOffice($input: CreateOfficeInput!) {
    createOffice(input: $input) {
      office {
        ...OfficeAnagraphicFields
      }
    }
  }
  ${OfficeAnagraphicFieldsFragmentDoc}
`;
export type CreateOfficeMutationFn = Apollo.MutationFunction<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;

/**
 * __useCreateOfficeMutation__
 *
 * To run a mutation, you first call `useCreateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfficeMutation, { data, loading, error }] = useCreateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfficeMutation, CreateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfficeMutation, CreateOfficeMutationVariables>(
    CreateOfficeDocument,
    options
  );
}
export type CreateOfficeMutationHookResult = ReturnType<typeof useCreateOfficeMutation>;
export type CreateOfficeMutationResult = Apollo.MutationResult<CreateOfficeMutation>;
export type CreateOfficeMutationOptions = Apollo.BaseMutationOptions<
  CreateOfficeMutation,
  CreateOfficeMutationVariables
>;
export const UpdateOfficeDocument = gql`
  mutation UpdateOffice($input: UpdateOfficeInput!) {
    updateOffice(input: $input) {
      office {
        id
      }
    }
  }
`;
export type UpdateOfficeMutationFn = Apollo.MutationFunction<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;

/**
 * __useUpdateOfficeMutation__
 *
 * To run a mutation, you first call `useUpdateOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOfficeMutation, { data, loading, error }] = useUpdateOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOfficeMutation, UpdateOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOfficeMutation, UpdateOfficeMutationVariables>(
    UpdateOfficeDocument,
    options
  );
}
export type UpdateOfficeMutationHookResult = ReturnType<typeof useUpdateOfficeMutation>;
export type UpdateOfficeMutationResult = Apollo.MutationResult<UpdateOfficeMutation>;
export type UpdateOfficeMutationOptions = Apollo.BaseMutationOptions<
  UpdateOfficeMutation,
  UpdateOfficeMutationVariables
>;
export const DeleteOfficeDocument = gql`
  mutation DeleteOffice($input: DeleteOfficeInput!) {
    deleteOffice(input: $input) {
      office {
        id
      }
    }
  }
`;
export type DeleteOfficeMutationFn = Apollo.MutationFunction<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;

/**
 * __useDeleteOfficeMutation__
 *
 * To run a mutation, you first call `useDeleteOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOfficeMutation, { data, loading, error }] = useDeleteOfficeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOfficeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOfficeMutation, DeleteOfficeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOfficeMutation, DeleteOfficeMutationVariables>(
    DeleteOfficeDocument,
    options
  );
}
export type DeleteOfficeMutationHookResult = ReturnType<typeof useDeleteOfficeMutation>;
export type DeleteOfficeMutationResult = Apollo.MutationResult<DeleteOfficeMutation>;
export type DeleteOfficeMutationOptions = Apollo.BaseMutationOptions<
  DeleteOfficeMutation,
  DeleteOfficeMutationVariables
>;
export const GetOfficesByRegistryIdDocument = gql`
  query GetOfficesByRegistryId($registryId: ID!) {
    allOffices(registry: $registryId) {
      edges {
        node {
          id
          name
          address
          city
          zip
          state
          country
          main
          notActive
        }
      }
    }
  }
`;

/**
 * __useGetOfficesByRegistryIdQuery__
 *
 * To run a query within a React component, call `useGetOfficesByRegistryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfficesByRegistryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfficesByRegistryIdQuery({
 *   variables: {
 *      registryId: // value for 'registryId'
 *   },
 * });
 */
export function useGetOfficesByRegistryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export function useGetOfficesByRegistryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfficesByRegistryIdQuery,
    GetOfficesByRegistryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfficesByRegistryIdQuery, GetOfficesByRegistryIdQueryVariables>(
    GetOfficesByRegistryIdDocument,
    options
  );
}
export type GetOfficesByRegistryIdQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdQuery
>;
export type GetOfficesByRegistryIdLazyQueryHookResult = ReturnType<
  typeof useGetOfficesByRegistryIdLazyQuery
>;
export type GetOfficesByRegistryIdQueryResult = Apollo.QueryResult<
  GetOfficesByRegistryIdQuery,
  GetOfficesByRegistryIdQueryVariables
>;
export const TokenAuthDocument = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      payload
      refreshToken
      refreshExpiresIn
      user {
        name
        country {
          code
        }
        currency
        dateJoined
        email
        id
        isActive
        isStaff
        isSuperuser
        language
        lastLogin
        phone
        timezone
        userType
        username
        uuid
      }
    }
  }
`;
export type TokenAuthMutationFn = Apollo.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
    TokenAuthDocument,
    options
  );
}
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      refreshToken
      refreshExpiresIn
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    options
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    allUsers {
      edges {
        node {
          id
          name
          email
          phone
          country {
            code
          }
          userType
          isActive
        }
      }
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(GetAllUsersDocument, options);
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersQuery, GetAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<typeof useGetAllUsersLazyQuery>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: ID!) {
    user(id: $id) {
      id
      lastLogin
      dateJoined
      name
      isActive
      email
      userType
      phone
      country {
        code
      }
      language
      timezone
      currency
      ...UserSignatureFields
      userPermissions {
        edges {
          node {
            id
            codename
            name
            contentType {
              model
              id
            }
          }
        }
      }
    }
  }
  ${UserSignatureFieldsFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const InviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
  InviteUserMutation,
  InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
    InviteUserDocument,
    options
  );
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
  InviteUserMutation,
  InviteUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        password
        lastLogin
        username
        dateJoined
        name
        email
        phone
        country {
          code
        }
        language
        timezone
        currency
        userType
        isActive
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const RequestUserResetPasswordDocument = gql`
  mutation RequestUserResetPassword($input: RequestUserResetPasswordInput!) {
    requestUserResetPassword(input: $input) {
      user {
        resetPasswordToken
      }
    }
  }
`;
export type RequestUserResetPasswordMutationFn = Apollo.MutationFunction<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;

/**
 * __useRequestUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestUserResetPasswordMutation, { data, loading, error }] = useRequestUserResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestUserResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestUserResetPasswordMutation,
    RequestUserResetPasswordMutationVariables
  >(RequestUserResetPasswordDocument, options);
}
export type RequestUserResetPasswordMutationHookResult = ReturnType<
  typeof useRequestUserResetPasswordMutation
>;
export type RequestUserResetPasswordMutationResult =
  Apollo.MutationResult<RequestUserResetPasswordMutation>;
export type RequestUserResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  RequestUserResetPasswordMutation,
  RequestUserResetPasswordMutationVariables
>;
export const ResetUserPasswordDocument = gql`
  mutation ResetUserPassword($input: ResetUserPasswordInput!) {
    resetUserPassword(input: $input) {
      user {
        email
      }
    }
  }
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetUserPasswordMutation, ResetUserPasswordMutationVariables>(
    ResetUserPasswordDocument,
    options
  );
}
export type ResetUserPasswordMutationHookResult = ReturnType<typeof useResetUserPasswordMutation>;
export type ResetUserPasswordMutationResult = Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const GetAllPermissionDocument = gql`
  query getAllPermission {
    allPermissions {
      edges {
        node {
          contentType {
            model
          }
          codename
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetAllPermissionQuery__
 *
 * To run a query within a React component, call `useGetAllPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export function useGetAllPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPermissionQuery, GetAllPermissionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPermissionQuery, GetAllPermissionQueryVariables>(
    GetAllPermissionDocument,
    options
  );
}
export type GetAllPermissionQueryHookResult = ReturnType<typeof useGetAllPermissionQuery>;
export type GetAllPermissionLazyQueryHookResult = ReturnType<typeof useGetAllPermissionLazyQuery>;
export type GetAllPermissionQueryResult = Apollo.QueryResult<
  GetAllPermissionQuery,
  GetAllPermissionQueryVariables
>;
export const UpdateUserPermissionsDocument = gql`
  mutation UpdateUserPermissions($input: UpdateUserPermissionInput!) {
    updateUserPermissions(input: $input) {
      user {
        userPermissions {
          edges {
            node {
              codename
              name
            }
          }
        }
      }
    }
  }
`;
export type UpdateUserPermissionsMutationFn = Apollo.MutationFunction<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;

/**
 * __useUpdateUserPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionsMutation, { data, loading, error }] = useUpdateUserPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPermissionsMutation,
    UpdateUserPermissionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPermissionsMutation, UpdateUserPermissionsMutationVariables>(
    UpdateUserPermissionsDocument,
    options
  );
}
export type UpdateUserPermissionsMutationHookResult = ReturnType<
  typeof useUpdateUserPermissionsMutation
>;
export type UpdateUserPermissionsMutationResult =
  Apollo.MutationResult<UpdateUserPermissionsMutation>;
export type UpdateUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>;
export const ToggleUserIsActiveDocument = gql`
  mutation ToggleUserIsActive($input: ToggleUserIsActiveInput!) {
    toggleUserIsactive(input: $input) {
      user {
        isActive
      }
    }
  }
`;
export type ToggleUserIsActiveMutationFn = Apollo.MutationFunction<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;

/**
 * __useToggleUserIsActiveMutation__
 *
 * To run a mutation, you first call `useToggleUserIsActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserIsActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserIsActiveMutation, { data, loading, error }] = useToggleUserIsActiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserIsActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserIsActiveMutation,
    ToggleUserIsActiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserIsActiveMutation, ToggleUserIsActiveMutationVariables>(
    ToggleUserIsActiveDocument,
    options
  );
}
export type ToggleUserIsActiveMutationHookResult = ReturnType<typeof useToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationResult = Apollo.MutationResult<ToggleUserIsActiveMutation>;
export type ToggleUserIsActiveMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserIsActiveMutation,
  ToggleUserIsActiveMutationVariables
>;
export const ToggleUserUserTypeDocument = gql`
  mutation ToggleUserUserType($input: ToggleUserUserTypeInput!) {
    toggleUserUserType(input: $input) {
      user {
        userType
      }
    }
  }
`;
export type ToggleUserUserTypeMutationFn = Apollo.MutationFunction<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;

/**
 * __useToggleUserUserTypeMutation__
 *
 * To run a mutation, you first call `useToggleUserUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserUserTypeMutation, { data, loading, error }] = useToggleUserUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleUserUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleUserUserTypeMutation,
    ToggleUserUserTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleUserUserTypeMutation, ToggleUserUserTypeMutationVariables>(
    ToggleUserUserTypeDocument,
    options
  );
}
export type ToggleUserUserTypeMutationHookResult = ReturnType<typeof useToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationResult = Apollo.MutationResult<ToggleUserUserTypeMutation>;
export type ToggleUserUserTypeMutationOptions = Apollo.BaseMutationOptions<
  ToggleUserUserTypeMutation,
  ToggleUserUserTypeMutationVariables
>;
export const AddUserSignatureDocument = gql`
  mutation AddUserSignature($input: AddUserSignatureInput!) {
    addUserSignature(input: $input) {
      success
      userSignature {
        file
      }
    }
  }
`;
export type AddUserSignatureMutationFn = Apollo.MutationFunction<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;

/**
 * __useAddUserSignatureMutation__
 *
 * To run a mutation, you first call `useAddUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserSignatureMutation, { data, loading, error }] = useAddUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserSignatureMutation,
    AddUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserSignatureMutation, AddUserSignatureMutationVariables>(
    AddUserSignatureDocument,
    options
  );
}
export type AddUserSignatureMutationHookResult = ReturnType<typeof useAddUserSignatureMutation>;
export type AddUserSignatureMutationResult = Apollo.MutationResult<AddUserSignatureMutation>;
export type AddUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  AddUserSignatureMutation,
  AddUserSignatureMutationVariables
>;
export const DeleteUserSignatureDocument = gql`
  mutation DeleteUserSignature($input: DeleteUserSignatureInput!) {
    deleteUserSignature(input: $input) {
      userSignature {
        id
      }
    }
  }
`;
export type DeleteUserSignatureMutationFn = Apollo.MutationFunction<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;

/**
 * __useDeleteUserSignatureMutation__
 *
 * To run a mutation, you first call `useDeleteUserSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserSignatureMutation, { data, loading, error }] = useDeleteUserSignatureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserSignatureMutation,
    DeleteUserSignatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserSignatureMutation, DeleteUserSignatureMutationVariables>(
    DeleteUserSignatureDocument,
    options
  );
}
export type DeleteUserSignatureMutationHookResult = ReturnType<
  typeof useDeleteUserSignatureMutation
>;
export type DeleteUserSignatureMutationResult = Apollo.MutationResult<DeleteUserSignatureMutation>;
export type DeleteUserSignatureMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserSignatureMutation,
  DeleteUserSignatureMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
