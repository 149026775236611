import {
  Box,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ApolloError } from "@apollo/client";
import { useTranslation } from "react-i18next";
// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import {
  AssetInput,
  CreateAssetMutation,
  UpdateAssetMutation,
} from "graphql/queries/generated/queries";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";
import HistoricDateForm from "pages/assets/shared/HistoricDateForm";


// interface GeneralInfoPanelNewCategoryFormProps {
//     handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
//     handleChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
//     handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void
//     values: {
//         author: string;
//         title: string;
//         series: string;
//         series_details: string;
//         description: string;
//         inventoryNumber: string;
//         archiveNumber: string;
//         catalogNumber: string;
//         criticalCatalogue: boolean;
//         nft: boolean | null;
//         nftNotes: string
//     }
//     category: string
//     children?: React.ReactNode
//     createAssetData: CreateAssetMutation | null | undefined
//     updateAssetData: UpdateAssetMutation | null | undefined
//     createAssetError: ApolloError | undefined
//     updateAssetError: ApolloError | undefined
//     createAssetLoading: boolean
//     updateAssetLoading: boolean
// }

interface GeneralInfoPanelNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean | undefined;
  updateAssetLoading: boolean | undefined;
  id: string | null;
}

const GeneralInfoPanelNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
  category,
  children,
  id
}: GeneralInfoPanelNewCategoryFormProps) => {
  // useEffect(() => {
  //     register("archiveNumber")
  // }, []);
  const { t } = useTranslation();
  const { title } = getValues();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const nft = watch("nft");
  return (
    <>
    {/* <Box p={6} maxW="6xl">
      <Stack spacing="4" divider={<StackDivider />}> */}
        <Box alignItems="flex-start">
          <Text textAlign="left">Categoria {category}</Text>
        </Box>



        <FieldGroup title={t("Name and author")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            <HStack>
              <FormInputHook label="Title" name="title" />
              <FormInputHook label="Author" name="author" />
            </HStack>
            <HStack>
              <FormInputHook name="authorEntityId" label="Author entity" type="registry" />
            </HStack>
          </VStack>
        </FieldGroup>



        <FieldGroup title={t("Description")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            <HStack>
              <FormInputHook label="" name="description" type="textarea" />
            </HStack>
          </VStack>
        </FieldGroup>

        <FieldGroup title={t("Date of creation")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            {/* <HStack>
              <FormInputHook label="Creation date" type="date" name="creationDate" />
              <FormInputHook name="creationPeriod" label="Creation period" />
            </HStack>

            <HStack>
              <FormInputHook name="hasFullDatetime" label="hasFullDatetime" type="checkbox" />
              <FormInputHook name="hasPeriod" label="hasPeriod" type="checkbox" />
              <FormInputHook name="hasYear" label="hasYear" type="checkbox" />
            </HStack> */}
            <HistoricDateForm
              onSubmit={onSubmit}
              getValues={getValues}
              register={register}
              setValue={setValue}
              setError={setError}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              watch={watch}
              // handleChange={formik.handleChange}
              // handleRadioButtons={handleRadioButtons}
              // values={formik.values}
              createAssetData={createAssetData}
              updateAssetData={updateAssetData}
              createAssetError={createAssetError}
              updateAssetError={updateAssetError}
              createAssetLoading={createAssetLoading}
              updateAssetLoading={updateAssetLoading}
              category={category}
            />

          </VStack>
        </FieldGroup>


        <FieldGroup title={t("Series")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            <HStack>
              <FormInputHook label="Series" name="series" />
              <FormInputHook label="Series Details" name="seriesDetails" />
            </HStack>
          </VStack>
        </FieldGroup>


        <FieldGroup title={t("Cataloguing")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">

            <HStack>
              <FormInputHook label="Inventory number" name="inventoryNumber" />
            </HStack>

            <HStack>
              <FormInputHook label="Archive number" name="archiveNumber" />
            </HStack>


            <HStack>
              <FormInputHook label="Catalog number" name="catalogNumber" />
              <FormInputHook label="Critical catalogue?" type="checkbox" name="criticalCatalogue" />
            </HStack>
          </VStack>
        </FieldGroup>


        <FieldGroup title={t("NFT")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
          <VStack width="full" align="left">
            <HStack>
              <FormInputHook label="Has related NFT?" type="checkbox" name="nft" />

              {nft && <FormInputHook label="" placeholder="NFT notes" name="nftNotes" />}
            </HStack>
          </VStack>
        </FieldGroup>






      {/* </Stack>

    </Box> */}
    {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
      <Button
          isLoading={createAssetLoading || updateAssetLoading}
          loadingText="Loading"
          type="submit"
          variant="primary"
        >
          {id  ? t("Uddate") : t("Save")}
        </Button>
        {children}
    </ButtonGroup> */}

      </>
  );
};

export default GeneralInfoPanelNewCategoryForm;
