/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, ButtonGroup, Button, FormLabel, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Trans } from "react-i18next";
import ASSET_CONSTANTS from 'constants/asset';

import { Wrapper } from '../../../components/ui/FormInputHook/Wrapper';







interface ChooseCategoryFormProps {
  onSubmit: any;
  // handleRadioButtons: (e: React.FormEvent<HTMLInputElement>) => void;
  resetCategory: (e: React.MouseEvent<HTMLButtonElement>) => void;
  // value: string;
  onChange: (value: string) => void
}

// eslint-disable-next-line react/prop-types
const ChooseCategoryForm = ({
  onSubmit,
  // handleRadioButtons,
  resetCategory,
  // value,
  onChange
}: ChooseCategoryFormProps) => (
  <form onSubmit={onSubmit}>
          {/* <CustomSelect
            size="sm"
            // eslint-disable-next-line @typescript-eslint/no-shadow
            options={options}
            useBasicStyles
            value={options.find(({ value: optionValue }) => value === optionValue)}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={(newValue) => onChange(newValue.value)}
          /> */}
          <Box>
            <Wrapper name="category">
              <FormLabel htmlFor="category" mb="1" fontSize="xs" display="inline-flex">
                <Text>
                  <Trans>Category</Trans>
                </Text>
              </FormLabel>
              <Box minW="12rem" background="white">
                <Select
                  size="sm"
                  name="category"
                  useBasicStyles
                  placeholder="Select a category..."
                  options={ASSET_CONSTANTS.SUBCATEGORY}
                  onChange={(newValue) => onChange(newValue.value)}
                />
              </Box>


            </Wrapper>


            <ButtonGroup variant="outline" spacing="6">
              <Button type="submit" variant="primary" colorScheme="blue">
                <Trans>Continue</Trans>
              </Button>
              <Button onClick={(e) => resetCategory(e)}>Annulla</Button>
            </ButtonGroup>
          </Box>

    {/* <RadioGroup name="category">
      <Flex bg="gray.100" p={6} align="center" justify="center" w="100%">
        <Box>
          <Flex p={6} align="center" direction="column" justify="space-around" h="100vh">
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="SCULPTURE">
                Sculpture
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Flex p={6} align="flex-start" direction="column" justify="center">
                <div>Quadro</div>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="PITTURA">
                  Pittura
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="DISEGNO">
                  Disegno
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="BOZZETTO">
                  Bozzetto
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="OPERA_SU_CARTA">
                  Opera su carta
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="ARAZZO">
                  Arazzo
                </Radio>
                <Radio
                  padding={2}
                  onChange={(e) => handleRadioButtons(e)}
                  value="ALTRA_BIDIMENSIONALE"
                >
                  Altra opera bidimensionale
                </Radio>
              </Flex>
            </Box>

            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Flex p={6} align="flex-start" direction="column" justify="center">
                <div>Fotografia e stampa</div>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="FOTOGRAFIA">
                  Fotografia
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="GRAFICA">
                  Grafica
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="LITOGRAFIA">
                  Litografia
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="STAMPA">
                  Stampa
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="SERIGRAFIE">
                  Serigrafie
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="XILOGRAFIE">
                  Xilografie
                </Radio>
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box>
          <Flex p={6} align="center" direction="column" justify="space-around" h="100vh">
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="ARTE_URBANA">
                Arte urbana
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="INSTALLAZIONE">
                Installazione
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Flex p={6} align="flex-start" direction="column" justify="center">
                <div>Opera digitale</div>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="VIDEO">
                  Video
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="AUDIO">
                  Audio
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="OPERA_STATICA">
                  Opera statica
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="OPERA_DINAMICA">
                  Opera dinamica
                </Radio>
              </Flex>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio
                padding={2}
                onChange={(e) => handleRadioButtons(e)}
                value="ANTIQUARIATO_DESIGN"
              >
                Antiquariato/Design
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="BENE_ARCHEOLOGICO">
                Bene archeologico
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="BENE_NATURALISTICO">
                Bene naturalistico
              </Radio>
            </Box>
          </Flex>
        </Box>

        <Box>
          <Flex p={6} align="center" direction="column" justify="space-around" h="100vh">
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="NUMISMATICA_MONETE">
                Numismatica: Monete/medaglie
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="BANCONOTE">
                Numismatica: Banconote
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="FILATELIA">
                Filatelia
              </Radio>
            </Box>
            <Box bg="white" m={6} p={2} rounded="md" border={2} borderColor="gray.200">
              <Flex p={6} align="flex-start" direction="column" justify="center">
                <div>Altro</div>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="AUTOMOBILE">
                  Automobile
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="GIOIELLO">
                  Gioiello
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="VINO">
                  Vino
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="OROLOGI">
                  Orologi
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="ABITI">
                  Abiti
                </Radio>
                <Radio
                  padding={2}
                  onChange={(e) => handleRadioButtons(e)}
                  value="STRUMENTI_MUSICALI"
                >
                  Strumenti musicali
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="GIOCATTOLI">
                  Giocattoli
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="OGGETTI_STORICI">
                  Oggetti storici
                </Radio>
                <Radio padding={2} onChange={(e) => handleRadioButtons(e)} value="MEMORABILIA">
                  Memorabilia
                </Radio>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </RadioGroup>
    <ButtonGroup variant="outline" spacing="6">
      <Button type="submit" colorScheme="blue">
        Submit
      </Button>
      <Button onClick={(e) => resetCategory(e)}>Annulla</Button>
    </ButtonGroup> */}

  </form>
);

export default ChooseCategoryForm;
