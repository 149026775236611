import { PolicyInputSchema as generatedInputSchema } from "graphql/queries/generated/validation-schema";
import * as Yup from "yup";
import { PolicyInput } from "../generated/queries";

export function PolicyInputSchema(): Yup.SchemaOf<PolicyInput> {
  return generatedInputSchema()
    .shape(
      {
        offerCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "PENDING",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "offerCode"]]
    )
    .shape(
      {
        policyCode: Yup.string().when(["policyStatus"], {
          is: (policyStatus: string) => policyStatus === "APPROVED",
          then: Yup.string().required(),
        }),
      },
      [["policyStatus", "policyCode"]]
    );
}
