const defaultValues = {
  exemption: false,
  brokerId: "",
  catNat: true,
  contractingPartyId: "",
  coverTerrorism: true,
  coverType: "NAIL_TO_NAIL",
  coveredRiskType: "ALL_RISK",
  evaluationType: "AGREED_VALUE",
  expiresAt: "",
  hasVariableBasket: false,
  insuranceCompanyId: "",
  issuedAt: "",
  policyType: "PERMANENT_COLLECTION",
  policyStatus: "PENDING",
  totalInsuredPremium: {
    amount: null as null | number,
    currency: "EUR",
  },
  totalInsuredValue: {
    amount: null as null | number,
    currency: "EUR",
  },
  policyCode: "",
  offerCode: "",
  autoRenewal: false,
  eventName: "",
  eventFrom: "",
  eventTo: "",
  eventEntityId: "",
  eventLocationEntityId: "",
};

export default defaultValues;
