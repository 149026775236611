import { useTranslation } from "react-i18next";
import { Box, HStack, Text, VStack } from "@chakra-ui/react";

// import { CreateAsset } from 'graphql/queries/types/CreateAsset';
// import { UpdateAsset } from 'graphql/queries/types/UpdateAsset';
import { ApolloError } from "@apollo/client";
import {
  CreateAssetMutation,
  UpdateAssetMutation,
  AssetInput,
} from "graphql/queries/generated/queries";

import { FieldGroup, FormInputHook } from "components/ui";
import { BaseSyntheticEvent } from "react";

import {
  UseFormRegister,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

interface TechnicalDetailsNewCategoryFormProps {
  onSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;
  category: string;
  children?: React.ReactNode;
  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean | undefined;
  updateAssetLoading: boolean | undefined;
}

const VOID_OPTION = [{ name: "-- Select --", value: "" }];

const TechnicalDetailsNewCategoryForm = ({
  // eslint-disable-next-line react/prop-types
  onSubmit,
  // handleChange,
  // handleRadioButtons,
  // values,
  register,
  setValue,
  setError,
  getValues,
  watch,
  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
  category,
  children,
}: TechnicalDetailsNewCategoryFormProps) => {
  const { t } = useTranslation();
  const { title } = getValues();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isArtistProof = watch("isArtistProof");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isArtMultiple = watch("isArtMultiple");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isSignedObject = watch("isSignedObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isTitledObject = watch("isTitledObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const isDatedObject = watch("isDatedObject");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasLabelsStamps = watch("hasLabelsStamps");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const hasOtherAutographed = watch("hasOtherAutographed");

  return (
    <>
      {/* <Box p={6} maxW="6xl">
        <Stack spacing="4" divider={<StackDivider />}> */}
          <Box alignItems="flex-start">
            <Text textAlign="left">Categoria {category}</Text>
          </Box>


          <FieldGroup title={t("Artist proof")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
              <FormInputHook label="Is artist proof?" type="checkbox" name="isArtistProof" />

              {isArtistProof && <FormInputHook label="" placeholder="Notes" name="artistProofNotes" />}
            </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Multiple")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Is multiple?" type="checkbox" name="isArtMultiple" />

                {isArtMultiple && (
                  <FormInputHook label="" placeholder="Multiple number" name="multipleNumber" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>

          <FieldGroup title={t("Sign")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Is signed?" type="checkbox" name="isSignedObject" />

                {isSignedObject && (
                  <FormInputHook label="" placeholder="Notes" name="signedObjectNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Title")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Is titled?" type="checkbox" name="isTitledObject" />

                {isTitledObject && (
                  <FormInputHook label="" placeholder="Notes" name="titledObjectNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Date")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Is dated?" type="checkbox" name="isDatedObject" />

                {isDatedObject && <FormInputHook label="" placeholder="Notes" name="datedObjectNotes" />}
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Labels & Stamps")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook label="Labels and Stamps" type="checkbox" name="hasLabelsStamps" />

                {hasLabelsStamps && (
                  <FormInputHook label="" placeholder="Notes" name="labelsStampsNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>



          <FieldGroup title={t("Autographs")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack>
                <FormInputHook
                  label="Has other autographed occurrencies"
                  type="checkbox"
                  name="hasOtherAutographed"
                />

                {hasOtherAutographed && (
                  <FormInputHook label="" placeholder="Notes" name="otherAutographedNotes" />
                )}
              </HStack>
            </VStack>
          </FieldGroup>


          <FieldGroup title={t("Awards and acknowledgments")} subtitle={t("Lorem ipsum dolor sit amet")} py={8}>
            <VStack width="full" align="left">
              <HStack alignItems="flex-end">
                <FormInputHook
                  label=""
                  name="relevantAwards"
                  type="textarea"
                />
              </HStack>
            </VStack>
          </FieldGroup>


          {/* <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
          >
            {t("Save")}
          </Button>
          {children} */}

        {/* </Stack>
      </Box> */}
      {/* <ButtonGroup pb={6} pl={6} display="block" textAlign="left" variant="outline">
        <Button
            isLoading={createAssetLoading || updateAssetLoading}
            loadingText="Loading"
            type="submit"
            variant="primary"
          >
            {t("Update")}
        </Button>
        {children}
      </ButtonGroup> */}
    </>
  );
};

export default TechnicalDetailsNewCategoryForm;
