import { HStack, Icon, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

export enum Status {
  ENABLED = "primaryGreen",
  DISABLED = "red.500",
  INDETERMINATE = "primaryYellow",
}

interface DotProps {
  status: boolean | "unknown";
  positiveLabel?: string;
  negativeLabel?: string;
}

const Dot = ({ status, positiveLabel, negativeLabel }: DotProps) => {
  const colorStatus =
    // eslint-disable-next-line no-nested-ternary
    status === "unknown" ? Status.INDETERMINATE : status ? Status.ENABLED : Status.DISABLED;
  return (
    <HStack spacing="0" mt="0">
      <Icon viewBox="0 0 200 200" color={colorStatus} mr={1}>
        <path
          fill="currentColor"
          d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
      </Icon>
      <Text color={status ? "green.500" : "red.500"} fontWeight="600">
        {status ? (
          <Trans>{positiveLabel ?? "Active"}</Trans>
        ) : (
          <Trans>{negativeLabel ?? "Inactive"}</Trans>
        )}
      </Text>
    </HStack>
  );
};

export default Dot;
