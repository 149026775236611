import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { CsvImporter } from "components/ui";
import { BaseRow } from "react-csv-importer";

const IMPORT_FIELDS = [
  { name: "category", optional: true },
  { name: "title", optional: false },
  { name: "author", optional: true },
  { name: "creationPeriod", optional: true },
  { name: "technique", optional: true },
  { name: "genericDimensions", optional: true },
  { name: "inventorynumber", optional: true },
  { name: "assetPresentValue.amout", optional: true },
  { name: "lender", optional: true },
  { name: "shipper", optional: true },
  { name: "lenderAddress", optional: true },
  { name: "lenderCountry", optional: true },
  { name: "coveredRiskType", optional: true },
  { name: "riskType", optional: true },
  { name: "evaluationType", optional: true },
  { name: "exemption", optional: true },
  { name: "coverTerrorism", optional: true },
  { name: "catNat", optional: true },
];
interface ImportAssetDrawerProps {
  show: boolean;
  onClose: () => void;
  processChunk?: (rows: BaseRow[], { startIndex }: { startIndex: number }) => void;
  fields?: { name: string; optional?: boolean }[];
}

const ImportAssetsDrawer = ({
  show,
  onClose,
  processChunk,
  fields = IMPORT_FIELDS,
}: ImportAssetDrawerProps) => (
  <Drawer
    isOpen={show}
    placement="right"
    onClose={onClose}
    size="2xl"
    // finalFocusRef={btnRef}
  >
    <DrawerOverlay />
    <DrawerContent>
      <DrawerCloseButton />
      <DrawerHeader>Import from CSV</DrawerHeader>
      <DrawerBody>
        {processChunk && (
          <CsvImporter fields={fields} processChunk={processChunk} onClose={onClose} />
        )}
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

export default ImportAssetsDrawer;
