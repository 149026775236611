export default {
  LENGTH_UNIT: [
    {
      label: "mm",
      value: "MM",
    },
    {
      label: "cm",
      value: "CM",
    },
    {
      label: "m",
      value: "M",
    },
    {
      label: "in",
      value: "IN",
    },
    {
      label: "ft",
      value: "FT",
    },
  ],
  WEIGHT_UNIT: [
    {
      label: "g",
      value: "G",
    },
    {
      label: "hg",
      value: "HG",
    },
    {
      label: "kg",
      value: "KG",
    },
    {
      label: "oz",
      value: "OZ",
    },
    {
      label: "lb",
      value: "LB",
    },
  ],
  LIGHT_UNIT: [
    { label: "Lux", value: "LUX" },
    { label: "Foot candles", value: "FOOT_CANDLES" },
  ],
  TEMPERATURE_UNIT: [
    { label: "C°", value: "C" },
    { label: "°F", value: "F" },
  ],
  DIMENSIONS_ACQUISITIONS: [
    { label: "Given", value: "GIVEN" },
    { label: "Measured", value: "MEASURED" },
    { label: "Not measured", value: "NOT_MEASURED" },
  ],
  INJURY_RISK: [
    { label: "None", value: "NONE" },
    { label: "Low", value: "LOW" },
    { label: "Average", value: "AVERAGE" },
    { label: "High", value: "HIGH" },
  ],
  ASSET_VALUES_ACQUISITIONS: [
    { label: "Received as a gift", value: "GIFT" },
    { label: "Inherited", value: "INHERITED" },
    { label: "Other", value: "OTHER" },
  ],
  ASSET_VALUES_REASONS: [
    { label: "Alienation", value: "ALIENATION" },
    { label: "CR", value: "CR" },
    { label: "Insurance quote", value: "INSURANCE_QUOTE" },
    { label: "Asset analysis", value: "ASSET_ANALYSIS" },
    { label: "Legal dispute", value: "LEGAL_DISPUTE" },
  ],
  ASSET_DATING_TYPES: [
    { label: "Period", value: "PERIOD" },
    { label: "Date", value: "DATE" },
    { label: "Year", value: "YEAR" },
    { label: "Century", value: "CENTURY" },
  ],
  ASSET_DATE_WINDOWS: [
    { label: "End", value: "END" },
    { label: "Half", value: "HALF" },
    { label: "Begin", value: "BEGIN" },
    { label: "First half", value: "FIRST_HALF" },
    { label: "Second half", value: "SECOND_HALF" },
  ],
  ASSET_DATE_ERAS: [
    { label: "Precambrian hadean", value: "PRECAMBRIAN_HADEAN" },
    { label: "Precambrian Archean", value: "PRECAMBRIAN_ARCHEAN" },
  ],
  CATEGORY: [
    { value: "sculpture", label: "sculpture" },
    { value: "painting", label: "painting" },
    { value: "print_or_photo", label: "print_or_photo" },
    { value: "urban", label: "urban" },
    { value: "installation", label: "installation" },
    { value: "digital", label: "digital" },
    { value: "design_or_antique", label: "design_or_antique" },
    { value: "archeologic", label: "archeologic" },
    { value: "naturalistic", label: "naturalistic" },
    { value: "numismatic", label: "numismatic" },
    { value: "philately", label: "philately" },
    { value: "other", label: "other" },
  ],
  SUBCATEGORY: [
    { value: "sculpture", label: "sculpture" },
    {
      label: "painting",
      options: [
        { value: "painting", label: "Painting" },
        { value: "drawing", label: "Drawing" },
        { value: "artist proof", label: "Artist Proof" },
        { value: "opera su carta", label: "Opera su Carta" },
        { value: "tapestries", label: "Tapestries" },
        { value: "other bidimensional works", label: "Other Bidimensional Works" },
      ],
    },
    {
      label: "print_or_photo",
      options: [
        { label: "photography", value: "Photography" },
        { label: "graphic", value: "Graphic" },
        { label: "prints", value: "Prints" },
        { label: "lithography", value: "Lithography" },
        { label: "serigraphy", value: "Serigraphy" },
        { label: "xylography", value: "Xylography" },
      ],
    },
    { value: "urban", label: "urban" },
    { value: "installation", label: "installation" },
    { value: "digital", label: "digital" },
    { value: "design_or_antique", label: "design_or_antique" },
    { value: "archeologic", label: "archeologic" },
    { value: "naturalistic", label: "naturalistic" },
    { value: "numismatic", label: "numismatic" },
    { value: "philately", label: "philately" },
    { value: "other", label: "other" },
    // { value: "---", label: "---" },
  ],
};
