import { Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormFooter } from "components/ui";
import {
  GetRegistryByIdQuery,
  RegistryInput,
  useUpdateRegistryMutation,
} from "graphql/queries/generated/queries";
import { RegistryInputSchema } from "graphql/queries/generated/validation-schema";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import RegistryFields from "../shared/RegistryFields";

interface PageProps {
  data: GetRegistryByIdQuery;
}

const Page = ({ data }: PageProps) => {
  const {
    registry: { __typename, id, ...registry },
  } = data;

  const [updateRegistry, { data: updateRegistryData, error, loading }] =
    useUpdateRegistryMutation();

  const methods = useForm<RegistryInput>({
    defaultValues: {
      ...registry,
    },
    resolver: yupResolver(RegistryInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    console.log(errors);
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setFocus(firstError as keyof RegistryInput);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const onSubmit: SubmitHandler<RegistryInput> = async (formData, e) => {
    console.log(formData);

    await updateRegistry({
      variables: {
        input: {
          id,
          registryData: {
            ...formData,
          },
        },
      },
    });
    return null;
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <RegistryFields watch={methods.watch} id={id} />
          <FormFooter
            data={updateRegistryData}
            loading={loading}
            errors={[error]}
            title="Registry entry"
          />
        </form>
      </FormProvider>
    </Stack>
  );
};

export default Page;
