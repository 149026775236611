import {
  useGetAssetCarryingValueLazyQuery,
  useGetLastPresentValueByAssetIdLazyQuery,
} from "graphql/queries/generated/queries";

const useGetAssetCurrentValue = () => {
  const [getAssetCarryingValue] = useGetAssetCarryingValueLazyQuery();
  const [getLastPresentValueByAssetId] = useGetLastPresentValueByAssetIdLazyQuery();

  const doUseGetAssetCurrentValue = async (
    id: string
  ): Promise<null | { currency: string; amount: number }> => {
    try {
      const { data: getLastPresentValueByAssetIdData } = await getLastPresentValueByAssetId({
        variables: {
          id,
        },
      });

      if (getLastPresentValueByAssetIdData?.asset?.presentValues) {
        const { data: getAssetCarryingValueData } = await getAssetCarryingValue({
          variables: {
            id,
          },
        });

        if (!getAssetCarryingValueData) return null;

        if (!getAssetCarryingValueData.asset?.carryingValueAmount) return null;

        return {
          amount: getAssetCarryingValueData.asset.carryingValueAmount.amount,
          currency: getAssetCarryingValueData.asset.carryingValueAmountCurrency,
        };
      }

      const {
        asset: {
          presentValues: { edges },
        },
      } = getLastPresentValueByAssetIdData;
      if (edges.length === 0) return null;

      const { node } = edges[0];
      const { amountCurrency, amount } = node;
      return { amount: amount?.amount, currency: amountCurrency };
    } catch {
      return null;
    }
  };

  return doUseGetAssetCurrentValue;
};

export default useGetAssetCurrentValue;
