import {
  AssetInput,
  GetPolicyByIdQuery,
  useCreateAssetMutation,
  useUpdatePolicyMutation,
} from "graphql/queries/generated/queries";
import { Stack } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray, SubmitHandler, FormProvider } from "react-hook-form";
import { PolicyInputExtended } from "pages/spin/types";
import { PolicyInputSchema } from "graphql/queries/overrides";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImportAssetsDrawer, NewAssetDrawer, EditPolicyForm } from "pages/spin/shared";
import { SelectedAsset } from "components/ui/PolicyAssetSearchBox/types";
import placeholderPNG from "assets/placeholder.png";
import { BaseRow } from "react-csv-importer";
import { TEPolicyAssetFields, PCPolicyAssetFields } from "../types";

const EditPolicyPage = ({
  data,
  policyAssetsFields,
}: {
  data: GetPolicyByIdQuery;
  policyAssetsFields: PCPolicyAssetFields | TEPolicyAssetFields;
}) => {
  const [showNewAssetDrawer, setShowNewAssetDrawer] = useState<boolean>(false);
  const [showImportAssetsDrawer, setShowImportAssetsDrawer] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [createAsset, { data: createAssetData, error: createAssetError }] =
    useCreateAssetMutation();

  const { policyId } = useParams();
  const [
    updatePolicy,
    { data: updatePolicyData, loading: updatePolicyLoading, error: updatePolicyError },
  ] = useUpdatePolicyMutation();

  const {
    policy: {
      id,
      policyAssets,
      policyDocuments,
      policyNotes,
      totalInsuredPremium,
      totalInsuredValue,
      broker,
      insuranceCompany,
      contractingParty,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      __typename,
      ...policy
    },
  } = data;

  const parsedForTablePolicyAssets = policyAssets.edges.flatMap((e) => {
    const { asset, insuredValue, ...rest } = e.node;
    return {
      ...rest,
      insuredValue: {
        amount: insuredValue.amount,
        currency: insuredValue.currency.code,
      },
      ...asset,
    };
  });

  const methods = useForm<PolicyInputExtended>({
    defaultValues: {
      ...policy,
      brokerId: broker.id,
      insuranceCompanyId: insuranceCompany.id,
      contractingPartyId: contractingParty.id,
      totalInsuredPremium: {
        ...totalInsuredPremium,
        currency: totalInsuredPremium?.currency?.code || "EUR",
      },
      totalInsuredValue: {
        ...totalInsuredValue,
        currency: totalInsuredValue?.currency?.code || "EUR",
      },
      policyAssets: parsedForTablePolicyAssets,
    },
    resolver: yupResolver(PolicyInputSchema()),
  });

  const {
    formState: { errors },
    setFocus,
  } = methods;

  useEffect(() => {
    const firstError = Object.keys(errors).reduce(
      (field, a) => ((errors as unknown as never)[field] ? field : a),
      null
    );

    try {
      if (firstError) {
        setTabIndex(0);
        setFocus(firstError as keyof PolicyInputExtended);
      }
    } catch (e) {
      console.log(e);
    }
  }, [errors, setFocus]);

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "policyAssets",
  });
  const {
    fields: noteFields,
    append: appendNote,
    remove: removeNote,
  } = useFieldArray({
    control: methods.control,
    name: "policyNote",
  });
  const {
    fields: documentFields,
    append: appendDocument,
    remove: removeDocument,
  } = useFieldArray({
    control: methods.control,
    name: "policyDocuments",
  });

  const watchPolicyAssets = methods.watch("policyAssets");

  const goToNextTab = () => {
    setTabIndex((prevIndex) => (prevIndex < 3 && prevIndex >= 0 ? prevIndex + 1 : prevIndex));
  };

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const addPolicyAsset = ({
    title,
    author,
    creationPeriod,
    objectID,
    gqlId,
    image,
  }: Partial<SelectedAsset>) => {
    const { evaluationType, catNat, coverTerrorism, coveredRiskType, coverType, exemption } =
      methods.getValues();
    append({
      ...policyAssetsFields,
      asset: gqlId,
      title,
      image: image ?? placeholderPNG,
      author,
      creationPeriod,
      evaluationType,
      catNat,
      coverTerrorism,
      coveredRiskType,
      coverType,
      exemption,
    });
  };

  const onSubmit: SubmitHandler<PolicyInputExtended> = async (formData, e) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const {
      policyAssets,
      policyDocuments: policyDocumentsEdited,
      policyNote,
      totalInsuredPremium: totalInsuredPremiumEdited,
      totalInsuredValue: totalInsuredValueEdited,
      ...policyData
    } = formData;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const cleanPolicyAssets = policyAssets.map((policyAsset) => {
      const { title, creationPeriod, image, author, ...policyAssetData } = policyAsset;
      return policyAssetData;
    });

    await updatePolicy({
      variables: {
        input: {
          id: policyId,
          policyData: {
            ...policyData,
            policyAssets: cleanPolicyAssets,
            totalInsuredPremium: {
              amount: totalInsuredPremiumEdited?.amount,
              currency: totalInsuredPremiumEdited?.currency || "EUR",
            },
            totalInsuredValue: {
              amount: totalInsuredValueEdited?.amount,
              currency: totalInsuredValueEdited?.currency || "EUR",
            },
          },
        },
      },
    });
  };

  const processCsvRows = async (rows: BaseRow[], { startIndex }: { startIndex: number }) => {
    const result = await Promise.all(
      rows.map(async (row: AssetInput) => {
        const copiedRow = row;
        // if (!row.category) {
        //   copiedRow.category = "---";
        //   copiedRow.subcategory = "";
        // }
        const data = await createAsset({
          variables: {
            input: {
              assetData: {
                ...copiedRow,
                validated: false,
              },
            },
          },
        });
        return data;
      })
    )
      .then((result) => {
        if (!result) throw new Error("Error importing asset");
        // eslint-disable-next-line array-callback-return
        result.map(({ data }) => {
          if (!data.createAsset) throw new Error("Error importing asset");
          const {
            createAsset: { asset },
          } = data;

          addPolicyAsset({
            ...asset,
          });
        });

        // const { createAsset } = result;
        // addPolicyAsset({});
      })
      .catch((e) => console.error(e));
  };

  return (
    <Stack spacing="4">
      <FormProvider {...methods}>
        <EditPolicyForm
          getValues={methods.getValues}
          originalPolicyAssets={parsedForTablePolicyAssets}
          currentPolicyAssets={watchPolicyAssets}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={methods.handleSubmit(onSubmit)}
          goToNextTab={goToNextTab}
          handleTabChange={handleTabChange}
          tabIndex={tabIndex}
          data={updatePolicyData}
          loading={methods.formState.isSubmitting}
          error={[updatePolicyError]}
          extraFields={{ fields, append, remove }}
          setShowNewAssetDrawer={setShowNewAssetDrawer}
          setShowImportAssetsDrawer={setShowImportAssetsDrawer}
          addPolicyAsset={addPolicyAsset}
        />
      </FormProvider>
      <NewAssetDrawer
        show={showNewAssetDrawer}
        onClose={() => setShowNewAssetDrawer(false)}
        addPolicyAsset={addPolicyAsset}
      />
      <ImportAssetsDrawer
        show={showImportAssetsDrawer}
        processChunk={processCsvRows}
        onClose={() => setShowImportAssetsDrawer(false)}
      />
    </Stack>
  );
};

export default EditPolicyPage;
