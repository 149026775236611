import { PolicyType } from "pages/spin/types";

const HIDDEN_BY_DEFAULT: string[] = [
  "asset",
  "catNat",
  "coverTerrorism",
  "exemption",
  "objectLocationEntityId",
  "objectLocationOfficeId",
  "lenderAddress",
  "lenderAddress.raw",
  "shipperAddress",
  "shipperAddress.raw",
  "pickupAddress",
  "pickupAddress.raw",
  "deliveryAddress",
  "deliveryAddress.raw",
];

export default function getHiddenColumns(type: PolicyType): string[] {
  if (type === "PERMANENT_COLLECTION")
    return [
      ...HIDDEN_BY_DEFAULT,
      "lender",
      "lenderAddress",
      "lenderId",
      "lenderAddress.raw",
      "shipper",
      "shipperAddress",
      "shipperId",
      "shipperAddress.raw",
      "pickupAddress",
      "pickupAddress.raw",
      "deliveryAddress",
      "deliveryAddress.raw",

      "asset",
    ];
  return [...HIDDEN_BY_DEFAULT];
}
