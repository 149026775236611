import { Text } from "@chakra-ui/react";
import { useGetRegistryByIdQuery } from "graphql/queries/generated/queries";
import { useParams } from "react-router-dom";
import Page from "./Page";

const EditRegistryPage = () => {
  const { registryId } = useParams();

  const { data, loading, error, refetch } = useGetRegistryByIdQuery({
    variables: {
      id: registryId,
    },
  });

  if (loading) return <Text>Loading...</Text>;
  if (!data) return null;

  if (data.registry != null && data.registry.authorType == null) data.registry.authorType = [];
  if (data.registry != null && data.registry.categories == null) data.registry.categories = [];
  if (data.registry != null && data.registry.isAuthor == null) data.registry.isAuthor = false;


  return <Page data={data} />;
};

export default EditRegistryPage;
