import { Box, Button, Heading, Image, Text, useToast, VStack } from "@chakra-ui/react";

import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  useDeleteUserSignatureMutation,
  UserSignatureNode,
} from "graphql/queries/generated/queries";

const UserSignatureCard = ({
  id,
  uuid,
  file,
  createdAt,
  index,
  refetch,
}: Pick<UserSignatureNode, "id" | "uuid" | "file" | "createdAt"> & {
  index: number;
  refetch: () => void;
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [
    deleteUserSignature,
    {
      data: deleteUserSignatureData,
      error: deleteUserSignatureError,
      loading: deleteUserSignatureLoading,
    },
  ] = useDeleteUserSignatureMutation();

  useEffect(() => {
    if (deleteUserSignatureData) {
      toast({
        title: t("Signature has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deleteUserSignatureError)
      toast({
        title: t("Signature couldn't be deleted."),
        description: t(`Error: ${deleteUserSignatureError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deleteUserSignatureData, deleteUserSignatureError]);

  const deleteSignature = async (id: string) => {
    await deleteUserSignature({
      variables: {
        input: {
          id,
        },
      },
    });

    refetch();
  };

  return (
    <Box p="2" shadow="xl" w="15rem" borderRadius="md">
      <VStack p="2" align="flex-start">
        <Heading size="md" mb="2">
          Signature #{index + 1}
        </Heading>
        <Image src={file} maxH="5rem" />
        <Text variant="muted">UUID</Text>
        <Text>{uuid}</Text>
        <Text variant="muted">
          <Trans>Created at</Trans>
        </Text>
        <Text>{new Date(createdAt).toLocaleString("it-IT")}</Text>
        <Button
          mt="4"
          isLoading={deleteUserSignatureLoading}
          onClick={() => deleteSignature(id)}
          variant="danger"
        >
          Delete
        </Button>
      </VStack>
    </Box>
  );
};

export default UserSignatureCard;
