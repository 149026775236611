import { ApolloError } from "@apollo/client";

import { TokenAuthMutation, useTokenAuthMutation } from "graphql/queries/generated/queries";
import { createContext, useContext } from "react";

type UserFromAuthentication = TokenAuthMutation["tokenAuth"]["user"];

export function getJwtToken(): string | null {
  return sessionStorage.getItem("jwtToken");
}

export function setJwtToken(token: string) {
  sessionStorage.setItem("jwtToken", token);
}

export function setSessionStorageUser(user: UserFromAuthentication) {
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function getSessionStorageUser(): UserFromAuthentication | null {
  try {
    const user = sessionStorage.getItem("user");
    if (!user) return null;
    return JSON.parse(user) as UserFromAuthentication;
  } catch (e) {
    // console.log(e);
    return null;
  }
}

export function getRefreshToken(): string | null {
  return sessionStorage.getItem("refreshToken");
}

export function setRefreshToken(token: string) {
  sessionStorage.setItem("refreshToken", token);
}

/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

const signout = (callback?: VoidFunction) => {
  sessionStorage.clear();

  // in callback
  localStorage.setItem("logout", Date.now().toString());
  // client.resetStore()
  if (callback) callback();
};

export { fakeAuthProvider };

interface AuthContextType {
  user: UserFromAuthentication;
  jwtToken: string | null;
  refreshToken: string | null;
  error: ApolloError | undefined;
  loading: boolean;
  signin: (username: string, password: string, callback: VoidFunction) => any;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  // const [user, setUser] = useState<TokenAuth_tokenAuth_user | null>(null);
  const jwtToken = getJwtToken();
  const refreshToken = getRefreshToken();
  const user = getSessionStorageUser();

  const [loginMutation, { loading, error }] = useTokenAuthMutation();

  const signin = async (username: string, password: string, callback: VoidFunction) => {
    try {
      const { data } = await loginMutation({
        variables: { email: username, password },
      });

      if (!data?.tokenAuth) throw new Error("Missing token in response.");

      const {
        tokenAuth: { token, refreshToken: obtainedRefreshToken, user: authenticatedUser },
      } = data;

      setJwtToken(token);
      setRefreshToken(obtainedRefreshToken);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (authenticatedUser) setSessionStorageUser(authenticatedUser);
      // setUser(authenticatedUser);
      callback();
    } catch {
      console.warn("[AUTH] Error:", error);
    }
  };
  // fakeAuthProvider.signin(() => {
  //   setUser(newUser);
  //   setJwtToken(Math.floor(Math.random() * 100).toString());
  //   setRefreshToken(Math.floor(Math.random() * 100).toString());
  //   callback();
  // });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user,
    error,
    jwtToken,
    refreshToken,
    signin,
    signout,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
