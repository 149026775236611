import { useTranslation } from "react-i18next";
import { Box, Divider, Skeleton, useToast } from "@chakra-ui/react";
import {
  BooleanFilter,
  HiddenHoverableCell,
  NoDataPlaceholder,
  QuickActions,
  Table,
} from "components/ui";
import { RiAddLine, RiFile2Fill, RiSignalTowerFill } from "react-icons/ri";
import getColumns, { BooleanCell } from "helpers/getColumns";
import {
  CertificateNode,
  useDeleteCertificateMutation,
  useGetAllCertificatesByTypeQuery,
  useSignCertificateMutation,
} from "graphql/queries/generated/queries";
import CreatedByFilter, { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { useEffect } from "react";
const CUSTOM_COLUMNS = [
  {
    Header: "#",
    Footer: "",
    accessor: "progressiveNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      String(value) === "0" ? "1" : value,
    weight: 101,
    disableFilters: true,
  },
  {
    Header: "#",
    Footer: "",
    accessor: "uuid",
    Cell: HiddenHoverableCell,
    weight: 100,
    disableFilters: true,
  },
  {
    Header: "Created on",
    Footer: "",
    accessor: "creationDate",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      new Date(value).toLocaleString("it-IT"),
  },
  {
    Header: "Policy #",
    Footer: "",
    accessor: "policy.policyCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "Offer #",
    Footer: "",
    accessor: "policy.offerCode",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },

  {
    Header: "Status",
    Footer: "",
    accessor: "isValid",
    Filter: BooleanFilter,
    Cell: BooleanCell,
  },
  {
    Header: "Signed",
    Footer: "",
    accessor: "signed",
    Filter: BooleanFilter,
    Cell: BooleanCell,
  },
  {
    Header: "File",
    Footer: "",
    accessor: "file",
    disableFilters: true,
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <a href={value} target="_blank" rel="noreferrer">
        <RiFile2Fill />
      </a>
    ),
  },
  {
    Header: "Created by",
    Footer: "Created by",
    disableFilters: false,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    Filter: CreatedByFilter,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    filter: createdByFilterFunc,
    accessor: "createdBy.name",
  },
];

const CUSTOM_FIELDS = CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

const QUICK_ACTIONS = [
  {
    name: "Add new policy",
    href: "new",
    icon: <RiAddLine />,
  },
];

const InventoriesPage = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useGetAllCertificatesByTypeQuery({
    variables: {
      policyType: "PERMANENT_COLLECTION",
    },
  });
  const [
    signCertificate,
    { data: signCertificateData, error: signCertificateError, loading: signCertificateLoading },
  ] = useSignCertificateMutation();

  const [
    deleteCertificate,
    {
      data: deleteCertificateData,
      error: deleteCertificateError,
      loading: deleteCertificateLoading,
    },
  ] = useDeleteCertificateMutation();

  useEffect(() => {
    if (signCertificateData) {
      toast({
        title: t("Certificate successfully signed."),
        description: t("We've signed this certificate using your last uploaded signature."),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (signCertificateError)
      toast({
        title: t("Certificate couldn't be signed."),
        description: t(`Error: ${signCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [signCertificateData, signCertificateError]);

  useEffect(() => {
    if (deleteCertificateData) {
      toast({
        title: t("Certificate has been deleted."),
        description: t(""),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (deleteCertificateError)
      toast({
        title: t("Certificate couldn't be deleted."),
        description: t(`Error: ${deleteCertificateError.message}`),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
  }, [deleteCertificateData, deleteCertificateError]);

  if (loading) return <Skeleton w="100%" h="20vh" isLoaded={!loading} />;
  if (error) return <p>{error.toString()}</p>;
  if (!data || !data.allCertificates || data.allCertificates.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allCertificates: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });
  const onDelete = async (id: string) => {
    await deleteCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  const onSignCertificate = async ({
    row: { original },
  }: {
    row: { original: CertificateNode };
  }) => {
    if (!original.id) throw new Error("Missing id in row certificate.");
    const { id } = original;
    await signCertificate({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <Box>
      {/* <QuickActions actions={QUICK_ACTIONS} /> */}
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={columns}
          hiddenColumns={[
            "certificateType",
            "createdAt",
            "updatedAt",
            "language",
            "lender",
            "shipper",
          ]}
          data={data.allCertificates.edges.flatMap((e) => e?.node)}
          deleteAction={(id: string) => onDelete(id)}
          refetch={refetch}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          viewAction={(row: any) => window.open(row?.original?.file)}
          customActions={[
            {
              icon: <RiSignalTowerFill />,
              name: "Sign certificate",
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              onClick: (row: any) => onSignCertificate({ row }),
            },
          ]}
          isLoading={deleteCertificateLoading || loading}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default InventoriesPage;
