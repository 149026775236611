import { /* Box, */   Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
// import Page from "./Page";
import { useGetAssetByIdQuery } from '../../../graphql/queries/generated/queries';
// eslint-disable-next-line import/no-named-as-default
import EditAssetForm from "./EditAssetForm";


const AssetEditPage = () => {
    const { assetId }  = useParams();
    const { data, loading, error, refetch } = useGetAssetByIdQuery({
        variables: {
            id: assetId
        }
    })

    if (loading) return <Text>Loading...</Text>;
    if(!data) return null;
    // return <Box>
    //     <Text>{JSON.stringify( data , null, 4)}</Text>
    // </Box>
    return <EditAssetForm data={data} />;
}



export default AssetEditPage;
