import { Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface PolicyDocument {
  id: string;
  file?: string;
}

const PolicyDocumentsSummary = ({ policyDocuments }: { policyDocuments: PolicyDocument[] }) => {
  if (!policyDocuments || policyDocuments.length === 0)
    return (
      <Text>
        <Trans>No documents added</Trans>
      </Text>
    );
  return (
    <>
      {policyDocuments.map(({ file = "" }) => (
        <Text>{file}</Text>
      ))}
    </>
  );
};

export default PolicyDocumentsSummary;
