/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Button, HStack, VStack } from "@chakra-ui/react";
import { RegistryWithOfficeInput } from "components/form";
import { FormInputHook, MoneyInputHook } from "components/ui";
import POLICY_CONSTANTS from "constants/policy";
import { Trans } from "react-i18next";

interface PolicyAssetFieldsHookProps {
  remove?: (index: string | number) => void;
  index: number;
  getToggleRowExpandedProps: () => any;
}

export const policyAssetFields = {
  asset: "",
  catNat: false,
  coverTerrorism: false,
  coveredRiskType: "",
  coverType: "",
  evaluationType: "",
  exemption: false,
  title: "",
  author: "",
  creationPeriod: "",
};

const PCPolicyAssetFields = ({
  remove,
  index,
  getToggleRowExpandedProps,
}: PolicyAssetFieldsHookProps) => {
  const { onClick } = getToggleRowExpandedProps() || {};
  return (
    <VStack width="full" spacing="3" align="left" p="4">
      <HStack justify="space-between">
        <HStack justify="flex-start">
          <FormInputHook
            label="Policy covers natural disasters?"
            type="checkbox"
            name={`policyAssets.${index}.catNat`}
          />

          <FormInputHook
            label="Policy covers terrorism?"
            type="checkbox"
            name={`policyAssets.${index}.coverTerrorism`}
          />
          <FormInputHook
            label="Exemption"
            type="checkbox"
            name={`policyAssets.${index}.exemption`}
          />
        </HStack>
      </HStack>
      <HStack>
        <RegistryWithOfficeInput
          registryLabel="Museum/Collector"
          officeLabel="Location"
          registryInputName={`policyAssets.${index}.objectLocationEntityId`}
          officeInputName={`policyAssets.${index}.objectLocationOfficeId`}
        />
      </HStack>
      <HStack>
        <FormInputHook
          label="Cover Type"
          options={POLICY_CONSTANTS.COVER_TYPE}
          name={`policyAssets.${index}.coverType`}
        />
        <FormInputHook
          label="Covered Risk Type"
          options={POLICY_CONSTANTS.RISK_TYPE}
          name={`policyAssets.${index}.coveredRiskType`}
        />
        <FormInputHook
          label="Evaluation Type"
          options={POLICY_CONSTANTS.EVALUATION_TYPE}
          name={`policyAssets.${index}.evaluationType`}
        />
        <FormInputHook name={`policyAssets.${index}.asset`} label="asset" hidden disabled />
      </HStack>
      <HStack>
        <MoneyInputHook name={`policyAssets.${index}.insuredValue.amount`} label="Insured value" />
      </HStack>

      <HStack>
        <Button onClick={onClick}>
          <Trans>Close </Trans>
        </Button>
        <Button
          w="auto"
          variant="danger"
          onClick={() => {
            remove(index);
          }}
        >
          <Trans>Remove asset</Trans>
        </Button>
      </HStack>
    </VStack>
  );
};
export default PCPolicyAssetFields;
