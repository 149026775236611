import { FormFooter } from "components/ui";
import {
  PolicyNoteInput,
  PolicyNoteNode,
  useCreatePolicyNoteMutation,
  useUpdatePolicyNoteMutation,
} from "graphql/queries/generated/queries";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import PolicyNotesFields from "../PolicyNotesFields";

interface PolicyNoteFormProps {
  data: Partial<PolicyNoteNode>[];
}

const PolicyNotesForm = ({ data }: PolicyNoteFormProps) => {
  const [
    updatePolicyNote,
    { data: updatePolicyNoteData, loading: updatePolicyNoteLoading, error: updatePolicyNoteError },
  ] = useUpdatePolicyNoteMutation();
  const [
    createPolicyNote,
    { data: createPolicyNoteData, loading: createPolicyNoteLoading, error: createPolicyNoteError },
  ] = useCreatePolicyNoteMutation();

  const methods = useForm<PolicyNoteInput>({
    defaultValues: {
      ...data[0],
    },
  });

  const onSubmit: SubmitHandler<PolicyNoteInput> = (formData, e) => {
    console.log(formData);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PolicyNotesFields />
        <FormFooter
          data={[updatePolicyNoteData, createPolicyNoteData]}
          errors={[updatePolicyNoteError, createPolicyNoteError]}
          loading={updatePolicyNoteLoading && createPolicyNoteLoading}
          title="Policy note"
        />
      </form>
    </FormProvider>
  );
};

export default PolicyNotesForm;
