import { Box, Divider, Skeleton, useDisclosure } from "@chakra-ui/react";
import { FocusableElement } from "@chakra-ui/utils";
import { NoDataPlaceholder, QuickActions, Table } from "components/ui";
import { useGetAllRegistriesQuery, useDeleteRegistryMutation } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import { MutableRefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RiAddLine } from "react-icons/ri";

// const BATCH_ACTIONS = [
//   {
//     name: <Trans>Disable selected users</Trans>,
//     action: (selectedRows: UserNodeEdge) => console.log(selectedRows),
//   },
// ];
// const FILTERABLE_COLUMNS = ["isActive", "status"];
const QUICK_ACTIONS = [
  {
    name: "Add new registry entry",
    href: "new",
    icon: <RiAddLine />,
  },
];

const RegistriesPage = () => {
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useGetAllRegistriesQuery();
  const [ deleteRegistry, { loading: deleteLoading } ] = useDeleteRegistryMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef() as MutableRefObject<FocusableElement>;

  if (loading) return null;
  if (error) return <p>{error.toString()}</p>;

  if (!data || !data.allRegistries || data.allRegistries.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allRegistries: { edges },
  } = data;

  if (edges[0] === null || !edges[0].node) return null;

  const dataToGetColumns = edges[0].node;
  const columns = getColumns({
    data: dataToGetColumns,
    // customFields: CUSTOM_FIELDS,
    // customColumns: CUSTOM_COLUMNS,
    // filterableColumns: FILTERABLE_COLUMNS,
  });

  // const onDelete = (id: string) => console.log(id);  

  const onDelete = async (id: string) => {
    await deleteRegistry({
      variables: {
        input: {
          id,
        },
      },
    });

    await refetch();
  };

  return (
    <Box>
      <QuickActions actions={QUICK_ACTIONS} />
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={columns}
          data={data.allRegistries.edges.flatMap((edge) => edge?.node)}
          pathname="/o/registry/"
          deleteAction={(id: string) => onDelete(id)}
        />
      </Skeleton>
    </Box>
  );
};

export default RegistriesPage;
