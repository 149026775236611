import { Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";

interface PolicyNote {
  content?: string;
  id: string;
}

const PolicyNotesSummary = ({ policyNotes }: { policyNotes: PolicyNote[] }) => {
  if (!policyNotes || policyNotes.length === 0)
    return (
      <Text>
        <Trans>No notes added</Trans>
      </Text>
    );
  return (
    <>
      {policyNotes.map(({ content = "" }) => (
        <Text>{content}</Text>
      ))}
    </>
  );
};

export default PolicyNotesSummary;
