/* eslint-disable import/prefer-default-export */
import { Badge, Image } from "@chakra-ui/react";
import placeholderPNG from "assets/placeholder.png";
import { BooleanFilter, CreatedByFilter } from "components/ui";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { BooleanCell } from "helpers/getColumns";
import { Trans } from "react-i18next";

export const TE_CUSTOM_COLUMNS = [
  {
    Header: "Image",
    Footer: "",
    accessor: "image",
    Cell: ({ cell: { value } }: { cell: { value: string } }) =>
      value ? <img src={value} alt="" /> : <Image src={placeholderPNG} alt="" />,
    disableFilters: true,
  },
  {
    Header: "Category",
    Footer: "",
    accessor: "category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Dimensions",
    Footer: "",
    accessor: "genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,

    disableFilters: true,
  },
  {
    Header: "Technique",
    Footer: "",
    accessor: "technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Inventory #",
    Footer: "",
    accessor: "inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lender.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Covered Risk Type",
    Footer: "",
    accessor: "coveredRiskType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Cover Type",
    Footer: "",
    accessor: "coverType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),

    disableFilters: true,
  },
  {
    Header: "Evaluation Type",
    Footer: "",
    accessor: "evaluationType",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => (
      <Badge variant={value}>
        <Trans>{value}</Trans>
      </Badge>
    ),
    disableFilters: true,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lenderId",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Validated",
    Footer: "",
    accessor: "validated",
    Cell: BooleanCell,
    Filter: BooleanFilter,
  },
  {
    Header: "",
    Footer: "",
    accessor: "createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
];

export const TE_CUSTOM_FIELDS = TE_CUSTOM_COLUMNS.map(({ accessor }) => accessor.split(".")[0]);

export const TE_HIDDEN_FIELDS = [
  "pickupAddress",
  "lenderAddress",
  "deliveryAddress",
  "catNat",
  "coverTerrorism",
  "exemption",
  "objectLocationEntity",
  "objectLocationOffice",
  "shipper",
  "shipperId",
  "asset.createdBy",
];
