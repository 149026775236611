/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { HStack, VStack, Badge, Text, Button, Input, Checkbox } from "@chakra-ui/react";
import { RefinementListProps, useRefinementList } from "react-instantsearch-hooks-web";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const RefinementList = ({ limit, attribute }: RefinementListProps) => {
  const {
    items,
    hasExhaustiveItems,
    createURL,
    refine,
    sendEvent,
    searchForItems,
    isFromSearch,
    canRefine,
    canToggleShowMore,
    isShowingMore,
    toggleShowMore,
  } = useRefinementList({ limit, attribute });

  const [extended, setExtended] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="ais-RefinementList">
      <Input
        onChange={(e) => searchForItems(e?.target?.value)}
        variant="flushed"
        borderRadius="unset !important"
        placeholder={t("Start typing here...")}
        my="2"
      />
      <VStack align="flex-start">
        {items?.map((item, i) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            style={{ listStyle: "none" }}
            onClick={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
            onKeyDown={(event) => {
              event.preventDefault();
              refine(item.value);
            }}
            key={item.label}
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <HStack>
              <Checkbox isChecked={item.isRefined} />
              <Text
                variant="muted"
                fontWeight="400"
                color={item.isRefined ? "gray.800" : "gray.500"}
              >
                {item.label}
              </Text>
              <Badge borderRadius="xl">{item.count}</Badge>
            </HStack>
          </li>
        ))}
      </VStack>
      <Button
        onClick={() => {
          toggleShowMore();
        }}
        className="ais-RefinementList-showMore"
        size="xs"
        mt={2}
      >
        {isShowingMore ? t("Show less") : t("Show more")}
      </Button>
    </div>
  );
};

export default RefinementList;
