import { ApolloError } from "@apollo/client";
import { Text, Alert, AlertIcon, VStack, Button, HStack } from "@chakra-ui/react";
import { isArray } from "@chakra-ui/utils";
import { ReactElement } from "react";
import { Trans } from "react-i18next";

interface FormFooterProps {
  errors: ApolloError[];
  data: any | any[];
  loading: boolean;
  title: string;
  cancelAction?: () => void;
  children?: ReactElement;
  disabled?: boolean;
}

const FormFooter = ({
  errors,
  data,
  loading,
  title,
  cancelAction,
  disabled = false,
  children,
}: FormFooterProps) => (
  <VStack align="flex-start" w="100%" my={8}>
    {errors &&
      errors.filter(Boolean).map((error) => (
        <Alert status="error" my={4}>
          <>
            <AlertIcon />
            {error.message}
          </>
        </Alert>
      ))}
    {isArray(data) && data.filter(Boolean).length > 0 && (
      <Alert status="success" my={4}>
        <>
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="{{title}} was created successfully" values={{ title }} />
            </Text>
          </VStack>
        </>
      </Alert>
    )}
    {data && !isArray(data) && (
      <Alert status="success" my={4}>
        <>
          <AlertIcon />
          <VStack alignItems="flex-start">
            <Text>
              <Trans defaults="{{title}} was created successfully" values={{ title }} />
            </Text>
          </VStack>
        </>
      </Alert>
    )}

    {children ?? (
      <HStack my={8}>
        <Button
          isLoading={loading}
          loadingText="Loading"
          type="submit"
          variant="primary"
          disabled={disabled}
        >
          <Trans>Save</Trans>
        </Button>
        {cancelAction && (
          <Button onClick={cancelAction} variant="danger">
            <Trans>Cancel</Trans>
          </Button>
        )}
      </HStack>
    )}
  </VStack>
);
export default FormFooter;
