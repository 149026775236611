/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// import { useMutation } from '@apollo/client';
import { BaseSyntheticEvent } from "react";
// import { Button } from "@chakra-ui/react";
// import { CreateAsset, CreateAssetVariables } from "graphql/queries/types/CreateAsset";
// import { UpdateAsset, UpdateAssetVariables } from 'graphql/queries/types/UpdateAsset';

import {
  AssetInput,
  CreateAssetMutation,
  UpdateAssetMutation,
} from "graphql/queries/generated/queries";

//  import { CREATE_ASSET, UPDATE_ASSET } from '../../../graphql/queries/assets.gql';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormSetError,
} from "react-hook-form";

import { ApolloError } from "@apollo/client";
import GeneralInfoPanelNewCategoryForm from "./info/GeneralInfoPanelNewCategoryForm";
import DimensionsPanelNewCategoryForm from "./info/DimensionsPanelNewCategoryForm";
import LegalAspectsPanelNewCategoryForm from "./info/LegalAspectsNewCategoryForm";
import TechnicalDetailsNewCategoryForm from "./info/TechnicalDetailsNewCategoryForm";
import PhysicalFeaturesNewCategoryForm from './info/PhysicalFeaturesNewCategoryForm';
import SettingNewCategoryForm from './info/SettingNewCategoryForm';
import EnvironmentalConditionsNewCategoryForm from './info/EnvironmentalConditionsNewCategoryForm';
import NotesNewCategoryForm from './info/NotesNewCategoryForm';


interface PanelNewCategoryFormProps {
  category: string;
  panel: string;
  steps: {
    key: string;
    label: string;
  }[];
  nextStep: () => void;
  prevStep: () => void;
  activeStep: number;
  children?: React.ReactNode;
  id: string;

  handleSubmit: (e?: BaseSyntheticEvent<object, any, any>) => Promise<void>;
  register: UseFormRegister<AssetInput>;
  getValues: UseFormGetValues<AssetInput>;
  watch: any; // UseFormWatch<AssetInput>,
  setValue: UseFormSetValue<AssetInput>;
  setError: UseFormSetError<AssetInput>;

  createAssetData: CreateAssetMutation | null | undefined;
  updateAssetData: UpdateAssetMutation | null | undefined;
  createAssetError: ApolloError | undefined;
  updateAssetError: ApolloError | undefined;
  createAssetLoading: boolean | undefined;
  updateAssetLoading: boolean | undefined;
}

const PanelNewCategoryForm = ({
  category,
  panel,
  steps,
  nextStep,
  prevStep,
  activeStep,
  children,
  id,

  handleSubmit,
  register,
  getValues,
  watch,
  setValue,
  setError,

  createAssetData,
  updateAssetData,
  createAssetError,
  updateAssetError,
  createAssetLoading,
  updateAssetLoading,
}: PanelNewCategoryFormProps) => {
  let formPart = null;
  switch (panel) {
    case "info_generali":
      formPart = (
        <GeneralInfoPanelNewCategoryForm
          // handleSubmit={formik.handleSubmit}
          onSubmit={handleSubmit}
          getValues={getValues}
          register={register}
          setValue={setValue}
          setError={setError}
          watch={watch}
          // handleChange={formik.handleChange}
          // handleRadioButtons={handleRadioButtons}
          // values={formik.values}
          createAssetData={createAssetData}
          updateAssetData={updateAssetData}
          createAssetError={createAssetError}
          updateAssetError={updateAssetError}
          createAssetLoading={createAssetLoading}
          updateAssetLoading={updateAssetLoading}
          category={category}
          id={id}
        >
          {children}
          {/* {id && (
            <Button
              isDisabled={activeStep === steps.length-1}
              mr={4}
              onClick={nextStep}
              variant="ghost"
            >
              Next
            </Button>
          )} */}
        </GeneralInfoPanelNewCategoryForm>
      );
      break;

    case "dimensioni":
      formPart = (
        <DimensionsPanelNewCategoryForm
          onSubmit={handleSubmit}
          getValues={getValues}
          register={register}
          setValue={setValue}
          setError={setError}
          watch={watch}
          createAssetData={createAssetData}
          updateAssetData={updateAssetData}
          createAssetError={createAssetError}
          updateAssetError={updateAssetError}
          createAssetLoading={createAssetLoading}
          updateAssetLoading={updateAssetLoading}
          category={category}
        >
          {children}
          {/* {id && (
            <Button
              isDisabled={activeStep === steps.length-1}
              mr={4}
              onClick={nextStep}
              variant="ghost"
            >
              Next
            </Button>
          )}
          <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
            Prev
          </Button> */}
        </DimensionsPanelNewCategoryForm>
      );
      break;
    case "aspetti_legali":
      formPart = (
        <LegalAspectsPanelNewCategoryForm
          onSubmit={handleSubmit}
          getValues={getValues}
          register={register}
          setValue={setValue}
          setError={setError}
          watch={watch}
          createAssetData={createAssetData}
          updateAssetData={updateAssetData}
          createAssetError={createAssetError}
          updateAssetError={updateAssetError}
          createAssetLoading={createAssetLoading}
          updateAssetLoading={updateAssetLoading}
          category={category}
        >
          {children}
          {/* {id && (
            <Button
              isDisabled={activeStep === steps.length-1}
              mr={4}
              onClick={nextStep}
              variant="ghost"
            >
              Next
            </Button>
          )}
          <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
            Prev
          </Button> */}
        </LegalAspectsPanelNewCategoryForm>
      );
      break;
    case "dettagli_tecnici":
      formPart = (
        <TechnicalDetailsNewCategoryForm
          onSubmit={handleSubmit}
          getValues={getValues}
          register={register}
          setValue={setValue}
          setError={setError}
          watch={watch}
          createAssetData={createAssetData}
          updateAssetData={updateAssetData}
          createAssetError={createAssetError}
          updateAssetError={updateAssetError}
          createAssetLoading={createAssetLoading}
          updateAssetLoading={updateAssetLoading}
          category={category}
        >
          {children}
          {/* {id && (
            <Button
              isDisabled={activeStep === steps.length-1}
              mr={4}
              onClick={nextStep}
              variant="ghost"
            >
              Next
            </Button>
          )}
          <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
            Prev
          </Button> */}
        </TechnicalDetailsNewCategoryForm>
      );
      break;
    case "caratteristiche_fisiche":
        formPart = (
          <PhysicalFeaturesNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            assetId={id}
            createAssetData={createAssetData}
            updateAssetData={updateAssetData}
            createAssetError={createAssetError}
            updateAssetError={updateAssetError}
            createAssetLoading={createAssetLoading}
            updateAssetLoading={updateAssetLoading}
            category={category}
          >
            {children}
            {/* {id && (
              <Button
                isDisabled={activeStep === steps.length-1}
                mr={4}
                onClick={nextStep}
                variant="ghost"
              >
                Next
              </Button>
            )}
            <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
              Prev
            </Button> */}
          </PhysicalFeaturesNewCategoryForm>
        );
        break;
      case "allestimento":
        formPart = (
          <SettingNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            createAssetData={createAssetData}
            updateAssetData={updateAssetData}
            createAssetError={createAssetError}
            updateAssetError={updateAssetError}
            createAssetLoading={createAssetLoading}
            updateAssetLoading={updateAssetLoading}
            category={category}
          >
            {children}
            {/* {id && (
              <Button
                isDisabled={activeStep === steps.length-1}
                mr={4}
                onClick={nextStep}
                variant="ghost"
              >
                Next
              </Button>
            )}
            <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
              Prev
            </Button> */}
          </SettingNewCategoryForm>
        );
        break;
      case "condizioni_ambientali":
        formPart = (
          <EnvironmentalConditionsNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            createAssetData={createAssetData}
            updateAssetData={updateAssetData}
            createAssetError={createAssetError}
            updateAssetError={updateAssetError}
            createAssetLoading={createAssetLoading}
            updateAssetLoading={updateAssetLoading}
            category={category}
          >
            {children}
            {/* {id && (
              <Button
                isDisabled={activeStep === steps.length-1}
                mr={4}
                onClick={nextStep}
                variant="ghost"
              >
                Next
              </Button>
            )}
            <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
              Prev
            </Button> */}
          </EnvironmentalConditionsNewCategoryForm>
        );
        break;
      case "note":
        formPart = (
          <NotesNewCategoryForm
            onSubmit={handleSubmit}
            getValues={getValues}
            register={register}
            setValue={setValue}
            setError={setError}
            watch={watch}
            createAssetData={createAssetData}
            updateAssetData={updateAssetData}
            createAssetError={createAssetError}
            updateAssetError={updateAssetError}
            createAssetLoading={createAssetLoading}
            updateAssetLoading={updateAssetLoading}
            category={category}
          >
            {children}
            {/* {id && (
              <Button
                isDisabled={activeStep === steps.length-1}
                mr={4}
                onClick={nextStep}
                variant="ghost"
              >
                Next
              </Button>
            )}
            <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
              Prev
            </Button> */}
          </NotesNewCategoryForm>
        );
        break;
    default:
      formPart = (
        <div>
          {children}
          {/* {id && (
            <Button
              isDisabled={activeStep === steps.length-1}
              mr={4}
              onClick={nextStep}
              variant="ghost"
            >
              Next
            </Button>
          )}
          <Button isDisabled={activeStep === 0} mr={4} onClick={prevStep} variant="ghost">
            Prev
          </Button> */}
        </div>
      );
  }

  return formPart;
  // <FormProvider {...methods}>
  //     { <form id="info_generali"
  //             // eslint-disable-next-line @typescript-eslint/no-misused-promises
  //             onSubmit={handleSubmit}> }
  //     {formPart}
  //     { </form> }
  // {</FormProvider>}
};

export default PanelNewCategoryForm;
