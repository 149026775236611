export default {
  primary: "#E72B78",
  lightPrimary: "#F9E9EC",
  secondary: "#8390FA",
  lightSecondary: "#D8DCFD",
  green: "#77BC10",
  lightGreen: "#E6FAC7",
  red: "#A20021",
  lightRed: "#FA9EB0",
  orange: "#F3752B",
  lightOrange: "#FBD9C5",
  yellow: "#FFCC00",
  lightYellow: "#FFFBEB",
  emerald: "#014751",
  lightEmerald: "#C3F5FD",
};
