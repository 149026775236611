import { Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Table } from "components/ui";
import { PolicyAssetInput } from "graphql/queries/generated/queries";
import getColumns from "helpers/getColumns";
import {
  FormProvider,
  SubmitHandler,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useForm,
} from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { PolicyAssetInputExtended, PolicyInputExtended, PolicyType } from "../../../types";
import PCPolicyAssetFields from "../../../pc-policies/PCPolicyAssetFields";
import TEPolicyAssetFields from "../../../te-policies/TEPolicyAssetFields";
import { CUSTOM_COLUMNS } from "./customColumns";
import getHiddenColumns from "./getHiddenColumns";
import BatchEditPolicyAssetModal from "../BatchEditPolicyAssetModal";

interface PolicyAssetEditableTableProps {
  data: PolicyAssetInputExtended[];
  mode?: "view" | "edit";
  remove?: UseFieldArrayRemove | ((arg0: number) => void);
  update?: UseFieldArrayUpdate<PolicyInputExtended, "policyAssets"> | ((arg0: number) => void);
  type: PolicyType;
}

interface BatchFields {
  shipperId?: string;
  lenderId?: string;
  lenderAddress: {
    raw: string;
  };
  pickupAddress: {
    raw: string;
  };
  deliveryAddress: {
    raw: string;
  };
  objectLocationEntityId: string;
  objectLocationOfficeId: string;
}

const defaultValues: BatchFields = {
  shipperId: null,
  lenderId: null,
  lenderAddress: {
    raw: null,
  },
  pickupAddress: {
    raw: null,
  },
  deliveryAddress: {
    raw: null,
  },
  objectLocationEntityId: null,
  objectLocationOfficeId: null,
};

const OnCreationPolicyAssetsTable = ({
  data,
  remove,
  type,
  mode = "edit",
  update,
}: PolicyAssetEditableTableProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batchValues, setBatchValues] = useState<Partial<BatchFields>>(null);
  const [batchRowsToUpdate, setBatchRowsToUpdate] = useState<
    { id: string; original: PolicyAssetInputExtended }[]
  >([]);
  const methods = useForm<Partial<BatchFields>>({
    shouldUseNativeValidation: true,
    resolver: null,
    defaultValues,
  });

  if (!data || data?.length === 0)
    return (
      <Text>
        <Trans>No assets has been added yet.</Trans>
      </Text>
    );

  const columns = getColumns<PolicyAssetInput>({
    data: data[0],
    customColumns: CUSTOM_COLUMNS,
    customFields: [
      "title",
      "author",
      "creationPeriod",
      "coveredRiskType",
      "evaluationType",
      "coverType",
      "image",
      "lender",
      "lenderId",
      "shipperId",
      "shipper",
      "lenderAddress",
      "deliveryAddress",
      "pickupAddress",
      "deliveryAddress.raw",
      "pickupAddress.raw",
      "lenderAddress.raw",
      "insuredValue",
    ],
  });

  const onSubmit: SubmitHandler<BatchFields> = (formData, e) => {
    const cleanedBatchValues: Partial<BatchFields> = Object.fromEntries(
      Object.entries(formData).filter(
        ([_, v]) =>
          (v !== null && v !== undefined && v !== "") ||
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          (v?.raw &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== "" &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== null &&
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v.raw !== undefined)
      )
    );

    setBatchValues(cleanedBatchValues);
    batchRowsToUpdate.forEach((row) => {
      update(Number(row.id), {
        ...row.original,
        ...cleanedBatchValues,
      });
    });
    toast({
      title: (
        <Trans
          defaults="{{count}} assets were updated successfully"
          values={{ count: batchRowsToUpdate.length }}
        />
      ),
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    methods.reset();
    setBatchRowsToUpdate([]);
    onClose();
  };

  const handleBatchUpdate = (rows: { id: string; original: PolicyAssetInputExtended }[]) => {
    if (rows.length === 0) {
      toast({
        title: <Trans>You must select at least one asset to use batch update.</Trans>,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    onOpen();
    setBatchRowsToUpdate(rows);
  };

  const BATCH_ACTIONS = [
    {
      name: <Trans>Update selected assets</Trans>,
      action: handleBatchUpdate,
    },
  ];

  return (
    <>
      <Table
        batchActions={BATCH_ACTIONS}
        hiddenColumns={getHiddenColumns(type)}
        columns={columns}
        data={data}
        deleteAction={(id: number) => {
          remove(id);
        }}
        // TODO: this is a shitshow, need to refactor
        renderRowSubComponent={
          type === "PERMANENT_COLLECTION" ? PCPolicyAssetFields : TEPolicyAssetFields
        }
      />
      <FormProvider {...methods}>
        <BatchEditPolicyAssetModal
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={methods.handleSubmit(onSubmit)}
          type={type}
          // setBatchValues={setBatchValues}
        />
      </FormProvider>
    </>
  );
};

export default OnCreationPolicyAssetsTable;
