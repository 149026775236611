import { Route } from "use-react-router-breadcrumbs";
import Layout from "layouts/Layout";
import RequireAuth from "middleware/RequireAuth";
import {
  NotFoundInternalPage,
  OnboardPage,
  LoginPage,
  NotFoundPage,
  RecoverPasswordPage,
  UsersEditPage,
  UsersInvitePage,
  UsersNewPage,
  UsersPage,
  UsersViewPage,
  PCPoliciesEditPage,
  PCPoliciesNewPage,
  PCPoliciesPage,
  PCPoliciesViewPage,
  TEPoliciesEditPage,
  TEPoliciesNewPage,
  TEPoliciesPage,
  TEPoliciesViewPage,
  AssetsPage,
  SpinIndexPage,
} from "pages";
import RegistriesPage from "pages/registry";
import RegistryViewPage from "pages/registry/view";
import EditRegistryPage from "pages/registry/edit";
import NewRegistryPage from "pages/registry/new";
import CertificatesPage from "pages/spin/certificates";
import InventoriesPage from "pages/spin/inventories";
import PcPoliciesObjectsPage from "pages/spin/pc-policies-objects";
import TePoliciesObjectsPage from "pages/spin/te-policies-objects";

import { Routes, useLocation, useRoutes, useResolvedPath, NavLink, Outlet } from "react-router-dom";
import { BCrumb } from "components/ui";
import AssetsNewPage from "../pages/assets/new/index";
import AssetEditPage from "../pages/assets/edit/index"

function ProtectedPage() {
  return <h3>Protected</h3>;
}

interface RoutingProps {
  setCurrentLocale?: (locale: string) => void;
}

function BreadcrumbsItem({ text }: { text: string }) {
  const location = useLocation();
  const resolvedLocation = useResolvedPath("");
  const isActive = location.pathname === resolvedLocation.pathname;

  return (
    <>
      {isActive ? <span>{text}</span> : <NavLink to={resolvedLocation.pathname}>{text}</NavLink>}
      <Outlet />
    </>
  );
}

const createRoutes = ({ createBreadcrumbs = false }: { createBreadcrumbs?: boolean }) => [
  {
    path: "/mgmt/assets",
    element: createBreadcrumbs ? <BCrumb title="Temporary Exhibition Policies" /> : <AssetsPage />,
    breadcrumb: "Assets",
  },
  {
    path: "/mgmt/assets/new",
    element: createBreadcrumbs ? <BCrumb title="Create a new asset" /> : <AssetsNewPage />,
  },
  {
    path: "/mgmt/assets/edit/:assetId",
    element: createBreadcrumbs ? <BCrumb title="Edit an Asset entry" /> : <AssetEditPage />,
  },

  {
    path: "/mgmt/collections",
    element: createBreadcrumbs ? <BCrumb title="Collections" /> : <ProtectedPage />,
  },
  {
    path: "/mgmt/exhibitions",
    element: createBreadcrumbs ? <BCrumb title="All exhibitions" /> : <ProtectedPage />,
  },
  {
    path: "/mgmt/logistic",
    element: createBreadcrumbs ? <BCrumb title="All logistics" /> : <ProtectedPage />,
  },
  {
    path: "/mgmt/insurance",
    element: createBreadcrumbs ? <BCrumb title="All insurance" /> : <ProtectedPage />,
  },
  {
    path: "/mgmt/appraisal",
    element: createBreadcrumbs ? <BCrumb title="All appraisal" /> : <ProtectedPage />,
  },
  { path: "/mgmt/cr-sr", element: <ProtectedPage /> },
  {
    path: "/mgmt/quotation",
    element: createBreadcrumbs ? <BCrumb title="All quotation" /> : <ProtectedPage />,
  },
  {
    path: "/mgmt/docs",
    element: createBreadcrumbs ? <BCrumb title="All docs" /> : <ProtectedPage />,
  },
  {
    path: "/spin",
    element: createBreadcrumbs ? <BCrumb title="All Insurances" /> : <SpinIndexPage />,
    breadcrumb: "Insurance",
  },
  {
    path: "/spin/te-policies",
    element: createBreadcrumbs ? (
      <BCrumb title="Temporary Exhibition Policies" />
    ) : (
      <TEPoliciesPage />
    ),
  },
  {
    path: "/spin/te-policies/edit/:policyId",
    element: createBreadcrumbs ? (
      <BCrumb title="Edit Temporary Exhibition Policy" />
    ) : (
      <TEPoliciesEditPage />
    ),
  },
  {
    path: "/spin/te-policies/new",
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Temporary Exhibition Policy" />
    ) : (
      <TEPoliciesNewPage />
    ),
  },
  {
    path: "/spin/te-policies/:policyId",
    element: createBreadcrumbs ? null : ( // <BCrumb title="View Temporary Exhibition Policy" />
      <TEPoliciesViewPage />
    ),
  },

  {
    path: "/spin/certificates",
    element: createBreadcrumbs ? <BCrumb title="All Certificates" /> : <CertificatesPage />,
  },

  {
    path: "/spin/pc-policies",
    element: createBreadcrumbs ? (
      <BCrumb title="All Permanent Collection Policies" />
    ) : (
      <PCPoliciesPage />
    ),
  },
  {
    path: "/spin/pc-policies/edit/:policyId",
    element: createBreadcrumbs ? (
      <BCrumb title="Edit Permanent Collection Policy" />
    ) : (
      <PCPoliciesEditPage />
    ),
  },
  {
    path: "/spin/pc-policies/new",
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Permanent Collection Policy" />
    ) : (
      <PCPoliciesNewPage />
    ),
  },
  {
    path: "/spin/pc-policies/:policyId",
    element: createBreadcrumbs ? // <BCrumb title="View Permanent Collection Policy" />
    null : (
      <PCPoliciesViewPage />
    ),
  },

  {
    path: "/spin/inventories",
    element: createBreadcrumbs ? <BCrumb title="All Inventories" /> : <InventoriesPage />,
  },

  {
    path: "/spin/te-policies-objects",
    element: createBreadcrumbs ? (
      <BCrumb title="All Temporary Exhibition Assets" />
    ) : (
      <TePoliciesObjectsPage />
    ),
  },
  {
    path: "/spin/pc-policies-objects",
    element: createBreadcrumbs ? (
      <BCrumb title="All Permanent Collection Assets" />
    ) : (
      <PcPoliciesObjectsPage />
    ),
  },

  {
    path: "/o/analysis",
    element: createBreadcrumbs ? <BCrumb title="Analysis" /> : <ProtectedPage />,
  },
  {
    path: "/o/registry",
    element: createBreadcrumbs ? <BCrumb title="All Registries" /> : <RegistriesPage />,
  },
  {
    path: "/o/registry/:registryId",
    element: createBreadcrumbs ? <BCrumb title="View Registry" /> : <RegistryViewPage />,
  },
  {
    path: "/o/registry/new",
    element: createBreadcrumbs ? (
      <BCrumb title="Create a new Registry entry" />
    ) : (
      <NewRegistryPage />
    ),
  },
  {
    path: "/o/registry/edit/:registryId",
    element: createBreadcrumbs ? <BCrumb title="Edit a Registry entry" /> : <EditRegistryPage />,
  },

  {
    path: "/o/users",
    element: createBreadcrumbs ? <BCrumb title="All users" /> : <UsersPage />,
  },
  {
    path: "/o/users/:userId",
    element: createBreadcrumbs ? <BCrumb title="View User" /> : <UsersViewPage />,
  },
  {
    path: "/o/users/edit/:userId",
    element: createBreadcrumbs ? <BCrumb title="Edit user" /> : <UsersEditPage />,
  },
  {
    path: "/o/users/invite",
    element: createBreadcrumbs ? <BCrumb title="Invite user" /> : <UsersInvitePage />,
  },
  {
    path: "/o/users/new",
    element: createBreadcrumbs ? <BCrumb title="Create a new user" /> : <UsersNewPage />,
  },

  { path: "*", element: <NotFoundInternalPage /> },
];

export const appRouteObjects = createRoutes({ createBreadcrumbs: false });
export const breadcrumbRouteObjects = createRoutes({ createBreadcrumbs: true });

const Routing = ({ setCurrentLocale }: RoutingProps) => {
  const protectedRoutesGenerated = useRoutes(appRouteObjects);
  const breadcrumbsGenerated = useRoutes(breadcrumbRouteObjects);
  if (!protectedRoutesGenerated) return null;

  return (
    <Routes>
      <Route path="/login" element={<LoginPage onLocaleChange={setCurrentLocale} />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route path="/recover-password/:token" element={<RecoverPasswordPage />} />
      <Route path="/onboard/:token" element={<OnboardPage />} />
      <Route element={<Layout onLocaleChange={setCurrentLocale} />} breadcrumb="Home">
        <Route
          path="/*"
          element={
            <RequireAuth>
              <>
                {breadcrumbsGenerated}
                {protectedRoutesGenerated}
              </>
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routing;
