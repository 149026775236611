/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Options, AsyncCreatableSelect as CustomSelect } from "chakra-react-select";
import { Box, FormHelperText, FormLabel, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import {
  useGetRegistriesBySearchLazyQuery,
  useCreateRegistryMutation,
  useGetRegistryByIdLazyQuery,
} from "graphql/queries/generated/queries";
import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { Wrapper } from "./Wrapper";
import { FormInputProps } from "./type";

export interface RegistryOption {
  label: string;
  value: string;
}

const Registry = ({
  name,
  label,
  type,
  errors,
  placeholder,
  disabled = false,
  register,
  watch,
  control,
  required,
}: FormInputProps) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [lastOptions, setLastOptions] = useState([]);
  const [getRegistryBySearch] = useGetRegistriesBySearchLazyQuery();
  const [createRegistry, { data, error, loading }] = useCreateRegistryMutation();
  const [getRegistryByIdLazy] = useGetRegistryByIdLazyQuery();
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: { required: false },
    defaultValue: "",
  });

  useEffect(() => {
    const asyncGetRegistryByIdLazy = async () => {
      const { data } = await getRegistryByIdLazy({
        variables: {
          id: value,
        },
      });

      if (data.registry) {
        const {
          registry: { fullName },
        } = data;
        setSelectedValue({ label: fullName, value });
      }
    };

    if (value && !isTouched) {
      asyncGetRegistryByIdLazy().catch((e) => console.error(e));
    }
  }, [value, isTouched]);

  const loadOptions = async ({
    inputValue,
    isTouched,
    value,
  }: {
    inputValue: string;
    isTouched: boolean;
    value: string | RegistryOption[];
  }) => {
    if (inputValue.length < 3) return [];

    const { data } = await getRegistryBySearch({
      variables: {
        search: inputValue,
      },
    });

    if (!data.registries || data.registries.length === 0) return [];
    const options = data.registries.map(({ id, fullName }) => ({
      label: fullName,
      value: id,
    }));

    setLastOptions(options);
    return options;
  };

  const handleCreateOption = async (inputValue: string) => {
    const { data } = await createRegistry({
      variables: {
        input: {
          registryData: {
            fullName: inputValue,
            isCompany: false,
          },
        },
      },
    });

    if (data) {
      const newValue = {
        value: data.createRegistry.registry.id,
        label: data.createRegistry.registry.fullName,
      };
      setSelectedValue(newValue);
      onChange(data.createRegistry.registry.id);
    }
  };

  const isOptionSelected = (option: any, values: Options<any>) =>
    values.some(({ value }) => option.value === value);

  return (
    <Wrapper name={name} error={errors[name]}>
      <FormLabel htmlFor={name} mb="1" fontSize="xs" display="inline-flex">
        <Text>
          <Trans>{label}</Trans>
        </Text>
        {required && (
          <Text px="1" color="red.500">
            <Trans>(required)</Trans>
          </Text>
        )}
      </FormLabel>
      <Box minW="12rem">
        <CustomSelect
          cacheOptions
          isLoading={loading}
          isOptionSelected={isOptionSelected}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onCreateOption={handleCreateOption}
          loadOptions={(inputValue) => loadOptions({ isTouched, inputValue, value })}
          size="sm"
          // eslint-disable-next-line @typescript-eslint/no-shadow
          useBasicStyles
          value={selectedValue}
          // value={value}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={(newValue) => onChange(newValue.value)}
        />

        <FormHelperText fontSize="xs">
          {!data && !error && <Trans>Start typing to search (min 3 characters).</Trans>}
          {data && (
            <Text color="green.500">
              <Trans>Registry entry created successfully!</Trans>
            </Text>
          )}
          {error && (
            <Text color="red.500">
              <Trans>Ops! Something went wrong creating a registry entry!</Trans>
              <Trans>Reason: {error.message}</Trans>
            </Text>
          )}
        </FormHelperText>
      </Box>
    </Wrapper>
  );
};

export default Registry;
