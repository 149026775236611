import { useTranslation } from "react-i18next";
import { Box, Divider, Skeleton } from "@chakra-ui/react";
import {
  BooleanFilter,
  CreatedByFilter,
  NoDataPlaceholder,
  QuickActions,
  Table,
} from "components/ui";
import { RiAddLine, RiEye2Fill } from "react-icons/ri";
import getColumns from "helpers/getColumns";
import { useGetAllPolicyAssetsByPolicyTypeQuery } from "graphql/queries/generated/queries";
import { createdByFilterFunc } from "components/ui/CreatedByFilter";
import { useNavigate } from "react-router-dom";

const CUSTOM_FIELDS = [
  "asset",
  "asset.title",
  "asset.author",
  "asset.creationPeriod",
  "coveredRiskType",
  "evaluationType",
  "coverType",
  "image",
  "lenderAddress",
  "deliveryAddress",
  "pickupAddress",
  "policy",
  "policy.offerCode",
  "policy.policyCode",
  "insuredValue",
];
const CUSTOM_COLUMNS = [
  {
    Header: "Category",
    Footer: "",
    accessor: "asset.category",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 101,
  },
  {
    Header: "Title",
    Footer: "",
    accessor: "asset.title",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 99,
  },
  {
    Header: "Author",
    Footer: "",
    accessor: "asset.author",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 100,
  },
  {
    Header: "Creation Period",
    Footer: "",
    accessor: "asset.creationPeriod",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Dimensions",
    Footer: "",
    accessor: "asset.genericDimensions",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Technique",
    Footer: "",
    accessor: "asset.technique",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Inventory #",
    Footer: "",
    accessor: "asset.inventoryNumber",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
    weight: 98,
  },
  {
    Header: "Lender",
    Footer: "",
    accessor: "lender.fullName",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
    disableFilters: true,
  },
  {
    Header: "Sum insured",
    Footer: "Sum insured",
    disableFilters: true,
    accessor: "insuredValue.amount",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => value,
  },
  {
    Header: "",
    Footer: "",
    accessor: "asset.validated",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    Filter: BooleanFilter,
    weight: 0,
  },
  {
    Header: "",
    Footer: "",
    accessor: "asset.createdBy.name",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => <span />,
    Filter: CreatedByFilter,
    filter: createdByFilterFunc,
    weight: 0,
  },
];

const QUICK_ACTIONS = [
  {
    name: "Add new TE policy",
    href: "/spin/te-policies/new",
    icon: <RiAddLine />,
  },
];

const TePoliciesObjectsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetAllPolicyAssetsByPolicyTypeQuery({
    variables: {
      policy_PolicyType: "TEMPORARY_EXHIBITION",
    },
  });

  if (loading) return <Skeleton isLoaded={!loading} />;
  if (error) return <p>{error.toString()}</p>;
  if (!data || !data.allPolicyAssets || data.allPolicyAssets.edges.length === 0)
    return (
      <Box>
        <QuickActions actions={QUICK_ACTIONS} />
        <Divider borderColor="gray.100" />
        <NoDataPlaceholder />
      </Box>
    );

  const {
    allPolicyAssets: {
      edges: [edge],
    },
  } = data;

  if (edge === null || !edge.node) return null;

  const dataToGetColumns = edge.node;
  const columns = getColumns({
    data: dataToGetColumns,
    customFields: CUSTOM_FIELDS,
    customColumns: CUSTOM_COLUMNS,
  });

  const onDelete = async (id: string) => {
    // await toggleIsActiveUser({
    //   variables: {
    //     input: {
    //       id,
    //     },
    //   },
    // });
    // await refetch();
  };

  return (
    <Box>
      {/* <QuickActions actions={QUICK_ACTIONS} /> */}
      <Divider borderColor="gray.100" />
      <Skeleton isLoaded={!loading}>
        <Table
          //   batchActions={BATCH_ACTIONS}
          columns={columns}
          hiddenColumns={[
            "lender",
            "lenderAddress",
            "delivery",
            "deliveryAddress",
            "pickupAddress",
            "shipper",
            "catNat",
            "terrorism",
          ]}
          data={data.allPolicyAssets.edges.flatMap((e) => e?.node)}
          deleteAction={(id: string) => onDelete(id)}
          refetch={refetch}
          customActions={[
            {
              icon: <RiEye2Fill />,
              name: "View policy",
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              onClick: (row: { original: { policy: { id: string } } }) => {
                const {
                  original: { policy },
                } = row;
                navigate(`/spin/te-policies/${policy?.id}`);
              },
            },
          ]}
          //   deleteActionLoading={toggleIsActiveUserLoading}
        />
      </Skeleton>
    </Box>
  );
};

export default TePoliciesObjectsPage;
